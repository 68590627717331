import { Button, Col, Layout, Result, Row, Space, Typography } from "antd";
import moment from "moment";
import { groupBy } from "ramda";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredEvents,
  getConfiguration,
  listEvents,
  selectedEvent,
  selectEvent,
} from "../../../services/redux/services/FacilitiesWS";
import { TitleSource } from "../../Common/Teleporters/Title";
import styles from "./DashboardFacilities.module.less";
import EventModal from "./EventModal/EventModal";

import { ReactComponent as UndrawNoData } from "../../../assets/svg/undraw_no_data.svg";
import EventItem from "./EventItem";

const FacilitiesResident = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentDate = useSelector((state) => state.facilitiesWS.date);

  const events = useSelector(filteredEvents);
  const event = useSelector(selectedEvent);

  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listEvents({ resident: true }));
  }, [currentDate, dispatch]);

  const grouped = Object.entries(
    groupBy((i) => moment(i.slot.start).format("YYYY-MM-DD"), events),
  ).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Layout className={styles.dashboardContainer}>
      <Layout>
        <TitleSource>
          <Typography.Title level={1} style={{ margin: "0" }}>
            {t("facilities", { ns: "csv" })}
          </Typography.Title>
        </TitleSource>

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Paragraph type="secondary">
              {t("facilities.resident.helper")}
            </Typography.Paragraph>
          </Col>
          {grouped.length ? (
            grouped.map(([date, events]) => (
              <Col span={24}>
                <Typography.Title level={3}>{moment(date).format("LL")}</Typography.Title>
                <div
                  style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", gap: 20 }}
                >
                  {events.map((e) => (
                    <EventItem resident event={e} />
                  ))}
                </div>
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Result
                icon={<UndrawNoData style={{ height: 200 }} />}
                title={t("facilities.resident.noEvent.title")}
                subTitle={t("facilities.resident.noEvent.subtitle")}
                extra={[
                  <Space>
                    <Button
                      key="refresh"
                      type="primary"
                      size="middle"
                      onClick={() => dispatch(listEvents({ resident: true }))}
                    >
                      {t("Refresh")}
                    </Button>
                  </Space>,
                ]}
              />
            </Col>
          )}
        </Row>
      </Layout>

      <EventModal event={event} visible={!!event} onClose={() => dispatch(selectEvent(null))} />
    </Layout>
  );
};

export default FacilitiesResident;
