import { isEmpty } from "ramda";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const FNO = (event, params = null) =>
  new Promise((resolve, reject) => {
    const { FnoMapManager } = window;
    FnoMapManager.sendEvent(event, params, (data, err) => {
      if (err) {
        console.error(err);
        reject(err);
      } else resolve(data);
    });
  });

export const FindOrderContext = createContext();

export const FindOrderProvider = ({ children }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapContainer, setMapContainer] = useState();
  const [metadata, setMetadata] = useState([]);
  const [floors, setFloors] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [, setFilteredUI] = useState();
  const [selectedContainerId, setSelectedContainerId] = useState();
  const [selectecdFloorId, setSelectecdFloorId] = useState();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const ref = useCallback((node) => {
    if (!node) return;
    setMapContainer(node);
  }, []);

  const registerMap = useCallback(async () => {
    const { FnoMapManager } = window;
    const allMetaData = await FNO("getAllMetaData");
    const floors = await FNO("getFloors");
    const allTemplates = await FNO("getAllTemplates");
    console.log("floors", floors, allMetaData);

    setMetadata(allMetaData?.results || []);
    setFloors(floors.floors);
    setTemplates(allTemplates?.templates || []);

    FnoMapManager.registerEvent("mouseClickContainer", (data) => {
      setSelectedContainerId(data.container.id);
      logEvent(analytics, "select_poi_from_map");
    });
    // FnoMapManager.registerEvent("mouseClickOutOfContainer", (data) => {
    //   setSelectedContainerId(null);
    // });
    FnoMapManager.registerEvent("FloorSelected", (data) => {
      setSelectecdFloorId(data.floor);
    });

    setMapLoaded(true);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  const findContainerByReference = useCallback(
    (reference) =>
      metadata.find((c) => !!c.metadata.find((m) => m.key === "id" && m.value === reference)),
    [metadata],
  );
  const findContainerById = useCallback(
    (id) => metadata.find((c) => +c.containerId === +id),
    [metadata],
  );

  const findfloorById = useCallback((id) => floors.find((c) => c.id === id), [floors]);

  const findFloorByReference = useCallback(
    (reference) => floors.find((c) => c.name === reference),
    [floors],
  );

  // select container on change of the last clicked id
  // useEffect(() => {
  //   if (!lastClickedContainer) return;
  //   setSelectedContainerId(lastClickedContainer.id);
  // }, [lastClickedContainer]);

  // React to change on the selected container to apply selection template
  useEffect(() => {
    const template = templates?.find((t) => /PrimarySelection/.test(t.name));
    if (!template || !selectedContainerId) return;
    FNO("setLocalTemplate", {
      container: selectedContainerId,
      template: template.id,
    });
    return () => {
      FNO("clearLocalTemplate", {
        container: selectedContainerId,
        template: template.id,
      });
    };
  }, [selectedContainerId, templates]);

  /**
   *
   * FUNCTIONS
   *
   */

  const centerOnPlaceId = useCallback(
    (reference) => {
      const container = findContainerByReference(reference);
      setSelectedContainerId(container?.containerId);
      if (container) {
        FNO("zoomOnContainer", { containerId: container.containerId });
      }
    },
    [findContainerByReference],
  );

  const filterOnPlaceIds = useCallback((placeIds) => {
    FNO("showAllUI");
    if (!placeIds) {
      FNO("filterUI", { filter: [] });
    } else if (isEmpty(placeIds)) {
      FNO("hideAllUI");
    } else {
      const filterUi = placeIds.map((reference) => ({ key: "id", value: reference }));

      FNO("filterUI", { filter: filterUi });
    }
    setFilteredUI(placeIds);
  }, []);

  const startItinerary = useCallback(
    async (from, to) => {
      const containerFrom = findContainerByReference(from);
      const containerTo = findContainerByReference(to);
      if (!containerFrom || !containerTo) return;
      const itinerary = await FNO("setItineraryToEntityWithId", {
        id_entity: containerTo.containerId, // destination
        id_start: containerFrom.containerId, // départ de l'itinéraire
        hideFloors: true,
      });
      return itinerary
        ? {
            distance: Math.floor(itinerary?.distanceMeter),
            duration: Math.round(
              Math.floor(itinerary.distanceMeter) /
                (10 / 0.13 + itinerary?.allFloorsId?.length * 0.3),
            ),
          }
        : undefined;
    },
    [findContainerByReference],
  );
  const stopItinerary = useCallback(() => {
    FNO("clearItinerary");
  }, []);

  const setFloorByReference = useCallback(
    async (reference) => {
      const floor = findFloorByReference(reference);
      if (!floor) return;
      FNO("setFloor", { floor: floor.id });
    },
    [findFloorByReference],
  );

  const zoomIn = useCallback(async (zoomValue) => {
    FNO("zoomIn", { zoomValue });
  }, []);
  const zoomOut = useCallback(async (zoomValue) => {
    FNO("zoomOut", { zoomValue });
  }, []);

  const applyTemplate = useCallback(async (containerId, templateId) => {
    FNO("setLocalTemplate", {
      container: containerId,
      template: parseInt(templateId),
    });
  }, []);

  const selectedOnMap = useMemo(() => {
    const container = findContainerById(selectedContainerId);
    const reference = container?.metadata?.find((m) => m.key === "id")?.value;
    return mapData?.resources.find((r) => r.map?.findAndOrder?.placeId === reference);
  }, [findContainerById, mapData?.resources, selectedContainerId]);

  const selectedFloor = useMemo(() => {
    const container = findfloorById(selectecdFloorId);
    const reference = container?.name;
    return mapData?.floors.find((f) => f.reference === reference);
  }, [findfloorById, mapData?.floors, selectecdFloorId]);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  return (
    <FindOrderContext.Provider
      value={{
        MAP_PROVIDER: "findorder",
        ref,
        mapLoaded,
        registerMap,
        mapContainer,
        templates,
        centerOnPlaceId,
        filterOnPlaceIds,
        startItinerary,
        stopItinerary,
        setFloorByReference,
        zoomIn,
        zoomOut,
        selectedOnMap,
        selectedFloor,
        selectedContainerId,
        setSelectecdFloorId,
        applyTemplate,
      }}
    >
      {children}
    </FindOrderContext.Provider>
  );
};

//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

const useFindOrder = () => useContext(FindOrderContext);

export const useFindOrderMap = () => {
  const { mapContainer, centerOnPlaceId, filterOnPlaceIds, stopItinerary } = useFindOrder();

  // Display the map when the hook id instanciated in the component
  useEffect(() => {
    if (!mapContainer) {
      console.log("useFindOrderMap: NO CONTAINER");
      return;
    }
    console.log("useFindOrderMap: BLOCK");
    mapContainer.style.display = "block";
    return () => {
      console.log("useFindOrderMap: NONE");
      mapContainer.style.display = "none";
      centerOnPlaceId();
      filterOnPlaceIds();
      stopItinerary();
    };
  }, [centerOnPlaceId, filterOnPlaceIds, mapContainer, stopItinerary]);
};

export default useFindOrder;
