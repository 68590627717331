/* eslint-disable no-unused-vars */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const MapboxContext = createContext();

export const MapboxProvider = ({ children }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMap] = useState();
  const [mapContainer, setMapContainer] = useState();
  const [metadata, setMetadata] = useState([]);
  const [floors, setFloors] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [, setFilteredUI] = useState();
  const [selectedContainerId, setSelectedContainerId] = useState();
  const [selectecdFloorId, setSelectecdFloorId] = useState();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const ref = useCallback((node) => {
    if (!node) return;
    setMapContainer(node);
  }, []);

  const registerMap = useCallback(async (map) => {
    setMap(map);
    const floors = await map.getFloors();
    // const allTemplates = await FNO("getAllTemplates");

    setMetadata([]);
    setFloors(floors);
    setTemplates([]);

    map.on("clickOnRoom", ({ details: room }) => {
      setSelectedContainerId(room.id);
      map.centerOnPlace(room.id);
    });
    map.on("floorChange", ({ details: floor }) => {
      setSelectecdFloorId(floor.id);
    });

    // FnoMapManager.registerEvent("mouseClickContainer", (data) => {
    //   setSelectedContainerId(data.container.id);
    // });
    // FnoMapManager.registerEvent("mouseClickOutOfContainer", (data) => {
    //   setSelectedContainerId(null);
    // });
    // FnoMapManager.registerEvent("FloorSelected", (data) => {
    //   setSelectecdFloorId(data.floor);
    // });

    setMapLoaded(true);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  // const findContainerByReference = useCallback(
  //   (reference) =>
  //     metadata.find((c) => !!c.metadata.find((m) => m.key === "id" && m.value === reference)),
  //   [metadata],
  // );
  // const findContainerById = useCallback(
  //   (id) => metadata.find((c) => +c.containerId === +id),
  //   [metadata],
  // );

  // const findfloorById = useCallback((id) => floors.find((c) => c.id === id), [floors]);

  // const findFloorByReference = useCallback(
  //   (reference) => floors.find((c) => c.name === reference),
  //   [floors],
  // );

  // select container on change of the last clicked id
  // useEffect(() => {
  //   if (!lastClickedContainer) return;
  //   setSelectedContainerId(lastClickedContainer.id);
  // }, [lastClickedContainer]);

  // React to change on the selected container to apply selection template
  // useEffect(() => {
  //   const template = templates?.find((t) => /PrimarySelection/.test(t.name));
  //   if (!template || !selectedContainerId) return;
  //   FNO("setLocalTemplate", {
  //     container: selectedContainerId,
  //     template: template.id,
  //   });
  //   return () => {
  //     FNO("clearLocalTemplate", {
  //       container: selectedContainerId,
  //       template: template.id,
  //     });
  //   };
  // }, [selectedContainerId, templates]);

  /**
   *
   * FUNCTIONS
   *
   */

  const centerOnPlaceId = useCallback(
    (reference, applySelection) => {
      if (map) {
        map.centerOnPlace(reference, applySelection);
        if (applySelection) setSelectedContainerId(reference);
        else setSelectedContainerId(undefined);
      }
    },
    [map],
  );

  const filterOnPlaceIds = useCallback(
    (references) => {
      if (map) map.filterPlaces(references);
    },
    [map],
  );

  const startItinerary = useCallback(
    async (from, to) => {
      const itinerary = await map?.startItinerary(from, to);
      return itinerary
        ? {
            distance: itinerary.distance,
            duration: itinerary.duration,
          }
        : undefined;
    },
    [map],
  );
  const stopItinerary = useCallback(() => {
    map?.stopItinerary();
  }, [map]);

  const setFloorByReference = useCallback(
    async (reference) => {
      if (map) map.setFloor(reference);
    },
    [map],
  );

  const zoomIn = useCallback(async () => {}, []);
  const zoomOut = useCallback(async () => {}, []);

  const applyTemplate = useCallback(
    async (reference, template) => {
      map?.setRoomTemplate(reference, template);
    },
    [map],
  );

  const selectedOnMap = useMemo(() => {
    return mapData?.resources.find((r) => r.map?.findAndOrder?.placeId === selectedContainerId);
  }, [mapData?.resources, selectedContainerId]);

  const selectedFloor = useMemo(() => {
    return mapData?.floors.find((f) => f.reference === selectecdFloorId);
  }, [mapData?.floors, selectecdFloorId]);

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  return (
    <MapboxContext.Provider
      value={{
        MAP_PROVIDER: "mapbox",
        ref,
        map,
        mapLoaded,
        registerMap,
        mapContainer,
        templates,
        centerOnPlaceId,
        filterOnPlaceIds,
        startItinerary,
        stopItinerary,
        setFloorByReference,
        zoomIn,
        zoomOut,
        selectedOnMap,
        selectedFloor,
        selectedContainerId,
        setSelectecdFloorId,
        applyTemplate,
      }}
    >
      {children}
    </MapboxContext.Provider>
  );
};

//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

const useMapbox = () => useContext(MapboxContext);

export const useMapboxMap = () => {
  const { map, mapContainer, centerOnPlaceId, filterOnPlaceIds, stopItinerary } = useMapbox();

  // Display the map when the hook id instanciated in the component
  useEffect(() => {
    if (!mapContainer) {
      console.log("useMapboxMap: NO CONTAINER");
      return;
    }
    console.log("useMapboxMap: BLOCK");
    mapContainer.style.display = "block";
    map.resize();
    return () => {
      console.log("useMapboxMap: NONE");
      mapContainer.style.display = "none";
      centerOnPlaceId();
      filterOnPlaceIds();
      stopItinerary();
    };
  }, [centerOnPlaceId, filterOnPlaceIds, map, mapContainer, stopItinerary]);
};

export default useMapbox;
