import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import SmartControl from "../../api/SmartControlConnector";

const INITIAL_STATE = {
  acOffset: [],
  acFanSpeed: [],
  lighting: {},
  blinds: {},
  angle: {},
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CONFORT ///////////////////////////////

// ACOFFSET //
export const getComfortAcOffset = thunk("confort/getComfortAcOffset", async ({ roomId, zone }) => {
  const comfortAcOffset = await SmartControl.getComfortAcOffset(roomId, zone);
  return comfortAcOffset;
});

EXTRA_REDUCERS[getComfortAcOffset.fulfilled] = (state, action) => {
  state.acOffset = action.payload;
};

export const setAcOffset = thunk(
  "confort/setAcOffset",
  async ({ roomId, zone = 0, command, repeats }, { dispatch }) => {
    const acOffset = await SmartControl.setAcOffset(roomId, zone, command, repeats);
    // await dispatch(getComfortAcOffset({ roomId: roomId }));
    return acOffset;
  },
);

// ACFANSPEED //
export const getComfortAcFanSpeed = thunk(
  "confort/getComfortAcFanSpeed",
  async ({ roomId, zone }) => {
    const comfortAcFanSpeed = await SmartControl.getComfortAcFanSpeed(roomId, zone);
    return comfortAcFanSpeed;
  },
);

EXTRA_REDUCERS[getComfortAcFanSpeed.fulfilled] = (state, action) => {
  state.acFanSpeed = action.payload;
};

export const setAcFanSpeed = thunk(
  "confort/setAcFanSpeed",
  async ({ roomId, zone, command }, { dispatch }) => {
    const acFanSpeed = await SmartControl.setAcFanSpeed(roomId, zone, command);
    // await dispatch(getComfortAcFanSpeed({ roomId: roomId, zone: zone }));
    return acFanSpeed;
  },
);

export const setAcMode = thunk(
  "confort/setAcMode",
  async ({ roomId, zone, command }, { dispatch }) => {
    const acMode = await SmartControl.setAcMode(roomId, zone, command);
    // await dispatch(getComfortAcFanSpeed({ roomId: roomId, zone: zone }));
    return acMode;
  },
);

// LIGHTING //
export const getComfortLighting = thunk("confort/getComfortLighting", async ({ roomId, zone }) => {
  const comfortLighting = await SmartControl.getComfortLighting(roomId, zone);
  return { result: comfortLighting, zone };
});

EXTRA_REDUCERS[getComfortLighting.fulfilled] = (state, action) => {
  state.lighting = { ...state.lighting, [action.payload.zone]: action.payload.result };
};

export const setLighting = thunk(
  "confort/setLighting",
  async ({ roomId, zone, command, value }, { dispatch }) => {
    const lighting = await SmartControl.setLighting(roomId, zone, command, value);
    // await dispatch(getComfortLighting({ roomId: roomId, zone: zone }));
    return lighting;
  },
);

// STORES //
export const getComfortBlinds = thunk("confort/getComfortBlinds", async ({ roomId, zone }) => {
  const comfortBlinds = await SmartControl.getComfortBlinds(roomId, zone);
  return { result: comfortBlinds, zone };
});

EXTRA_REDUCERS[getComfortBlinds.fulfilled] = (state, action) => {
  state.blinds = { ...state.blinds, [action.payload.zone]: action.payload.result };
};

export const getComfortBlindsAngle = thunk(
  "confort/getComfortBlindsAngle",
  async ({ roomId, zone }) => {
    const comfortBlindsAngle = await SmartControl.getComfortBlindsAngle(roomId, zone);
    return { result: comfortBlindsAngle, zone };
  },
);

EXTRA_REDUCERS[getComfortBlindsAngle.fulfilled] = (state, action) => {
  state.angle = { ...state.angle, [action.payload.zone]: action.payload.result };
};

export const setBlinds = thunk(
  "confort/setBlinds",
  async ({ roomId, zone, command, value }, { dispatch }) => {
    const blind = await SmartControl.setBlinds(roomId, zone, command, value);
    // await dispatch(getComfortBlinds({ roomId: roomId, zone: zone }));
    return blind;
  },
);

export const setBlindsAngle = thunk(
  "confort/setBlindsAngle",
  async ({ roomId, zone, command, value }, { dispatch }) => {
    const angles = await SmartControl.setBlindsAngle(roomId, zone, command, value);
    // await dispatch(getComfortBlindsAngle({ roomId: roomId, zone: zone }));
    return angles;
  },
);

// Mode AUTO //
export const setModeAuto = thunk("confort/setModeAuto", async ({ roomId }, { dispatch }) => {
  const auto = await SmartControl.setModeAuto(roomId);
  // await dispatch(getComfortBlindsAngle({ roomId: roomId }));
  // await dispatch(getComfortBlinds({ roomId: roomId }));
  // await dispatch(getComfortLighting({ roomId: roomId }));
  return auto;
});

// Mode AUTO //
export const setVirtualRemote = thunk(
  "confort/setVirtualRemote",
  async ({ roomId, command }, { dispatch }) => {
    const virtualRemote = await SmartControl.setVirtualRemote(roomId, command);
    return virtualRemote;
  },
);

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const clientSlice = createSlice({
  name: "client",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default clientSlice.reducer;
