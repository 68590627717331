import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import clsx from "clsx";
import moment from "moment";
import { without } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDimensions from "../../../../hook/useDimensions";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import {
  assignAutomatically,
  assignSpace,
  checkConflicts,
  createRequest,
  createRequestsAndAutoPlace,
} from "../../../../services/redux/services/SpaceServiceAdmin";
import Glyph from "../../../Common/Glyph/Glyph";
import StepsTracker from "../../../Common/StepsTracker/StepsTracker";
import RequestDetails from "./RequestDetails";
import RequestDetailsModal from "./RequestDetailsModal";
import styles from "../SpaceBooking.module.less";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

export const Modes = {
  Auto: "auto",
  Single: "single",
};

const AffectationModals = ({
  visible,
  slots,
  date,
  selectedRoom,
  selectedDesk,
  onContinue,
  onFinish,
  onCancel,
}) => {
  const mode = selectedDesk ? Modes.Single : Modes.Auto;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ref, dimensions] = useDimensions();

  const [idx, setIdx] = useState(0);
  const current = slots[idx];
  const sectorId = current?.request.sectorId;

  const [showModalRooms, setShowModalRooms] = useState();
  const [showModalValidation, setShowModalValidation] = useState();

  const [endDate, setEndDate] = useState();
  const [comment, setComment] = useState("");
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [conflicts, setConflicts] = useState();
  const [selectedConflict, setSelectedConflict] = useState();

  const rooms = useSelector((state) => state.clientsWS.campus.mapData.resources);
  const isLoading = useSelector(
    createLoadingSelector(["spaceService/checkConflicts", "spaceService/assignSpace"]),
  );

  useEffect(() => {
    setEndDate(undefined);
    setComment("");
    setSelectedRooms([]);
    setConflicts(undefined);
    setSelectedConflict(undefined);
    setIdx(0);
    setShowModalRooms(visible && mode === Modes.Auto);
    setShowModalValidation(visible && mode === Modes.Single);
  }, [mode, visible]);

  const handleAssignAndContinue = (forceAssign, allOccurences) => {
    const operation = current.id
      ? assignSpace({
          sectorId,
          slotId: current.id,
          spaceId: selectedDesk.id,
          comment,
          allOccurences,
          forceAssign,
        })
      : createRequest({
          ...current.request,
          forUserId: current.request.user.id,
          spaceId: selectedDesk.id,
          comment,
          allOccurences,
          forceAssign,
        });

    dispatch(operation)
      .unwrap()
      .then(() => {
        onContinue(mode);
      });
  };
  const handleAssignAuto = (allOccurences) => {
    const operation = current.id
      ? assignAutomatically({
          slotIds: slots.map((s) => s.id),
          roomIds: selectedRooms,
          endDate: endDate ? moment(endDate).toISOString() : undefined,
          comment,
          allOccurences,
        })
      : createRequestsAndAutoPlace({
          ...current.request,
          forceEndDate: endDate ? moment(endDate).toISOString() : undefined,
          comment,
          allOccurences,
          forUserIds: slots.map((s) => s.request.user.id),
          roomIds: selectedRooms,
        });

    dispatch(operation)
      .unwrap()
      .then(() => {
        onFinish();
      });
  };

  const handleCheckConflict = () => {
    dispatch(checkConflicts({ ...current.request, startDate: date, spaceId: selectedDesk.id }))
      .unwrap()
      .then((conflicts) => {
        setConflicts(conflicts);
        if (conflicts.length) return;
        handleAssignAndContinue(false, true);
      })
      .catch(() => {
        message.error(t("spas.messages.conflicts.error"));
      });
  };

  const columns = [
    {
      title: t("spas.conflict.date"),
      dataIndex: ["date"],
      render: (i) => moment(i).format("DD/MM/YYYY"),
    },
    {
      title: t("spas.conflict.user"),
      dataIndex: ["request", "user", "displayName"],
    },
    {
      title: t("Infos"),
      key: "seeMore",
      align: "right",
      render: (_, record) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          underline
          onClick={() => setSelectedConflict(record)}
        >
          {t("SeeMore")}
        </Typography.Text>
      ),
    },
  ];

  const nbSelectedSeats = selectedRooms.reduce((acc, curr) => {
    return acc + rooms.find((ro) => ro.id === curr).features.seats;
  }, 0);

  return (
    <>
      <Modal
        className={clsx(styles.modal)}
        open={showModalRooms}
        onCancel={() => onCancel(false)}
        footer={
          <Row style={{ width: "100%" }} gutter={[10, 10]}>
            {nbSelectedSeats < slots.length && (
              <Col span={24}>
                <Alert
                  style={{ textAlign: "left" }}
                  message={t("spas.request.actions.assignAuto.selectMore")}
                  type="info"
                  showIcon
                />
              </Col>
            )}
            <Col span={12}>
              <Button block type="default" onClick={() => onCancel(false)}>
                {t("Cancel")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                type="primary"
                disabled={nbSelectedSeats < slots.length}
                onClick={() => {
                  setShowModalRooms(false);
                  setShowModalValidation(true);
                }}
              >
                {t("Confirm")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Typography.Title level={5}>{t("spas.request.actions.assignAuto")}</Typography.Title>
        <Typography.Paragraph type="secondary">
          {t("spas.request.actions.assignAuto.helper")}
        </Typography.Paragraph>

        <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 40 }}>
          <Card
            style={{ cursor: "pointer" }}
            bordered={false}
            onClick={() =>
              setSelectedRooms(
                !selectedRooms.length
                  ? rooms.filter((room) => room.sectorId === sectorId).map((room) => room.id)
                  : [],
              )
            }
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <Checkbox
                  checked={
                    selectedRooms.length ===
                    rooms.filter((room) => room.sectorId === sectorId).length
                  }
                />
                <Typography.Text strong>{t("SelectAll")}</Typography.Text>
              </Space>
            </div>
          </Card>
          <br />
          {rooms
            .filter((room) => room.sectorId === sectorId)
            .map((room) => (
              <Card
                key={room.id}
                style={{ cursor: "pointer" }}
                bordered={false}
                onClick={() =>
                  setSelectedRooms(
                    selectedRooms.includes(room.id)
                      ? without([room.id], selectedRooms)
                      : [...selectedRooms, room.id],
                  )
                }
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Space>
                    <Checkbox checked={selectedRooms.includes(room.id)} />
                    <Typography.Text strong>{room.title}</Typography.Text>
                  </Space>
                  <Tag icon={<Glyph name="group" style={{ marginRight: "8px" }} />}>
                    {room.features?.seats}
                  </Tag>
                </div>
              </Card>
            ))}
        </div>
      </Modal>

      <Modal
        className={clsx(styles.modal)}
        open={showModalValidation}
        onCancel={() => onCancel(false)}
        footer={[
          <Row key="1" style={{ width: "100%" }} gutter={[10, 20]}>
            <Col span={24}>
              <Card bodyStyle={{ textAlign: "left" }}>
                <Row>
                  <Col flex={"auto"}>
                    <Typography.Text strong>{t("spas.request.forceEndDate")}</Typography.Text>
                  </Col>
                  <Col>
                    <DatePicker
                      style={{ margin: -8 }}
                      format="ll"
                      disabledDate={(date) => date.isBefore(moment(), "date")}
                      value={endDate}
                      onChange={setEndDate}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <TextArea
                disabled={isLoading}
                placeholder={t("spas.request.comment.helper")}
                autoSize={{ minRows: 2, maxRows: 6 }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
            {current?.request.recurrence && current?.request.recurrence !== "NONE" ? (
              <>
                <Col span={12}>
                  <Button
                    disabled={isLoading}
                    block
                    onClick={() =>
                      mode === Modes.Auto
                        ? handleAssignAuto(false)
                        : handleAssignAndContinue(false, false)
                    }
                  >
                    {t("spas.request.actions.occurence")}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    disabled={isLoading}
                    block
                    onClick={() =>
                      mode === Modes.Auto ? handleAssignAuto(true) : handleCheckConflict()
                    }
                  >
                    {t("spas.request.actions.serie")}
                  </Button>
                </Col>
              </>
            ) : (
              <Col span={24}>
                <Button
                  disabled={isLoading}
                  block
                  onClick={() =>
                    mode === Modes.Auto ? handleAssignAuto(false) : handleCheckConflict()
                  }
                >
                  {t("spas.request.actions.assignValidate")}
                </Button>
              </Col>
            )}
          </Row>,
        ]}
      >
        <Row gutter={[10, 10]}>
          <Col flex="auto">
            <Typography.Title level={5}>
              {t("spas.request.actions.confirmBooking")}
            </Typography.Title>
          </Col>
          {slots.length > 1 && (
            <>
              <Col>
                <Typography.Title strong level={5} className="secondary">
                  {idx + 1}/{slots.length}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <StepsTracker
                  steps={slots.map((s, index) => ({
                    key: index,
                    current: index === idx,
                  }))}
                />
              </Col>
            </>
          )}
          {idx > 0 && (
            <Col span={3}>
              <Card
                bordered={false}
                style={{ height: "100%", cursor: "pointer" }}
                bodyStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                }}
                onClick={() => setIdx(idx - 1)}
              >
                <Glyph name={"chevron_left"} style={{ verticalAlign: "middle" }} />
              </Card>
            </Col>
          )}
          <Col span={24 - (idx > 0 ? 3 : 0) - (idx < slots.length - 1 ? 3 : 0)}>
            <RequestDetails.User slot={current} />
          </Col>
          {idx < slots.length - 1 && (
            <Col span={3}>
              <Card
                bordered={false}
                style={{ height: "100%", cursor: "pointer" }}
                bodyStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                }}
                onClick={() => setIdx(idx + 1)}
              >
                <Glyph name={"navigate_next"} style={{ verticalAlign: "middle" }} />
              </Card>
            </Col>
          )}
        </Row>
        <Divider />
        <RequestDetails.Infos slot={current} />
      </Modal>

      <Modal
        destroyOnClose
        className={clsx(styles.modal)}
        open={conflicts?.length}
        onOk={undefined}
        onCancel={() => setConflicts(null)}
        footer={[
          <Row gutter={[10, 10]} style={{ textAlign: "left" }}>
            <Col span={24}>
              <Typography.Text strong>{t("spas.conflict.question")}</Typography.Text>
            </Col>
            <Col span={12}>
              <Button
                disabled={isLoading}
                block
                type="default"
                ghost
                onClick={() => handleAssignAndContinue(true, true)}
              >
                {t("spas.conflict.forceAssign")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                disabled={isLoading}
                block
                type="primary"
                onClick={() => handleAssignAndContinue(false, true)}
              >
                {t("spas.conflict.onlyAvailbale")}
              </Button>
            </Col>
            <Col span={10} offset={1}>
              <Typography.Text type="secondary">
                {t("spas.conflict.forceAssign.helper")}
              </Typography.Text>
            </Col>
            <Col span={10} offset={2}>
              <Typography.Text type="secondary">
                {t("spas.conflict.onlyAvailbale.helper", {
                  user: current?.request.user.displayName,
                })}
              </Typography.Text>
            </Col>
          </Row>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 10, height: "100%" }}>
          <Row gutter={[10, 0]}>
            <Col flex={"none"}>
              <Typography.Title level={5} type="danger">
                <Glyph name="warning" />
              </Typography.Title>
            </Col>
            <Col flex={"auto"}>
              <Typography.Title level={5}>
                {t("spas.request.actions.confirmBooking")}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Text type="secondary">{t("spas.request.isSerie")}</Typography.Text>
            </Col>
          </Row>
          <Typography.Paragraph strong>
            {t("spas.conflict", { user: current?.request.user?.displayName })}
          </Typography.Paragraph>

          <div ref={ref} style={{ flexGrow: 1 }}>
            {dimensions.height && (
              <Table
                size="small"
                columns={columns}
                dataSource={conflicts || []}
                showHeader={false}
                pagination={false}
                scroll={{ y: dimensions.height }}
              />
            )}
          </div>
        </div>
      </Modal>

      <RequestDetailsModal slot={selectedConflict} onCancel={() => setSelectedConflict(null)} />
    </>
  );
};

export default AffectationModals;
