import { Image, Typography, Form, Input, Button, message, Spin } from "antd";
import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "./Password.less";
import Glyph from "../../Common/Glyph/Glyph";
import { confirmPassword, getCreatePassword } from "../../../services/redux/services/UserWS";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Password = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const authResult = new URLSearchParams(window.location.search);
  const login = authResult.get("login");

  const [isActive, setIsActive] = useState(true);

  const onFinish = (values) => {
    const authResult = new URLSearchParams(window.location.search);
    const code = authResult.get("code");
    dispatch(getCreatePassword({ login: values.email, code: code, newPassword: values.password }))
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/public/password/confirmation/`,
          state: { login: values.email },
        });
      })
      .catch((error) => {
        console.error(error);
        message.error(t("Public.password.error"), 5);
      });
  };

  useEffect(() => {
    form.setFieldsValue({ email: login });
  }, [form, login]);

  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);
    const code = authResult.get("code");
    const login = authResult.get("login");
    dispatch(confirmPassword({ login: login, code: code }))
      .unwrap()
      .then((value) => {
        if (!value.isValid) {
          history.push(`/public/password/error/`);
        } else {
          setIsActive(false);
        }
      })
      .catch((error) => console.error(error));
  }, [dispatch, history]);

  return isActive ? (
    <div className="loader-map">
      <div>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        <br />
        <Typography.Title level={3}>{t("Public.password.verification")}</Typography.Title>
      </div>
    </div>
  ) : (
    <div className="password__layout">
      <div className="password__bg">
        <Image src={Logo} style={{ height: "58px" }} preview={false} />
        <Typography.Title level={4} className="processing">
          {t("Public.password.activated")}
        </Typography.Title>
        <Typography.Text>{t("Public.password.experience")}</Typography.Text>

        <Form form={form} style={{ marginTop: "30px" }} onFinish={onFinish}>
          <Typography.Text>{t("Public.password.identifiant")}</Typography.Text>
          <Form.Item name="email">
            <Input disabled placeholder={login} />
          </Form.Item>
          <Typography.Text style={{ fontSize: "11px" }}>
            {t("Public.password.email")}
          </Typography.Text>

          <Typography.Paragraph style={{ marginTop: "16px" }}>
            {t("Public.password.password")}
          </Typography.Paragraph>
          <Form.Item
            name="password"
            rules={[{ required: true, message: t("Public.password.obligatory") }]}
          >
            <Input.Password placeholder="Mot de passe" />
          </Form.Item>
          <Typography.Text style={{ fontSize: "11px" }}>
            {t("Public.password.strongPassword")}
          </Typography.Text>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <div
                style={{
                  display: "grid",
                  gridGap: "5px",
                  gridTemplateColumns: "repeat(auto-fill, 200px )",
                  marginTop: "20px",
                }}
              >
                <Typography.Text
                  style={{ fontSize: "12px" }}
                  className={(getFieldValue("password")?.length || 0) < 8 ? "waiting" : ""}
                >
                  {(getFieldValue("password")?.length || 0) < 8 ? (
                    <Glyph name="radio_button_unchecked" style={{ marginRight: "8px" }} />
                  ) : (
                    <Glyph
                      name="check_circle"
                      className="processing"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {t("Public.password.eight")}
                </Typography.Text>
                <Typography.Text
                  style={{ fontSize: "12px" }}
                  className={/\W/.test(getFieldValue("password")) ? "" : "waiting"}
                >
                  {/\W/.test(getFieldValue("password")) ? (
                    <Glyph
                      name="check_circle"
                      className="processing"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <Glyph name="radio_button_unchecked" style={{ marginRight: "8px" }} />
                  )}
                  {t("Public.password.one")}
                </Typography.Text>
                <Typography.Text
                  style={{ fontSize: "12px" }}
                  className={/.*[A-Z]/.test(getFieldValue("password")) ? "" : "waiting"}
                >
                  {/.*[A-Z]/.test(getFieldValue("password")) ? (
                    <Glyph
                      name="check_circle"
                      className="processing"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <Glyph name="radio_button_unchecked" style={{ marginRight: "8px" }} />
                  )}
                  {t("Public.password.maj")}
                </Typography.Text>
                <Typography.Text
                  style={{ fontSize: "12px" }}
                  className={/.*[0-9]/.test(getFieldValue("password")) ? "" : "waiting"}
                >
                  {/.*[0-9]/.test(getFieldValue("password")) ? (
                    <Glyph
                      name="check_circle"
                      className="processing"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <Glyph name="radio_button_unchecked" style={{ marginRight: "8px" }} />
                  )}
                  {t("Public.password.number")}
                </Typography.Text>
                <Typography.Text
                  style={{ fontSize: "12px" }}
                  className={/.*[a-z]/.test(getFieldValue("password") || "") ? "" : "waiting"}
                >
                  {/.*[a-z]/.test(getFieldValue("password") || "") ? (
                    <Glyph
                      name="check_circle"
                      className="processing"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <Glyph name="radio_button_unchecked" style={{ marginRight: "8px" }} />
                  )}
                  {t("Public.password.min")}
                </Typography.Text>
              </div>
            )}
          </Form.Item>

          <Typography.Paragraph style={{ marginTop: "16px" }}>
            {t("Public.password.confirm")}
          </Typography.Paragraph>
          <Form.Item
            name="comfirmPassword"
            rules={[{ required: true, message: t("Public.password.obligatory") }]}
          >
            <Input.Password placeholder={t("Public.password.confirm")} />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <div style={{ marginTop: "10px" }}>
                <Typography.Text
                  style={{ fontSize: "12px" }}
                  className={
                    form.getFieldValue("comfirmPassword") === form.getFieldValue("password") &&
                    !!form.getFieldValue("comfirmPassword")
                      ? ""
                      : "waiting"
                  }
                >
                  {form.getFieldValue("comfirmPassword") === form.getFieldValue("password") &&
                  !!form.getFieldValue("comfirmPassword") ? (
                    <Glyph
                      name="check_circle"
                      className="processing"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <Glyph name="radio_button_unchecked" style={{ marginRight: "8px" }} />
                  )}
                  {t("Public.password.same")}
                </Typography.Text>
              </div>
            )}
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "16px",
            }}
            shouldUpdate
          >
            {({ getFieldValue }) => (
              <Button
                style={{ padding: "0 15px" }}
                type="primary"
                icon={<Glyph name="done" style={{ marginRight: "8px" }} />}
                htmlType="submit"
                disabled={
                  !/.*[a-z]/.test(getFieldValue("password")) ||
                  !/.*[A-Z]/.test(getFieldValue("password")) ||
                  !/.*[0-9]/.test(getFieldValue("password")) ||
                  !/\W/.test(getFieldValue("password")) ||
                  getFieldValue("password")?.length < 8 ||
                  getFieldValue("password") !== getFieldValue("comfirmPassword")
                }
              >
                {t("Public.password.active")}
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Password;
