import { Card, Col, DatePicker, Form, Row, Select, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import Glyph from "../../../../../Common/Glyph/Glyph";
import SpaceWhoHere from "../../../SpaceWhoHere";
import { isEmpty } from "ramda";

const RequestStepUpdate = ({ slot }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  return (
    <Row gutter={[0, 40]} style={{ width: "100%", height: "100%" }}>
      <Col
        span={14}
        style={{
          borderRight: "1px solid #a8bec3ff",
          paddingTop: "30px",
          paddingRight: "30px",
          paddingLeft: "30px",
          paddingBottom: "30px",
        }}
      >
        <Col>
          <Typography.Paragraph strong type="secondary">
            {t("spas.request.site")}
          </Typography.Paragraph>
          <Card style={{ marginBottom: 10 }}>
            <Row gutter={[20, 0]}>
              <Col>
                <Glyph name="business" className={"secondary"} />
              </Col>
              <Col flex={"auto"}>
                <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
                  <Select
                    className="accent"
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      marginTop: -8,
                      marginBottom: -8,
                      backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                    }}
                    options={sites
                      ?.filter((site) => !!site?.spaceBooking)
                      ?.filter((s) => !isEmpty(s?.sectors))
                      .map((s, index) => ({
                        label: s.title,
                        value: s.id,
                        key: index,
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue(["siteId"]) ? (
                <Form.Item noStyle name={["sectorId"]} rules={[{ required: true }]}>
                  <Select
                    size={"large"}
                    style={{
                      width: "100%",
                      display:
                        sites?.find((c) => c?.id === getFieldValue(["siteId"]))?.spaceBooking
                          ?.type === 3
                          ? "block"
                          : "none",
                    }}
                    options={(sites.find((s) => s.id === getFieldValue(["siteId"]))?.sectors || [])
                      .filter((s) => s.canBook)
                      .map((s, index) => ({
                        label: s.title,
                        value: s.id,
                        key: index,
                      }))}
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Col>
        <Col>
          <Typography.Title style={{ marginTop: "16px" }} level={4}>
            {t("spas.request.startDate.title")}
          </Typography.Title>
          <Card>
            <Row>
              <Col flex={"auto"}>
                <Typography.Text strong>{t("spas.request.startDate.helper")}</Typography.Text>
              </Col>
              <Col>
                <Form.Item noStyle name={["startDate"]} rules={[{ required: true }]}>
                  <DatePicker
                    disabled={moment(slot?.request?.startDate).isBefore(moment(), "date")}
                    style={{ margin: -8 }}
                    format="DD MMM YYYY"
                    disabledDate={(date) => date.isBefore(moment(), "date")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col>
          <Typography.Title style={{ marginTop: "16px" }} level={4}>
            {t("spas.request.period.title")}
          </Typography.Title>
          <Form.Item noStyle name={["period"]} rules={[{ required: true }]}>
            <Select
              size={"large"}
              style={{ width: "100%" }}
              placeholder={t("spas.request.period.helper")}
              options={[
                {
                  label: t("spas.period.DAY"),
                  value: "DAY",
                },
                {
                  label: t("spas.period.MORNING"),
                  value: "MORNING",
                },
                {
                  label: t("spas.period.AFTERNOON"),
                  value: "AFTERNOON",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Typography.Title style={{ marginTop: "16px" }} level={4}>
            {t("spas.request.recurrence")}
          </Typography.Title>
          <Form.Item noStyle name={["recurrence"]} rules={[{ required: false }]}>
            <Select
              size={"large"}
              style={{ width: "100%" }}
              placeholder={t("spas.recurrence.NONE")}
              options={[
                {
                  label: t("spas.recurrence.NONE"),
                  value: "NONE",
                },
                {
                  label: t("spas.recurrence.EVERY_DAY"),
                  value: "EVERY_DAY",
                },
                {
                  label: t("spas.recurrence.EVERY_WEEK", {
                    day: moment(slot?.date).format("dddd"),
                  }),
                  value: "EVERY_WEEK",
                },
                {
                  label: t("spas.recurrence.EVERY_2_WEEK", {
                    day: moment(slot?.date).format("dddd"),
                  }),
                  value: "EVERY_2_WEEK",
                },
              ]}
            />
          </Form.Item>
          <br />
          <br />
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.recurrence !== next.recurrence}>
            {({ getFieldValue }) =>
              getFieldValue("recurrence") && getFieldValue("recurrence") !== "NONE" ? (
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        {t("spas.request.endDate", { date: "" })}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Form.Item noStyle name={["endDate"]} rules={[{ required: false }]}>
                        <DatePicker
                          style={{ margin: -8 }}
                          format="DD MMM YYYY"
                          disabledDate={(date) => date.isBefore(getFieldValue(["startDate"]))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              ) : null
            }
          </Form.Item>
        </Col>
      </Col>
      <Col span={10}>
        <SpaceWhoHere slot={slot} />
      </Col>
    </Row>
  );
};

export default RequestStepUpdate;
