import { Card, Col, Row, Select, Typography } from "antd";
import debounce from "lodash.debounce";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "../../../../helpers/utils";
import { searchResidents, searchVisitors } from "../../../../services/redux/services/VisitorsWS";
import Glyph from "../../../Common/Glyph/Glyph";
import EditUser from "../EditUser";
import { isEmpty } from "ramda";

const UserInput = ({ value, onChange, type, allowCreation, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState("");
  const [editingUser, setEditingUser] = useState(null);

  const searchVisitor = useSelector((state) => state.visitorsWS.searchVisitors);
  const searchResident = useSelector((state) => state.visitorsWS.searchResidents);
  const isVisitor = useSelector((state) => state.userWS.userData.profile.isVisitor);

  const handleSearch = useCallback(
    (value) => {
      if (type === "visitor") {
        dispatch(searchVisitors({ search: value }));
      } else {
        dispatch(searchResidents({ search: value }));
      }
    },
    [dispatch, type],
  );
  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 600), [handleSearch]);

  return (
    <>
      <Card bodyStyle={{ paddingTop: 5, paddingBottom: 5 }}>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Glyph name={"person"} className="secondary" />
          <Select
            {...rest}
            bordered={false}
            filterOption={false}
            style={{ width: "100%" }}
            value={value}
            onChange={(_, user) => onChange(user)}
            showSearch
            searchValue={searchString}
            onSearch={(val) => {
              setSearchString(val);
              debouncedHandleSearch(val);
            }}
            options={
              type === "visitor"
                ? searchVisitor?.items?.map((res) => ({
                    ...res,
                    label: (
                      <>
                        <Typography.Text strong>
                          {res.firstname} {res.lastname?.toUpperCase()}
                        </Typography.Text>{" "}
                        <Typography.Text type={"secondary"}>({res.email})</Typography.Text>
                      </>
                    ),
                    value: res.id,
                  }))
                : searchResident?.items?.map((res) => ({
                    ...res,
                    label: (
                      <>
                        <Typography.Text strong>
                          {res.firstname} {res.lastname?.toUpperCase()}
                        </Typography.Text>{" "}
                        <Typography.Text type={"secondary"}>({res.email})</Typography.Text>
                      </>
                    ),
                    value: res.id,
                  }))
            }
            dropdownRender={(menu) => (
              <>
                {isEmpty(searchVisitor?.items) && isEmail(searchString) && allowCreation ? (
                  <Card
                    onClick={() => setEditingUser({ email: searchString })}
                    style={{ cursor: "pointer" }}
                  >
                    <Row gutter={[20, 0]} align="middle">
                      <Col>
                        <Glyph name={"person_add"} className="secondary" />
                      </Col>
                      <Col flex={"auto"}>
                        <Typography.Text>{t("visitors.forms.user.create")}</Typography.Text>
                      </Col>
                      <Col>
                        <Glyph name={"chevron_right"} />
                      </Col>
                    </Row>
                  </Card>
                ) : (
                  menu
                )}
              </>
            )}
          />
        </div>
      </Card>

      {!isVisitor && (
        <EditUser
          open={!!editingUser}
          user={editingUser}
          onDismiss={(user) => {
            setEditingUser(null);
            handleSearch(searchString);
            if (user) onChange(user);
          }}
        />
      )}
    </>
  );
};

export default UserInput;
