import { useState, useEffect } from "react";
import { Modal, Typography, Button, Progress } from "antd";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

// import component
import Glyph from "../../Common/Glyph/Glyph";
import styles from "./Fastbooking.module.less";

// import svg
import { ReactComponent as Confirm } from "../../../assets/svg/confirmation.svg";
import { ReactComponent as Error } from "../../../assets/svg/error.svg";
import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../hook/useDesignTokens";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const AnimatedProgress = ({ status }) => {
  const [percent, setPercent] = useState(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let interval;
    if (ready) {
      interval = setInterval(() => {
        setPercent(percent + 20);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [ready, percent]);

  useEffect(() => {
    if (!ready) setReady(true);
  }, [ready]);

  return (
    <Progress
      type="circle"
      percent={percent}
      style={{ display: "flex", justifyContent: "end" }}
      width={80}
      showInfo={percent >= 100}
      status={status === "error" ? "exception" : status === "error" ? "success" : "normal"}
    />
  );
};

const Confirmation = ({
  isVisible,
  onCancel,
  current,
  setCurrent,
  status,
  selectedRoom,
  duration,
}) => {
  const history = useHistory();
  const filters = useSelector((state) => state.fastbookingWS.filters);

  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  return (
    <Modal
      open={isVisible}
      closable={false}
      destroyOnClose={true}
      title={
        <Typography.Title level={4} style={{ textAlign: "center", marginBottom: "0" }}>
          {t("Reservation")}
        </Typography.Title>
      }
      footer={
        current === 0 ? (
          <Button
            ghost
            style={{ width: "375px" }}
            onClick={() => {
              onCancel();
              setCurrent(0);
            }}
          >
            {t("cancel")}
          </Button>
        ) : current === 1 ? (
          <>
            <Button
              ghost
              style={{ width: "375px" }}
              onClick={() => {
                onCancel();
                setCurrent(0);
                history.push("/fastBooking");
              }}
            >
              {t("BackHome")}
            </Button>
            <Button
              type="primary"
              style={{ width: "375px" }}
              onClick={() => {
                logEvent(analytics, "fast_booking_itinerary");
              }}
            >
              <Link
                to={{
                  pathname: "/indoorMapping/itinerary",
                  state: {
                    back: `/fastBooking/${selectedRoom.map.findAndOrder.placeId}`,
                    selectedRoom: selectedRoom,
                  },
                }}
              >
                {t("SeeItinerary")}
              </Link>
            </Button>
          </>
        ) : (
          <Button
            type="default"
            style={{ width: "375px" }}
            onClick={() => {
              onCancel();
              setCurrent(0);
              history.push("/fastBooking");
            }}
          >
            {t("BackHome")}
          </Button>
        )
      }
    >
      <div className="steps-content" style={{ display: current === 0 ? "initial" : "none" }}>
        <AnimatedProgress status={status} />
        <Typography.Paragraph
          strong
          style={{ fontSize: "24px", margin: "24px 0", opacity: "0" }}
          id={styles["first"]}
        >
          <Glyph
            name="check_circle"
            style={{ marginRight: "8px", fontSize: "20px", color: colors.success_light }}
          />
          {selectedRoom.title} - {selectedRoom.floor?.displayName}
        </Typography.Paragraph>
        <Typography.Paragraph
          strong
          style={{ fontSize: "24px", margin: "24px 0", opacity: "0" }}
          id={styles["second"]}
        >
          <Glyph
            name="check_circle"
            style={{ marginRight: "8px", fontSize: "20px", color: colors.success_light }}
          />
          {t("Today")}
        </Typography.Paragraph>
        <Typography.Paragraph
          strong
          style={{ fontSize: "24px", margin: "24px 0", opacity: "0" }}
          id={styles["third"]}
        >
          <Glyph
            name="check_circle"
            style={{ marginRight: "8px", fontSize: "20px", color: colors.success_light }}
          />
          {filters.startDate ? moment(filters.startDate).format("HH:mm") : moment().format("HH:mm")}{" "}
          -{" "}
          {filters.startDate
            ? moment(filters.startDate).add(duration, "minutes").format("HH:mm")
            : moment().add(duration, "minutes").format("HH:mm")}
        </Typography.Paragraph>
      </div>

      <div
        className="steps-content"
        style={{
          display: current === 1 ? "flex" : "none",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <Confirm />
        <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: "20px" }}>
          <Glyph
            name="check_circle"
            style={{
              fontSize: "18px",
              verticalAlign: "-1px",
              marginRight: "8px",
              fontWeight: "normal",
              color: colors.success_light,
            }}
          />
          {t("BookingArea")}
        </Typography.Paragraph>
        <Typography.Text strong style={{ color: colors.pending_light }}>
          {selectedRoom.title} le{" "}
          {filters.startDate
            ? moment(filters.startDate).format("DD/MM/YYYY - HH:mm")
            : moment().format("DD/MM/YYYY - HH:mm")}{" "}
          -{" "}
          {filters.startDate
            ? moment(filters.startDate).add(duration, "minutes").format("HH:mm")
            : moment().add(duration, "minutes").format("HH:mm")}
        </Typography.Text>
      </div>

      <div
        className="steps-content"
        style={{
          display: current === 2 ? "flex" : "none",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Error />
        <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: "20px" }}>
          <Glyph
            name="cancel"
            style={{
              fontSize: "18px",
              verticalAlign: "-1px",
              marginRight: "8px",
              fontWeight: "normal",
              color: colors.error_light,
            }}
          />
          {t("BookingDontWork")}
        </Typography.Paragraph>
        <Typography.Text strong style={{ color: colors.pending_light }}>
          {t("TryAgain")}
        </Typography.Text>
      </div>
    </Modal>
  );
};

export default Confirmation;
