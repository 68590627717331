import { Button, Card, Form, InputNumber, message, Modal, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import clsx from "clsx";
import moment from "moment";
import { isEmpty } from "ramda";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import checkDelayRule from "../../../helpers/checkdaysRules";
import { getParent } from "../../../helpers/ticketHelpers";
import { deletePrestation, updatePrestation } from "../../../services/redux/services/CateringWS";
import styles from "./Catering.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const UpdateServicesModal = ({ visible, onCancel, space }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const services = useSelector((state) => state.cateringWS.allServices);

  const [presta, setPresta] = useState(null);
  const [total, setTotal] = useState(space?.formProps?.total);
  const [noAttendees, setNoAttendees] = useState(space?.formProps?.no_Attendees);
  const [isDisabled, setIsDisabled] = useState(true);

  const idRubrique = getParent(services, space?.categoryId);

  const canOrder =
    !isEmpty(services) &&
    checkDelayRule(
      space?.formProps?.startDate,
      services
        ?.find((s) => s.id === idRubrique)
        ?.categories?.find((c) => c.id === space?.categoryId).form?.orderNotice,
    );

  const canceledOrder =
    !isEmpty(services) &&
    checkDelayRule(
      space?.formProps?.startDate,
      services
        ?.find((s) => s.id === idRubrique)
        ?.categories?.find((c) => c.id === space?.categoryId).form?.cancelNotice,
    );

  const confirm = () => {
    Modal.confirm({
      title: t("catering.canceled"),
      content: t("catering.sureCanceled"),
      okText: <div block>Oui</div>,
      onOk: () => {
        onCancel();
        dispatch(
          deletePrestation({
            ticketId: space?.id,
          }),
        );
      },
      cancelText: <div block>{t("No")}</div>,
    });
  };

  const handleSubmit = () => {
    dispatch(
      updatePrestation({
        ticketId: space?.id,
        roomId: space?.resources?.id,
        startDate: presta?.formProps?.startDate,
        endDate: presta?.formProps?.endDate,
        values: presta?.formProps?.values,
        no_Attendees: noAttendees,
        total: total,
      }),
    )
      .unwrap()
      .then(() => {
        onCancel();
        message.success(t("catring.successUpdate"));
      })
      .catch(() => {
        message.error(t("catering.errorUpdate"));
      });
  };

  return (
    <Modal
      className={clsx(styles.modal)}
      destroyOnClose
      title={<Typography.Title level={5}>{space?.resources?.title}</Typography.Title>}
      open={!!visible}
      onCancel={() => {
        onCancel();
      }}
      footer={[
        <div style={{ display: "flex" }}>
          <Button
            type="danger"
            block
            disabled={!canceledOrder}
            style={{ width: "220px" }}
            onClick={() => {
              confirm();
              logEvent(analytics, "catering_delete_request");
            }}
          >
            {t("catering.canceled")}
          </Button>
          <Button
            type="primary"
            block
            style={{ width: "220px" }}
            disabled={!canOrder || isDisabled}
            onClick={() => {
              form.submit();
              logEvent(analytics, "catering_edit_request");
            }}
          >
            {t("catering.update")}
          </Button>
        </div>,
      ]}
      bodyStyle={{ padding: "0" }}
    >
      <Form
        style={{ padding: "0 16px 0 16px" }}
        form={form}
        initialValues={{
          startDate: moment(space?.formProps?.startDate),
          values: space?.formProps?.values.reduce((acc, info) => {
            return { ...acc, [info.key]: info.value };
          }, {}),
        }}
        onValuesChange={(_, values) => {
          setIsDisabled(false);
          const value = services
            ?.find((s) => s.id === idRubrique)
            ?.categories?.find((c) => c.id === space?.categoryId)
            ?.form?.elements?.filter((e) => e.type === "NUMBER")
            ?.reduce((acc, curr) => acc + (curr.fee || 0) * (values.values[curr.key] || 0), 0);
          const noAttendees = services
            ?.find((s) => s.id === idRubrique)
            ?.categories?.find((c) => c.id === space?.categoryId)
            ?.form?.elements?.filter((e) => e.type === "NUMBER")
            .reduce((acc, curr) => acc + (values.values[curr.key] || 0), 0);
          setNoAttendees(noAttendees);
          setTotal(Math.round(value * 100) / 100);
          setPresta({
            roomId: space?.id,
            formProps: {
              startDate: moment(space?.formProps?.startDate).toISOString(),
              endDate: moment(space?.formProps?.endDate).toISOString(),
              values: Object.entries(form.getFieldValue(["values"])).reduce((acc, [key, value]) => {
                return [...acc, { key: key, value: value }];
              }, []),
            },
          });
        }}
        onFinish={handleSubmit}
      >
        {!canOrder ? <Typography.Text strong>{t("catering.noOrder")}</Typography.Text> : null}
        {services
          ?.find((s) => s.id === idRubrique)
          ?.categories?.find((c) => c.id === space?.categoryId)
          ?.form?.elements?.map((element, index) => (
            <div
              key={index}
              style={{
                marginTop: "16px",
                height: "100%",
                flexFlow: "column",
                padding: "16px 0 0 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text strong>
                  {element?.name} {element?.required ? "*" : null}
                </Typography.Text>
                {element?.type === "NUMBER" && (
                  <Typography.Text strong>
                    {element?.fee.toFixed(2)}{" "}
                    {
                      services
                        ?.find((s) => s.id === "6387bbbac4be60604fb62f75")
                        ?.categories?.find((c) => c.id === space?.categoryId)?.form?.currency
                    }
                  </Typography.Text>
                )}
              </div>

              {element?.type === "NUMBER" ? (
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text strong>{t("amount")}</Typography.Text>
                    <Form.Item
                      noStyle
                      name={["values", element?.key]}
                      rules={[
                        {
                          required: element?.required,
                          message: t("obligatory"),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ margin: -8 }}
                        size="medium"
                        min={0}
                        defaultValue={0}
                        disabled={!canOrder}
                      />
                    </Form.Item>
                  </div>
                </Card>
              ) : (
                <Form.Item
                  name={["values", element?.key]}
                  rules={[
                    {
                      required: element?.required,
                      message: t("obligatory"),
                    },
                  ]}
                >
                  <TextArea
                    disabled={!canOrder}
                    maxLength={100}
                    style={{
                      height: 100,
                      resize: "none",
                    }}
                  />
                </Form.Item>
              )}
              <Typography.Text strong type="secondary">
                {element?.description}
              </Typography.Text>
            </div>
          ))}
      </Form>
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "40px",
          borderTop: "1px solid black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
          position: "sticky",
          bottom: "0",
        }}
      >
        <Typography.Text strong>{t("Total")}</Typography.Text>
        <Typography.Text>{total.toFixed(2)} €</Typography.Text>
      </div>
    </Modal>
  );
};

export default UpdateServicesModal;
