// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    Back: "Back",
    BackTo: "Back to {{topic}}",
    Sure_Leave:
      "You are about to exit the content editor. You will lose the unpublished work in progress. Are you sure you want to continue ?",
    Yes_Leave: "Yes, leave",
    Cancel: "Cancel",
    Select_A_Group: "Select at least one group to be able to publish",
    Content_Editing: "Content editing",
    Notif_Editing: "Notification editing",
    Publish: "Publish",
    Update: "Update",
    Upload: "Add",
    Languages: "Languages",
    Edit_Internationalized: "Edit your internationalized content",
    French: "French",
    English: "English",
    Dutch: "Dutch",
    Image_Library: "Image library",
    Add_Image: "Upload an image",
    Select_Image: "Select an image from the library",
    Title: "Title",
    Subtitle: "Subtitle",
    Notif_Trigger: "Notification trigger",
    Choose_Title: "Write a title",
    Choose_Subtitle: "Write a subtitle",
    Publication: "Publication",
    Content_To_Be_Shawn: "The content will be visible in the application from",
    Hide_Content: "Hide content before publication",
    Send_Notif: "Send a notification",
    Access: "Public / restricted content access",
    Target: "Target",
    Select_Group:
      "To restric content access, select users groups authorized to access this content",
    Select_Group2: "Select recipients of the notification",
    No_Group_Found: "No group found",
    Topics: "Topics",
    Select_Topics: "Select one or many topics for the news",
    No_Topic: "No topic available",
    Location: "Location",
    Attach_To_Place:
      "Attach this content to a place. (You can either select a resource or directly enter the name of the place)",
    Attach_To_Place2: "Attach this content to a place",
    Stage: "Stage",
    No_Stage_Found: "No stage found",
    Select_A_Room: "Select a room",
    Select_A_Stage: "Select a stage",
    No_Room_Found: "No room found",
    Room: "Room",
    Name: "Name",
    Enter_Name_Place: "Enter the name of the place/room",
    Publication_Preview: "Preview in the app",
    Add_News: "Create a news",
    Add_Notif: "Create a notification",
    Add_Topic: "Create a topic",
    Update_Topic: "Update a topic",
    Manage_Topic: "Manage topics",
    Trendings: "Spotlight",
    Published_On: "Published on",
    Created_On: "Created on",
    Header_Image: "Header image",
    At: "at",
    Get_Content: "Fetching contents...",
    Get_Content_Error: "Error while fetching contents",
    Get_Groups: "Fetching Swizi groups...",
    Get_Groups_Error: "Error while fetching Swizi groups",
    Get_Topcis_Error: "Error while fetching topics",
    Get_Media: "Fetching Swizi medias...",
    Get_Media_Error: "Error while fetching Swizi medias",
    Get_Resource: "Fetching Swizi resources....",
    Get_Resource_Error: "Error while Fetching Swizi resources",
    Show_HTML: "HTML view",
    Show_Editor: "Editor view",
    Format_Code: "Format HTML code",
    ShowMore: "Show more >",
    Published: "Published {{date}}",
    PublishedAt: "Published {{date}}",
    News: "News",
    InTheSameTopic: "In the same topic",
    Event: "Event",
    Event_description: "Create an event to get it on the news",
    Event_startDate: "Start",
    Event_endDate: "End",
    Event_date_place: "Date and place of the event",
    Event_from: "From",
    Event_to: "To",
  },
};
