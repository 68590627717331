import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const updateOnboarding = thunk(
  "onboarding/updateOnboarding",
  async ({ viewId, onboarding }, { getState }) => {
    await DWMConnector.updateOnboarding(viewId, onboarding);
  },
);

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const cateringSlice = createSlice({
  name: "onboarding",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default cateringSlice.reducer;
