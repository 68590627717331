import { Button, Image, Typography } from "antd";

import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { ReactComponent as Confirm } from "../../../assets/svg/order_confirmed.svg";
import { ReactComponent as Apple } from "../../../assets/svg/apple-badge.svg";
import { ReactComponent as Google } from "../../../assets/svg/google-play-badge.svg";

import "./Password.less";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Glyph from "../../Common/Glyph/Glyph";

const Confirmation = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const login = history?.location?.state?.login;
  return (
    <div className="password__layout">
      <div className="password__bg">
        <Image src={Logo} style={{ height: "58px" }} preview={false} />
        <Typography.Title level={4} className="processing">
          {t("Public.activated")}
        </Typography.Title>
        <Typography.Paragraph style={{ marginBottom: "30px" }}>
          {process.env.REACT_APP_PORTAIL !== "-" ? t("Public.connected") : t("Public.connected2")}{" "}
          {login}.
        </Typography.Paragraph>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Confirm />
        </div>
        <Typography.Paragraph style={{ marginTop: "30px", marginBottom: "20px" }}>
          {process.env.REACT_APP_PORTAIL !== "-" ? t("Public.upload") : t("Public.upload2")}
        </Typography.Paragraph>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href={process.env.REACT_APP_STORE_IOS} style={{ marginRight: "16px" }}>
            <Apple />
          </a>
          <a href={process.env.REACT_APP_STORE_ANDROID} style={{ marginRight: "16px" }}>
            <Google />
          </a>
          {process.env.REACT_APP_PORTAIL !== "-" && (
            <a href={process.env.REACT_APP_PORTAIL}>
              <Button
                style={{
                  backgroundColor: "black",
                  border: "none",
                  color: "white",
                  borderRadius: "5px",
                  height: "42px",
                }}
                icon={<Glyph name="public" />}
              >
                Web
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
