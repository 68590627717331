import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  cgu: null,
  privacyPolicy: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CGU ///////////////////////////////
export const getCgu = thunk("contractPolicy/getCgu", async ({ os, locale }) => {
  const Cgu = await DWMConnector.getCgu(os, locale);
  return Cgu;
});

EXTRA_REDUCERS[getCgu.fulfilled] = (state, action) => {
  state.cgu = action.payload;
};

///////////////////////// PRIVACY POLICY ///////////////////////////////
export const getPrivacyPolicy = thunk("contractPolicy/getPrivacyPolicy", async ({ os, locale }) => {
  const Cgu = await DWMConnector.getPrivacyPolicy(os, locale);
  return Cgu;
});

EXTRA_REDUCERS[getPrivacyPolicy.fulfilled] = (state, action) => {
  state.privacyPolicy = action.payload;
};

///////////////////////// ACCEPT CGU ///////////////////////////////
export const acceptCgu = thunk("contractPolicy/getPrivacyPolicy", async () => {
  const Cgu = await DWMConnector.acceptCgu();
  return Cgu;
});

const ContractPolicy = createSlice({
  name: "ContractPolicy",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default ContractPolicy.reducer;
