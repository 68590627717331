import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Comment, Spin, Typography, Input, Button, Popover } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import {
  addCommentNews,
  deleteCommentnews,
  likesComments,
  listCommentNews,
} from "../../../../services/redux/services/NewsWS";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../News.module.less";
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../../../../services/api/Firebase/Firebase";

const analytics = getAnalytics(app);

const { TextArea } = Input;

const Comments = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id, sectionId } = useParams();
  const { keycloak } = useKeycloak();
  const { colors } = useDesignTokens();

  const userData = useSelector((state) => state.userWS.userData);
  const commentNews = useSelector((state) => state.newsWS.comment);
  const isLoading = useSelector(createLoadingSelector(["news/listCommentNews"]));

  const [comment, setComment] = useState("");

  const onchangeComment = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    dispatch(
      listCommentNews({
        itemId: parseInt(id),
        date: moment().toISOString(),
        locale: i18n.language.split("-")[0],
        viewId: sectionId,
      }),
    );
  }, [dispatch, i18n.language, id, sectionId]);

  return (
    <div style={{ marginTop: "16px" }}>
      <Typography.Text strong style={{ fontSize: "24px" }}>
        {t("news.comments")}
      </Typography.Text>
      <div style={{ display: "flex", flexFlow: "row", marginTop: "8px" }}>
        <Avatar
          src={`${userData?.profile?.photoUrl}?access_token=${keycloak?.token}`}
          style={{
            height: "30px",
            width: "30px",
            marginRight: "16px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontSize: "10px",
          }}
        >
          {`${userData?.profile?.firstName} ${userData?.profile?.lastName}`
            .toUpperCase()
            .split(" ")
            .map((i) => i.charAt(0).toUpperCase())
            .join("")}
        </Avatar>
        <TextArea
          className={styles.inputComment}
          rows={2}
          placeholder={t("news.comment.write")}
          onChange={onchangeComment}
          value={comment}
        />
        <div style={{ display: "flex", justifyContent: "flex-end", flexFlow: "column" }}>
          <Button
            style={{ marginLeft: "8px" }}
            type="primary"
            size="medium"
            icon={<Glyph name="send" />}
            onClick={() => {
              dispatch(
                addCommentNews({
                  itemId: parseInt(id),
                  comment: comment,
                  locale: i18n.language.split("-")[0],
                  viewId: sectionId,
                }),
              )
                .unwrap()
                .then(() => {
                  logEvent(analytics, `news_add_comment`);
                  dispatch(
                    listCommentNews({
                      itemId: parseInt(id),
                      locale: i18n.language.split("-")[0],
                      viewId: sectionId,
                    }),
                  );
                });
              setComment("");
            }}
          >
            {t("news.comment.send")}
          </Button>
        </div>
      </div>
      <Spin spinning={isLoading}>
        {commentNews?.map((c, index) => (
          <div key={index} style={{ display: "flex", flexFlow: "row", marginTop: "16px" }}>
            <Avatar
              src={`${c?.user?.photoUrl}?access_token=${keycloak?.token}`}
              style={{
                height: "30px",
                width: "30px",
                marginRight: "16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                fontSize: "10px",
              }}
            >
              {`${c?.user?.displayName}`
                .toUpperCase()
                .split(" ")
                .map((i) => i.charAt(0).toUpperCase())
                .join("")}
            </Avatar>
            <Comment
              className={styles.comment}
              author={
                <Typography.Text strong style={{ color: colors.primary_dark }}>
                  {c?.user?.displayName}
                </Typography.Text>
              }
              content={
                <div style={{ display: "flex", flexFlow: "column", position: "relative" }}>
                  <Typography.Text style={{ fontSize: "14px", marginBottom: "16px" }}>
                    {c?.comment}
                  </Typography.Text>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "10px", cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          likesComments({
                            itemId: id,
                            commentId: c.id,
                            like: !c.isLiked,
                            viewId: sectionId,
                          }),
                        )
                          .unwrap()
                          .then(() => {
                            logEvent(analytics, `news_like_comment`);
                            dispatch(
                              listCommentNews({
                                itemId: parseInt(id),
                                locale: i18n.language.split("-")[0],
                                viewId: sectionId,
                              }),
                            );
                          });
                      }}
                    >
                      {c.isLiked ? (
                        <Glyph name="favorite" style={{ color: colors?.error_light }} />
                      ) : (
                        <Glyph name="favorite_bordered" />
                      )}
                      {c?.nbLikes}
                    </Typography.Text>
                    <Popover
                      content={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          style={{ color: colors?.error_light }}
                          onClick={() => {
                            dispatch(
                              deleteCommentnews({
                                viewId: sectionId,
                                itemId: parseInt(id),
                                commentId: c.id,
                              }),
                            )
                              .unwrap()
                              .then(() => {
                                logEvent(analytics, `news_delete_comment`);
                                dispatch(
                                  listCommentNews({
                                    itemId: parseInt(id),
                                    locale: i18n.language.split("-")[0],
                                    viewId: sectionId,
                                  }),
                                );
                              });
                          }}
                        >
                          {t("news.editor.delete")}
                        </a>
                      }
                      placement="right"
                      trigger="click"
                    >
                      <div style={{ cursor: "pointer" }}>
                        <Glyph name="more_horiz" />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              datetime={<Typography.Text>{moment(c?.createdAt).format("L - LT")}</Typography.Text>}
            />
          </div>
        ))}
      </Spin>
    </div>
  );
};

export default Comments;
