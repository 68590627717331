import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Button, Input, Space, Spin, Typography } from "antd";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";

import Glyph from "../../../Common/Glyph/Glyph";
import Tile from "../../../Common/Tile/Tile";

import { listFollowed, listPresence } from "../../../../services/redux/services/SpaceService";

import { useTranslation } from "react-i18next";
import { ReactComponent as Filter } from "../../../../assets/svg/filterSpaas.svg";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import PhoneBook from "../../PhoneBook/PhoneBook";
import { PhoneBookTitleTarget } from "../../PhoneBook/PhoneBookTitle";

const GUTTER_SIZE = 8;

const PeopleFollow = ({ siteId, date }) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const presence = useSelector((state) => state.spaceServiceWS.presence?.followed);
  const followed = useSelector((state) => state.spaceServiceWS.followed);
  const profile = useSelector((state) => state.userWS.userData?.profile);

  const isLoading = useSelector(createLoadingSelector(["spaceService/listPresence"]));

  const [showPhonebook, setShowPhonebook] = useState(false);
  const [searchPresence, setSearch] = useState("");

  useEffect(() => {
    if (!siteId && !date) return;
    dispatch(listPresence({ siteId: siteId, date: date }));
  }, [date, dispatch, siteId]);

  useEffect(() => {
    dispatch(listFollowed());
  }, [dispatch]);

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const searchResult = presence
    ?.filter((value) =>
      searchPresence ? new RegExp(searchPresence, "i").test(value.displayName) : true,
    )
    .sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

  const Row = ({ style, index }) => {
    const site = sites?.find((s) => s.id === searchResult[index]?.siteId);
    const sector = site?.sectors?.find((s) => s.id === searchResult[index]?.sectorId);
    return (
      <Tile
        style={{
          ...style,
          height: style.height - GUTTER_SIZE,
          padding: GUTTER_SIZE,
          justifyContent: "normal",
          margin: "0",
          cursor: "default",
        }}
      >
        <div style={{ marginRight: "16px" }}>
          <Avatar src={`${searchResult[index]?.photoUrl}?access_token=${keycloak.token}`}>
            {`${searchResult[index]?.displayName}`
              .toUpperCase()
              .split(" ")
              .map((i) => i.charAt(0).toUpperCase())
              .join("")}
          </Avatar>
        </div>
        <div style={{ display: "flex", flexFlow: "column" }}>
          <Typography.Paragraph strong>{searchResult[index]?.displayName}</Typography.Paragraph>
          <Space>
            <Typography.Text>{site?.title}</Typography.Text>
            {site?.spaceBooking?.type !== 1 && (
              <>
                {site && sector && <Typography.Text strong>•</Typography.Text>}
                <Typography.Text>{sector?.title}</Typography.Text>
              </>
            )}
          </Space>
        </div>
      </Tile>
    );
  };
  return showPhonebook ? (
    <>
      <PhoneBookTitleTarget />
      <PhoneBook onBack={() => setShowPhonebook(false)} manage={false} />
    </>
  ) : (
    <>
      <Typography.Paragraph>{t("spas.people.present")}</Typography.Paragraph>
      <Typography.Paragraph strong type="secondary">
        {t("spas.people.whoResa")}
      </Typography.Paragraph>
      <Input
        style={{ width: "100%", marginTop: "16px" }}
        placeholder={t("spas.people.find")}
        prefix={<Glyph name="search" />}
        onChange={handleSearch}
        value={searchPresence}
      />
      {!isEmpty(followed) || profile?.isManager ? (
        isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <Spin></Spin>
          </div>
        ) : (
          <div>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {searchResult?.length === 0 ? (
                <div style={{ paddingTop: "20px" }}>
                  <Typography.Paragraph strong type="secondary">
                    {t("spas.people.noResult")}
                  </Typography.Paragraph>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="primary"
                      icon={<Glyph name="person_add" />}
                      style={{ marginTop: "20px" }}
                      onClick={() => setShowPhonebook(true)}
                    >
                      {t("spas.manage.addPerson")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <Typography.Paragraph style={{ marginTop: "16px" }}>
                    {t("All")}
                  </Typography.Paragraph>
                  <List height={400} itemCount={searchResult?.length} itemSize={80}>
                    {Row}
                  </List>
                </>
              )}
            </Space>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "column",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          <Filter style={{ height: "150px" }} />
          <Typography.Paragraph strong style={{ paddingTop: "16px" }}>
            {t("spas.manage.noFollower")}
          </Typography.Paragraph>
          <Typography.Paragraph strong type="secondary">
            {t("spas.manage.searchFollower")}
          </Typography.Paragraph>
          <Button
            type="primary"
            icon={<Glyph name="person_add" />}
            style={{ marginTop: "20px" }}
            onClick={() => setShowPhonebook(true)}
          >
            {t("spas.manage.addPerson")}
          </Button>
        </div>
      )}
    </>
  );
};

export default PeopleFollow;
