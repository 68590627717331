import { propOr } from "ramda";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useArrayTranslate = () => {
  const { i18n } = useTranslation();

  const tArr = useCallback(
    (arr = [], strict = false) => {
      let translation = arr.locale === i18n.language;
      if ((!translation || !translation.content) && !strict) {
        translation = arr.content;
      }
      return propOr("", "content", translation);
    },
    [i18n.language],
  );

  return { tArr };
};

export const withArrayTranslate =
  (Component) =>
  ({ ...props }) => {
    const { tArr } = useArrayTranslate();
    return <Component tArr={tArr} {...props} />;
  };
