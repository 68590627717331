import { useState } from "react";
import { Button, Image, Modal, Typography } from "antd";
import styles from "./Onboarding.module.less";
import clsx from "clsx";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateOnboarding } from "../../../services/redux/services/OnboardingWS";
import { getUserData } from "../../../services/redux/services/UserWS";

const Onboarding = ({ open, onCancel }) => {
  const { colors } = useDesignTokens();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let siteId = localStorage.getItem("siteId");

  const [activeIndex, setActiveIndex] = useState(0);

  const viewType = useSelector((state) => state.clientsWS.lastView);
  const userData = useSelector((state) => state.userWS.userData);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  let spaceBookingArray = null;

  for (const key in ONBOARDINGS) {
    if (key.toUpperCase().includes(viewType?.toUpperCase())) {
      spaceBookingArray = ONBOARDINGS[key];
      break;
    }
  }

  const handleNext = () => {
    if (activeIndex < spaceBookingArray.length - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleCheck = () => {
    dispatch(
      updateOnboarding({
        viewId: userData?.views?.find((v) => v.type === viewType)?.id,
        onboarding: false,
      }),
    )
      .unwrap()
      .then(() => {
        onCancel();
        setActiveIndex(0);
        dispatch(
          getUserData({ locale: i18n.language.split("-")[0], siteId: siteId ? siteId : "" }),
        );
      });
  };

  return (
    <Modal destroyOnClose closable={false} open={open} onOk={onCancel} width={350} footer={false}>
      {spaceBookingArray[activeIndex] && (
        <div>
          <Image src={spaceBookingArray[activeIndex]} preview={false} />
          <Typography.Title level={5} style={{ textAlign: "center", marginTop: "8px" }}>
            {t(
              `onboarding_spacebooking_${
                userData?.campus?.find((v) => v.id === mapData?.id)?.spaceBooking?.type
              }_${[activeIndex + 1]}_title`,
              { ns: "csv" },
            )}
          </Typography.Title>
          <Typography.Text>
            {t(
              `onboarding_spacebooking_${
                userData?.campus?.find((v) => v.id === mapData?.id)?.spaceBooking?.type
              }_${[activeIndex + 1]}_text`,
              { ns: "csv" },
            )}
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              marginTop: "16px",
              padding: "1px",
            }}
          >
            {spaceBookingArray.map((_, index) => (
              <div
                key={index}
                className={clsx(
                  { [styles["active-slider"]]: index === activeIndex },
                  styles["slider"],
                )}
              ></div>
            ))}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          marginTop: "16px",
        }}
      >
        {activeIndex < spaceBookingArray.length - 1 ? (
          <>
            <div style={{ cursor: "pointer" }} onClick={handleCheck}>
              <Typography.Text strong>{t("Skip")}</Typography.Text>
            </div>
            <div
              onClick={handleNext}
              style={{
                cursor: "pointer",
              }}
            >
              <Typography.Text
                strong
                style={{
                  color: colors?.interactive_03,
                }}
              >
                {t("Next_onboarding")} <Glyph name="skip_next" />
              </Typography.Text>
            </div>
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Button
              type="primary"
              icon={<Glyph name="check" />}
              style={{ width: "100%" }}
              onClick={handleCheck}
            >
              {t("toStart")}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Onboarding;
