import { Button, Modal, Typography, message } from "antd";
import { getNewsEditor, hideNews } from "../../../../../services/redux/services/NewsWS";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const HideNews = ({ isModalOpen, onCancel, newsHide }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sectionId } = useParams();

  return (
    <Modal
      title={
        <Typography.Text strong style={{ display: "flex", justifyContent: "center" }}>
          {!newsHide?.hidden ? t("news.editor.hide.title") : t("news.editor.noHide.title")}
        </Typography.Text>
      }
      open={isModalOpen}
      closable={false}
      footer={[
        <Button style={{ width: "170px" }} ghost onClick={() => onCancel()}>
          {t("Cancel")}
        </Button>,
        <Button
          style={{ width: "170px" }}
          type="primary"
          onClick={() => {
            dispatch(hideNews({ itemId: newsHide?.id, hide: !newsHide?.hidden, viewId: sectionId }))
              .unwrap()
              .then(() => {
                message.success(
                  t(!newsHide?.hidden ? "news.editor.hide.success" : "news.editor.noHide.success"),
                );
                dispatch(
                  getNewsEditor({
                    viewId: sectionId,
                    topic: null,
                    init: true,
                  }),
                );
                onCancel();
              })
              .catch((error) => {
                console.error(error);
                message.error(t("news.editor.hide.error"));
              });
          }}
        >
          {!newsHide?.hidden ? t("news.editor.hide") : t("news.editor.noHide")}
        </Button>,
      ]}
    >
      <Typography.Text strong>
        {!newsHide?.hidden ? t("news.editor.hide.content") : t("news.editor.noHide.content")}
      </Typography.Text>
    </Modal>
  );
};

export default HideNews;
