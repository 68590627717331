import { Button, Card, Col, Image, Row, Typography, message, Modal, Input } from "antd";
import moment from "moment";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../News.module.less";
import Meta from "antd/lib/card/Meta";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteNews,
  getNewsEditor,
  getTopics,
  removeItemEditor,
} from "../../../../services/redux/services/NewsWS";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import { isEmpty, isNil } from "ramda";
import EditorTopics from "./EditorTopics";
import HideNews from "./component/HideNews";

const EditorList = () => {
  const { colors } = useDesignTokens();
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { sectionId } = useParams();

  const news = useSelector((state) => state.newsWS.newsEditor);
  const topics = useSelector((state) => state.newsWS.topics);
  const userData = useSelector((state) => state.userWS.userData);

  const [topicFilter, setTopicFilter] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moreNews, setMoreNews] = useState();
  const [hide, setHide] = useState(false);
  const [newsHide, setNewsHide] = useState(null);
  const [searchNews, setSearchNews] = useState("");

  useEffect(() => {
    dispatch(
      getNewsEditor({
        viewId: sectionId,
        topic: topicFilter,
        init: true,
      }),
    );
  }, [dispatch, i18n.language, sectionId, topicFilter]);

  useEffect(() => {
    dispatch(getTopics({ viewId: sectionId }));
  }, [dispatch, sectionId]);

  const onClick = (e) => {
    setTopicFilter(e.target.value);
  };

  const confirm = (s) => {
    Modal.confirm({
      title: <Typography.Text>{t("news.editor.confirm.title")}</Typography.Text>,
      content: <Typography.Text>{t("news.editor.cnfirm.content")}</Typography.Text>,
      okText: <div block>{t("Yes")}</div>,
      onOk: () => {
        dispatch(deleteNews({ itemId: s.id, viewId: sectionId }))
          .unwrap()
          .then(() => {
            message.success("News supprimé");
            dispatch(
              getNewsEditor({
                topic: topicFilter,
                init: true,
                viewId: sectionId,
              }),
            );
          });
      },
      cancelText: <div block>{t("No")}</div>,
    });
  };

  const handleSearch = (evt) => {
    setSearchNews(evt.target.value);
  };

  const searchResult = news.filter((value) =>
    searchNews ? new RegExp(searchNews, "i").test(value?.title[i18n.language.split("-")[0]]) : true,
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          marginBottom: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexFlow: "row" }}>
          <NavLink
            to="editor/new"
            onClick={() => {
              dispatch(removeItemEditor());
            }}
          >
            <Button
              style={{ marginRight: "8px" }}
              type="primary"
              className={styles.btn}
              icon={<Glyph name="add" style={{ fontSize: "24px", marginLeft: "-7px" }} />}
            >
              <Typography.Text
                strong
                style={{
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
              >
                {t("news.editor.new")}
              </Typography.Text>
            </Button>
          </NavLink>
          <Button
            type="secondary"
            className={styles.btn}
            icon={<Glyph name="folder" style={{ fontSize: "24px", marginLeft: "-7px" }} />}
            onClick={() => setIsModalOpen(true)}
          >
            <Typography.Text
              strong
              style={{
                color: "white",
                textTransform: "uppercase",
                fontSize: "14px",
              }}
            >
              {t("news.editor.new.topic")}
            </Typography.Text>
          </Button>
        </div>

        <Input
          placeholder={t("news.editor.find")}
          prefix={<Glyph name="search" />}
          style={{ width: "20%", marginLeft: "16px", height: "50px" }}
          onChange={handleSearch}
          value={searchNews}
        />
      </div>
      <div
        style={{
          overflowX: "auto",
          zIndex: "999",
          display: "flex",
          height: "auto",
          padding: "10px",
        }}
      >
        <Button
          style={{ fontSize: "14px", marginLeft: "0" }}
          onClick={() => {
            setTopicFilter([]);
          }}
          className={
            isNil(topicFilter) || isEmpty(topicFilter) ? "active-allFilter" : "btnAllFilter"
          }
        >
          <Glyph name="group_work" style={{ verticalAlign: "-2px", fontSize: "16px" }} />
          {t("news.edior.all.topics")}
        </Button>
        {topics?.map((topic) => (
          <Checkbox
            key={topic.id}
            value={topic.id}
            text={topic.label[i18n.language.split("-")[0]]}
            onClick={onClick}
            checked={topicFilter && parseInt(topicFilter) === topic.id}
          />
        ))}
      </div>
      {!isEmpty(topicFilter) ? (
        <Typography.Text strong style={{ fontSize: "24px" }}>
          {t("news.editor.published.topics")}{" "}
          {topics?.find((t) => t.id === parseInt(topicFilter))?.label[i18n.language.split("-")[0]]}
        </Typography.Text>
      ) : (
        <Typography.Text strong style={{ fontSize: "24px" }}>
          {t("news.edior.all.topics")}
        </Typography.Text>
      )}

      <Row gutter={[16, 16]}>
        {searchResult?.map((item, index) => (
          <Col sm={24} md={24} lg={12} xl={8} xxl={6} key={index}>
            <Card
              style={{ cursor: "pointer", backgroundColor: item?.hidden && colors.grey_20 }}
              cover={
                <div style={{ boxShadow: "none" }} className={styles["newsPictures"]}>
                  {!isNil(item?.header) ? (
                    <div className={styles["hideNews"]}>
                      {item?.hidden && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: colors?.grey_20,
                            zIndex: 1,
                            opacity: 0.5,
                          }}
                        ></div>
                      )}
                      <Image
                        preview={false}
                        src={`${item?.header[i18n.language.split("-")[0]]?.url}?access_token=${
                          keycloak.token
                        }`}
                      />
                    </div>
                  ) : (
                    <div
                      className={styles["noPictures"]}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "150px",
                        width: "100%",
                        backgroundColor: colors.grey_40,
                        opacity: 0.3,
                        zIndex: 1,
                      }}
                    >
                      <Glyph
                        style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                        name="article"
                      />
                    </div>
                  )}

                  <div className={styles["newsPictures_btn"]}>
                    <NavLink to={`editor/${item.id}`}>
                      <Button
                        style={{
                          color: "white",
                          display: "flex",
                          flexFlow: "column",
                          borderRadius: "0px",
                          height: "100%",
                          fontSize: "12px",
                          border: "none",
                        }}
                        icon={<Glyph name="edit" />}
                      >
                        {t("news.editor.update")}
                      </Button>
                    </NavLink>

                    <Button
                      style={{
                        color: "white",
                        display: "flex",
                        flexFlow: "column",
                        borderRadius: "0px",
                        height: "100%",
                        fontSize: "12px",
                        border: "none",
                      }}
                      icon={<Glyph name={!item?.hidden ? "visibility" : "visibility_off"} />}
                      onClick={() => {
                        setHide(true);
                        setNewsHide(item);
                      }}
                    >
                      {!item?.hidden ? t("news.editor.hide") : t("news.editor.noHide")}
                    </Button>
                    <Button
                      style={{
                        color: "white",
                        display: "flex",
                        flexFlow: "column",
                        borderRadius: "0px",
                        height: "100%",
                        fontSize: "12px",
                        border: "none",
                      }}
                      icon={<Glyph name="delete" />}
                      onClick={() => confirm(item)}
                    >
                      {t("news.editor.delete")}
                    </Button>
                  </div>
                </div>
              }
            >
              <Meta
                title={
                  <Typography.Title level={5}>
                    {item?.title[i18n.language.split("-")[0]]}
                  </Typography.Title>
                }
                description={
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography.Text type="secondary">
                      <Glyph
                        name="schedule"
                        style={{ marginRight: "4px", fontSize: "12px", verticalAlign: "0px" }}
                      />
                      {moment(item.startDate).format("L")}
                    </Typography.Text>
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                      {item?.authorizeComment && (
                        <>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: "12px", marginRight: "4px" }}
                          >
                            <Glyph name="comment" style={{ marginRight: "4px" }} />
                            {item?.nbComments}
                          </Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: "12px", marginRight: "4px" }}
                          >
                            {item?.isLiked ? (
                              <Glyph
                                name="favorite"
                                style={{ marginRight: "4px", color: colors?.error_light }}
                              />
                            ) : (
                              <Glyph name="favorite_border" style={{ marginRight: "4px" }} />
                            )}

                            {item?.nbLikes}
                          </Typography.Text>
                          <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                            <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                            {item?.nbViews}
                          </Typography.Text>
                        </>
                      )}
                    </div>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>

      {(moreNews?.news?.length === 12 || isNil(moreNews?.news)) && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Button
            ghost
            icon={<Glyph name="add" />}
            style={{ width: "300px" }}
            onClick={() => {
              dispatch(
                getNewsEditor({
                  topic: topicFilter,
                  date: moment(news.slice(-1)[0]?.startDate).toISOString(),
                  viewId: sectionId,
                }),
              )
                .unwrap()
                .then((data) => setMoreNews(data));
            }}
          >
            {t("SeeMore")}
          </Button>
        </div>
      )}

      <EditorTopics isModalOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} />
      <HideNews isModalOpen={hide} onCancel={() => setHide(false)} newsHide={newsHide} />
    </>
  );
};

export default EditorList;
