import "./ReleaseRoom.less";
import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { Image } from "antd";
import { useTranslation } from "react-i18next";

const SuccessKeep = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="ReleaseRoom__layout">
      <div className="ReleaseRoom__bg">
        <Image src={Logo} style={{ height: "200px" }} preview={false} />
        <h1 style={{ fontWeight: "bold" }}>{t("Public.keep.kept")}</h1>
        <h3>
          {t("public.keep.spaces", {
            title: data?.room?.title,
            startHour: data?.slot?.startHour,
            endHour: data?.slot?.endHour,
          })}
          <br />
          <br />
          {t("Public.keep.noAlert")}
        </h3>
      </div>
    </div>
  );
};

export default SuccessKeep;
