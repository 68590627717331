import { Button, Input, Space, Table, Typography } from "antd";
import moment from "moment";
import { pluck } from "ramda";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../../../hook/useDebounce";
import useDidUpdateEffect from "../../../../../hook/useDidUpdate";
import { createLoadingSelector } from "../../../../../services/redux/managers/LoadingManager";
import { selectedCampus } from "../../../../../services/redux/services/UserWS";
import Glyph from "../../../../Common/Glyph/Glyph";
import { ActionBannerSource } from "../ActionBanner";
import CreateRequestModal from "../CreateRequestModal";
import { SORT } from "../SpaceBookingDashboard";

const STATUS = "UNASSIGNED";

const Unassigned = ({ currentDate, data, onListRequests }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const isLoading = useSelector(createLoadingSelector(["spaceServiceAdmin/listRequests"]));
  const site = useSelector(selectedCampus);
  const sectorId = useSelector((state) => state.spaceServiceAdminWS.selectedSectorId);

  const searchInput = useRef();
  const searchInputDebounced = useDebounce(searchInputValue, 500);
  useDidUpdateEffect(() => {
    if (!data) return;
    onListRequests({
      status: STATUS,
      page: 1,
      size: +data.size,
      search: searchInputDebounced,
      sortDir: data.direction,
    })
      .unwrap()
      .then(() => {
        searchInput.current.focus();
      });
    // Voluntarily omiting some deps to run the hook only with search input
  }, [onListRequests, searchInputDebounced]);

  const onPagination = (page, size) => {
    onListRequests({
      status: STATUS,
      page,
      size,
      search: searchInputDebounced,
      sortDir: data.direction,
    });
  };

  const handleSubmit = (values) => {
    history.push("/spaceBooking/admin", {
      slots: selectedRows.map((user) => ({
        status: "PENDING",
        date: moment(values.startDate).toISOString(),
        request: {
          ...values,
          siteId: site.id,
          sectorId,
          user,
          startDate: moment(values.startDate).toISOString(),
          endDate: moment(values.endDate || values.startDate).toISOString(),
        },
      })),
    });
  };

  const columns = [
    {
      title: t("spas.admin.tabs.resident"),
      dataIndex: ["displayName"],
      sorter: true,
      sortOrder: SORT[data?.direction],
      onHeaderCell: () => ({
        onClick: () => {
          onListRequests({
            status: STATUS,
            page: +data.page,
            size: +data.size,
            search: searchInputDebounced,
            sortDir: data.direction === "ASC" ? "DESC" : "ASC",
          });
        },
      }),
    },
    {
      title: t("spas.admin.tabs.manager"),
      dataIndex: ["manager", "displayName"],
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Input
          ref={searchInput}
          placeholder="Rechercher une personne"
          style={{ maxWidth: 400 }}
          disabled={isLoading}
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />
        <Table
          loading={isLoading}
          rowKey={"id"}
          bordered={false}
          style={{ marginLeft: -20, marginRight: -20, maxWidth: "none" }}
          size="small"
          columns={columns}
          dataSource={data?.items || []}
          rowSelection={{
            selectedRowKeys: pluck("id", selectedRows),
            onChange: (_, rows) => setSelectedRows(rows),
          }}
          pagination={
            data
              ? {
                  current: +data.page,
                  pageSize: +data.size,
                  total: data.totalItems,
                  onChange: onPagination,
                }
              : undefined
          }
        />
      </Space>

      <CreateRequestModal
        visible={showCreateModal ? currentDate : false}
        onSubmit={handleSubmit}
        onCancel={() => setShowCreateModal(false)}
      />

      <ActionBannerSource visible={selectedRows.length}>
        <Typography.Text>
          {t("spas.admin.tabs.selected", { count: selectedRows.length })}
        </Typography.Text>
        <Space>
          <Button
            icon={<Glyph name="desk" />}
            type="primary"
            onClick={() => setShowCreateModal(true)}
          >
            {t("spas.request.actions.approve", { count: selectedRows.length })}
          </Button>
        </Space>
      </ActionBannerSource>
    </>
  );
};

export default Unassigned;
