// import Map from "./FindOrder/FindOrder";
// import Map from "./Mapbox/Mapbox";

import { cond, equals } from "ramda";
import { lazy, Suspense } from "react";

const FindOrder = lazy(() => import("./FindOrder/FindOrder"));
const Mapbox = lazy(() => import("./Mapbox/Mapbox"));

const Component = cond([
  [equals("findorder"), () => FindOrder],
  [equals("mapbox"), () => Mapbox],
])(process.env.REACT_APP_MAP_PROVIDER || "findorder");

const Map = (props) => {
  return (
    <Suspense fallback={<div>Chargement...</div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default Map;
