import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TitleSource } from "../../Common/Teleporters/Title";
import SpaceBookingDashboard from "./Admin/SpaceBookingDashboard";
import SpaceBookingMap from "./Admin/SpaceBookingMap";

const SpaceBooking = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const slots = location.state?.slots;

  return (
    <>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          {t("spas")}
        </Typography.Title>
      </TitleSource>
      {slots ? <SpaceBookingMap toAssign={slots} /> : <SpaceBookingDashboard />}
    </>
  );
};

export default SpaceBooking;
