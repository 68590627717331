import { useKeycloak } from "@react-keycloak/web";
import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { Button, Col, Form, Image, Layout, Row, Typography } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Login.less";

import QrCode from "../Home/QrCode/QrCode";

import { useEffect } from "react";

const Login = () => {
  const [form] = Form.useForm();

  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const login = useCallback(
    (type) => {
      keycloak?.login({ idpHint: type });
    },
    [keycloak],
  );

  const authResult = new URLSearchParams(window.location.search);
  const reload = authResult.get("reload");

  useEffect(() => {
    if (reload === true) {
      keycloak?.login();
    }
  }, [keycloak, reload]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Layout className="Login__layout">
        <Form form={form} name="login" className="form__login">
          <Image preview={false} src={Logo} />
          <Typography.Title level={4} strong>
            {t("Welcome")}
          </Typography.Title>
          <Typography.Paragraph>{t("OnDashboard")}</Typography.Paragraph>
          <Form.Item>
            <Button
              block
              type="secondary"
              onClick={() => {
                login(process.env.REACT_APP_IDP);
              }}
              style={{ marginBottom: "8px" }}
            >
              {t("Collaborators", { ns: "csv" })}
            </Button>
            {process.env.REACT_APP_NOAZURE === "true" ? (
              <Button
                block
                ghost
                onClick={() => {
                  login("");
                }}
              >
                {t("Guests")}
              </Button>
            ) : null}
          </Form.Item>
          <Link
            style={{ textDecoration: "underline", marginRight: "16px", fontSize: "12px" }}
            to="/public/cgu"
          >
            CGU
          </Link>
          <Link
            style={{ textDecoration: "underline", fontSize: "12px" }}
            to="/public/privacyPolicy"
          >
            {t("PrivacyPolicy")}
          </Link>
        </Form>
      </Layout>
      <Row gutter={[10, 10]} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col xs={14} sm={14} md={8} lg={8} xl={8} xxl={6}>
          {process.env.REACT_APP_QRCODE === "true" && <QrCode />}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
