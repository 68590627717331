import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  filesInfos: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const addFilesExternalUser = thunk(
  "manageExternal/addFilesExternalUser",
  async ({ siteId, file, locale }, { dispatch }) => {
    const addFilesExternalUser = await DWMConnector.addFilesExternalUser(siteId, file, locale);
    return addFilesExternalUser;
  },
);

EXTRA_REDUCERS[addFilesExternalUser.fulfilled] = (state, action) => {
  state.filesInfos = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const ManageExternalSlice = createSlice({
  name: "manageExternal",
  initialState: INITIAL_STATE,
  reducers: {
    removeFilesInfos(state, action) {
      state.filesInfos = null;
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const { removeFilesInfos } = ManageExternalSlice.actions;
export default ManageExternalSlice.reducer;
