import moment from "moment";

const checkDelayRule = (desiredDate, restriction, now = moment()) => {
  let daysBefore = 0;
  let hoursBefore = 0;
  let hasDaysBefore = restriction.match(/(^[0-9]{1,3}days)+/g);
  let hasHoursBefore = restriction.match(/([0-9]{1,2}h)+/g);
  if (hasDaysBefore) daysBefore = parseInt(hasDaysBefore[0]);
  if (hasHoursBefore) hoursBefore = parseInt(hasHoursBefore[0]);

  let max;
  if (daysBefore) {
    let minutesBefore = 0;

    if (!hasHoursBefore) {
      hoursBefore = moment(desiredDate).hours();
      minutesBefore = moment(desiredDate).minutes();
    }
    max = moment(desiredDate);
    max
      .set("hour", hoursBefore)
      .set("minute", minutesBefore)
      .set("second", 0)
      .set("millisecond", 0)
      .subtract(daysBefore, "days");
    return now.isBefore(max);
  } else {
    max = now;
    max.add(hoursBefore, "hour");
    return moment(desiredDate).isAfter(max);
  }
};

export default checkDelayRule;
