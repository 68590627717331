import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  placesInfos: {},
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const getPlacesInfos = thunk("agora/getPlacesInfos", async ({ viewId, locale }) => {
  const placesInfos = await DWMConnector.getPlacesInfos(viewId, locale);
  return { placesInfos, viewId };
});

EXTRA_REDUCERS[getPlacesInfos.fulfilled] = (state, action) => {
  state[action.payload.viewId] = state[action.payload.viewId] || {};
  state[action.payload.viewId].placesInfos = action.payload.placesInfos;
};
/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const agoraSlice = createSlice({
  name: "agora",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default agoraSlice.reducer;
