import { Space, Typography } from "antd";
import { groupBy } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { removeFilter, setFilter } from "../../../../services/redux/services/FastbookingWS";
import useMap from "../../../Common/Map/useMap";
import Tile from "../../../Common/Tile/Tile";
import { useTranslation } from "react-i18next";

const NoResults = () => {
  const { setFloorByReference } = useMap();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const campus = useSelector((state) => state.clientsWS.campus);

  const space = Object.entries(
    groupBy(
      (room) =>
        campus?.mapData?.floors.find((r) => r?.reference === room?.resources?.floorReference)
          ?.displayName,
      allRoomFastbooking?.rooms,
    ),
  );

  const changeFloor = (value) => {
    setFloorByReference(value);
    dispatch(
      setFilter({
        ...filters,
        floor: campus?.mapData?.floors?.find((floor) => floor.reference === value)?.id,
      }),
    );
  };

  return (
    <>
      <Typography.Paragraph strong>{t("NoResultFloor")}</Typography.Paragraph>
      <Typography.Paragraph>{t("TrySearching")}</Typography.Paragraph>
      <Typography.Paragraph strong style={{ fontSize: "17px", marginTop: "56px" }}>
        {t("AvailbleFloor")}
      </Typography.Paragraph>

      <Space style={{ width: "100%" }} direction="vertical" size="small">
        {space.sort().map((s) => (
          <>
            <Tile
              style={{ margin: "0" }}
              onClick={() => {
                changeFloor(s[0]);
              }}
            >
              <Typography.Text>
                {t("Floor")} {s[0]}
              </Typography.Text>
              <Typography.Text>{s[1].length}</Typography.Text>
            </Tile>
          </>
        ))}
      </Space>

      <Tile
        style={{ margin: "40px 0 0 0" }}
        onClick={() => {
          dispatch(removeFilter());
        }}
      >
        <Typography.Text strong>{t("Erase")}</Typography.Text>
      </Tile>
    </>
  );
};

export default NoResults;
