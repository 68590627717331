import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  occupancy: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};
let siteIdStorage = localStorage.getItem("siteId");
///////////////////////// OCCUPANCY ///////////////////////////////
export const getRoomOccupancy = thunk(
  "occupancy/getRoomOccupancy",
  async ({ roomId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find(
      (v) => v.type === "fastBooking" || v.type === "fastBookingV2",
    );
    const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
    const occupancy = await DWMConnector.getRoomOccupancy(viewId.id, roomId, siteId);
    return occupancy;
  },
);
EXTRA_REDUCERS[getRoomOccupancy.pending] = (state, action) => {
  state.occupancy = [];
};
EXTRA_REDUCERS[getRoomOccupancy.fulfilled] = (state, action) => {
  state.occupancy = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const occupancySlice = createSlice({
  name: "occupancy",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default occupancySlice.reducer;
