import { LoadingOutlined } from "@ant-design/icons";
import { Card, Spin, Typography } from "antd";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import {
  getAllRoomFastbooking,
  getRoomFastbooking,
  setFilter,
} from "../../../services/redux/services/FastbookingWS";
import { getPlanning } from "../../../services/redux/services/PlanningWS";
import Glyph from "../../Common/Glyph/Glyph";
import NoResults from "./NoResults";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import RoomDetails from "./RoomDetails";
import useDesignTokens from "../../../hook/useDesignTokens";

const FastbookingList = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors } = useDesignTokens();

  const planning = useSelector((state) => state.planningWS.planning);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);

  const isLoading = useSelector(createLoadingSelector(["fastbooking/getRoomFastbooking"]));

  // Filtre par défaut
  useEffect(() => {
    if (isNil(filters?.floor)) {
      const floor = allRoomFastbooking?.rooms?.reduce(
        (acc, r) =>
          !acc
            ? campus?.mapData?.floors.find((f) => f.reference === r.resources.floorReference)
            : acc,
        null,
      );
      dispatch(
        setFilter({
          floor: floor?.id,
        }),
      );
    }
  }, [allRoomFastbooking, campus?.mapData?.floors, dispatch, filters]);

  moment.defineLocale(i18n.language.split("-")[0], {
    relativeTime: {
      future: "dans %s",
      s: "1 sec",
      m: "1 min",
      mm: "%d mins",
      h: "%d mins",
      hh: "%d h",
      d: "1 j",
      dd: "%d j",
    },
  });

  useEffect(() => {
    if (!isEmpty(filters) && !isNil(filters)) {
      dispatch(
        getRoomFastbooking({
          locale: i18n.language.split("-")[0],
          ...filters,
          startDate: filters.startDate || moment().toISOString(),
        }),
      );
    }
  }, [dispatch, filters, i18n.language]);

  useEffect(() => {
    if (!!filters?.seats) {
      logEvent(analytics, "fast_booking_filter_capacity", {
        selected_capacity: filters?.seats,
      });
    }
  }, [filters?.seats]);

  useEffect(() => {
    if (!!filters?.startDate) {
      logEvent(analytics, "fast_booking_filter_time");
    }
  }, [filters?.startDate]);

  useEffect(() => {
    if (!!filters?.equipments) {
      logEvent(analytics, "fast_booking_filter_devices", {
        equipment_type: filters?.equipments
          ?.map((equipment) => {
            return campus?.mapData?.equipments.find((e) => e.id === equipment)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.equipments, filters?.equipments]);

  useEffect(() => {
    if (!!filters?.services) {
      logEvent(analytics, "fast_booking_filter_services", {
        service_type: filters?.services
          ?.map((service) => {
            return campus?.mapData?.services.find((e) => e.id === service)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.services, filters?.services]);

  useEffect(() => {
    dispatch(
      getPlanning({
        locale: i18n.language.split("-")[0],
        startDate: moment().toISOString(),
        endDate: moment().endOf("day").toISOString(),
      }),
    );
  }, [dispatch, i18n.language]);

  useEffect(() => {
    filters &&
      dispatch(
        getAllRoomFastbooking({
          locale: i18n.language.split("-")[0],
          ...filters,
          startDate: filters.startDate || moment().toISOString(),
          floor: null,
        }),
      );
  }, [dispatch, i18n.language, filters]);

  const isRoom = planning?.slots?.items
    .filter((f) => f.locations.length !== 0)
    ?.filter((r) => r.isOrganizer === true);

  return (
    <Card
      title={
        <>
          <Typography.Title level={5}>{t("fast_booking_title", { ns: "csv" })}</Typography.Title>
          <Typography.Text strong style={{ fontSize: "14px", color: colors.pending_light }}>
            {roomFastbooking?.rooms?.length} {t("ResultFloor")}
          </Typography.Text>
        </>
      }
      extra={
        <NavLink to="/fastBooking/filter">
          <Glyph name="tune" />
        </NavLink>
      }
      style={{ width: "35%", height: "fit-content", overflow: "auto", maxHeight: "100%" }}
    >
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <>
          {!isEmpty(isRoom) &&
            !isEmpty(
              isRoom?.filter((r) => r?.locations?.find((l) => l.siteId === campus?.mapData?.id)),
            ) && (
              <>
                <Typography.Paragraph strong style={{ fontSize: "17px" }}>
                  {t("CurrentReservation")}
                </Typography.Paragraph>
                {planning?.slots &&
                  planning?.slots?.items?.map((item, index) => (
                    <div key={index}>
                      {item?.resources?.map((r, index) => (
                        <RoomDetails key={index} item={item} room={r} planning={true} />
                      ))}
                    </div>
                  ))}
              </>
            )}

          {!isEmpty(roomFastbooking.rooms) ? (
            <Typography.Paragraph strong style={{ fontSize: "17px" }}>
              {t("SpaceAvailable")}
            </Typography.Paragraph>
          ) : (
            <NoResults />
          )}
          {!isEmpty(roomFastbooking) &&
            [...(roomFastbooking.rooms || [])]
              .sort(
                (a, b) =>
                  /^\d/.test(a.resources.title) - /^\d/.test(b.resources.title) ||
                  a.resources.title.localeCompare(b.resources.title),
              )
              .map((room) => <RoomDetails key={room.roomId} item={room} room={room.resources} />)}
        </>
      )}
    </Card>
  );
};

export default FastbookingList;
