import { Button, Card, Col, DatePicker, Form, Modal, Row, TimePicker, Typography } from "antd";
import moment from "moment";
import { append, range, update } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { cancelVisit, createVisit, updateVisit } from "../../../services/redux/services/VisitorsWS";
import Glyph from "../../Common/Glyph/Glyph";
import EditUser from "./EditUser";
import UserInput from "./UserInput/UserInput";
import UserList from "./UserList/UserList";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const deserialize = (visit) => {
  if (!visit) return;
  return {
    date: moment(visit.startDate),
    start: moment(visit.startDate),
    end: moment(visit.endDate),
    resident: visit.resident,
    visitors: visit?.visitors?.map((r) => r),
  };
};

const EditVisit = ({ user, visit, open, onDismiss }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [editingUser, setEditingUser] = useState(null);
  const isSaving = useSelector(
    createLoadingSelector(["visitors/createVisit", "visitors/updateVisit"]),
  );
  const isCanceling = useSelector(createLoadingSelector(["visitors/cancelVisit"]));
  const isVisitor = useSelector((state) => state.userWS.userData.profile.isVisitor);
  const profile = useSelector((state) => state.userWS.userData.profile);
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (visit && open) form.setFieldsValue(deserialize(visit));
    return () => {
      form.resetFields();
    };
  }, [form, visit, open]);

  const handleAddVisitor = (user) => {
    if (!user) return;
    const visitors = form.getFieldValue(["visitors"]) || [];
    const idx = visitors.findIndex((v) => v.id === user.id);
    if (idx === -1) form.setFieldsValue({ visitors: append(user, visitors) });
    else form.setFieldsValue({ visitors: update(idx, user, visitors) });
    setIsDisabled(false);
  };

  const handleDeleteUser = (user) => {
    if (user === "resident") {
      form.setFieldsValue({ resident: null });
    } else {
      form.setFieldsValue({
        visitors: form.getFieldValue(["visitors"]).filter((v) => v.id !== user.id),
      });
    }

    if (form.getFieldValue(["visitors"]).length === 0) {
      setIsDisabled(true);
    }
  };

  const handleSubmit = ({ date, start, end, resident, visitors }) => {
    const visitData = {
      startDate: moment(date).set({
        hour: moment(start).hour(),
        minute: moment(start).minute(),
      }),
      endDate: moment(date).set({
        hour: moment(end).hour(),
        minute: moment(end).minute(),
      }),
      resident: resident ? parseInt(resident.id) : profile.id,
      visitors: visitors.map((user) => {
        return user.id;
      }),
    };

    const operation = !visit?.id
      ? dispatch(createVisit(visitData))
      : dispatch(updateVisit(Object.assign({}, visit, visitData)));

    operation.unwrap().then(() => {
      onDismiss();
    });
  };
  const handleCancel = () => {
    dispatch(cancelVisit(visit))
      .unwrap()
      .then(() => {
        onDismiss();
      });
  };

  const handleChanges = (changed) => {
    if (changed.start) form.setFieldsValue({ end: moment(changed.start).add(1, "h") });
  };

  return (
    <>
      <Modal
        destroyOnClose
        open={open}
        onCancel={onDismiss}
        footer={
          !isVisitor && (
            <Row gutter={[20, 20]} style={{ width: "100%" }}>
              {!visit?.id ? (
                <Col span={24}>
                  <Button
                    type="primary"
                    block
                    loading={isSaving}
                    disabled={isSaving || isDisabled}
                    onClick={() => {
                      form.submit();
                      logEvent(analytics, "visitors_new_visit");
                    }}
                  >
                    {t("visitors.forms.visit.create")}
                  </Button>
                </Col>
              ) : (
                <>
                  <Col span={12}>
                    <Button
                      type="primary"
                      block
                      loading={isSaving}
                      disabled={isSaving || isCanceling}
                      onClick={() => {
                        form.submit();
                        logEvent(analytics, "visitors_update_visit");
                      }}
                    >
                      {t("visitors.forms.visit.update")}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      block
                      loading={isCanceling}
                      disabled={
                        isSaving || isCanceling || moment(visit?.endDate).isBefore(moment())
                      }
                      onClick={() => {
                        handleCancel();
                        logEvent(analytics, "visitors_delete_visit");
                      }}
                    >
                      {t("visitors.forms.visit.delete")}
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          )
        }
      >
        <Form
          form={form}
          // initialValues={deserialize(visit)}
          onFinish={handleSubmit}
          onValuesChange={handleChanges}
        >
          <Row gutter={[20, 20]} align="middle">
            {isVisitor ? (
              <Col>
                <Typography.Title level={3}>{t("visitors.forms.visit.detail")}</Typography.Title>
              </Col>
            ) : !visit?.id ? (
              <Col>
                <Typography.Title level={3}>{t("visitors.forms.visit.create")}</Typography.Title>
              </Col>
            ) : (
              <Col>
                <Typography.Title level={3}>{t("visitors.forms.visit.update")}</Typography.Title>
              </Col>
            )}

            <Col span={24}>
              <Card bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Glyph name="event" className="secondary" />
                  <Form.Item noStyle name={["date"]}>
                    <DatePicker
                      disabled={isVisitor}
                      bordered={false}
                      style={{ width: "100%" }}
                      suffixIcon={null}
                      format="dddd DD MMMM YYYY"
                    />
                  </Form.Item>
                </div>
              </Card>
            </Col>
            <Col span={11}>
              <Card bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Glyph name="schedule" className="secondary" />
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => (
                      <Form.Item noStyle name={["start"]}>
                        <TimePicker
                          disabled={isVisitor}
                          bordered={false}
                          style={{ width: "100%" }}
                          suffixIcon={null}
                          format="HH:mm"
                          disabledHours={() =>
                            moment(visit?.startDate).format("DD MM YYYY") ===
                              moment().format("DD MM YYYY") &&
                            moment(getFieldValue(["date"])).format("DD MM YYYY") ===
                              moment().format("DD MM YYYY") &&
                            range(0, moment().hour())
                          }
                          disabledMinutes={(selectedHour) =>
                            moment().hour() === selectedHour &&
                            moment(visit?.startDate).format("DD MM YYYY") ===
                              moment().format("DD MM YYYY") &&
                            moment(getFieldValue(["date"])).format("DD MM YYYY") ===
                              moment().format("DD MM YYYY")
                              ? range(0, moment().minutes())
                              : []
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </div>
              </Card>
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              à
            </Col>
            <Col span={11}>
              <Card bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Glyph name="schedule" className="secondary" />
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => (
                      <Form.Item noStyle name={["end"]}>
                        <TimePicker
                          disabled={isVisitor}
                          bordered={false}
                          style={{ width: "100%" }}
                          suffixIcon={null}
                          format="HH:mm"
                          disabledHours={() => range(0, moment(getFieldValue(["start"])).hour())}
                          disabledMinutes={(selectedHour) =>
                            moment(getFieldValue(["start"])).hour() === selectedHour
                              ? range(0, moment(getFieldValue(["start"])).minutes())
                              : []
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </div>
              </Card>
            </Col>

            <Col span={24}>
              <Form.Item noStyle name={["resident"]}>
                {visit?.id ? (
                  <Card bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                    <UserList users={[visit.resident]} descriptionKey="function" />
                  </Card>
                ) : (
                  <UserInput
                    placeholder={t("visitors.forms.visit.fields.resident")}
                    type="resident"
                    onChange={() => logEvent(analytics, "visitors_delegation")}
                  />
                )}
              </Form.Item>
            </Col>
            {!isVisitor && (
              <Col span={24}>
                <Typography.Paragraph type="secondary">
                  {t("visitors.forms.visit.fields.visitors.helper")}
                </Typography.Paragraph>

                <UserInput
                  allowCreation
                  placeholder={t("visitors.forms.visit.fields.visitors")}
                  type="visitor"
                  value={null}
                  onChange={handleAddVisitor}
                />
              </Col>
            )}
            <Col span={24}>
              <Form.Item noStyle name={["visitors"]} />
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <>
                    <UserList
                      style={{ cursor: "pointer" }}
                      users={getFieldValue(["visitors"])}
                      descriptionKey="email"
                      onDelete={handleDeleteUser}
                      onClick={(user) => setEditingUser(user)}
                    />
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {!isVisitor && (
        <EditUser
          open={!!editingUser}
          user={editingUser}
          onDismiss={(user) => {
            setEditingUser(null);
            if (user) handleAddVisitor(user);
          }}
        />
      )}
    </>
  );
};

export default EditVisit;
