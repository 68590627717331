import { cond, equals } from "ramda";
import useFindOrder, { FindOrderProvider, useFindOrderMap } from "./FindOrder/useFindOrder";
import useMapbox, { MapboxProvider, useMapboxMap } from "./Mapbox/useMapbox";

const [useMap, MapProvider, useMapLayer] = cond([
  [equals("findorder"), () => [useFindOrder, FindOrderProvider, useFindOrderMap]],
  [equals("mapbox"), () => [useMapbox, MapboxProvider, useMapboxMap]],
])(process.env.REACT_APP_MAP_PROVIDER || "findorder");

export { MapProvider, useMapLayer };
export default useMap;
