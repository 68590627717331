import { Button, Form, Input, message, Space } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateCategories } from "../../../../services/redux/services/FacilitiesWS";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../DashboardFacilities.module.less";
import EmptyIco from "./empty.svg";

const ICONS = ["local_bar", "meeting_room", "business", "delete", "edit", "schedule"];

const IconList = ({ onSelect }) => {
  return (
    <div className={clsx(styles.iconGrid)}>
      {ICONS.map((name) => (
        <div key={name} className={clsx(styles.iconTile)} onClick={() => onSelect(name)}>
          <Glyph name={name} />
        </div>
      ))}
    </div>
  );
};

const IconPicker = ({ value, onChange }) => {
  const [showIconList, setShowIconList] = useState(false);

  return (
    <>
      <div className={clsx(styles.iconTile)} onClick={() => setShowIconList(true)}>
        {value ? (
          <Glyph name={value} />
        ) : (
          <img
            src={value || EmptyIco}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = EmptyIco;
            }}
            alt=""
          />
        )}
      </div>
      {showIconList && (
        <IconList
          onSelect={(val) => {
            onChange(val);
            setShowIconList(false);
          }}
        />
      )}
    </>
  );
};

const CategoryForm = ({ category, onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const categories = useSelector((state) => state.facilitiesWS.configuration?.categories || []);

  useEffect(() => {
    form.resetFields();
  }, [form, category]);

  const onSubmit = (values) => {
    values.color = typeof values.color === "string" ? values.color : values.color.hex;
    const data = values.id
      ? categories.map((c) => (c.id === values.id ? values : c))
      : [...categories, values];
    return dispatch(updateCategories(data))
      .unwrap()
      .then(() => {
        onCancel();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  return (
    <Form
      form={form}
      size="middle"
      layout="vertical"
      style={{ padding: 10 }}
      initialValues={category}
      onFinish={onSubmit}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Form.Item hidden name={["id"]} />

        <Form.Item noStyle name={["icon"]}>
          <IconPicker />
        </Form.Item>
        <Form.Item noStyle name={["title"]}>
          <Input />
        </Form.Item>
        <Form.Item noStyle name={["color"]} valuePropName="color">
          <TwitterPicker triangle="hide" width="100%" />
        </Form.Item>

        <div style={{ display: "flex", gap: 10 }}>
          <Button size="middle" block type="dashed" onClick={onCancel}>
            {t("Back")}
          </Button>
          <Button htmlType="submit" size="middle" block type="primary">
            {t("Save")}
          </Button>
        </div>
      </Space>
    </Form>
  );
};

const CategoryList = ({ onSelect, onEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const categories = useSelector((state) => state.facilitiesWS.configuration?.categories || []);

  const onDelete = ({ id }) => {
    return dispatch(updateCategories(categories.filter((c) => c.id !== id)))
      .unwrap()
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  return (
    <div style={{ padding: 10 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {categories.map((cat) => (
          <div
            className={styles.categoryOption}
            style={{ backgroundColor: `${cat.color}99` }}
            onClick={() => onSelect(cat.id)}
          >
            <Space>
              <Glyph name={cat.icon} />
              {cat.title}
            </Space>
            <Space>
              <Button
                type="text"
                size="small"
                icon={<Glyph name={"edit"} />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit(cat);
                }}
              />
              <Button
                type="text"
                size="small"
                icon={<Glyph name={"delete"} />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete(cat);
                }}
              />
            </Space>
          </div>
        ))}
        <Button type="text" size="middle" block onClick={() => onEdit({})}>
          {t("facilities.categories.new")}
        </Button>
      </Space>
    </div>
  );
};

const CategoryMenu = ({ onSelect }) => {
  const [selected, setSelected] = useState();

  return !selected ? (
    <CategoryList onSelect={onSelect} onEdit={setSelected} />
  ) : (
    <CategoryForm category={selected} onCancel={() => setSelected(null)} />
  );
};

export default CategoryMenu;
