import { Input } from "antd";
import { forwardRef } from "react";

const I18nInput = forwardRef(
  ({ component, value = {}, locale, onChange, valueParser, placeholder, ...props }, ref) => {
    const defaultparser = (e) => e.target.value;

    valueParser = valueParser || defaultparser;
    const handle = (e) => {
      let content = value;
      content = e !== "<p><br></p>" ? e : e.replace("<p><br></p>", "");
      const newValue = { ...value, [locale]: valueParser(content) };
      onChange(newValue);
    };

    const Component = component || Input;
    return (
      <Component
        ref={ref}
        value={value[locale] || ""}
        onChange={handle}
        placeholder={placeholder}
        {...props}
      />
    );
  },
);

export default I18nInput;
