import { useKeycloak } from "@react-keycloak/web";
import { Typography } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FloorSelector from "../../../../../Common/Map/Mapbox/FloorSelector";
import {
  MapboxProvider,
  useMapboxInstance,
} from "../../../../../Common/Map/Mapbox/useMapboxInstance";

const CreateStepWorkplaceMap = ({ sectorId, siteId, onChange }) => {
  const { keycloak } = useKeycloak();

  const mapData = useSelector((state) => state.mapDataWS[siteId]?.mapData);
  const workplaces = useSelector((state) => state.spaceServiceWS.listWorkplace?.availability || []);
  const presence = useSelector((state) => state.spaceServiceWS.presence?.followed || []);

  const { map, registerMap, mapReady, setDesksDisplay, centerOnPlace } = useMapboxInstance();

  const roomToDiplay = useMemo(() => {
    if (!mapData) return;
    return {
      rooms: mapData.resources
        .filter((i) => i.sectorId === sectorId)
        .map((room) => ({
          roomUid: room.id,
          availableDeskUids: workplaces
            .filter((w) => w?.workplaces?.roomId === room.id)
            .map((w) => w.id),
        })),
      users: presence.map((f) => ({
        id: f.userId,
        name: f.displayName,
        photoUrl: `${f.photoUrl}?access_token=${keycloak.token}`,
        deskUid: f.spaceId,
      })),
    };
  }, [keycloak.token, mapData, presence, sectorId, workplaces]);

  useEffect(() => {
    if (!mapReady) return;
    setDesksDisplay(roomToDiplay);
    if (roomToDiplay?.rooms.length) {
      centerOnPlace(roomToDiplay.rooms[0].roomUid, false, false);
    }
  }, [centerOnPlace, mapReady, roomToDiplay, setDesksDisplay]);

  useEffect(() => {
    if (!mapReady || !map || !mapData) return;

    const handler = ({ details: { id } }) => {
      const desk = mapData.workplaces.find((i) => i.map?.findAndOrder.placeId === id);
      if (desk && workplaces.some((w) => w.id === desk.id)) onChange(desk.id);
    };

    map.on("clickOnDesk", handler);
    return () => {
      map.off("clickOnDesk", handler);
    };
  }, [map, mapData, mapReady, onChange, workplaces]);

  return (
    <>
      <div ref={registerMap} id="workplace_map" style={{ width: "100%", height: "100%" }} />
      <div style={{ position: "absolute", top: 0, left: 0, padding: 10 }}>
        <FloorSelector />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          opacity: mapReady ? 0 : 1,
          transition: mapReady ? "opacity .3s ease-in-out" : "none",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography.Title level={3}>{"Chargement du plan"}...</Typography.Title>
      </div>
    </>
  );
};

const Helper = () => {
  const { t } = useTranslation();

  return (
    <>
      <br />
      <Typography.Text type="secondary">{t("spas.workplacemap.helper")}</Typography.Text>
    </>
  );
};

const Wrapper = (props) => {
  const { sectorId } = props;

  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  const siteId = useMemo(() => {
    const site = sites.find((site) => {
      return (site.sectors || []).find((sector) => sector.id === sectorId);
    });
    return site?.id;
  }, [sectorId, sites]);

  return (
    <MapboxProvider siteId={siteId} mode={"2D"}>
      <CreateStepWorkplaceMap {...props} siteId={siteId} />
    </MapboxProvider>
  );
};

Wrapper.Helper = Helper;

export default Wrapper;
