import { createSlice, createAsyncThunk as thunk } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  floorTemplates: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const getFloorTemplates = thunk(
  "realtime/getFloorTemplates",
  async ({ floor }, { getState }) => {
    // const state = getState();
    const siteId = localStorage.getItem("siteId");
    const floorTemplates = await DWMConnector.getFloorTemplates(siteId, floor);
    return floorTemplates;
  },
);

EXTRA_REDUCERS[getFloorTemplates.fulfilled] = (state, action) => {
  state.floorTemplates = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const realTimeSlice = createSlice({
  name: "realTime",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default realTimeSlice.reducer;
