import { Button, Input, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const AddIframe = ({ isIframe, onCancel, setUrl, reactQuillRef, url, cursorPos }) => {
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (onCancel) {
      setDisabled(false);
    }
  }, [onCancel]);
  return (
    <Modal
      destroyOnClose
      width={600}
      title={
        <Typography.Text
          strong
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {t("news.editor.iframe.title")}
        </Typography.Text>
      }
      open={isIframe}
      onCancel={() => {
        onCancel();
        setDisabled(false);
      }}
      footer={[
        <Button
          ghost
          style={{ width: "170px" }}
          onClick={() => {
            onCancel();
            setDisabled(false);
          }}
        >
          {t("Cancel")}
        </Button>,
        <Button
          type="primary"
          style={{ width: "170px" }}
          onClick={() => {
            setDisabled(true);
            const parser = new DOMParser();
            const el = parser.parseFromString(url, "text/html").getElementsByTagName("iframe")[0];
            el.width = "100%";
            reactQuillRef.current
              .getEditor()
              .clipboard.dangerouslyPasteHTML(cursorPos.index, el.outerHTML);
            onCancel();
          }}
          disabled={disabled}
        >
          {t("Next")}
        </Button>,
      ]}
    >
      <Typography.Text>{t("news.editor.iframe.content")}</Typography.Text>

      <TextArea
        rows={4}
        placeholder={t("news.editor.iframe.placeholder")}
        onChange={(e) => setUrl(e.target.value)}
      />
    </Modal>
  );
};

export default AddIframe;
