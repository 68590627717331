import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  report: null,
  listReport: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// POWERBI ///////////////////////////////
export const getListReport = thunk("powerBi/getListReport", async () => {
  const listReport = await DWMConnector.getListReport();
  return listReport;
});

EXTRA_REDUCERS[getListReport.fulfilled] = (state, action) => {
  state.listReport = action.payload;
};

export const getReport = thunk("powerBi/getReport", async (reportId, { getState }) => {
  // const state = getState();
  const report = await DWMConnector.getReport(reportId);
  return report;
});

EXTRA_REDUCERS[getReport.fulfilled] = (state, action) => {
  state.report = action.payload;
};

const powerBiSlice = createSlice({
  name: "powerBi",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default powerBiSlice.reducer;
