import { useKeycloak } from "@react-keycloak/web";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { subscribeTab, updateEvent } from "../../../../services/redux/services/FacilitiesWS";
import Glyph from "../../../Common/Glyph/Glyph";
import CategoryMenu from "./CategoryMenu";
import Comments from "./Comments";

const CategorySelector = ({ value, onChange, disabled }) => {
  const categories = useSelector((state) => state.facilitiesWS.configuration?.categories || []);

  const handleChange = (id) => {
    onChange(categories.find((c) => c.id === id));
  };

  const options = categories.map((c) => ({
    ...c,
    label: (
      <Space>
        <Glyph name={c.icon} />
        {c.title}
      </Space>
    ),
    value: c.id,
  }));

  if (value && !options.some((o) => o.value === value?.id))
    options.push({
      ...value,
      label: (
        <Space>
          <Glyph name={value.icon} />
          {value.title}
        </Space>
      ),
      value: value.id,
    });

  return (
    <Select
      disabled={disabled}
      style={{ width: "100%" }}
      labelInValue
      value={value?.id}
      onChange={handleChange}
      options={options}
      dropdownRender={() => <CategoryMenu onSelect={handleChange} />}
    />
  );
};

const General = ({ event, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [form] = useForm();

  const profile = useSelector((state) => state.userWS.userData.profile);
  const isFacility = useSelector((state) => state.userWS.userData.profile?.rights.facility);

  const isSubscribed = (event.subscribers || []).includes(profile?.email);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = (values) => {
    dispatch(updateEvent(values))
      .unwrap()
      .then(() => {
        form.resetFields();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const handleSubscribe = () => {
    dispatch(subscribeTab({ subscribe: !isSubscribed }))
      .unwrap()
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const initialValues = useMemo(() => {
    return event
      ? {
          ...event,
          slot: { ...event.slot, start: moment(event.slot.start), end: moment(event.slot.end) },
        }
      : undefined;
  }, [event]);

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}>
      <Row gutter={[20, 20]}>
        <Col flex="auto">
          <Form.Item noStyle name={["title"]}>
            <Input
              className="filled title"
              size="large"
              suffix={<Glyph name="edit" />}
              disabled={!isFacility}
            />
          </Form.Item>
        </Col>
        <Col flex={"none"}>
          <Space>
            {isFacility && (
              <Dropdown
                overlay={
                  <Menu
                    items={[
                      {
                        key: "archive",
                        label: (
                          <Space>
                            <Glyph name={event?.hidden ? "visibility_on" : "visibility_off"} />
                            {event?.hidden
                              ? t("facilities.event.hide_off")
                              : t("facilities.event.hide")}
                          </Space>
                        ),
                        onClick: () => onFinish({ hidden: !event?.hidden }),
                      },
                    ]}
                  />
                }
                trigger={["click"]}
              >
                <Button size="middle" type="ghost" icon={<Glyph name={"more_horiz"} />} />
              </Dropdown>
            )}
            <Tooltip
              title={
                isSubscribed
                  ? t("facilities.event.subscribed")
                  : t("facilities.event.notSubscribed")
              }
            >
              <Button
                size="middle"
                type={isSubscribed ? "primary" : "ghost"}
                icon={<Glyph name={isSubscribed ? "notifications_none" : "notifications_off"} />}
                onClick={handleSubscribe}
              />
            </Tooltip>
            <Button size="middle" type="ghost" icon={<Glyph name={"close"} />} onClick={onClose} />
          </Space>
        </Col>
        <Col span={24}>
          <Typography.Paragraph type="secondary">
            {t("facilities.event.helper")}
          </Typography.Paragraph>
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <Glyph name="schedule" className={"secondary"} style={{ fontSize: "1.5rem" }} />
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {t("facilities.event.date")}
                </Typography.Title>
              </Space>
              <Card>
                <Row>
                  <Col flex={"auto"}>
                    <Typography.Text strong>{t("facilities.event.startDate")}</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text strong>
                      {moment(event.slot.start).format("LLL")}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
              <Card>
                <Row>
                  <Col flex={"auto"}>
                    <Typography.Text strong>{t("facilities.event.endDate")}</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text strong>{moment(event.slot.end).format("LLL")}</Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Space>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <Glyph name="place" className={"secondary"} style={{ fontSize: "1.5rem" }} />
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {t("facilities.event.location")}
                </Typography.Title>
              </Space>
              <Card>
                <Typography.Text strong>{event.location?.title}</Typography.Text>
              </Card>
            </Space>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {t("facilities.event.description")}
                </Typography.Title>
              </Space>
              <Form.Item noStyle name={["description"]}>
                <Input.TextArea autoSize={{ minRows: 7, maxRows: 12 }} disabled={!isFacility} />
              </Form.Item>
            </Space>
          </Space>
        </Col>
        <Col span={12}>
          <Card bordered={false}>
            <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Space>
                  <Glyph name="turned_in" className={"secondary"} style={{ fontSize: "1.5rem" }} />
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    {t("facilities.event.category")}
                  </Typography.Title>
                </Space>
                <Form.Item noStyle name={["category"]}>
                  <CategorySelector disabled={!isFacility} />
                </Form.Item>
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Space>
                  <Glyph name="person_pin" className={"secondary"} style={{ fontSize: "1.5rem" }} />
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    {t("facilities.event.organizer")}
                  </Typography.Title>
                </Space>
                <Card>
                  <Card.Meta
                    avatar={
                      <Avatar
                        size={"large"}
                        src={`${event?.slot.organizer.photoUrl}?access_token=${keycloak.token}`}
                      >
                        {event?.slot.organizer.displayName
                          .split(" ")
                          .map((i) => i.charAt(0).toUpperCase())
                          .join("")}
                      </Avatar>
                    }
                    title={event?.slot.organizer.displayName}
                    description={event?.slot.organizer.email}
                  />
                </Card>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col flex={"auto"}></Col>
        <Col flex={"none"}>
          <Form.Item noStyle shouldUpdate>
            {({ isFieldsTouched }) =>
              isFieldsTouched() ? (
                <Space>
                  <Button size="middle" type="dashed" onClick={() => form.resetFields()}>
                    {t("facilities.event.save_cancel")}
                  </Button>
                  <Button size="middle" htmlType="submit" type="primary">
                    {t("facilities.event.save")}
                  </Button>
                </Space>
              ) : null
            }
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider />
          <Comments comments={event?.comments} />
        </Col>
      </Row>
    </Form>
  );
};

export default General;
