const axios = require("axios");

export default class ResourceConnector {
  constructor({ url }) {
    this.token = null;
    this.axiosRMInst = axios.create({ baseURL: url });
    this.axiosRMInst.interceptors.request.use((request) => {
      request.headers["apiKey"] = this.apiKey;
      request.headers["secretKey"] = this.secretKey;
      request.headers["Authorization"] = `Bearer ${this.token}`;
      return request;
    }, null);
  }
  async authent(api, secret, token) {
    this.apiKey = api;
    this.secretKey = secret;
    this.token = token;
    return true;
  }
  async getResources(locale = "fr", withChild = true, returnedFields) {
    try {
      const res = await this.axiosRMInst.get(`/api/resources`, {
        headers: { "swizi-service": "News Builder" },
        params: {
          locale,
          withChild,
          returnedFields,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
}
