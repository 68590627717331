import { Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { useTranslation } from "react-i18next";

import styles from "./GlobalLoader.module.less";

const GlobalLoader = () => {
  const isLoading = useSelector(
    createLoadingSelector(["users/getUserData", "clients/getCampusData"]),
  );
  const { t } = useTranslation();

  return isLoading
    ? createPortal(
        <div className={styles.globalLoader}>
          <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            <br />
            <Typography.Title level={3}>{t("Loading")}...</Typography.Title>
          </div>
        </div>,
        document.getElementById("root"),
      )
    : null;
};

export default GlobalLoader;
