import { Button, Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { createUser, updateUser } from "../../../services/redux/services/VisitorsWS";
import Glyph from "../../Common/Glyph/Glyph";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const FORBIDDEN_DOMAINS = (process.env.REACT_APP_VISITORS_FORBIDDEN_DOMAINS || "")
  .split(",")
  .filter(Boolean);

const EditUser = ({ open, onDismiss, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isLoading = useSelector(
    createLoadingSelector(["visitors/createUser", "visitors/updateUser"]),
  );

  useEffect(() => {
    form.resetFields();
  }, [form, user]);

  const handleSubmit = (values) => {
    const data = { ...values, email: values.email };
    const operation = !user?.id
      ? dispatch(createUser(data))
      : dispatch(updateUser(Object.assign({}, user, data)));
    operation.unwrap().then((newUser) => {
      onDismiss(newUser);
    });
  };

  return (
    <>
      {user && (
        <Modal
          open={open}
          onCancel={() => onDismiss()}
          footer={
            <Button
              block
              type="primary"
              onClick={() => {
                form.submit();
                user?.id
                  ? logEvent(analytics, "visitors_edit_contact")
                  : logEvent(analytics, "visitors_new_contact");
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              {user?.id ? t("visitors.forms.user.update") : t("visitors.forms.user.create")}
            </Button>
          }
        >
          <Form layout="vertical" form={form} initialValues={user} onFinish={handleSubmit}>
            <Form.Item
              name={["firstname"]}
              label={t("visitors.forms.user.fields.firstname")}
              rules={[{ required: true }]}
            >
              <Input prefix={<Glyph name={"person"} />} />
            </Form.Item>
            <Form.Item
              name={["lastname"]}
              label={t("visitors.forms.user.fields.lastname")}
              rules={[{ required: true }]}
            >
              <Input prefix={<Glyph name={"person"} />} />
            </Form.Item>
            <Form.Item
              name={["email"]}
              label={t("visitors.forms.user.fields.login")}
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const isForbidden = FORBIDDEN_DOMAINS.some((i) => value.endsWith(i));
                    return isForbidden
                      ? Promise.reject(t("visitors.forms.user.fields.login.forbidden"))
                      : Promise.resolve();
                  },
                },
              ]}
            >
              <Input prefix={<Glyph name={"mail"} />} disabled={!!user?.id} />
            </Form.Item>
            <Form.Item name={["company"]} label={t("visitors.forms.user.fields.company")}>
              <Input prefix={<Glyph name={"work"} />} />
            </Form.Item>
            <Form.Item name={["function"]} label={t("visitors.forms.user.fields.function")}>
              <Input prefix={<Glyph name={"work"} />} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditUser;
