const axios = require("axios");
// const R = require("ramda");

// const { warn } = require("../../logger")("SwiziConnector");

// const errorData = err => ({
//   config: err.config,
//   responseData: R.path(["response", "data"], err),
// });

export default class SwiziConnector {
  constructor({ url, apiKey, token, appId }) {
    this.id = appId;
    let headers = {};
    if (apiKey) headers.apiKey = apiKey;
    if (token) headers.authtoken = token;
    // if (appId) headers.projectId = appId
    let baseURL = url;
    this.axiosInst = axios.create({ baseURL, headers });
  }
  async getSection(sectionId) {
    try {
      const res = await this.axiosInst.get(`/api/section/${sectionId}`, {
        headers: {
          projectId: this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async getTopics(sectionId) {
    try {
      const res = await this.axiosInst.get(`/api/section/topics/${sectionId}`, {
        headers: {
          projectId: this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async saveTopics(sectionId, topics, options) {
    let data = {
      id: sectionId,
      topics,
    };

    if (options) {
      data.options = options;
    }

    try {
      await this.axiosInst.put(`/api/section/update/topics`, data, {
        headers: {
          projectId: this.id,
        },
      });
      return this.getTopics(sectionId);
    } catch (err) {
      throw Error(err);
    }
  }
  async getContents(sectionId, filter) {
    try {
      const res = await this.axiosInst.get(
        `/api/content/genericList/${sectionId}?sort=creation&dir=DESC&filterForUser=${filter}`,
        {
          headers: {
            projectId: this.id,
          },
        },
      );
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async postContent(content, sectionId) {
    try {
      const res = await this.axiosInst.post(`/api/content/genericList/${sectionId}`, content, {
        headers: {
          projectId: this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async updateContent(content) {
    try {
      const res = await this.axiosInst.put(`/api/content/genericList/`, content, {
        headers: {
          projectId: this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async deleteContent(contentId) {
    try {
      const res = await this.axiosInst.delete(`/api/content/genericList/${contentId}`, {
        headers: {
          projectId: this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }

  async getMedias(tags) {
    try {
      const res = await this.axiosInst.get(
        `/api/media/list?page=1&size=200&dir=DESC&tags=${encodeURIComponent(tags)}&sort=creation`,
        {
          headers: {
            appId: this.id,
          },
        },
      );
      res.data.content = res.data.content.sort((c1, c2) => (c1.creation < c2.creation ? 1 : -11));
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async getAppColors(appId) {
    try {
      const res = await this.axiosInst.get(`/style/colors/${135}`);
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async postMedia(file) {
    try {
      var formData = new FormData();
      formData.append("file", file);
      const res = await this.axiosInst.post("/api/media/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "projectId": this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async postBlobMedia(file, filename) {
    try {
      var formData = new FormData();
      formData.append("file", file, filename !== null ? filename : "Image.png");
      const res = await this.axiosInst.post("/api/media/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "projectId": this.id,
        },
      });
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async tagMedia(mediaId, tags) {
    try {
      const res = await this.axiosInst.put(
        "/api/media/tags",
        {
          id: mediaId,
          tags: tags,
        },
        {
          headers: {
            projectId: this.id,
          },
        },
      );
      return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
  async getGroups(filter = true) {
    try {
      const res = await this.axiosInst.get("/api/group/list?sort=label");
      if (filter) {
        return res.data.filter((g) => !g.specific && g.type !== "SPACE");
      } else return res.data;
    } catch (err) {
      throw Error(err);
    }
  }
}
