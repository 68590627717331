import { Button, Card, Checkbox, Dropdown, Empty, Form, Input, Typography } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { normalizeString } from "../../../../helpers/utils";
import Glyph from "../../Glyph/Glyph";
import styles from "./DropdownSelect.module.less";

const DropdownSelect = ({ searchable, icon, placeholder, options, value, onChange, ...rest }) => {
  const [search, setSearch] = useState("");

  const isFiltered = (o) =>
    (!search && o.divider) ||
    (!o.divider && new RegExp(normalizeString(search), "i").test(normalizeString(o.label)));
  const filtered = options.filter(isFiltered);

  const overlay = (
    <Card className={styles.dropdownSelectOverlay}>
      {!!searchable && (
        <Form.Item>
          <Input
            size="small"
            placeholder="Rechercher..."
            suffix={<Glyph name={"search"} />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Form.Item>
      )}
      {!!filtered.length ? (
        <Checkbox.Group value={value} onChange={onChange}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 8,
            }}
          >
            {options.map((o) =>
              o.divider ? (
                <Typography.Text
                  style={{ display: isFiltered(o) ? undefined : "none" }}
                  type="secondary"
                >
                  {o.title}
                </Typography.Text>
              ) : (
                <Checkbox
                  style={{ display: isFiltered(o) ? undefined : "none", margin: 0 }}
                  key={o.value}
                  value={o.value}
                >
                  {o.label}
                </Checkbox>
              ),
            )}
          </div>
        </Checkbox.Group>
      ) : (
        <Empty />
      )}
    </Card>
  );

  const label = !value?.length
    ? placeholder
    : value?.length === 1
    ? options.find((o) => o.value === value[0])?.label
    : `${value.length} filtres sélectionnés`;

  return (
    <Dropdown trigger={["click"]} dropdownRender={() => overlay}>
      <Button
        {...rest}
        type={!!value?.length ? "primary" : "default"}
        className={clsx(styles.dropdownSelect)}
        icon={icon}
      >
        {label}
      </Button>
    </Dropdown>
  );
};

export default DropdownSelect;
