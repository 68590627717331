import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Layout,
  Row,
  Select,
  Spin,
  Tag,
  TimePicker,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import styles from "./Catering.module.less";
import moment from "moment";
import { isEmpty } from "ramda";
import { LoadingOutlined } from "@ant-design/icons";
import CreateServicesModal from "./CreateServicesModal";
import { useState } from "react";
import { useEffect } from "react";
import { AllOrders, allServicesCatering } from "../../../services/redux/services/CateringWS";
import UpdateServicesModal from "./UpdateServicesModal";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { useTranslation } from "react-i18next";
import { getParent } from "../../../helpers/ticketHelpers";

const { Meta } = Card;

export const STEPS = {
  SEARCH: "SEARCH",
  INFOS: "INFOS",
  SERVICES: "SERVICES",
  RUBRIQUE: "RUBRIQUE",
  DETAILS: "DETAILS",
  RESUME: "RESUME",
};

const Catering = () => {
  const [form] = Form.useForm();
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(createLoadingSelector(["catering/AllOrders"]));

  const [showServicesModal, setShowServicesModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [step, setStep] = useState(STEPS.SEARCH);
  const [startDate, setStartDate] = useState(moment());
  const [space, setSpace] = useState();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const campus = useSelector((state) => state.clientsWS.campus);
  const orders = useSelector((state) => state.cateringWS.orders);
  const services = useSelector((state) => state.cateringWS.allServices);

  useEffect(() => {
    dispatch(AllOrders({ page: 1, size: 10, inprogress: false }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(allServicesCatering());
  }, [dispatch]);

  return (
    <>
      <Layout className={styles.dashboardContainer}>
        <Layout>
          <Form
            form={form}
            initialValues={{
              siteId: sites?.find((c) => c?.id === campus?.mapData?.id)?.title,
              startDate: moment(),
            }}
            onValuesChange={(values) => {
              setStartDate(values?.startDate);
            }}
          >
            <Row gutter={[10, 20]}>
              <Col flex={"auto"}>
                <Card
                  title={<Typography.Title level={5}>{t("catering.order")}</Typography.Title>}
                  actions={[
                    <div
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <Button
                        icon={<Glyph name="add" />}
                        type="primary"
                        style={{ width: "300px", marginRight: "16px" }}
                        onClick={() => {
                          setShowServicesModal(true);
                        }}
                      >
                        {t("catering.newOrder")}
                      </Button>
                    </div>,
                  ]}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Card>
                        <Row gutter={[20, 0]}>
                          <Col>
                            <Glyph name="business" className={"secondary"} />
                          </Col>
                          <Col flex={"auto"}>
                            <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
                              <Select
                                className="accent"
                                bordered={false}
                                size={"middle"}
                                style={{
                                  width: "100%",
                                  marginTop: -8,
                                  marginBottom: -8,
                                  backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                                }}
                                options={sites.map((s) => ({
                                  label: s.title,
                                  value: s.id,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card>
                        <Row>
                          <Col flex={"auto"}>
                            <Typography.Text strong>
                              <Glyph
                                name="event"
                                style={{ color: colors.secondary_base, marginRight: "8px" }}
                              />
                              {t("catering.orderFor")}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Form.Item noStyle name={["startDate"]}>
                              <DatePicker
                                style={{ margin: -8 }}
                                format="DD MMM YYYY"
                                disabledDate={(date) => date.isBefore(moment(), "date")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card>
                        <Row>
                          <Col flex={"auto"}>
                            <Typography.Text strong>
                              <Glyph
                                name="schedule"
                                style={{ color: colors.secondary_base, marginRight: "8px" }}
                              />
                              {t("From")}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Form.Item noStyle name={["startDate"]} trigger="onSelect">
                              <TimePicker showNow={false} style={{ margin: -8 }} format="HH:mm" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
          <Card
            title={<Typography.Title level={5}>{t("catering.prestation")}</Typography.Title>}
            bodyStyle={{ padding: "0px", overflowX: "hidden" }}
            style={{ minHeight: "400px", position: "relative" }}
          >
            <Spin
              spinning={isLoading}
              indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            >
              <Row gutter={[8, 16]} style={{ padding: "16px" }}>
                {orders?.tickets
                  ?.filter((t) => moment(t?.formProps?.startDate) > moment())
                  ?.map((ticket, index) => (
                    <Col key={index} span={6} style={{ position: "relative" }}>
                      <Tag
                        style={{
                          position: "absolute",
                          zIndex: "900",
                          top: "10px",
                          right: "16px",
                        }}
                        color={
                          ticket?.statusId === 0
                            ? colors.pending_light
                            : ticket?.statusId === 1
                            ? colors.success_light
                            : ticket?.statusId === 2
                            ? colors.highlight_light
                            : colors.error_light
                        }
                      >
                        <Typography.Text strong style={{ color: "white" }}>
                          {ticket?.statusId === 0
                            ? t("New")
                            : ticket?.statusId === 1
                            ? t("TakenIntoAccount")
                            : ticket?.statusId === 2
                            ? t("Suspended")
                            : ticket?.statusId === 3
                            ? t("closed")
                            : t("canceled")}
                        </Typography.Text>
                      </Tag>
                      <Card
                        onClick={() => {
                          setShowUpdateModal(true);
                          setSpace(ticket);
                        }}
                        style={{ cursor: "pointer" }}
                        cover={
                          ticket?.resources?.photos && !isEmpty(ticket?.resources?.photos) ? (
                            <div style={{ boxShadow: "none" }} className={styles["roomPictures"]}>
                              <Image preview={false} src={ticket?.resources?.photos[0]} />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "200px",
                                width: "100%",
                                backgroundColor: colors.grey_40,
                                opacity: 0.3,
                              }}
                            >
                              <Glyph
                                style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                                name={
                                  campus?.mapData?.categories?.find(
                                    (c) => c.id === ticket?.resources?.categoryId,
                                  )?.icon
                                }
                              />
                            </div>
                          )
                        }
                      >
                        <Meta
                          title={
                            <div style={{ display: "flex", flexFlow: "column" }}>
                              <Typography.Text strong style={{ fontSize: "14px" }}>
                                {t("catering.request")} n° #{ticket?.uid}
                              </Typography.Text>
                              <Typography.Text strong style={{ fontSize: "14px" }}>
                                {
                                  campus?.mapData?.resources?.find((r) => r?.id === ticket?.roomId)
                                    ?.title
                                }{" "}
                                {ticket?.origin === "outlook" ? `- ${t("catering.outlook")}` : null}
                              </Typography.Text>
                            </div>
                          }
                          description={
                            <div>
                              <Typography.Paragraph>
                                {t("catering.date")}{" "}
                                {moment(ticket?.formProps?.startDate).format("DD/MM/YYYY")} -{" "}
                                {moment(ticket?.formProps?.startDate).format("HH:mm")}
                              </Typography.Paragraph>
                              <Typography.Paragraph
                                key={index}
                                type="secondary"
                                style={{ marginRight: "6px" }}
                              >
                                {`${
                                  services?.find(
                                    (s) => s?.id === getParent(services, ticket?.categoryId),
                                  )?.label
                                } * ${ticket?.formProps?.no_Attendees}`}
                              </Typography.Paragraph>
                            </div>
                          }
                        />
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Spin>
          </Card>
        </Layout>
      </Layout>
      {showServicesModal && (
        <CreateServicesModal
          visible={showServicesModal}
          step={step}
          setStep={setStep}
          startDate={startDate}
          setStartDate={setStartDate}
          onCancel={() => {
            setShowServicesModal(false);
            setStep(STEPS.SEARCH);
          }}
        />
      )}
      {showUpdateModal && (
        <UpdateServicesModal
          visible={showUpdateModal}
          onCancel={() => {
            setShowUpdateModal(false);
          }}
          space={space}
        />
      )}
    </>
  );
};

export default Catering;
