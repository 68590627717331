/* eslint-disable import/no-anonymous-default-export */
import DWMTrads from "@trad/DWMProduct.json";
import CommonTrads from "./Common.json";

const trads = DWMTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.en };
}, {});

const common = CommonTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.en };
}, {});

export default {
  translation: {
    //COMMON
    "NoResult": "No result",
    "Available": "Available",
    "In": "In",
    "SeeItinerary": "See the itinerary",
    "Today": "Today",
    "Filters": "Filters",
    "Back": "Back",
    "Next": "Next",
    "Finish": "Finish",
    "Save": "Save",
    "Floor": "Floor",
    "Cancel": "Cancel",
    "Infos": "Infos",
    "SeeMore": "See more",
    "Full": "full",
    "Confirm": "Confirm",
    "SelectAll": "Select all",
    "All": "All",
    "PrivacyPolicy": "Privacy policy",
    "CreateActu": "Manage news",
    "CreateActuPermanent": "Manage permanent content",
    "Hidden": "Hidden",
    "ErrorOccured": "An error occured",
    "Add": "Add",
    "Refresh": "Refresh",
    "Yes": "Yes",
    "Guests": "Guests & External",
    "Collaborators": "Collaborators",
    "cancel": "Cancel",
    "accept": "Accept",
    "notAccept": "Do not accept",
    "pleaseRead": "Please read the T&Cs and Privacy Policy to continue",
    "readthe": "Read the",
    "validCGU": "Validate the T&Cs and Privacy Policy",
    "Next_onboarding": "Next",
    "Skip": "Skip",
    "toStart": "Start",
    "Languages": "Languages",
    "French": "French",
    "English": "English",
    "Dutch": "Dutch",
    "Closed": "Closed",

    "OCCUPIED": "Occupied",
    "AVAILABLE": "Available",

    // LOGIN
    "Welcome": "Welcome",
    "OnDashboard": "On your portal, register to continue.",
    "Login": "Login",

    // LOADER
    "Loading": "Loading",
    "LoadingMap": "Loading map...",
    "LoadingPlanning": "Loading planning",

    //HEADER

    //DASHBOARD
    "booking": "Booking in progress",
    "bookings": "Booking in progress",
    "WelcomeDesk": "Welcome Desk",

    //FastBooking
    "SpaceAvailable": "Space(s) available",
    "CurrentReservation": "My current reservations",
    "Book": "Book",
    "BookDuring": "Book during",
    "CancelBooking": "Cancel the reservation",
    "BackHome": "Back home page",
    "BookingArea": "Resserved area",
    "BookingDontWork": "The reservation has not been made",
    "TryAgain": "Try reserving the room again.",
    "Erase": "Clear filters",
    "Apply": "Apply",
    "SpacesAvailable": "Show spaces coming soon",
    "ReservationDetail": "Reservation details",
    "Slots": "Time slot",
    "numberPeople": "Number of people",
    "AllSpaces": "All spaces",
    "NoResultFloor": "No results on this floor",
    "TrySearching": "Try searching on another floor or remove filters.",
    "AvailbleFloor": "Availability on other floors",
    "ResultFloor": "result(s) on this floor",
    "Equipements": "Equipment(s)",

    //fastbooking evo
    "SeeSpaces": "See the list of spaces",
    "BookThe": "Book the",
    "From": "From",
    "When": "For",
    "MyDay": "My day",
    "Consultbook": "Click here to view all your reservations",
    "ApplyFilter": "Apply filters",
    "AvancedSearch": "Advanced search",
    "AllFloor": "All stages",
    "Services": "Services",
    "Result": "Results",
    "Treatment": "In progress",
    "Places": "seat(s)",
    "BookCanceled": "Booking canceled",
    "ErrorCancelBook": "Impossible to cancel the booking",
    "Spaces": "Spaces",
    "SelectTheSpace": "Select the space",
    "Of": "of",
    "NumberPlaces": "Number of seat(s)",
    "None": "None",
    "SearchByName": "Search by name",
    "Until": "Until",
    "Started": "Start at",
    "StartedThe": "Start the",
    "AdvertisementMessage": "Attention: After this date, self-service spaces will not be displayed.",
    "fastbooking.sureCanceled": "Are you sure you want to cancel the reservation?",
    "fastbooking.rooms.types": "Room types",
    "fastbooking.updateSite": "Change site",
    "fastbooking.redirectSite": "You will be redirected to the {{site}} site portal. Once logged in, click on {{fastbooking_name}} to book a space",
    "backToFilter": "Back to filters",

    //GuidMe
    "FindSpace": "Enter space name",
    "LastSearch": "My latest research",
    "Favoris": "My favorites",
    "Itinerary": "Itinerary",
    "Duration": "Duration",
    "Start": " Find a starting point",
    "End": "Find an arrival point",

    //Ticketing
    "Requests": "My tickets",
    "NewTicket": "New Ticket",
    "New": "New",
    "TakenIntoAccount": "Acknowledged",
    "Suspended": "Pending",
    "Close": "Closed",
    "NoWaiting": "No new ticket",
    "NoTaken": "No acknowledged ticket",
    "NoSuspended": "No pending ticket",
    "NoClose": "No closed ticket",
    "Status": "Status",
    "Location": "Location",
    "Pictures": "Pictures",
    "By": "By",
    "Category": "Category",
    "SelectCategory": "Select a category",
    "ChooseLocation": "Specify a location",
    "WriteSomething": "Add details about your request and the location",
    "Adds": "Add documents",
    "YourRequest": "Your request is taken into account",
    "Informed": "You will be informed of the progress of your application",
    "UseSearch": "Use the search field to find a space.",
    "closed": "Closed",
    "CreateTicket": "Create a ticket",
    "AddComment": "Add a comment",
    "AllRequest": "All my tickets",
    "Summary": "Summary",
    "Followed": "Followed",
    "Discussion": "Discussion",
    "Subcategory": "Sub-category",
    "MoreDetails": "Enter a subcategory",
    "Description": "Comment",
    "errorTicket": "Error creating ticket",
    "errorDoc": "You have reached the limit of the number of documents allowed",
    "refresh": "refresh",

    /**
     * VISITORS
     */
    "visitors.delegated": "My delegated visits",
    "visitors.forms.visit.create": "Create a new visit",
    "visitors.forms.visit.update": "Update the visit",
    "visitors.forms.visit.delete": "Cancel the visit",
    "visitors.forms.visit.fields.resident": "Invite for someone else",
    "visitors.forms.visit.fields.visitors": "Add guests",
    "visitors.forms.visit.fields.visitors.helper": "Enter the name of the person you want to add to the invitation. If you can't find this person in the repository, just enter their email address.",
    "visitors.forms.user.create": "Create a visitor",
    "visitors.forms.user.update": "Update a visitor",
    "visitors.forms.user.fields.firstname": "Firstname",
    "visitors.forms.user.fields.lastname": "Lastname",
    "visitors.forms.user.fields.login": "Email address",
    "visitors.forms.user.fields.login.forbidden": "This domain name is not allowed to create a new visitor",
    "visitors.forms.user.fields.company": "Company",
    "visitors.forms.user.fields.function": "Function",
    "visitors.forms.visit.detail": "Details of your visit",

    /**
     * WIFI
     */
    "wifi.ssid": "Network name",
    "wifi.password": "Password",
    "wifi.helper.title": "Save some time !",
    "wifi.helper.description": "Scan this QR Code with your smartphone to automatically connect to the Wifi network",

    /**
     * SPACE AS A SERVICE
     */
    "spas": "Space As a Service",
    "spas.status.PENDING": "Pending",
    "spas.status.PENDING_other": "Pending",
    "spas.status.UNASSIGNED": "Unaffected",
    "spas.status.UNASSIGNED_other": "Unaffected",
    "spas.status.APPROVED": "Declaration accepted",
    "spas.status.APPROVED_other": "Declaration accepted",
    "spas.status.DECLINED": "Refused",
    "spas.status.DECLINED_other": "Refused",
    "spas.status.CANCELLED": "Canceled",
    "spas.status.CANCELLED_other": "Canceled",
    "spas.period.MORNING": "Morning",
    "spas.period.AFTERNOON": "Afternoon",
    "spas.period.DAY": "Whole day",
    "spas.recurrence.NONE": "None",
    "spas.recurrence.RECURRENCE": "Recurrency:",
    "spas.recurrence.EVERY_DAY": "Every day",
    "spas.recurrence.EVERY_WEEK": "Every {{day}}",
    "spas.recurrence.EVERY_MONTH": "Every month",
    "spas.recurrence.EVERY_2_WEEK": "Every other {{day}}",
    "spas.history.createRequest": "The request has been created",
    "spas.history.approve": "A date has been accepted",
    "spas.history.approveAll": "All dates have been accepted",
    "spas.history.decline": "A date has been canceled",
    "spas.history.declineAll": "All dates have been canceled",
    "spas.history.cancel": "A date has been canceled",
    "spas.history.cancelAll": "All dates have been canceleds",
    "spas.history.overrided": "Dates have been canceled to free up space",
    "spas.planning": "Planning",
    "spas.request": "Request",
    "spas.request.details": "Request Details",
    "spas.request.update": "Updating declaration",
    "spas.request.new": "New declaration",
    "spas.request.cancel": "Cancel declaration",
    "spas.request.comments": "Comments",
    "spas.request.id.title": "ID request {{id}}",
    "spas.request.site": "Where do you want to work today?",
    "spas.request.isSerie": "This reservation has an occurrence",
    "spas.request.startDate.title": "Start date",
    "spas.request.startDate.helper": "start from",
    "spas.request.period.title": "Duration",
    "spas.request.period.helper": "Select a duration",
    "spas.request.recurrence": "Recurrency",
    "spas.request.endDate": "Ends on {{date}}",
    "spas.request.forceEndDate": "Force end date",
    "spas.request.from": "From : {{user}}",
    "spas.request.comment.title": "Comments",
    "spas.request.comment.helper": "Add a comment (required)",
    "spas.request.actions.confirmBooking": "Confirm booking",
    "spas.request.actions.book": "Next",
    "spas.request.actions.map": "See on the map",
    "spas.request.actions.workplaces": "See the list",
    "spas.request.actions.approve": "Assign a seat",
    "spas.request.actions.approve_other": "Assign seats",
    "spas.request.actions.decline": "Refuse the request",
    "spas.request.actions.decline_other": "Refuse requests",
    "spas.request.actions.cancel": "Cancel declaration",
    "spas.request.actions.updateReservation": "Update declaration",
    "spas.request.actions.saveReservation": "Save Changes",
    "spas.request.actions.updateSpace": "Change Assignment",
    "spas.request.actions.updateSpace_other": "Change assignments",
    "spas.request.actions.removeSpace": "Delete Assignment",
    "spas.request.actions.removeSpace_other": "Delete Assignments",
    "spas.request.actions.occurence": "Only this day",
    "spas.request.actions.serie": "The whole series",
    "spas.request.actions.assignValidate": "Validate assignment",
    "spas.request.actions.assignIn": "Assign in {{desk}}",
    "spas.request.actions.assignAuto": "Automatically assign",
    "spas.request.actions.assignAuto.selectMore": "You must select more rooms to reach a sufficient number of places",
    "spas.request.actions.assignAuto.helper": "Select in which space(s) to assign {{count}} person",
    "spas.request.actions.assignAuto.helper_other": "Select in which space(s) to assign {{count}} people",
    "spas.request.backCalendar": "Back to calendar",
    "spas.request.seeDay": "see my day",
    "spas.request.reservation": "Registration in progress",
    "spas.request.resaAccepted": "Declaration accepted",
    "spas.request.resaRefused": "Registration has failed",
    "spas.request.tryAgain": "Try Again",
    "spas.conflict": "The workstation selected for {{user}} is already occupied on the following dates:",
    "spas.conflict.question": "What do you want to do ?",
    "spas.conflict.date": "Date",
    "spas.conflict.user": "User",
    "spas.conflict.forceAssign": "Assign anyway",
    "spas.conflict.forceAssign.helper": "The above assignments will be cancelled. Each person will have to make a new statement.",
    "spas.conflict.onlyAvailbale": "Assign on available days",
    "spas.conflict.onlyAvailbale.helper": "This position will be assigned to {{user}} only on available dates. A new statement will have to be made for the days above.",
    "spas.admin.header.week": "Week {{number}}",
    "spas.admin.header.weekPrevious": "Previous week",
    "spas.admin.header.weekNext": "Next week",
    "spas.admin.header.pending": "Pending requests",
    "spas.admin.header.approved": "Validated requests",
    "spas.admin.header.estimated": "Estimated occupancy",
    "spas.admin.header.available": "{{count}} / {{total}} available",
    "spas.admin.header.available_other": "{{count}} / {{total}} available",
    "spas.admin.header.occupied": "{{count}} / {{total}} occupied place",
    "spas.admin.header.occupied_other": "{{count}} / {{total}} occupied place",
    "spas.admin.header.map": "See the building plan",
    "spas.admin.tabs.search": "Find a person",
    "spas.admin.tabs.selected": "{{count}} selected",
    "spas.admin.tabs.selected_other": "{{count}} selected",
    "spas.admin.tabs.resident": "Resident name",
    "spas.admin.tabs.manager": "Manager name",
    "spas.admin.tabs.from": "Author request",
    "spas.admin.tabs.createdAt": "date of the request",
    "spas.admin.tabs.recurrence": "Recurrence",
    "spas.admin.tabs.space": "Location",
    "spas.admin.tabs.affectedAt": "Date of assignment",
    "spas.admin.tabs.declinedAt": "Date refused",
    "spas.admin.map.search.title": "Select a space",
    "spas.admin.map.search.helper": "Use the map or search field to select a space to assign.",
    "spas.admin.map.search": "Find a space",
    "spas.widget.noResa": "No declaration today",
    "spas.manage.followingPerson": "Followed people",
    "spas.manage.waiting.invitation": "pending invite(s)",
    "spas.manage.lengthPerson": "Tap here to view your pending invites",
    "spas.manage.noResult": "No results in your people followed.",
    "spas.manage.addPerson": "Add people",
    "spas.manage.stopFollow": "stop flollowing",
    "spas.manage.noFollower": "You are not currently following anyone.",
    "spas.manage.searchFollower": "Find someone in your organization's directory and send an invitation.",
    "spas.manage.received.invitation": "Invitations received",
    "spas.manage.shareNotebook": "Tap an invite to share your calendar.",
    "spas.manage.accepted": "Accept the invitation",
    "spas.manage.ignored": "Ignore the invitation",
    "spas.messages.create.error": "Unable to update the declaration",
    "spas.messages.cancel.error": "Unable to cancel declaration",
    "spas.messages.decline.error": "Unable to deny the request",
    "spas.messages.removeSpace.error": "Unable to remove assignment",
    "spas.messages.conflicts.error": "Unable to check space availability",
    "spas.people.present": "Who will be present on site ?",
    "spas.people.whoResa": "Find out who will be present that day",
    "spas.people.noResult": "No results in your people followed for this day.",
    "spas.people.find": "Search followed people",
    "spas.messages.cancel.success": "The cancellation request has been taken into account.",
    "spas.request.confirm.presence": "Confirmed my presence",
    "spas.request.validate": "Validate",
    "spas.presence.confirmed": "Presence confirmed",
    "spas.presence.present": "Your presence has already been confirmed",
    "spas.presence.error": "An error has occurred, your presence could not be confirmed. Please try again.",
    "spas.presence.today": "Your presence could not be confirmed. You can confirm your presence only during the corresponding booking period.",
    "spas.presence.success": "You have confirmed your presence",
    "spas.presence.code": "Enter the 6-digit code located on the office label",
    "spas.place.available": "Available seats",
    "spas.workplace.title": "Workplace",
    "space.workplace.subtitle": "Select a workplace",
    "spas.workplace.your": "Your workspace",
    "spas.workplace.free": "available seat(s)",
    "spas.workplacemap.helper": "Select an available desk by clicking on the map.",
    "spas.prsence.alreadyConfirmed": "Already confirmed",
    "spas.presence.noConfirmed": "You cannot confirm your presence on a reservation that has not started",
    "spas.request.error.sectors": "No sector assigned",
    "spas.request.error.sectors.content": "No sector is assigned to you on this site.",
    "spas.request.error.date": "Start date greater than end date",

    /**
     * PHONE BOOK
     */

    "phoneBook.seachPhoneBook": "Find someone in your organization's directory",
    "phonebook.useSearch": "Use the input field located at the top of the screen to find a person by writing the first 3 characters of their name or surname.",
    "phoneBook.backToSearch": "Back to search",
    "phoneBook.title": "Contact List",
    "phoneBook.send.invitation": "Send an invitation",
    "phoneBook.success": "Invitation sent",
    "phoneBook.error": "The invitation has not been sent",
    "phoneBook.error.alreadySend": "The invitation has already been sent",
    "phoneBook.number.contact": "Displaying {{nbItems}}/{{totalItems}} contacts. Refine your search to find the person you are looking for.",
    "phoneBook.search": "Find a contact",

    /**
     * CONFORT
     */

    "Stage": "Floor",
    "Temperature": "Temperature",
    "Lighting": "Lighting",
    "Ventilation": "Ventilation",
    "Blind": "Blind",
    "Blinds": "All blinds",
    "Light": "Light",
    "ControlEquipment": "Control the comfort equipment in a space by selecting it on the left of the screen.",

    // SEARCH ROOM
    "SelectSpace": "Select a space",
    "UseCard": "Use the search box to find a space to pilot.",
    "ResultSearch": "Result of the research",
    "SearchSpace": "Search for a space...",

    // ERROR
    "ErrorSetLight": "An error has occured on the lights",
    "ErrorSetBlind": "An error has occured on the blinds",
    "ErrorSetBlindAngle": "An error has occured on the blinds' angle",
    "ErrorSetTemperatures": "An error has occured on the temperature",
    "ErrorSetVentilation": "An error has occured on the ventilation",

    "ErrorModeAuto": "Cannot start auto mode",

    "ErrorGetLight": "Something went wrong on the lights. Please try a new search, or refresh the page.",
    "ErrorGetBlind": "Something went wrong on the blinds. Please try a new search, or refresh the page.",
    "ErrorGetTemperatures": "Something went wrong on the temperature. Please try a new search, or refresh the page.",
    "ErrorGetVentilation": "Something went wrong on the ventilation. Please try a new search, or refresh the page.",
    "ErrorGetComfort": "Sorry, we cannot reach the comfort of your room. Please try a new search, or refresh the page.",

    // POWERBI
    "TypeDuration": "Choose here to display the results for the whole day or only for half a day.",
    "IntervalDate": "Select a pre-selected date interval or define a custom interval.",
    "Interval": "Interval",

    // NEWS
    "BackTo": "Back to {{topic}}",
    "Sure_Leave": "You are about to exit the content editor. You will lose the unpublished work in progress. Are you sure you want to continue ?",
    "Yes_Leave": "Yes, leave",
    "Select_A_Group": "Select at least one group to be able to publish",
    "Content_Editing": "Content editing",
    "Notif_Editing": "Notification editing",
    "Publish": "Publish",
    "Update": "Update",
    "Upload": "Add",
    "Edit_Internationalized": "Select the language in which you want to edit your publication.",
    "Image_Library": "Image library",
    "Add_Image": "Upload an image",
    "Select_Image": "Select an image from the library",
    "Title": "Title",
    "Subtitle": "Subtitle",
    "Notif_Trigger": "Notification trigger",
    "Choose_Title": "Write a title",
    "Choose_Subtitle": "Write a subtitle",
    "Publication": "Publication",
    "Content_To_Be_Shawn": "The content will be visible in the application from",
    "Hide_Content": "Hide content before publication",
    "Send_Notif": "Send a notification",
    "Access": "Visibility restriction",
    "Target": "Target",
    "Select_Group": "To limit the visibility of this publication, select the user groups that are allowed to view it. (By default, the publication will be visible by all groups)",
    "Select_Group2": "Select recipients of the notification",
    "No_Group_Found": "No group found",
    "Topics": "Topics",
    "Select_Topics": "Select one or many topics for the news",
    "No_Topic": "No topic available",
    "Attach_To_Place": "If it is an event, you can add a location to your publication.",
    "Attach_To_Place2": "Attach this content to a place",
    "No_Stage_Found": "No stage found",
    "Select_A_Room": "Select a space",
    "Select_A_Stage": "Select a stage",
    "No_Room_Found": "No space found",
    "Room": "Space",
    "Name": "Name",
    "Enter_Name_Place": "Enter the name of the place/room",
    "Publication_Preview": "Preview in the app",
    "Add_News": "Create a news",
    "Add_Notif": "Create a notification",
    "Add_Topic": "Create a topic",
    "Update_Topic": "Update a topic",
    "Manage_Topic": "Manage topics",
    "Trendings": "Spotlight",
    "Published_On": "Published on",
    "Created_On": "Created on",
    "Header_Image": "Header image",
    "At": "at",
    "Get_details": "Retrieving section details...",
    "Get_Content": "Fetching contents...",
    "Get_Content_Error": "Error while fetching contents",
    "Get_Groups": "Fetching Swizi groups...",
    "Get_Groups_Error": "Error while fetching Swizi groups",
    "Get_Topcis_Error": "Error while fetching topics",
    "Get_Media": "Fetching Swizi medias...",
    "Get_Media_Error": "Error while fetching Swizi medias",
    "Get_Resource": "Fetching Swizi resources....",
    "Get_Resource_Error": "Error while Fetching Swizi resources",
    "Show_HTML": "HTML view",
    "Show_Editor": "Editor view",
    "Format_Code": "Format HTML code",
    "ShowMore": "Show more >",
    "Published": "Published {{date}}",
    "PublishedAt": "Published {{date}}",
    "News": "News",
    "InTheSameTopic": "In the same topic",
    "Event": "Event",
    "Event_description": "Create an event to get it on the news",
    "Event_startDate": "Start",
    "Event_endDate": "End",
    "Event_date_place": "Date and place of the event",
    "Event_from": "From",
    "Event_to": "To",
    "noFloorSelect": "No floor selected",

    // AGORA
    "Places.Frequentation": "Attendance",
    "Places.Now": "Now",
    "Places.Informations": "Informations",
    "Places.hours": "Opening hours",
    "Places.Location": "See location",
    "Places.Equipments": "Equipments",
    "Places.others": "Others",
    "Places.Open": "Open",
    "Places.Closed": "Closed",
    "Places.Services": "Services",
    "Places.Clicked": "Click here",

    // FACILITIES
    "facilities.week": "Week {{number}}",
    "facilities.weekPrevious": "Previous week",
    "facilities.weekNext": "Next week",
    "facilities.dashboard.noEvent": "No event scheduled this day",
    "facilities.resident.helper": "facilities.resident.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.resident.noEvent.title": "You have no scheduled event.",
    "facilities.resident.noEvent.subtitle": "Book a space in your Outlook calendar so that your event can be organized by the facilities team.",
    "facilities.filters.categories": "Categories",
    "facilities.filters.rooms": "Rooms",
    "facilities.filters.rooms.rooms": "Room",
    "facilities.filters.rooms.types": "Room types",
    "facilities.filters.organizer": "Organizer",
    "facilities.filters.hidden": "Display hidden events",
    "facilities.filters.new": "Hide unprocessed events",
    "facilities.filters.tasks": "Hide events without current action",
    "facilities.filters.erase": "Remove filters",
    "facilities.event.new": "New event",
    "facilities.event.notHandled": "Unprocessed",
    "facilities.event.noCategory": "No category",
    "facilities.event.tasks.tooltip": "{{completed}} actions out of {{total}}",
    "facilities.event.subscribed": "Alert emails are activated",
    "facilities.event.notSubscribed": "Alert emails aren't activated",
    "facilities.event.helper": "facilities.event.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.tab.new": "New tab",
    "facilities.event.tab.helper": "facilities.event.tab.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.tab.general": "General",
    "facilities.event.tab.description": "Notes",
    "facilities.event.section.TEXT": "Text area",
    "facilities.event.section.TEXT.helper": "facilities.event.section.TEXT.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.TEXT.new": "New text area",
    "facilities.event.section.RADIO": "Choice list",
    "facilities.event.section.RADIO.helper": "facilities.event.section.RADIO.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.RADIO.new": "New choice list",
    "facilities.event.section.LIST": "Bulleted list",
    "facilities.event.section.LIST.helper": "facilities.event.section.LIST.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.LIST.new": "New bulleted list",
    "facilities.event.section.CHECKBOX": "Action list",
    "facilities.event.section.CHECKBOX.helper": "facilities.event.section.CHECKBOX.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.CHECKBOX.new": "New action list",
    "facilities.event.section.FILE": "Attachments",
    "facilities.event.section.FILE.helper": "facilities.event.section.FILE.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.FILE.new": "New attachment area",
    "facilities.event.section.add": "Add a section of your choice",
    "facilities.event.hide": "Hide this event",
    "facilities.event.hide_off": "Unhide this event",
    "facilities.event.date": "Date",
    "facilities.event.startDate": "Start on",
    "facilities.event.endDate": "End on",
    "facilities.event.location": "Location",
    "facilities.event.description": "Description",
    "facilities.event.category": "Category",
    "facilities.event.organizer": "Organizer",
    "facilities.event.save_cancel": "Cancel changes",
    "facilities.event.save": "Save",
    "facilities.event.contributors": "Contributors",
    "facilities.event.contributors.add": "Add a contributor",
    "facilities.event.contributors.remove": "Remove",
    "facilities.event.contributors.helper": "facilities.event.contributors.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.externals": "Externals",
    "facilities.event.externals.add": "Add an external email",
    "facilities.event.externals.remove": "Remove",
    "facilities.event.externals.helper": "facilities.event.externals.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.edit.open": "Edit this tab",
    "facilities.event.edit.close": "Exit edition mode",
    "facilities.event.delete": "Delete this tab",
    "facilities.event.comments": "Comments",
    "facilities.event.comments.add": "Add a comment",
    "facilities.event.comments.send": "Send",
    "facilities.categories.new": "Create a category",
    "facilities.files.status.NEW": "New",
    "facilities.files.status.PENDING": "Pending",
    "facilities.files.status.VALIDATED": "Validated",
    "facilities.files.status.DECLINED": "Refused",
    "facilities.files.status.ABANDONED": "Abandoned",
    "facilities.files.remove": "Remove",
    "facilities.files.upload.title": "Click or drag n drop inside this area to add files",
    "facilities.files.upload.save": "Save those files",

    //PUBLIC
    "Public.activated": "Your account is activated!",
    "Public.connected": "Please connect now on the application and on the web portal with your login",
    "Public.connected2": "Please connect now on the application with your login",
    "Public.upload": "Download the mobile app from the app stores or go to the web portal using the links below.",
    "Public.upload2": "Download the mobile app from the app stores using the links below.",
    "Public.error.activated": "Your account is already activated",
    "Public.password.verification": "User verification",
    "Public.password.activated": "Activate your account",
    "Public.password.experience": "To enjoy the experience on our mobile application and on the web portal, please activate your guest account by choosing a password.",
    "Public.password.identifiant": "Login",
    "Public.password.email": "Use the email address from which you received your invitation email.",
    "Public.password.password": "Choose a password",
    "Public.password.obligatory": "The password field is mandatory",
    "Public.password.strongPassword": "Choose a strong password according to the following requirements.",
    "Public.password.eight": "8 characters minimum",
    "Public.password.one": "1 special character",
    "Public.password.maj": "1 uppercase",
    "Public.password.number": "1 figure",
    "Public.password.min": "1 lowercase",
    "Public.password.confirm": "Confirm your password",
    "Public.password.same": "Same passwords",
    "Public.password.active": "Activate my account",
    "Public.expired.finish": "Your booking is over",
    "Public.expired.free": "You cannot release / keep your space",
    "Public.failure.request": "Your request could not be processed.",
    "Public.failure.spaces": "Your request to book the space {{title}} from {{startHour}} to {{endHour}} could not be processed due to an error.",
    "Public.failure.excuse": "We apologise for the inconvenience and invite you to try again later.",
    "Public.success.free": "The space has been freed up",
    "Public.success.spaces": "The booking of the space {{title}} from {{startHour}} to {{endHour}} has been cancelled.",
    "Public.success.nowFree": "This space is now available for other bookings.",
    "Public.keep.kept": "Your booking is kept",
    "Public.keep.noAlert": "You will no longer receive any alerts for this booking.",
    "public.keep.spaces": "The booking of the space {{title}} from {{startHour}} to {{endHour}} is kept.",
    "Public.password.error": "Error while creating the password",

    // TRANSFERT
    "transfert.myDeparture": "My departure",
    "transfert.site": "Place of departure",
    "transfert.building": "Building",
    "transfert.boxes": "Boxes deposit",
    "transfert.move": "Moving",
    "transfert.myMoveIn": "My move-in",
    "transfert.moveIn": "Planned move-in",
    "transfert.zone": "Arrival area",
    "transfert.help": "Need help ?",
    "transfert.question": "A question about your move ?",
    "transfert.contact": "Contact your correspondent who can help you.",
    "transfert.myMove": "My move",
    "transfert.welcome": "Welcome to the Transfer feature !",
    "transfert.findInfo": "Find all the information related to your move here.",
    "transfert.understand": "I understand",
    "transfert.noMove": "You have no move in progress",
    "transfert.addAndUpdateFile": "Add or modify a file",
    "transfert.addFile": "Add file",
    "transfert.error": "Error when adding the file",
    "transfert.success": "File add successfully",
    "transfert.created": "Created",
    "transfert.updated": "Edited",
    "transfert.deleted": "Deleted",
    "transfert.row": "Row",
    "transfert.errors": "Error(s)",

    // CATERING
    "catering.prestationAvailable": "Available services",
    "catering.prestationNoAvailable": "Unavailable services",
    "catering.orderOutOfTime": "Your order is out of time, it is too late to book the services below.",
    "catering.order": "Order a service",
    "catering.newOrder": "New request",
    "catering.orderFor": "Order for the",
    "catering.prestation": "My services in progress",
    "catering.outlook": "Ordered from Outlook",
    "catering.newPresta": "New service",
    "catering.thisSpace": "Choose this space",
    "catering.addPresta": "Add the service",
    "catering.saveOrder": "Register the order",
    "catering.askSpace": "In which space would you like to order the service?",
    "catering.orderThe": "Order the",
    "catering.addAPrestra": "Add a service",
    "catering.selectRubrique": "Select an item",
    "Total": "Total",
    "catering.resume": "Summary of the service",
    "catering.canceled": "Cancel the order",
    "catering.sureCanceled": "Are you sure you want to cancel the order?",
    "No": "No",
    "catring.successUpdate": "Successful change",
    "catering.errorUpdate": "Error when editing",
    "catering.update": "Edit the order",
    "catering.noOrder": "You can no longer edit the order",
    "amount": "Quantity",
    "obligatory": "Required field",
    "canceled": "Canceled",
    "catering.request": "Request",
    "catering.date": "Date:",

    // NEWS V2
    "news.read.article": "Read this article",
    "news.spotlight": "Top news",
    "news.all.article": "View all articles",
    "news.event.title": "Date and place of the event",
    "news.event.agence": "Agency of",
    "news.article.like": "I like this article",
    "news.article.share": "Share this article",
    "news.article.same": "Similar articles",
    "news.comments": "Comments",
    "news.comment.write": "Write your commet here",
    "news.comment.send": "Send",
    "news.editor.confirm.title": "Delete the news",
    "news.editor.cnfirm.content": "Are you sure you want to delete the news",
    "news.editor.new": "New publication",
    "news.editor.new.topic": "Manage topics",
    "news.edior.all.topics": "All topics",
    "news.editor.published.topics": "Published in",
    "news.editor.update": "Modify",
    "news.editor.hide": "Hide",
    "news.editor.noHide": "Unhide",
    "news.editor.delete": "Delete",
    "news.editor.publish": "Publish",
    "news.editor.hide.title": "Hide the publication",
    "news.editor.noHide.title": "Unhide the publication",
    "news.editor.hide.content": "This publication will be hidden and will not be visible to users. Its content will not be deleted.",
    "news.editor.noHide.content": "This publication will be unhidden and visible to users",
    "news.editor.hide.success": "News hidden",
    "news.editor.noHide.success": "News unhidden",
    "news.editor.hide.error": "The news has not been hidden",
    "news.editor.topics.create.success": "Successful creation of the topic",
    "news.editor.topics.create.error": "Topic creation failed",
    "news.editor.topics.update.success": "Topic modified successfully",
    "news.editor.topics.update.error": "Topic modification failed",
    "news.editor.topics.delete": "Topic deleted",
    "news.editor.topics.use": "Already used",
    "news.editor.topics.add": "Add a topic",
    "news.editor.topics.new": "New topic",
    "news.editor.topics.update": "Modify the topic",
    "news.editor.topics.create": "Create the topic",
    "news.editor.topics.list": "Topics list",
    "news.editor.topics.name": "Topic name",
    "news.editor.update.success": "Successful modification",
    "news.editor.update.error": "Modification failed",
    "news.editor.create.success": "Successful publication",
    "news.editor.create.error": "Publication failed",
    "news.editor.create.error.pictures": "Please add a header image",
    "news.editor.web": "Web",
    "news.editor.mobile": "Mobile",
    "news.editor.title": "Click to add a title",
    "news.editor.subtitle": "Click to add a subtitle",
    "news.editor.header": "Click to add a header image",
    "news.editor.content": "Click to enter the publication body",
    "news.editor.options.languages": "Select the language and edit the content of this publication on the left of the screen.",
    "news.editor.options.topics": "Select one or more topics in which to add the publication.",
    "news.editor.options.notification.title": "Send a notification",
    "news.editor.options.notification.content": "Select this option if you want users to receive a notification when this publication goes online.",
    "news.editor.options.publication.title": "Schedule the publication",
    "news.editor.options.publication.content": "Select this option and specify the date and time when this publication will be automatically uploaded.",
    "news.editor.options.hide.title": "Hide automatically",
    "news.editor.options.hide.content": "Select this option and specify the date and time when this publication will be hidden from users.",
    "news.editor.options.event.title": "Add an event",
    "news.editor.options.event.placeholder": "Add a name to the event",
    "news.editor.options.event.content": "If it's an event you can add a location to your publication.",
    "news.editor.options.event.name": "Event name",
    "news.editor.options.event.start": "Start of the event",
    "news.editor.options.event.end": "End of the event",
    "news.editor.options.event.localisation": "Location",
    "news.editor.options.event.building": "Building",
    "news.editor.options.event.outBuilding": "Outside building",
    "news.editor.options.event.findAdress": "Search an address",
    "news.editor.options.groups.title": "Limit visibility",
    "news.editor.options.groups.content": "To limit the visibility of this publication, select the user groups authorised to view it (By default, the publication will be visible to all groups).",
    "news.editor.options.groups.select": "Select a group",
    "news.editor.options.comments.title": "Allow comments",
    "news.editor.options.comments.content": "Select this option to allow users to add a comment to the publication.",
    "news.editor.iframe.title": "HTML integration",
    "news.editor.iframe.content": "Copy the HTML code of the site you want to integrate.",
    "news.editor.iframe.placeholder": "Insert the HTML code here",
    "news.editor.library.import.error": "Error when adding an image",
    "news.editor.library.title": "Insert an image",
    "news.editor.library.validation": "Select this image",
    "news.editor.library.content": "Select an existing image from the library below or import a new image from your computer.",
    "news.editor.library.format": "Supported formats: png, jpeg Recommended header size: 1000x300px",
    "news.editor.library.import": "Import",
    "news.editor.pictures.title": "Integrate an image tag",
    "news.editor.library.import.modal.title": "Confirm import ?",
    "news.editor.library.import.modal.p1": "The import of your image will begin.",
    "news.editor.library.import.modal.p2": "It will be compressed and available in a few moments.",
    "news.editor.library.import.success": "Successful import",
    "news.details.start.event": "From",
    "news.details.end.event": "until",
    "news.detail.noNews": "No publication for the moment, back later",
    "news.editor.find": "Find a news",
    "news.editor.library.search": "Find a picture",

    // TRANSPORT
    "transport.title": "Public transport",
    "transport.subtitle": "Select a stop",
    "transport.segmented.allStop": "All stops",
    "transport.segmented.fav": "My favorites",
    "transport_no_favorite": "Browse stops and bookmark lines to find them here.",
    "select_a_line": "Sélectionnez une ligne",
    "go_to": "Go to",
    "traffic_info": "Traffic info",
    "all_lines": "Toutes les lignes",
    "favorite": "Favorite",
    "next_departures": "Next departures",
    "no_next_departures": "No next departures",
    "navitia_data": "Data provided by Navitia",
    "transport_no_alerts": "Everything is fine, no alerts for the moment.",
    "my_latest_research": "My last research",
    "itinerary_sub": "Indicate a starting and ending point",
    "walking": "walking",
    "departure_from": "Departure from",
    "walk_to": "Walk to",
    "stop": "stops",

    // QRCODE
    "qrcode.title": "Do more with the {{app}} mobile app",
    "qrcode.content": "Scan this QR Code to download the application",
    // AROUNDME
    "aroundMe.select.categorie": "Select a categorie",
    "phone_number": "Phone",
    "web_site": "Web site",
    "around_me_search": "Search a {{cat}}",
    "restaurants": "Restaurants",
    "bars": "Bars",
    "hotels": "Hotels",
    "banks": "Banks",
    "parking": "Parking",
    "restaurants_sing": "Restaurant",
    "bars_sing": "Bar",
    "hotels_sing": "Hotel",
    "banks_sing": "Bank",
    "parking_sing": "Parking",
  },
  csv: trads,
  common: common,
};
