import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Card, Typography, Spin } from "antd";

// import css
import styles from "./SettingsUI.module.less";

// import component
import Glyph from "../../../Common/Glyph/Glyph";

// import redux
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";

// autre
import { useTranslation } from "react-i18next";
import DegreesDimmer from "./Dimmer/DegreesDimmer";

const SettingsDegrees = ({ setIsAuto, zone, extra, selectedRoom }) => {
  const { t } = useTranslation();

  const isLoading = useSelector(createLoadingSelector(["clients/setAcOffset"]));

  const [status, setStatus] = useState(null);

  return (
    <Card
      className={styles["SettingsUI"]}
      title={
        <Typography.Title level={5} style={{ display: "flex", alignItems: "center" }}>
          <Glyph name="thermostat" className={styles["SettingsUI__pictos"]} />
          {zone ? t("Temperature") + ` - zone ${zone.label}` : t("Temperature")}
        </Typography.Title>
      }
      style={{ padding: "15px", margin: "0 15px" }}
      extra={
        isLoading ? (
          <Spin />
        ) : status === "error" ? (
          <Glyph name="cancel" className={styles["SettingsUI__error"]} />
        ) : status === "success" ? (
          <Glyph name="check_circle" className={styles["SettingsUI__success"]} />
        ) : (
          extra
        )
      }
    >
      <DegreesDimmer
        setIsAuto={setIsAuto}
        isLoading={isLoading}
        setStatus={setStatus}
        selectedRoomNomap={selectedRoom}
      />
    </Card>
  );
};

export default SettingsDegrees;
