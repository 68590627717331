import React from "react";
import { createTeleporter } from "react-teleporter";

const Title = createTeleporter();

export function PhoneBookTitleTarget() {
  return <Title.Target />;
}

export function PhoneBookTitleSource({ children }) {
  return <Title.Source>{children}</Title.Source>;
}
