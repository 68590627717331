import { Button, Checkbox, Form, Space, TimePicker, Typography } from "antd";
import moment from "moment";
import { isEmpty, range } from "ramda";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../services/redux/services/FastbookingWS";
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import useDesignTokens from "../../../hook/useDesignTokens";

import styles from "../../Home/FastBooking/Fastbooking.module.less";

const FORMAT = "HH:mm";

const FilterOptions = ({ options, details, equipments, services, form, seats, setSeats }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);

  const onChange = (e) => {
    if (e.target.checked === true) {
      dispatch(
        setFilter({
          ...filters,
          equipments: [...(filters.equipments || []), e.target.value],
        }),
      );
    } else {
      dispatch(
        setFilter({
          ...filters,
          equipments: [...filters.equipments.filter((id) => id !== e.target.value)],
        }),
      );
    }
  };

  const onChangeServices = (e) => {
    if (e.target.checked === true) {
      dispatch(
        setFilter({
          ...filters,
          services: [...(filters.services || []), e.target.value],
        }),
      );
    } else {
      dispatch(
        setFilter({
          ...filters,
          services: [...filters.services.filter((id) => id !== e.target.value)],
        }),
      );
    }
  };

  const onChangeFlexTime = (e) => {
    if (e.target.checked === true) {
      dispatch(
        setFilter({
          ...filters,
          flexTime: e.target.value,
        }),
      );
    } else {
      dispatch(
        setFilter({
          ...filters,
          flexTime: "",
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(setFilter({ ...filters, seats: seats }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, seats]);

  return (
    <Form
      form={form}
      initialValues={{
        startTime: filters.startDate ? moment(filters.startDate) : moment(),
        endTime: filters.endDate
          ? moment(filters.endDate)
          : filters.startDate
          ? moment(filters.startDate).add(15, "minutes")
          : moment().add(15, "minutes"),
      }}
      onValuesChange={(changedValues) => {
        if (changedValues.startTime) {
          dispatch(
            setFilter({ ...filters, startDate: moment(changedValues.startTime).toISOString() }),
            setFilter({
              ...filters,
              endDate: moment(changedValues.startTime).add(15, "minutes").toISOString(),
            }),
          );
          form.setFieldsValue({ endTime: moment(changedValues.startTime).add(15, "minutes") });
        }
        if (changedValues.endTime) {
          dispatch(setFilter({ ...filters, endDate: moment(changedValues.endTime).toISOString() }));
        }
      }}
    >
      {options && (
        <section style={{ marginTop: "16px", marginRight: "8px" }}>
          <Typography.Title level={5}>Options</Typography.Title>
          <Tile style={{ margin: "0" }}>
            <Typography.Text strong>{t("SpacesAvailable")}s</Typography.Text>
            <Checkbox
              value="30"
              onChange={onChangeFlexTime}
              checked={filters.flexTime && filters.flexTime.includes("30")}
            ></Checkbox>
          </Tile>
        </section>
      )}
      {details && (
        <section style={{ marginTop: "16px", marginRight: "8px" }}>
          <Typography.Title level={5}>{t("ReservationDetail")}</Typography.Title>
          <Space style={{ width: "100%" }} direction="vertical" size="small">
            <Tile style={{ margin: "0" }}>
              <Typography.Text strong>
                <Glyph
                  name="schedule"
                  style={{
                    verticalAlign: "-4px",
                    fontWeight: "normal",
                    marginRight: "8px",
                    color: colors.secondary_base,
                  }}
                />
                {t("Slots")}
              </Typography.Text>
              <Form.Item name="startTime" trigger="onSelect" noStyle>
                <TimePicker
                  //defaultValue={moment()}
                  className={styles["timePicker"]}
                  format={FORMAT}
                  disabledHours={() => range(0, moment().hour())}
                  disabledMinutes={(selectedHour) =>
                    moment().hour() === selectedHour ? range(0, moment().minutes()) : []
                  }
                />
              </Form.Item>
            </Tile>
            <Tile style={{ margin: "0" }}>
              <Typography.Text strong>
                <Glyph
                  name="groups"
                  style={{
                    verticalAlign: "-4px",
                    fontWeight: "normal",
                    marginRight: "8px",
                    color: colors.secondary_base,
                  }}
                />
                {t("numberPeople")}
              </Typography.Text>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                  shape="circle"
                  size="small"
                  style={{ border: "none" }}
                  icon={<Glyph name="remove_circle" />}
                  onClick={() => {
                    if (seats !== 1) setSeats(seats - 1);
                  }}
                ></Button>
                <Typography.Text>{seats}</Typography.Text>
                <Button
                  shape="circle"
                  size="small"
                  style={{ border: "none" }}
                  icon={<Glyph name="add_circle" />}
                  onClick={() => {
                    setSeats(seats + 1);
                  }}
                ></Button>
              </div>
            </Tile>
          </Space>
        </section>
      )}
      {equipments &&
        (!isEmpty(campus?.mapData?.equipments) ? (
          <section style={{ marginTop: "16px", marginRight: "8px" }}>
            <Typography.Title level={5}>Équipements</Typography.Title>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {campus?.mapData?.equipments.map((equipment) => (
                <Tile style={{ margin: "0" }}>
                  <Typography.Text strong>
                    <Glyph
                      name={equipment?.icon}
                      style={{
                        verticalAlign: "-4px",
                        fontWeight: "normal",
                        marginRight: "8px",
                        color: colors.secondary_base,
                      }}
                    />
                    {equipment?.title}
                  </Typography.Text>
                  <Checkbox
                    value={equipment?.id}
                    onChange={onChange}
                    checked={filters.equipments && filters.equipments.includes(equipment.id)}
                  ></Checkbox>
                </Tile>
              ))}
            </Space>
          </section>
        ) : null)}
      {services &&
        (!isEmpty(campus?.mapData?.services) ? (
          <section style={{ marginTop: "16px", marginRight: "8px" }}>
            <Typography.Title level={5}>Services</Typography.Title>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {campus?.mapData?.services.map((service) => (
                <Tile style={{ margin: "0" }}>
                  <Typography.Text strong>
                    <Glyph
                      name={service?.icon}
                      style={{
                        verticalAlign: "-4px",
                        fontWeight: "normal",
                        marginRight: "8px",
                        color: colors.secondary_base,
                      }}
                    />
                    {service?.title}
                  </Typography.Text>
                  <Checkbox
                    value={service?.id}
                    onChange={onChangeServices}
                    checked={filters.services && filters.services.includes(service.id)}
                  ></Checkbox>
                </Tile>
              ))}
            </Space>
          </section>
        ) : null)}
    </Form>
  );
};

export default FilterOptions;
