import { Card, Divider, Image, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDesignTokens from "../../../hook/useDesignTokens";

// import component
import Glyph from "../../Common/Glyph/Glyph";
import styles from "../Dashboard/Dashboard.module.less";

const Widget = () => {
  const { t, i18n } = useTranslation();
  const { colors } = useDesignTokens();
  const userData = useSelector((state) => state.userWS.userData);
  const history = useHistory();

  let userDataStorage = localStorage.getItem("auth_data_dwm");
  userDataStorage = JSON.parse(userDataStorage);

  return (
    <Card style={{ overflow: "auto" }}>
      <Typography.Title level={5} style={{ textTransform: "uppercase", color: colors.grey_40 }}>
        <Glyph
          name="article"
          style={{
            verticalAlign: "-5px",
            fontSize: "30px",
            marginRight: "8px",
            color: colors.interactive_03,
          }}
        />
        {t("long_news", { ns: "csv" })}
      </Typography.Title>
      {userData?.views
        ?.find((v) => v.type === "news" && !v.cold && v?.layout?.dashboard)
        ?.layout?.dashboard?.data?.map((item, index) => (
          <div key={index}>
            <div
              className={styles["news"]}
              style={{ marginBottom: "10px" }}
              onClick={() =>
                history.push(
                  `/news/${
                    userData?.views?.find(
                      (v) => v.type === "news" && !v.cold && v.layout?.dashboard,
                    ).id
                  }/${item?.topics[0]}/${item?.id}`,
                )
              }
            >
              <div className="ql-editor" style={{ width: "700px" }}>
                <Typography.Paragraph
                  strong
                  style={{ marginBottom: "6px", fontSize: "24px", cursor: "pointer" }}
                >
                  {item?.title}
                </Typography.Paragraph>
                <Typography.Paragraph strong style={{ marginBottom: "6px", cursor: "pointer" }}>
                  {item?.subtitle}
                </Typography.Paragraph>
                <p
                  style={{ margin: "0px", cursor: "pointer" }}
                  dangerouslySetInnerHTML={{
                    __html: item?.content?.slice(0, 200),
                  }}
                ></p>
                <Typography.Text className={styles["news__author"]}>
                  {/* {moment(item.publicationDate).format("LLLL")} */}
                  {t("Published", {
                    date: moment(item.publicationDate)
                      .locale(i18n.language.split("-")[0])
                      .fromNow(),
                  })}
                </Typography.Text>
              </div>
              {item.headerMediaUrl && (
                <div className={styles["pictures"]}>
                  <Image
                    width={100}
                    height={100}
                    src={`${item.headerMediaUrl}?access_token=${userDataStorage.token}`}
                    preview={false}
                  />
                </div>
              )}
            </div>
            <Divider style={{ margin: "5px 0" }} />
          </div>
        ))}
    </Card>
  );
};

export default Widget;
