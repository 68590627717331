import { Card, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  filteredTickets,
  selectedTicket as selectedTicketSelector,
  selectTicket,
} from "../../../../services/redux/services/TicketingWS";
// import component
import Glyph from "../../../Common/Glyph/Glyph";
import TicketDetails from "../TicketDetails";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const Waiting = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { colors } = useDesignTokens();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const ticketing = useSelector(filteredTickets);
  const category = useSelector((state) => state.ticketingWS.category);
  const selectedTicket = useSelector(selectedTicketSelector);

  return (
    <section style={{ marginTop: "16px", padding: "10px 0" }}>
      <div
        style={{
          display: "grid",
          gridGap: "10px",
          gridTemplateColumns: "repeat(auto-fill, 380px)",
        }}
      >
        {ticketing?.items
          ?.filter((i) => i.statusId === 1)
          ?.map((item) => (
            <Card
              onClick={() => {
                setIsModalVisible(true);
                dispatch(selectTicket(item.id));
                logEvent(analytics, "ticketing_access_details", { current_ticket: item.id });
              }}
              style={{ cursor: "pointer" }}
              title={
                <div style={{ display: "flex", flexFlow: "column" }}>
                  <Typography.Text strong>
                    <Glyph
                      style={{
                        marginRight: "8px",
                        verticalAlign: "-4px",
                        fontWeight: "normal",
                        color: colors.secondary_base,
                      }}
                      name="receipt"
                    />
                    Ticket ID {item.uid}
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: "14px", color: colors.success_light }}>
                    <Glyph name="fiber_manual_record" style={{ verticalAlign: "-4px" }} />
                    {item.status}
                  </Typography.Text>
                </div>
              }
              extra={
                <Typography.Text strong style={{ fontSize: "12px" }}>
                  {moment(item.date).format("DD/MM/YYYY")}
                </Typography.Text>
              }
            >
              <Typography.Paragraph strong style={{ marginBottom: "0", fontSize: "14px" }}>
                <Glyph
                  name="format_list_bulleted"
                  style={{ verticalAlign: "-4px", marginRight: "8px" }}
                />
                {
                  category?.ticketGroups?.find((group) => group.id === item.ticketGroupId)?.name[
                    i18n.language.split("-")[0]
                  ]
                }
              </Typography.Paragraph>
              <Typography.Paragraph style={{ marginBottom: "0", fontSize: "14px" }}>
                {item.title}
              </Typography.Paragraph>
            </Card>
          ))}
      </div>
      {selectedTicket && (
        <TicketDetails
          isVisible={isModalVisible}
          selectedTicket={selectedTicket}
          onCancel={() => {
            setIsModalVisible(false);
          }}
        />
      )}
    </section>
  );
};

export default Waiting;
