import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { releaseRoom } from "../../../services/redux/services/UserWS";
import jwt_decode from "jwt-decode";
import { Spin } from "antd";

import Expired from "./Expired";
import Failure from "./Failure";
import SuccessKeep from "./SuccessKeep";
import SuccessCancel from "./SuccessCancel";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ReleaseRoom = () => {
  let query = useQuery();

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(releaseRoom({ action: query.get("action"), token: query.get("token") }))
      .unwrap()
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setSuccess(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, query]);

  const decoded = jwt_decode(query.get("token"));

  if (isLoading) {
    return <Spin />;
  } else {
    if (decoded.exp < Date.now() / 1000) {
      return <Expired data={decoded} />;
    } else if (query.get("action") === "keep" && success === true) {
      return <SuccessKeep data={decoded} />;
    } else if (query.get("action") === "keep" && success === false) {
      return <Failure data={decoded} />;
    } else if (query.get("action") === "cancel" && success === false) {
      return <Failure data={decoded} />;
    } else if (query.get("action") === "cancel" && success === true) {
      return <SuccessCancel data={decoded} />;
    }
  }
};

export default ReleaseRoom;
