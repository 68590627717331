/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ListIconTransport from "../../../../helpers/ListIconTransport";
import ReactDOMServer from "react-dom/server";

const MapsGoogle = ({ setInputSearchTo, stop }) => {
  const history = useHistory();
  const map = useRef();

  const itineraryFeatures = useRef([]);

  const listTransport = useSelector((state) => state.transportWS.listTransport.stopAreas);
  const infos = useSelector((state) => state.transportWS.detailsJourney);
  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const campus = useSelector((state) => state.clientsWS.campus);

  const lat = sites?.find((c) => c?.id === campus?.mapData?.id)?.lat;
  const lng = sites?.find((c) => c?.id === campus?.mapData?.id)?.lon;

  const [markers, setMarkers] = useState([]);
  const [markerBuilding, setMarkerBuilding] = useState();

  const selectMarker = useCallback((marker) => {
    setMarkers((markers) => {
      markers?.forEach((m) => {
        if (m.marker === marker || m.id === marker) {
          m.marker.setIcon(m.iconSelected);
        } else {
          m.marker.setIcon(m.icon);
        }
      });
      return markers;
    });
  }, []);

  useEffect(() => {
    if (!lat || !lng) return;
    map.current = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: lat,
        lng: lng,
      },
      zoom: 17,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      disableDefaultUI: true,
    });

    const customIconBuilding = ListIconTransport("building");
    const customIconStringBuilding = ReactDOMServer.renderToString(customIconBuilding);

    const markerBuilding = new window.google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: map.current,
      icon: {
        url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(customIconStringBuilding),
      },
    });
    setMarkerBuilding(markerBuilding);
  }, [lat, lng]);

  useEffect(() => {
    if (!markerBuilding) return;
    const listener = () => {
      selectMarker();
      history.push({
        pathname: `/transport/stop`,
        state: { building: true },
      });
      setInputSearchTo({
        name: sites?.find((c) => c?.id === campus?.mapData?.id)?.address,
        id: `${lng};${lat}`,
      });
    };
    markerBuilding.addListener("click", listener);
  }, [
    campus?.mapData?.id,
    history,
    lat,
    lng,
    markerBuilding,
    selectMarker,
    setInputSearchTo,
    sites,
  ]);

  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    for (const feature of itineraryFeatures.current) {
      feature.setMap(null);
    }
    itineraryFeatures.current = [];

    infos?.sections?.forEach((section) => {
      const fromCoords = {
        lat: parseFloat(section?.from?.lat),
        lng: parseFloat(section?.from?.lon),
      };

      const toCoords = {
        lat: parseFloat(section?.to?.lat),
        lng: parseFloat(section?.to?.lon),
      };

      const color =
        section.informations && section.informations.color ? section.informations.color : "#808080";

      const request = {
        origin: fromCoords,
        destination: toCoords,
        travelMode: window.google.maps.TravelMode.WALKING,
      };
      if (section?.mode !== "walking") {
        const borderPolyline = new window.google.maps.Polyline({
          path: [fromCoords, toCoords],
          geodesic: true,
          strokeColor: "#FFFFFF",
          strokeOpacity: 1.0,
          strokeWeight: 12,
        });

        const polyline = new window.google.maps.Polyline({
          path: [fromCoords, toCoords],
          geodesic: true,
          strokeColor: color,
          strokeOpacity: 1.0,
          strokeWeight: 7,
        });
        borderPolyline.setMap(map.current);
        polyline.setMap(map.current);
        itineraryFeatures.current.push(borderPolyline, polyline);
      } else {
        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            var lineSymbol = {
              path: "M1,0 A1,1 0 1,1 1,2 A1,1 0 1,1 1,0",
              strokeOpacity: 0,
              scale: 4,
              fillColor: color,
              fillOpacity: 1,
            };

            const route = result.routes[0];

            const polyline = new window.google.maps.Polyline({
              path: route.overview_path,
              geodesic: true,
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            });
            polyline.setMap(map.current);
            itineraryFeatures.current.push(polyline);
          }
        });
      }
      const customIconStart = ListIconTransport("start");
      const customIconStringStart = ReactDOMServer.renderToString(customIconStart);
      const markerStart = new window.google.maps.Marker({
        position: {
          lat: parseFloat(infos?.sections[0]?.from?.lat),
          lng: parseFloat(infos?.sections[0]?.from?.lon),
        },
        map: map.current,
        icon: {
          url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(customIconStringStart),
        },
      });

      const customIconEnd = ListIconTransport("end");
      const customIconStringEnd = ReactDOMServer.renderToString(customIconEnd);
      const markerEnd = new window.google.maps.Marker({
        position: {
          lat: parseFloat(infos?.sections[infos?.sections.length - 1]?.to?.lat),
          lng: parseFloat(infos?.sections[infos?.sections.length - 1]?.to?.lon),
        },
        map: map.current,
        icon: {
          url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(customIconStringEnd),
        },
      });
      map.current.setCenter({
        lat: parseFloat(infos?.sections[0]?.from?.lat),
        lng: parseFloat(infos?.sections[0]?.from?.lon),
      });

      itineraryFeatures.current.push(markerStart, markerEnd);
    });
  }, [infos]);

  useEffect(() => {
    const newMarkers = listTransport?.map((list, index) => {
      const customIcon = ListIconTransport(list?.modes[0]?.id);
      const customIconString =
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(ReactDOMServer.renderToString(customIcon));

      const customIconSeleted = ListIconTransport(list?.modes[0]?.id + "_selected");
      const customIconStringSelected =
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(ReactDOMServer.renderToString(customIconSeleted));

      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(list?.lat), lng: parseFloat(list?.lon) },
        map: map.current,
        icon: {
          url: customIconString,
        },
      });

      marker.addListener("click", () => {
        selectMarker(marker);
        history.push({
          pathname: `/transport/stop`,
          state: { stop: list },
        });
        setInputSearchTo(list);
        map.current.setZoom(17);
        map.current.setCenter({ lat: parseFloat(list?.lat), lng: parseFloat(list?.lon) });
      });

      return {
        marker,
        icon: customIconString,
        iconSelected: customIconStringSelected,
        id: list?.id,
      };
    });
    setMarkers((markers) => {
      markers?.forEach((m) => m.marker.setMap(null));
      return newMarkers;
    });
  }, [history, listTransport, selectMarker, setInputSearchTo]);

  useEffect(() => {
    selectMarker(stop?.id);
    if (stop) {
      map.current.setZoom(17);
      map.current.setCenter({ lat: parseFloat(stop?.lat), lng: parseFloat(stop?.lon) });
    }
  }, [selectMarker, stop]);
  return <div id="map" style={{ width: "100%", height: "100%", position: "absolute" }} />;
};

export default MapsGoogle;
