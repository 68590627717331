import { useCallback, useState } from "react";

const useDimensions = () => {
  const [dimensions, setDimensions] = useState(0);
  const ref = useCallback((node) => {
    if (node !== null) {
      setDimensions(node.getBoundingClientRect().toJSON());
    }
  }, []);

  return [ref, dimensions];
};

export default useDimensions;
