import { Breadcrumb, Button, Card, Col, Image, Row, Typography } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import styles from "./News.module.less";
import Tile from "../../Common/Tile/Tile";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getNewsByTopics,
  getNewsItem,
  likesNews,
  readNews,
} from "../../../services/redux/services/NewsWS";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { useKeycloak } from "@react-keycloak/web";
import Meta from "antd/lib/card/Meta";
import Comments from "./Comments/Comments";
import useDesignTokens from "../../../hook/useDesignTokens";
import { isNil } from "ramda";
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../../../services/api/Firebase/Firebase";

const analytics = getAnalytics(app);

const NewsDetails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id, topicId, sectionId } = useParams();
  const { keycloak } = useKeycloak();
  const { colors } = useDesignTokens();
  const history = useHistory();

  const item = useSelector((state) => state.newsWS.item);
  const news = useSelector((state) => state.newsWS.newsByTopics);
  const userData = useSelector((state) => state.userWS.userData);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  useEffect(() => {
    dispatch(getNewsItem({ itemId: id, locale: i18n.language.split("-")[0], viewId: sectionId }));
  }, [dispatch, i18n.language, id, sectionId]);

  useEffect(() => {
    dispatch(readNews({ itemId: id, viewId: sectionId }));
  }, [dispatch, id, sectionId]);

  useEffect(() => {
    dispatch(
      getNewsByTopics({
        locale: i18n.language.split("-")[0],
        viewId: sectionId,
      }),
    );
  }, [dispatch, i18n.language, sectionId]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={15}>
        <Breadcrumb separator=">" style={{ marginBottom: "16px" }}>
          <Breadcrumb.Item>
            <Link to={`/news/${sectionId}`}>
              {t(userData?.views?.find((view) => view.id === parseInt(sectionId))?.title, {
                ns: "csv",
              })}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/news/${sectionId}/${topicId}`}>
              {news?.topics?.find((topic) => topic.id === parseInt(topicId))?.label}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ backgroundColor: "transparent" }}>
            {news?.news?.find((n) => n.id === parseInt(id))?.title}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Card bodyStyle={{ display: "flex", flexFlow: "column" }}>
          <Typography.Title level={4} style={{ fontSize: "24px" }}>
            {item?.title}
          </Typography.Title>
          <Typography.Text style={{ fontSize: "18px" }}>{item?.subtitle}</Typography.Text>
          <div style={{ display: "flex", flexFlow: "row", justifyContent: "space-between" }}>
            <Typography.Text type="secondary" style={{ margin: "16px 0", fontSize: "12px" }}>
              <Glyph name="schedule" style={{ marginRight: "8px" }} />
              {moment(item?.publishedAt).format("L - LT")}
            </Typography.Text>
            <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
              {item?.authorizeComment && (
                <>
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px", marginRight: "8px" }}
                  >
                    <Glyph name="comment" style={{ marginRight: "4px" }} />
                    {item?.nbComments}
                  </Typography.Text>

                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px", marginRight: "8px" }}
                  >
                    {item?.isLiked ? (
                      <Glyph
                        name="favorite"
                        style={{ marginRight: "4px", color: colors?.error_light }}
                      />
                    ) : (
                      <Glyph name="favorite_border" style={{ marginRight: "4px" }} />
                    )}

                    {item?.nbLikes}
                  </Typography.Text>
                  <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                    <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                    {item?.nbViews}
                  </Typography.Text>
                </>
              )}
            </div>
          </div>

          {!isNil(item?.header) && (
            <div className={styles["newsListPictures"]}>
              <Image
                preview={false}
                height={300}
                src={`${item?.header?.url}?access_token=${keycloak.token}`}
              />
            </div>
          )}

          {(!isNil(item?.event?.resource?.id) || !isNil(item?.event?.place?.address)) && (
            <Tile
              style={{
                flexFlow: "column",
                height: "auto",
                alignItems: "flex-start",
                margin: "16px 0 0 0",
                padding: "16px",
                cursor: "default",
              }}
            >
              <Typography.Title
                level={5}
                style={{ color: colors?.secondary_base, fontSize: "24px" }}
              >
                {t("news.event.title")}
              </Typography.Title>
              <Typography.Text strong>{item?.event?.title}</Typography.Text>
              <Typography.Text strong>
                {t("news.details.start.event")} {moment(item?.event?.startDate).format("LLL")}{" "}
                {t("news.details.end.event")} {moment(item?.event?.endDate).format("LLL")}
              </Typography.Text>
              {!isNil(item?.event?.resource) ? (
                <Typography.Text strong>
                  {t("news.event.agence")}{" "}
                  {userData?.campus?.find((c) => c?.id === item?.event?.resource?.siteId)?.title} -{" "}
                  {item?.event?.resource?.title}
                </Typography.Text>
              ) : (
                <Typography.Text>{item?.event?.place?.address}</Typography.Text>
              )}

              {!isNil(item?.event?.resource?.id) && mapData?.id === item?.event?.resource?.siteId && (
                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Link
                    to={{
                      pathname: `/indoorMapping/${
                        mapData?.resources?.find((r) => r?.id === item?.event?.resource?.id)?.map
                          ?.findAndOrder?.placeId
                      }`,
                      state: {
                        room: mapData?.resources?.find((r) => r?.id === item?.event?.resource?.id),
                      },
                    }}
                    exact="true"
                  >
                    <Button ghost icon={<Glyph name="map" />} style={{ width: "300px" }}>
                      {t("spas.request.actions.map")}
                    </Button>
                  </Link>
                </div>
              )}
            </Tile>
          )}
          {/** Ajout de la classe ql-editor pour pouvoir avoir le css de reactQuill  */}
          <div style={{ margin: "16px 0" }} className="ql-editor">
            <p
              dangerouslySetInnerHTML={{
                __html: item?.content,
              }}
            ></p>
          </div>

          {item?.authorizeComment && (
            <div style={{ display: "flex", flexFlow: "row", justifyContent: "center" }}>
              <Tile
                style={{ width: "300px", justifyContent: "center", height: "64px" }}
                onClick={() => {
                  dispatch(likesNews({ itemId: id, like: !item.isLiked, viewId: sectionId }))
                    .unwrap()
                    .then(() => {
                      logEvent(analytics, `news_like`);
                      dispatch(
                        getNewsItem({
                          itemId: id,
                          locale: i18n.language.split("-")[0],
                          viewId: sectionId,
                        }),
                      );
                    });
                }}
              >
                {item?.isLiked ? (
                  <Glyph
                    name="favorite"
                    style={{ marginRight: "8px", color: colors?.error_light }}
                  />
                ) : (
                  <Glyph name="favorite_border" style={{ marginRight: "8px" }} />
                )}

                <Typography.Text>{t("news.article.like")}</Typography.Text>
              </Tile>

              {/* <Tile style={{ width: "300px", justifyContent: "center", height: "64px" }}>
                <Glyph name="share" style={{ marginRight: "8px" }} />
                <Typography.Text>{t("news.article.share")}</Typography.Text>
              </Tile> */}
            </div>
          )}
        </Card>
        {item?.authorizeComment && <Comments />}
      </Col>

      <Col span={9}>
        {news?.news
          ?.filter((f) => f.topics?.filter((t) => item?.topics.includes(t)))
          .filter((n) => n.id !== parseInt(id)).length !== 0 && (
          <Typography.Text strong style={{ fontSize: "24px" }}>
            {t("news.article.same")}
          </Typography.Text>
        )}

        {news?.news
          ?.filter((f) => f.topics?.filter((t) => item?.topics.includes(t)))
          .filter((n) => n.id !== parseInt(id))
          .slice(0, 3)
          .map((n, index) => (
            <Card
              onClick={() => history.push(`/news/${sectionId}/${topicId}/${n?.id}`)}
              key={index}
              style={{ cursor: "pointer", marginTop: "8px" }}
              cover={
                !isNil(n?.header) ? (
                  <div style={{ boxShadow: "none" }} className={styles["newsListPictures"]}>
                    <Image
                      preview={false}
                      src={`${n?.header?.url}?access_token=${keycloak.token}`}
                    />
                  </div>
                ) : (
                  <div
                    className={styles["noPictures"]}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                      width: "100%",
                      backgroundColor: colors.grey_40,
                      opacity: 0.3,
                      zIndex: 1,
                    }}
                  >
                    <Glyph
                      style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                      name="article"
                    />
                  </div>
                )
              }
            >
              <Meta
                title={<Typography.Title level={5}>{n?.title}</Typography.Title>}
                description={
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography.Paragraph type="secondary">
                      <Glyph name="schedule" style={{ marginRight: "8px" }} />
                      {moment(n.publishedAt).format("L")}
                    </Typography.Paragraph>
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                      {n?.authorizeComment && (
                        <>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: "12px", marginRight: "8px" }}
                          >
                            <Glyph name="comment" style={{ marginRight: "4px" }} />
                            {n?.nbComments}
                          </Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: "12px", marginRight: "8px" }}
                          >
                            {n?.isLiked ? (
                              <Glyph
                                name="favorite"
                                style={{ marginRight: "4px", color: colors?.error_light }}
                              />
                            ) : (
                              <Glyph name="favorite_border" style={{ marginRight: "4px" }} />
                            )}

                            {n?.nbLikes}
                          </Typography.Text>
                          <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                            <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                            {n?.nbViews}
                          </Typography.Text>
                        </>
                      )}
                    </div>
                  </div>
                }
              />
            </Card>
          ))}
      </Col>
    </Row>
  );
};

export default NewsDetails;
