/* eslint-disable no-template-curly-in-string */
import { ConfigProvider, Modal } from "antd";
import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import nlBE from "antd/lib/locale/nl_BE";

import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./Routes";
import store, { persistor } from "./services/redux/configureStore";
import { MapProvider } from "./views/Common/Map/useMap";
import GlobalLoader from "./views/Common/GlobalLoader/GlobalLoader";

const antLocales = {
  fr: frFR,
  en: enUS,
  nl: nlBE,
};

const App = () => {
  const { i18n } = useTranslation();
  const lng = i18n.language.split("-")[0];

  moment.locale(lng, {
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });

  const authResult = new URLSearchParams(window.location.search);
  const siteId = authResult.get("siteId");
  if (siteId) {
    localStorage.setItem("siteId", siteId);
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider componentSize="large" locale={antLocales[lng]}>
          <MapProvider>
            <Router
              getUserConfirmation={(message, callback) => {
                Modal.confirm({
                  title: message,
                  onOk() {
                    return callback(true);
                  },
                  onCancel() {
                    return callback(false);
                  },
                });
              }}
            >
              <GlobalLoader />
              <Routes />
            </Router>
          </MapProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
