import { Button, Col, Modal, Row, Space, Typography } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../SpaceBooking.module.less";
import CancelRequestModal from "./CancelRequestModal";
import DeclineRequestModal from "./DeclineRequestModal";
import PendingRequestModal from "./PendingRequestModal";
import RequestDetails from "./RequestDetails";

const RequestDetailsModal = ({ self = false, slot, onCancel }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const isLoading = useSelector(
    createLoadingSelector([
      "spaceService/cancelSlot",
      "spaceServiceAdmin/declineSlots",
      "spaceServiceAdmin/pendingSlots",
    ]),
  );

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);

  return (
    <>
      <Modal
        className={clsx(styles.modal)}
        open={!!slot}
        onCancel={onCancel}
        title={<Typography.Text strong>{t("spas.request.details")}</Typography.Text>}
        okButtonProps={{ block: true }}
        cancelButtonProps={{ block: true }}
        footer={
          self
            ? [
                <Button
                  key="cancel"
                  block
                  ghost
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => setShowCancelModal(true)}
                >
                  {t("spas.request.actions.cancel", { count: 1 })}
                </Button>,
              ]
            : slot?.status === "PENDING"
            ? [
                <Button
                  key="decline"
                  block
                  ghost
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => setShowDeclineModal(true)}
                >
                  {t("spas.request.actions.decline", { count: 1 })}
                </Button>,
                <Button
                  key="approve"
                  block
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => history.push("/spaceBooking/admin", { slots: [slot] })}
                >
                  {t("spas.request.actions.approve", { count: 1 })}
                </Button>,
              ]
            : slot?.status === "APPROVED"
            ? [
                <Button
                  key="pending"
                  block
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => setShowPendingModal(true)}
                >
                  {t("spas.request.actions.removeSpace", { count: 1 })}
                </Button>,
                <Button
                  key="approve"
                  block
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => history.push("/spaceBooking/admin", { slots: [slot] })}
                >
                  {t("spas.request.actions.updateSpace", { count: 1 })}
                </Button>,
              ]
            : null
        }
      >
        <Typography.Title level={4}>
          <Space>
            <Glyph name="receipt" className={"secondary"} style={{ fontSize: "1.1em" }} />
            {t("spas.request.id.title", { id: slot?.request?.id })}
          </Space>
        </Typography.Title>
        <br />
        {/* <Tabs>
          <Tabs.TabPane tab={t("spas.request")} key="requests">
            <Row gutter={[0, 10]}>
              {!self && (
                <Col span={24}>
                  <RequestDetails.User slot={slot} />
                </Col>
              )}
              <Col span={24}>
                <RequestDetails.Sector slot={slot} />
              </Col>
              <Col span={24}>
                <RequestDetails.Infos slot={slot} />
              </Col>
              <Col span={24}>
                <RequestDetails.From slot={slot} />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("spas.request.comments")} key="comments">
            <RequestDetails.Comments slot={slot} />
          </Tabs.TabPane>
        </Tabs> */}
        <Row gutter={[0, 10]}>
          {!self && (
            <Col span={24}>
              <RequestDetails.User slot={slot} />
            </Col>
          )}
          <Col span={24}>
            <RequestDetails.Sector slot={slot} />
          </Col>
          <Col span={24}>
            <RequestDetails.Infos slot={slot} />
          </Col>
          <Col span={24}>
            <RequestDetails.From slot={slot} />
          </Col>
        </Row>
      </Modal>

      <CancelRequestModal
        visible={showCancelModal}
        slot={slot}
        onSubmit={() => {
          setShowCancelModal(false);
          onCancel();
        }}
        onCancel={() => setShowCancelModal(false)}
      />
      <DeclineRequestModal
        visible={showDeclineModal}
        slots={[slot]}
        onSubmit={() => {
          setShowDeclineModal(false);
          onCancel();
        }}
        onCancel={() => setShowDeclineModal(false)}
      />
      <PendingRequestModal
        visible={showPendingModal}
        slots={[slot]}
        onSubmit={() => {
          setShowPendingModal(false);
          onCancel();
        }}
        onCancel={() => setShowPendingModal(false)}
      />
    </>
  );
};

export default RequestDetailsModal;
