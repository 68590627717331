import clsx from "clsx";
import { createTeleporter } from "react-teleporter";
import styles from "../SpaceBooking.module.less";

const ActionBanner = createTeleporter();

export const ActionBannerSource = ({ visible, children }) => {
  return (
    <ActionBanner.Source>
      <div className={clsx(styles.actionBanner, { [styles.visible]: !!visible })}>{children}</div>
    </ActionBanner.Source>
  );
};

export default ActionBanner;
