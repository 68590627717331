import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SwiziNews from "./src/App";

const News = () => {
  const { sectionId } = useParams();
  const [display, setDisplay] = useState(true);

  const campus = useSelector((state) => state.clientsWS.campus);
  const userData = useSelector((state) => state.userWS.userData);

  const campusData = userData.campus.find((c) => c.id === campus?.mapData?.id);

  const newsInfo = userData.views.filter((v) => v.type === "news");

  useEffect(() => {
    setDisplay(false);
  }, [sectionId]);

  useEffect(() => {
    if (!display) setDisplay(true);
  }, [display]);

  return (
    display && (
      <SwiziNews
        common={{ swiziSectionId: sectionId }}
        campusData={campusData}
        newsInfo={newsInfo}
      />
    )
  );
};

export default News;
