import { createSelector, createSlice } from "@reduxjs/toolkit";
import { equals } from "ramda";

const BASE_FILTERS = {
  categories: [],
  rooms: [],
  organizer: undefined,
  hidden: false,
  tasks: false,
  new: false,
};

const INITIAL_STATE = {
  facilities: {
    filters: BASE_FILTERS,
  },
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const preferencesSlice = createSlice({
  name: "preferences",
  initialState: INITIAL_STATE,
  reducers: {
    patchFacilitiesFilters(state, action) {
      Object.assign(state.facilities.filters, action.payload);
    },
    resetFacilitiesFilters(state) {
      state.facilities.filters = BASE_FILTERS;
    },
  },
  extraReducers: undefined,
});

export const hasFacilitiesFilters = createSelector(
  (state) => state.PreferencesManager.facilities.filters,
  (filters) => {
    return !equals(filters, BASE_FILTERS);
  },
);

export const { patchFacilitiesFilters, resetFacilitiesFilters } = preferencesSlice.actions;
export default preferencesSlice.reducer;
