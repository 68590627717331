import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const IconStyles = { color: "rgba(0, 0, 0, 0.45)" };

const Helper = (props) => {
  return (
    <Tooltip {...props}>
      <InfoCircleOutlined style={IconStyles} />
    </Tooltip>
  );
};

export default Helper;
