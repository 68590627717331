import { message, Modal, Spin, Tabs } from "antd";
import { last } from "ramda";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import { createTab } from "../../../../services/redux/services/FacilitiesWS";
import styles from "../DashboardFacilities.module.less";
import General from "./General";
import Page from "./Page";

const EventModal = ({ event, visible, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ref = useRef();

  const [activeKey, setActiveKey] = useState("general");

  const isLoading = useSelector(
    createLoadingSelector([
      "facilities/updateEvent",
      "facilities/createTab",
      "facilities/updateTab",
      "facilities/deleteTab",
      "facilities/createSection",
      "facilities/updateSection",
      "facilities/deleteSection",
    ]),
  );
  const isFacility = useSelector((state) => state.userWS.userData.profile?.rights.facility);

  useEffect(() => {
    setActiveKey("general");
  }, [visible]);

  const onEdit = (_, action) => {
    if (action === "add") {
      dispatch(createTab({ title: t("facilities.event.tab.new"), description: "" }))
        .unwrap()
        .then((event) => {
          setActiveKey(last(event.tabs)._id);
        })
        .catch(() => {
          message.error(t("ErrorOccured"));
        });
    }
  };

  const handleAddSection = () => {
    const el = ref.current.querySelector(".sectionsHolder > .ant-space-item:last-of-type");
    el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  };

  const items = [
    {
      key: "general",
      label: t("facilities.event.tab.general"),
      children: <General event={event} onClose={onClose} />,
      closable: false,
    },
    ...(event?.tabs || []).map((t) => ({
      key: t._id,
      label: t.title,
      children: (
        <Page
          event={event}
          tab={t}
          onClose={onClose}
          onDeleteTab={() => setActiveKey("general")}
          onAddSection={handleAddSection}
        />
      ),
      closable: false,
    })),
  ];

  return (
    <Modal
      className={styles.eventModal}
      width={800}
      destroyOnClose={true}
      open={!!visible}
      onCancel={onClose}
      closable={false}
      footer={null}
    >
      <div ref={ref}>
        <Tabs
          style={{ maxHeight: "90vh" }}
          type={isFacility ? "editable-card" : "card"}
          onChange={(key) => setActiveKey(key)}
          activeKey={activeKey}
          onEdit={onEdit}
          items={items}
        />
      </div>
      {isLoading && (
        <Spin
          spinning
          size="large"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(5px)",
          }}
        />
      )}
    </Modal>
  );
};

export default EventModal;
