import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";
import useDesignTokens from "../../helpers/useDesignTokens";

const QRCode = ({ value, ...rest }) => {
  const { colors } = useDesignTokens();

  const ref = useRef(null);
  const qrCode = useRef(
    new QRCodeStyling({
      width: 800,
      height: 800,
      dotsOptions: {
        color: colors.primary_base,
        type: "rounded",
      },
      backgroundOptions: {
        color: "transparent",
      },
      imageOptions: {
        imageSize: 1,
        hideBackgroundDots: false,
      },
      qrOptions: {
        errorCorrectionLevel: "L",
      },
    }),
  );

  useEffect(() => {
    qrCode.current.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.current.update({ data: value });
  }, [value]);

  return <div {...rest} className="qrcode" ref={ref} />;
};

export default QRCode;
