import clsx from "clsx";
import styles from "./StepsTracker.module.less";

const StepsTracker = ({ steps }) => {
  return (
    <div className={styles.steps}>
      {steps.map((s) => (
        <div
          key={s.key}
          className={clsx(styles.step, {
            [styles.current]: !!s.current,
            [styles.active]: !!s.active || !!s.current,
          })}
        />
      ))}
    </div>
  );
};

export default StepsTracker;
