import { createSelector, createSlice, createAsyncThunk as thunk } from "@reduxjs/toolkit";
import moment from "moment";
import { isEmpty } from "ramda";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  today: null,
  slots: [],
  team: [],
  users: [],
  followed: [],
  followedPending: [],
  presence: [],
  workplaceCount: null,
  listWorkplace: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const getToday = thunk("spaceService/getToday", async () => {
  const slots = await DWMConnector.listSpaasSlots({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  return slots[0];
});

EXTRA_REDUCERS[getToday.fulfilled] = (state, action) => {
  state.today = action.payload;
};

export const listSlots = thunk("spaceService/listSlots", async ({ startDate, endDate } = {}) => {
  if (!startDate) startDate = moment().startOf("month").subtract(1, "month").toISOString();
  if (!endDate) endDate = moment(startDate).endOf("month").add(6, "month").toISOString();
  const slots = await DWMConnector.listSpaasSlots({
    startDate,
    endDate,
  });
  return slots;
});

EXTRA_REDUCERS[listSlots.fulfilled] = (state, action) => {
  state.slots = action.payload;
};

export const createRequest = thunk("spaceService/createRequest", async (data, { dispatch }) => {
  await DWMConnector.createSpaasRequest(data);
  await dispatch(listSlots());
});

export const cancelSlot = thunk("spaceService/cancelSlot", async ({ id, data }, { dispatch }) => {
  await DWMConnector.cancelSpaasSlot(id, data);
  await dispatch(listSlots());
});

export const updateSlot = thunk(
  "spaceService/updateSlot",
  async ({ id, ...data }, { dispatch }) => {
    await DWMConnector.updateSpaasRequest(id, data);
    await dispatch(listSlots());
  },
);

export const getTeam = thunk("spaceService/getTeam", async (_, { getState }) => {
  const state = getState();
  const siteId = state.userWS.userData?.campus?.[0]?.id;
  const { team } = await DWMConnector.getSpasTeam({ siteId });
  return team;
});

EXTRA_REDUCERS[getTeam.fulfilled] = (state, action) => {
  state.team = action.payload;
};

export const addToTeam = thunk(
  "spaceService/addToTeam",
  async ({ userId }, { getState, dispatch }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    await DWMConnector.addSpasTeam({ siteId, userId });
    await dispatch(getTeam());
  },
);

export const removeFromTeam = thunk(
  "spaceService/removeFromTeam",
  async ({ userId }, { getState, dispatch }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    await DWMConnector.removeSpasTeam({ siteId, userId });
    await dispatch(getTeam());
  },
);

export const searchUsers = thunk("spaceService/searchUsers", async ({ search, page, size }) => {
  const users = await DWMConnector.searchUsers({ search, page, size });
  return users;
});

EXTRA_REDUCERS[searchUsers.fulfilled] = (state, action) => {
  state.users = action.payload;
};

export const listFollowed = thunk("spaceService/listFollowed", async () => {
  const followed = await DWMConnector.listFollowed();
  return followed;
});

EXTRA_REDUCERS[listFollowed.fulfilled] = (state, action) => {
  state.followed = action.payload.followed.filter((p) => p.status === "accepted");
  state.followedPending = action.payload.followed.filter((p) => p.status === "pending");
};

export const listPresence = thunk("spaceService/listPresence", async ({ siteId, date }) => {
  const presence = await DWMConnector.listPresence({ siteId, date });
  return presence;
});

EXTRA_REDUCERS[listPresence.fulfilled] = (state, action) => {
  state.presence = action.payload;
};

export const revokeFollow = thunk(
  "spaceService/revokeFollow",
  async ({ followId }, { dispatch }) => {
    await DWMConnector.revokeFollow({ followId });
    await dispatch(listFollowed());
  },
);

export const answerFollow = thunk(
  "spaceService/answerFollow",
  async ({ followId, accepted }, { dispatch }) => {
    await DWMConnector.answerFollow({ followId, accepted });
    await dispatch(listFollowed());
  },
);

export const sendFollow = thunk("spaceService/sendFollow", async ({ userId }, { dispatch }) => {
  await DWMConnector.sendFollow({ userId });
});

export const workplaceCount = thunk("spaceService/workplaceCount", async ({ siteId, date }) => {
  const workplaceCount = await DWMConnector.workplaceCount({ siteId, date });
  return workplaceCount;
});

EXTRA_REDUCERS[workplaceCount.fulfilled] = (state, action) => {
  state.workplaceCount = action.payload;
};

export const listWorkplace = thunk(
  "spaceService/listWorkplace",
  async ({ siteId, sectorId, date, period }, { getState }) => {
    const state = getState();
    const listWorkplace = await DWMConnector.listWorkplace({ siteId, sectorId, date, period });
    listWorkplace.availability = listWorkplace.availability.map((list) => ({
      ...list,
      workplaces: state.mapDataWS[siteId]?.mapData?.workplaces.find((w) => w.id === list.id),
    }));
    return listWorkplace;
  },
);
EXTRA_REDUCERS[listWorkplace.fulfilled] = (state, action) => {
  state.listWorkplace = action.payload;
};

export const confirmPresence = thunk(
  "spaceService/confirmPresence",
  async ({ slotId, siteId, code }, { dispatch }) => {
    await DWMConnector.confirmPresence({ slotId, siteId, code });
  },
);

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const spaceServiceSlice = createSlice({
  name: "spaceService",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    for (const [key, handler] of Object.entries(EXTRA_REDUCERS)) {
      builder.addCase(key, handler);
    }
  },
});

export const listWorkplaceWithDetails = createSelector(
  (state) => state.spaceServiceWS.listWorkplace,
  (state, siteId) => state.mapDataWS[siteId]?.mapData,
  (listWorkplace, mapData) => {
    console.log("Processing selector listWorkplaceWithDetails", listWorkplace, mapData);
    if (isEmpty(listWorkplace)) return;
    const modifiedListWorkplace = {
      ...listWorkplace,
      availability: listWorkplace.availability.map((list) => ({
        ...list,
        workplaces: mapData?.workplaces.find((w) => w.id === list.id),
      })),
    };

    return modifiedListWorkplace;
  },
);

export default spaceServiceSlice.reducer;
