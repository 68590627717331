import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useSelectedRoom = () => {
  const { id } = useParams();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const selectedRoom = useMemo(() => {
    const room = mapData?.resources?.find((r) => r.map.findAndOrder.placeId === id || r.id === id);
    if (!room) return undefined;
    return { ...room, floor: mapData?.floors?.find((f) => f.reference === room.floorReference) };
  }, [id, mapData]);

  return selectedRoom;
};

export default useSelectedRoom;
