import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  transfertInfos: null,
  filesInfos: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const getTransfertInfos = thunk("transfert/getTransfertInfos", async () => {
  const transfert = await DWMConnector.getTransfertInfos();
  return transfert;
});

EXTRA_REDUCERS[getTransfertInfos.fulfilled] = (state, action) => {
  state.transfertInfos = action.payload;
};

export const updateCorrespondant = thunk(
  "transfert/updateCorrespondant",
  async ({ id, understood }, { dispatch }) => {
    await DWMConnector.updateCorrespondant({ id, understood });
    await dispatch(getTransfertInfos());
  },
);

export const addFiles = thunk(
  "transfert/addFiles",
  async ({ siteId, transfertFile, locale }, { dispatch }) => {
    const addFiles = await DWMConnector.addFiles(siteId, transfertFile, locale);
    return addFiles;
  },
);

EXTRA_REDUCERS[addFiles.fulfilled] = (state, action) => {
  state.filesInfos = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const TransfertSlice = createSlice({
  name: "transfert",
  initialState: INITIAL_STATE,
  reducers: {
    removeFilesInfos(state, action) {
      state.filesInfos = null;
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const { removeFilesInfos } = TransfertSlice.actions;
export default TransfertSlice.reducer;
