// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    BackTo: "Retour à {{topic}}",
    Sure_Leave:
      "Vous êtes sur le point de quitter l'éditeur de contenu. Vous perdrez le travail en cours non publié. Êtes-vous sûr de vouloir continuer ?",
    Yes_Leave: "Oui, quitter",
    Cancel: "Annuler",
    Select_A_Group: "Sélectionnez au moins un groupe pour pouvoir publier",
    Content_Editing: "Edition de contenu",
    Notif_Editing: "Edition de notification",
    Publish: "Publier",
    Update: "Mettre à jour",
    Upload: "Ajouter",
    Languages: "Langues",
    Edit_Internationalized: "Editer votre contenu internationalisé",
    French: "Français",
    English: "Anglais",
    Dutch: "Néerlandais",
    Image_Library: "Bibliothèque d'image",
    Add_Image: "Ajouter une image",
    Select_Image: "Sélectionnez une image depuis la bibliothèque",
    Title: "Titre",
    Notif_Trigger: "Déclenchement de la notification",
    Subtitle: "Sous-titre",
    Choose_Title: "Choisissez un titre",
    Choose_Subtitle: "Choisissez un sous-titre",
    Publication: "Publication",
    Content_To_Be_Shawn: "Le contenu sera visible dans l’application à partir du",
    Hide_Content: "Cacher le contenu avant sa publication",
    Send_Notif: "Envoyer une notification",
    Access: "Accès publique / limité à ce contenu",
    Target: "Cibles",
    Select_Group:
      "Pour limiter la visibilité de ce contenu, sélectionnez les groupes autorisés à le consulter",
    Select_Group2: "Sélectionner les destinataires de la notification",
    No_Group_Found: "Aucun groupe trouvé",
    Topics: "Topics",
    Select_Topics: "Sélectionner un ou plusieurs topics pour la news",
    No_Topic: "Aucun topic disponible",
    Location: "Localisation",
    Attach_To_Place:
      "Rattacher ce contenu à un lieu. (Vous pouvez soit sélectionner une ressource, soit directement renseigné le nom du lieu)",
    Attach_To_Place2: "Rattacher ce contenu à un lieu",
    Stage: "Etage",
    Room: "Pièce",
    No_Stage_Found: "Aucun étage trouvé",
    Select_A_Room: "Sélectionnez une pièce",
    Select_A_Stage: "Sélectionnez un étage",
    No_Room_Found: "Aucun pièce trouvé",
    Name: "Nom",
    Enter_Name_Place: "Entrez le nom du lieu",
    Publication_Preview: "Prévisualisation dans l'app",
    Add_News: "Ajouter une news",
    Add_Notif: "Créer une notification",
    Add_Topic: "Ajouter un topic",
    Update_Topic: "Modifier un topic",
    Manage_Topic: "Gérer les topics",
    Trendings: "À la une",
    Published_On: "Publié le",
    Created_On: "Créé le",
    Header_Image: "Image d'en-tête",
    At: "à",
    Get_Content: "Récupération des contenus...",
    Get_Content_Error: "Echec lors de la récupération des détails de la section...",
    Get_Groups: "Récupération des groupes Swizi...",
    Get_Groups_Error: "Echec lors de la récupération des groupes",
    Get_Topics_Error: "Echec lors de la récupération des topics",
    Get_Media: "Récupération des médias Swizi...",
    Get_Media_Error: "Echec lors de la récupération des groupes Swizi",
    Get_Resource: "Récupération des ressources Swizi..",
    Get_Resource_Error: "Echec lors de la récupération des ressources Swizi.",
    Show_HTML: "Passer en mode HTML",
    Show_Editor: "Passer en mode editeur",
    Format_Code: "Formater le code HTML",
    ShowMore: "Voir plus >",
    Published: "Publié {{date}}",
    PublishedAt: "Publié le {{date}}",
    News: "Actualités",
    InTheSameTopic: "Dans la même rubrique",
    Event: "Événement",
    Event_description: "Définissez un événement pour l'afficher dans la news",
    Event_startDate: "Début",
    Event_endDate: "Fin",
    Event_date_place: "Date et lieu de l’évènement",
    Event_from: "Le",
    Event_to: "au",
  },
};
