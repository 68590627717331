import { useKeycloak } from "@react-keycloak/web";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  message,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import debounce from "lodash.debounce";
import { cond, equals, T } from "ramda";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DWMConnector from "../../../../services/api/DWMConnector";
import {
  createSection,
  deleteTab,
  subscribeTab,
  updateTab,
} from "../../../../services/redux/services/FacilitiesWS";
import Glyph from "../../../Common/Glyph/Glyph";
import Helper from "../../../Common/Helper";
import styles from "../DashboardFacilities.module.less";
import Comments from "./Comments";
import Section from "./Section";

const ContributorsInput = ({ value, onChange, disabled }) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const [userSearch, setUserSearch] = useState([]);

  const handleSearch = useMemo(
    () =>
      debounce(async (search) => {
        const users = await DWMConnector.searchUsers({ search, page: 1, size: 50 })
          .then((res) => res.items)
          .catch(() => []);
        setUserSearch(
          users.map((u) => ({
            ...u,
            displayName: `${u.firstname} ${u.lastname}`,
          })),
        );
      }, 500),
    [],
  );

  return (
    <Row gutter={[10, 10]}>
      <Col flex={"auto"}>
        <Space align="start">
          <Typography.Title level={4}>{t("facilities.event.contributors")}</Typography.Title>
          <Helper title={t("facilities.event.contributors.helper")} />
        </Space>
      </Col>
      <Col span={12}>
        {!disabled && (
          <Select
            showSearch
            style={{ width: "100%" }}
            size="middle"
            placeholder={t("facilities.event.contributors.add")}
            value={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={(val) => {
              const contributors = value || [];
              const user = userSearch.find((u) => u.id === val);
              if (contributors.some((c) => c.id === val) || !user) return;
              onChange([...contributors, user]);
            }}
            options={userSearch.map((u) => ({
              value: u.id,
              label: u.firstname + " " + u.lastname,
            }))}
          />
        )}
      </Col>
      <Col span={24}>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue(["contributors"]).length ? (
              <List
                bordered
                dataSource={getFieldValue(["contributors"])}
                renderItem={(user) => (
                  <List.Item
                    actions={
                      !disabled
                        ? [
                            <Button
                              key="remove"
                              size="small"
                              type="ghost"
                              onClick={() => onChange(value.filter((c) => c.id !== user.id))}
                            >
                              {t("facilities.event.contributors.remove")}
                            </Button>,
                          ]
                        : undefined
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={"large"}
                          src={`${user.photoUrl}?access_token=${keycloak.token}`}
                        >
                          {user.displayName
                            .split(" ")
                            .map((i) => i.charAt(0).toUpperCase())
                            .join("")}
                        </Avatar>
                      }
                      title={user.displayName}
                      description={user.email}
                    />
                  </List.Item>
                )}
              />
            ) : null
          }
        </Form.Item>
      </Col>
    </Row>
  );
};

const ExternalsInput = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[10, 10]}>
      <Col flex={"auto"}>
        <Space align="start">
          <Typography.Title level={4}>{t("facilities.event.externals")}</Typography.Title>
          <Helper title={t("facilities.event.externals.helper")} />
        </Space>
      </Col>
      <Col span={12}>
        {!disabled && (
          <Input.Search
            allowClear
            placeholder={t("facilities.event.externals.add")}
            enterButton="Ajouter"
            size="middle"
            onSearch={(email) => {
              const externals = value || [];
              const e = email.trim().toLowerCase();
              if (!e || externals.includes(e)) return;
              onChange([...externals, e]);
            }}
          />
        )}
      </Col>
      <Col span={24}>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue(["externals"]).length ? (
              <List
                size="small"
                bordered
                dataSource={getFieldValue(["externals"])}
                renderItem={(email) => (
                  <List.Item
                    actions={
                      !disabled
                        ? [
                            <Button
                              key="remove"
                              size="small"
                              type="ghost"
                              onClick={() => onChange(value.filter((c) => c !== email))}
                            >
                              {t("facilities.event.externals.remove")}
                            </Button>,
                          ]
                        : undefined
                    }
                  >
                    <Button size="small" type="link" href={`mailto:${email}`} target="_blank">
                      {email}
                    </Button>
                  </List.Item>
                )}
              />
            ) : null
          }
        </Form.Item>
      </Col>
    </Row>
  );
};

const Page = ({ event, tab, onClose, onDeleteTab, onAddSection }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const profile = useSelector((state) => state.userWS.userData.profile);
  const canWrite = profile?.rights.facility || tab?.contributors.some((c) => c.id === profile?.id);

  const [isEditMode, setIsEditMode] = useState(false);

  const isSubscribed = (event.subscribers || []).includes(profile?.email);

  const onSubmit = (values) => {
    dispatch(updateTab({ tabId: tab._id, ...values }))
      .unwrap()
      .then(() => {
        form.resetFields();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const handleDeleteTab = () => {
    dispatch(deleteTab({ tabId: tab._id }))
      .unwrap()
      .then(() => {
        onDeleteTab();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const handleSubscribe = () => {
    dispatch(subscribeTab({ tabId: tab._id, subscribe: !isSubscribed }))
      .unwrap()
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const handleAddSection = (type) => {
    const title = cond([
      [equals("TEXT"), () => t("facilities.event.section.TEXT.new")],
      [equals("RADIO"), () => t("facilities.event.section.RADIO.new")],
      [equals("LIST"), () => t("facilities.event.section.LIST.new")],
      [equals("CHECKBOX"), () => t("facilities.event.section.CHECKBOX.new")],
      [equals("FILE"), () => t("facilities.event.section.FILE.new")],
      [T, () => ""],
    ]);
    dispatch(
      createSection({
        tabId: tab._id,
        type,
        title: title(type),
        items: [],
      }),
    )
      .unwrap()
      .then(() => {
        onAddSection();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const menu = (
    <Space>
      {canWrite && (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  key: "edit",
                  label: (
                    <Space>
                      <Glyph name="edit" />
                      {isEditMode
                        ? t("facilities.event.edit.close")
                        : t("facilities.event.edit.open")}
                    </Space>
                  ),
                  onClick: () => setIsEditMode(!isEditMode),
                },
                profile?.rights.facility
                  ? {
                      key: "delete",
                      label: (
                        <Space>
                          <Glyph name="delete" />
                          {t("facilities.event.delete")}
                        </Space>
                      ),
                      onClick: handleDeleteTab,
                    }
                  : undefined,
              ].filter(Boolean)}
            />
          }
          trigger={["click"]}
        >
          <Button size="middle" type="ghost" icon={<Glyph name={"more_horiz"} />} />
        </Dropdown>
      )}
      <Tooltip
        title={
          isSubscribed ? t("facilities.event.subscribed") : t("facilities.event.notSubscribed")
        }
      >
        <Button
          size="middle"
          type={isSubscribed ? "primary" : "ghost"}
          icon={<Glyph name={isSubscribed ? "notifications_none" : "notifications_off"} />}
          onClick={handleSubscribe}
        />
      </Tooltip>
      <Button size="middle" type="ghost" icon={<Glyph name={"close"} />} onClick={onClose} />
    </Space>
  );

  return (
    <>
      {isEditMode && (
        <Card className={styles.toolbox} bodyStyle={{ paddingTop: 8, paddingBottom: 8 }}>
          <Typography.Paragraph type="secondary" strong>
            {t("facilities.event.section.add")}
          </Typography.Paragraph>
          <div className={styles.toolboxBar}>
            <div onClick={() => handleAddSection("TEXT")}>
              <Glyph name={"text_fields"} className="secondary" />
              <Typography.Text>{t("facilities.event.section.TEXT")}</Typography.Text>
            </div>
            <div onClick={() => handleAddSection("RADIO")}>
              <Glyph name={"radio_button_checked"} className="secondary" />
              <Typography.Text>{t("facilities.event.section.RADIO")}</Typography.Text>
            </div>
            <div onClick={() => handleAddSection("LIST")}>
              <Glyph name={"list"} className="secondary" />
              <Typography.Text>{t("facilities.event.section.LIST")}</Typography.Text>
            </div>
            <div onClick={() => handleAddSection("CHECKBOX")}>
              <Glyph name={"check_box"} className="secondary" />
              <Typography.Text>{t("facilities.event.section.CHECKBOX")}</Typography.Text>
            </div>
            <div onClick={() => handleAddSection("FILE")}>
              <Glyph name={"attach_file"} className="secondary" />
              <Typography.Text>{t("facilities.event.section.FILE")}</Typography.Text>
            </div>
          </div>
          <div style={{ position: "absolute", top: 20, right: 20 }}>{menu}</div>
        </Card>
      )}
      <Form form={form} initialValues={tab} onFinish={onSubmit}>
        <Row gutter={[20, 20]}>
          {isEditMode ? (
            <Col span={24}>
              <Form.Item noStyle name={["title"]}>
                <Input
                  className="filled title"
                  size="large"
                  suffix={<Glyph name="edit" />}
                  disabled={!canWrite}
                />
              </Form.Item>
            </Col>
          ) : (
            <>
              <Col flex="auto">
                <Form.Item noStyle name={["title"]}>
                  <Input
                    className="filled title"
                    size="large"
                    suffix={<Glyph name="edit" />}
                    disabled={!canWrite}
                  />
                </Form.Item>
              </Col>
              <Col flex={"none"}>{menu}</Col>
            </>
          )}
          <Col span={24}>
            <Typography.Paragraph type="secondary">
              {t("facilities.event.tab.helper")}
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Typography.Title level={4}>{t("facilities.event.tab.description")}</Typography.Title>
            <Form.Item noStyle name={["description"]}>
              <Input.TextArea
                className="filled title"
                autoSize={{ minRows: 7, maxRows: 12 }}
                disabled={!canWrite}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item noStyle name={["contributors"]}>
              <ContributorsInput disabled={!canWrite} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item noStyle name={["externals"]}>
              <ExternalsInput disabled={!canWrite} />
            </Form.Item>
          </Col>
          <Col flex={"auto"} />
          <Col flex={"none"}>
            <Form.Item noStyle shouldUpdate>
              {({ isFieldsTouched }) =>
                isFieldsTouched() ? (
                  <Space>
                    <Button size="middle" type="dashed" onClick={() => form.resetFields()}>
                      {t("facilities.event.save_cancel")}
                    </Button>
                    <Button size="middle" htmlType="submit" type="primary">
                      {t("facilities.event.save")}
                    </Button>
                  </Space>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br />
      <br />
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Space
            className="sectionsHolder"
            direction="vertical"
            size={"large"}
            style={{ width: "100%" }}
          >
            {tab.sections.map((i) => (
              <Section tab={tab} section={i} editable={canWrite && isEditMode} />
            ))}
          </Space>
        </Col>
        <Col span={24}>
          <Divider />
          <Comments tab={tab} comments={tab?.comments} />
        </Col>
      </Row>
    </>
  );
};

export default Page;
