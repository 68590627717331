import { Input, Space, Typography } from "antd";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setFilter } from "../../../../services/redux/services/FastbookingWS";
import useMap from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import { TitleSource } from "../../../Common/Teleporters/Title";
import Tile from "../../../Common/Tile/Tile";
import { FixedSizeList as List } from "react-window";
import clsx from "clsx";
import styles from "./SearchRoom.module.less";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const GUTTER_SIZE = 8;

const SearchRoom = ({ setSelectedRoom, selectedRoom, setIsZone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedOnMap, centerOnPlaceId } = useMap();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);

  const [searchRoom, setSearch] = useState("");

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
    if (evt.target.value.length === 1) {
      logEvent(analytics, "smart_control_search_poi");
    }
  };

  const searchResult = searchRoom
    ? campus?.mapData?.resources
        .filter((r) => !!r.features?.comfort)
        .filter((value) => {
          if (searchRoom === "") {
            return value;
          } else {
            return value.title.toLowerCase().includes(searchRoom.toLowerCase());
          }
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1))
    : null;

  // Filtre par défaut
  useEffect(() => {
    if (isEmpty(filters)) {
      const floor = allRoomFastbooking?.rooms?.reduce(
        (acc, r) =>
          !acc
            ? campus?.mapData?.floors.find((f) => f.reference === r.resources.floorReference)
            : acc,
        null,
      );
      dispatch(
        setFilter({
          floor: floor?.id,
        }),
      );
    }
  }, [allRoomFastbooking, campus?.mapData?.floors, dispatch, filters]);

  const goRoom = (value) => {
    centerOnPlaceId(value?.map?.findAndOrder?.placeId);
    logEvent(analytics, "smart_control_select_poi_from_list");
    dispatch(
      setFilter({
        ...filters,
        floor: campus.mapData.floors.find((floor) => floor.reference === value.floorReference)?.id,
      }),
    );
  };

  useEffect(() => {
    if (selectedOnMap) history.push(`/smartControl/${selectedOnMap.id}`);
  }, [history, selectedOnMap]);

  const Row = ({ style, index }) =>
    process.env.REACT_APP_ID_MAP !== "-" ? (
      <Tile
        className="tile--room"
        href={`smartControl/${searchResult[index].id}`}
        key={index}
        onClick={() => {
          // dispatch(setVirtualRemote({ roomId: room.id, command: "OPEN" }));
          goRoom(searchResult[index]);
        }}
        style={{ ...style, height: style.height - GUTTER_SIZE, width: "95%", margin: "0" }}
      >
        <Typography.Text
          strong
          style={{
            width: "200px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {searchResult[index].title}
        </Typography.Text>
        <span>
          {t("Stage")}{" "}
          {
            campus?.mapData?.floors.find(
              (floor) => floor.reference === searchResult[index].floorReference,
            ).displayName
          }
          <Glyph name="navigate_next" />
        </span>
      </Tile>
    ) : (
      <Tile
        className={clsx({
          [styles.Noactive]: searchResult[index].id === selectedRoom?.id,
        })}
        key={index}
        onClick={() => {
          setSelectedRoom(searchResult[index]);
          setIsZone(false);
        }}
        style={{
          ...style,
          height: style.height - GUTTER_SIZE,
          width: "95%",
          margin: "0",
          border: "2px solid transparent",
        }}
      >
        <Typography.Text
          strong
          style={{
            width: "200px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {searchResult[index].title}
        </Typography.Text>
        <span>
          {t("Stage")}{" "}
          {
            campus?.mapData?.floors.find(
              (floor) => floor.reference === searchResult[index].floorReference,
            ).displayName
          }
          <Glyph name="navigate_next" />
        </span>
      </Tile>
    );

  return (
    <>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          {t("smart_control", { ns: "csv" })}
        </Typography.Title>
      </TitleSource>

      <Space
        style={{ width: "100%", padding: "15px", paddingBottom: "0" }}
        direction="vertical"
        size="large"
      >
        <Input
          onChange={handleSearch}
          value={searchRoom}
          placeholder={t("SearchSpace")}
          prefix={<Glyph name="search" />}
          allowClear={true}
        />

        {!searchResult ? (
          <Typography.Text type="secondary">{t("UseCard")}</Typography.Text>
        ) : (
          <>
            <Typography.Title level={5}>{t("ResultSearch")}</Typography.Title>

            {!searchResult ? null : (
              <Space style={{ width: "100%" }} direction="vertical" size="small">
                {searchResult.length === 0 ? (
                  <Typography.Text type="secondary">{t("NoResult")}</Typography.Text>
                ) : (
                  <List height={400} itemCount={searchResult.length} itemSize={50}>
                    {Row}
                  </List>
                )}
              </Space>
            )}
          </>
        )}
      </Space>
    </>
  );
};

export default SearchRoom;
