import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";
import { evolve, head } from "ramda";

import DWMConnector from "../../api/DWMConnector";
import { updateMapData } from "./MapDataWS";

const INITIAL_STATE = {
  campus: null,
  favoris: [],
  lastSearch: [],
  lastView: null,
  favorisTransport: [],
  lastSearchTransport: [],
  lang: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const getCampusData = thunk(
  "clients/getCampusData",
  async ({ locale, siteId }, { _, dispatch }) => {
    // const campusId = selectedCampus(state)?.id;
    const campusData = await DWMConnector.campusData(locale, siteId);
    dispatch(updateMapData({ siteId, data: campusData }));
    return campusData;
  },
);

EXTRA_REDUCERS[getCampusData.fulfilled] = (state, action) => {
  state.campus = evolve({ mapData: head }, action.payload);
};

export const getlang = thunk("clients/getlang", async (_) => {
  const getlang = await DWMConnector.getLang();
  return getlang;
});

EXTRA_REDUCERS[getlang.fulfilled] = (state, action) => {
  state.lang = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const clientSlice = createSlice({
  name: "client",
  initialState: INITIAL_STATE,
  reducers: {
    addFavoris(state, action) {
      state.favoris.push(action.payload);
    },
    removeFavoris(state, action) {
      state.favoris = state.favoris.filter((f) => f.id !== action.payload);
    },
    addLastView(state, action) {
      state.lastView = action.payload;
    },
    removeLastView(state, action) {
      state.lastView = null;
    },
    addLastSearch(state, action) {
      const isExist = !!state.lastSearch.find((r) => r.id === action.payload.id);
      if (!isExist) {
        if (state.lastSearch.length === 5) {
          state.lastSearch.pop();
          state.lastSearch.unshift(action.payload);
        } else {
          state.lastSearch.unshift(action.payload);
        }
      }
    },
    addFavorisTransport(state, action) {
      state.favorisTransport.push(action.payload);
    },
    removeFavorisTransport(state, action) {
      state.favorisTransport = state.favorisTransport.filter((f) => f.routeId !== action.payload);
    },
    addLastSearchTransport(state, action) {
      const isExist = !!state.lastSearchTransport.find((r) => r.id === action.payload.id);
      if (!isExist) {
        if (state.lastSearchTransport.length === 5) {
          state.lastSearchTransport.pop();
          state.lastSearchTransport.unshift(action.payload);
        } else {
          state.lastSearchTransport.unshift(action.payload);
        }
      }
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const {
  addFavoris,
  removeFavoris,
  addLastSearch,
  addLastView,
  removeLastView,
  addFavorisTransport,
  removeFavorisTransport,
  addLastSearchTransport,
} = clientSlice.actions;
export default clientSlice.reducer;
