import { Card, Col, Form, Row, Typography } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Glyph from "../../../../../Common/Glyph/Glyph";
import RoomDetails from "../../../../../Common/RoomDetails/RoomDetails";

const CreateStepReview = ({ form }) => {
  const { t } = useTranslation();

  const siteId = Form.useWatch("siteId", form);
  const spaceId = Form.useWatch("spaceId", form);

  const mapData = useSelector((state) => state.mapDataWS[siteId]?.mapData);

  const desk = useMemo(() => {
    return mapData?.workplaces.find((r) => r.id === spaceId);
  }, [mapData?.workplaces, spaceId]);

  const room = useMemo(() => {
    return mapData?.resources.find((r) => r.id === desk?.roomId);
  }, [desk?.roomId, mapData?.resources]);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <RoomDetails.Photos room={room} />
      </Col>
      <Col span={24}>
        <RoomDetails.Equipments room={room} />
      </Col>
      <Col flex={"auto"}>
        <RoomDetails.Title room={room} />
      </Col>
      <Col>
        <RoomDetails.Floor className={"secondary"} room={room} />
      </Col>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <>
            <Col span={24}>
              <Card>
                <Row>
                  <Glyph style={{ marginRight: 8 }} name="desk" className={"secondary"} />
                  <Typography.Text strong>{desk?.title}</Typography.Text>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Row>
                  <Col flex={"auto"}>
                    <Glyph style={{ marginRight: 8 }} name="event" className={"secondary"} />
                    <Typography.Text strong>{t("spas.request.startDate.helper")}</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>
                      {moment(getFieldValue("startDate")).format("DD MMM YYYY")}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Glyph style={{ marginRight: 8 }} name={"schedule"} className={"secondary"} />
                <Typography.Text strong>
                  {t(`spas.period.${getFieldValue("period")}`)}
                </Typography.Text>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Glyph style={{ marginRight: 8 }} name={"sync"} className={"secondary"} />
                <Typography.Text strong>
                  {t(`spas.recurrence.${getFieldValue("recurrence")}`, {
                    day: moment(getFieldValue(["startDate"])).format("dddd"),
                  })}
                </Typography.Text>
              </Card>
            </Col>
            {getFieldValue("recurrence") !== "NONE" && (
              <>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col flex={"auto"}>
                        <Glyph style={{ marginRight: 8 }} name="event" className={"secondary"} />
                        <Typography.Text strong>
                          {t("spas.request.endDate", { date: "" })}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text>
                          {moment(getFieldValue("endDate")).format("DD MMM YYYY")}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </>
            )}
          </>
        )}
      </Form.Item>
    </Row>
  );
};

export default CreateStepReview;
