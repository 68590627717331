import { Button, Form, Input, message, Modal, Radio, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";

import Select from "react-select";
// import svg
import { ReactComponent as Check } from "../../../assets/svg/checkTicket.svg";
// redux
import {
  addAttachment,
  createTicket,
  getListCategory,
  getListTicket,
} from "../../../services/redux/services/TicketingWS";
// import component
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import SelectRoom from "./Localisation/SelectRoom";
// import css
import styles from "./Ticketing.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { findCategory } from "../../../helpers/ticketHelpers";

const { TextArea } = Input;

const CreateTickets = ({ onOk, isVisible }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const { colors } = useDesignTokens();

  const [current, setCurrent] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [spaceTypesRestriction, setSpaceTypesRestriction] = useState([]);
  const [hasSpaceRestriction, setHasSpaceRestriction] = useState([]);
  const [isVisibleLocation, setVisibleLocation] = useState(false);
  const [mustSelectSpace, setMustSelectSpace] = useState(true);

  const groupFilter = useSelector((state) => state.ticketingWS.groupFilter);
  const category = useSelector((state) => state.ticketingWS.category);

  const [selectedTypeGroup, setSelectedTypeGroup] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState();
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedTypeGroup(
      category?.ticketGroups.find((group) => group.id === groupFilter && !group.readOnly)?.id,
    );
    form.setFieldsValue({ types: groupFilter });
  }, [category?.ticketGroups, form, groupFilter, isVisible]);

  useEffect(() => {
    dispatch(getListCategory({ locale: i18n.language.split("-")[0] }));
  }, [dispatch, i18n.language]);

  useEffect(() => {
    if (!category) return;
    const catLevel1 = findCategory(category.ticketTypes, selectedCategories[0]);
    const catLevel2 = findCategory(category.ticketTypes, selectedCategories[1]);

    if (!catLevel1 || !catLevel2) return;

    const spaceRestriction = !!(catLevel1?.spaceRestriction || catLevel2?.spaceRestriction);
    let spaceList = [];
    if (spaceRestriction) {
      spaceList = catLevel1.spaceTypes.concat(catLevel2.spaceTypes || []);
    }
    setHasSpaceRestriction(spaceRestriction);
    setSpaceTypesRestriction(spaceList);
    setMustSelectSpace(!spaceRestriction || spaceList.length > 0);
  }, [category, selectedCategories]);

  const props = {
    listType: "picture",
    iconRender: () => <Glyph name="description" style={{ color: colors.grey_40 }} />,
    maxCount: "5",
    onChange: ({ fileList }) => setFileList(fileList),
    beforeUpload: () => {
      return false;
    },
  };

  const options = category?.ticketGroups
    ?.filter((group) => !group.readOnly)
    ?.map((group) => ({
      label: (
        <div
          key={group.id}
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Glyph
            name="report_problem"
            style={{
              fontWeight: "normal",
              fontSize: "24px",
              color: colors.secondary_base,
              marginBottom: "8px",
            }}
          />
          <Typography.Paragraph
            strong
            style={{ margin: "0", fontSize: "14px", lineHeight: "initial" }}
          >
            {group.name[i18n.language.split("-")[0]]}
          </Typography.Paragraph>
        </div>
      ),
      value: group.id,
    }));

  const onChangeRadio = (e) => {
    setSelectedCategories([]);
    form.resetFields();
    setSelectedTypeGroup(e.target.value);
    form.setFieldsValue({ types: e.target.value });
  };

  const chooseCategory = (category, idx = 0) => {
    setSelectedCategories([...selectedCategories.slice(0, idx), category.id]);
    setSelectedType(category.ticketTypeId);
  };

  const optionsCategory = (path) => {
    const type = category.ticketGroups.find((group) => group.id === selectedTypeGroup);
    return (
      !path
        ? type.categories
        : path.reduce((acc, curr) => {
            return acc?.find((cat) => cat.id === curr)?.categories || [];
          }, type?.categories)
    ).map((cat) => ({
      category: cat,
      value: cat.id,
      label: (
        <div
          key={cat.id}
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
        >
          <p style={{ marginBottom: "0" }}>{cat.name[i18n.language.split("-")[0]]}</p>
        </div>
      ),
    }));
  };

  const changeDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleCreateTicket = () => {
    const categoryId = selectedCategories[selectedCategories.length - 1];
    dispatch(
      createTicket({
        typeId: selectedType,
        categoryId,
        description: description,
        resourceId: selectedRoom && selectedRoom.id,
      }),
    )
      .unwrap()
      .then((ticket) => {
        setCurrent(current + 1);
        if (fileList.length !== 0) {
          return dispatch(
            addAttachment({
              ticketId: ticket.id,
              files: fileList.map((file) => file.originFileObj),
            }),
          ).unwrap();
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        dispatch(getListTicket({ locale: i18n.language.split("-")[0], page: 1, size: 100 }))
          .unwrap()
          .then(() => setIsLoading(false));
      })
      .catch(() => {
        message.error(t("errorTicket"));
        setIsLoading(false);
      });
    logEvent(analytics, "ticketing_send_ticket", {
      ticket_type: category?.ticketGroups?.find((group) => group.id === selectedTypeGroup).name[
        i18n.language.split("-")[0]
      ],
    });
  };
  const handleTypeTypeSelected = () => {
    setCurrent(current + 1);
  };
  return (
    <>
      <Modal
        destroyOnClose
        width={655}
        bodyStyle={{ height: 650 }}
        title={
          <Typography.Title level={5} style={{ textAlign: "center" }}>
            {t("CreateTicket")}
          </Typography.Title>
        }
        open={isVisible}
        onOk={() => {
          onOk(false);
          setCurrent(0);
        }}
        onCancel={() => {
          onOk(false);
          setCurrent(0);
          setSelectedTypeGroup(null);
          setSelectedCategories([]);
          setDescription("");
          setIsDisabled(true);
          setSelectedRoom();
          setFileList([]);
          form.resetFields();
        }}
        footer={[
          current === 1 ? (
            <>
              <Button ghost style={{ width: "375px" }} onClick={() => setCurrent(current - 1)}>
                {t("Back")}
              </Button>
              <Button
                type="primary"
                style={{ width: "375px" }}
                onClick={() => {
                  handleCreateTicket();
                  setIsLoading(true);
                }}
                loading={isLoading}
                disabled={mustSelectSpace ? !(selectedRoom && description) : !description}
              >
                {t("Next")}
              </Button>
            </>
          ) : current === 0 ? (
            <Button
              type="primary"
              style={{ width: "375px" }}
              onClick={handleTypeTypeSelected}
              disabled={isDisabled}
            >
              {t("Next")}
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ width: "375px" }}
              htmlType="submit"
              onClick={() => {
                onOk(false);
                setCurrent(0);
                setCurrent(0);
                setSelectedTypeGroup(null);
                setSelectedCategories([]);
                setDescription("");
                setIsDisabled(true);
                setSelectedRoom();
                setFileList([]);
                form.resetFields();
              }}
            >
              {t("Finish")}
            </Button>
          ),
        ]}
      >
        <Form form={form}>
          <div className="steps-content" style={{ display: current < 1 ? "initial" : "none" }}>
            <Form.Item name="types">
              <Radio.Group
                className={styles["radio-request"]}
                onChange={onChangeRadio}
                options={options}
                optionType="button"
                value={selectedTypeGroup}
              />
            </Form.Item>
            {selectedTypeGroup && (
              <Form.Item style={{ marginTop: "35px" }} name="category">
                <Typography.Title level={5}>{t("Category")}</Typography.Title>
                <Select
                  className="select-category"
                  classnameprefix="select-category"
                  options={optionsCategory()}
                  placeholder={t("SelectCategory")}
                  onChange={({ category }) => {
                    chooseCategory(category);
                    form.resetFields(["subCategory"]);
                  }}
                  isclearable="false"
                  isrtl="false"
                  issearchable="false"
                />
              </Form.Item>
            )}
            {selectedCategories.map((categoryId, idx) => {
              const options = optionsCategory(selectedCategories.slice(0, idx + 1));
              return options && options.length ? (
                <Form.Item style={{ marginTop: "35px" }} name="subCategory">
                  <Typography.Title level={5}>{t("Subcategory")}</Typography.Title>
                  <Select
                    key={categoryId.value}
                    className="select-category"
                    classnameprefix="select-category"
                    options={options}
                    placeholder={t("MoreDetails")}
                    onChange={({ category }) => {
                      chooseCategory(category, idx + 1);
                      setIsDisabled(false);
                    }}
                    isclearable="false"
                    isrtl="false"
                    issearchable="false"
                  />
                </Form.Item>
              ) : null;
            })}
          </div>

          <div className="steps-content" style={{ display: current === 1 ? "initial" : "none" }}>
            {mustSelectSpace ? (
              <Form.Item>
                <Typography.Title level={5}>{t("Location")}</Typography.Title>
                <Tile
                  style={{ backgroundColor: "white", margin: "0" }}
                  className={styles["tile-emplacement"]}
                  onClick={() => {
                    setVisibleLocation(true);
                  }}
                >
                  <Typography.Text strong>
                    <Glyph
                      name="place"
                      style={{
                        fontSize: "24px",
                        marginRight: "8px",
                        verticalAlign: "-5px",
                        color: colors.secondary_base,
                      }}
                    />
                    {t("ChooseLocation")}
                  </Typography.Text>
                  <Typography.Text>
                    {selectedRoom && selectedRoom.title}

                    <Glyph
                      name="navigate_next"
                      style={{ fontSize: "24px", marginRight: "8px", verticalAlign: "-5px" }}
                    />
                  </Typography.Text>
                </Tile>
              </Form.Item>
            ) : undefined}
            {/* <Form.Item>
          <Tile className="tile-emplacement">
            <Typography.Text strong>
              <Glyph
                name="schedule"
                className="icon--secondary"
                style={{ fontSize: "24px", marginRight: "8px", verticalAlign: "-5px" }}
              />
              Définir une date
              <Typography.Text italic style={{ marginLeft: "8px", fontSize: "12px" }}>
                Facultatif
              </Typography.Text>
            </Typography.Text>
            <DatePicker showTime style={{ border: "none" }} />
          </Tile>
        </Form.Item> */}

            <Form.Item name="description">
              <Typography.Title level={5}>{t("Description")}</Typography.Title>
              <TextArea
                rows={4}
                placeholder={t("WriteSomething")}
                onChange={changeDescription}
                value={description}
              />
            </Form.Item>
            {fileList?.length === 5 && (
              <Typography.Text type="secondary" strong>
                {t("errorDoc")}
              </Typography.Text>
            )}
            <Form.Item name="uploadImage" style={{ marginTop: "16px" }}>
              <Upload {...props} className="upload-list-inline">
                <Button
                  icon={
                    <Glyph name="note_add" style={{ fontWeight: "normal", fontSize: "24px" }} />
                  }
                  className={styles["btn-upload"]}
                >
                  <Typography.Text style={{ whiteSpace: "break-spaces" }}>
                    {t("Adds")}
                  </Typography.Text>
                  <Typography.Text type="secondary">5 max</Typography.Text>
                </Button>
              </Upload>
            </Form.Item>
          </div>
          <div
            style={{
              display: current === 2 ? "flex" : "none",
              justifyContent: "center",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <Check />
            <Typography.Text strong style={{ marginTop: "27px" }}>
              <Glyph
                name="check_circle"
                style={{ fontWeight: "normal", verticalAlign: "-3px", color: colors.success_light }}
              />
              {t("YourRequest")}
            </Typography.Text>
            <Typography.Text style={{ color: colors.pending_light }}>
              {t("Informed")}
            </Typography.Text>
          </div>
        </Form>
      </Modal>

      <SelectRoom
        // onOk={(room) => {
        //   form.setFieldsValue("location", room.id);
        //   setSelectRoomVisible(false);
        // }}
        selectedRoom={selectedRoom}
        setSelectedRoom={setSelectedRoom}
        isVisible={isVisibleLocation}
        onCancel={() => setVisibleLocation(false)}
        spaceTypeRestriction={hasSpaceRestriction ? spaceTypesRestriction : undefined}
      />
    </>
  );
};

export default CreateTickets;
