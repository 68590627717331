import { LoadingOutlined } from "@ant-design/icons";
import { Card, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useMap from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import Settings from "./Settings";
import styles from "./Settings.module.less";

const SettingsMap = ({ selectedRoom, equipment, isLoading, setIsLoading }) => {
  const { t } = useTranslation();

  const { selectedOnMap } = useMap();
  const room = selectedRoom || selectedOnMap;

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const queryEquipment = query.get("equipement");

  const nameEquipment = equipment || queryEquipment;

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      style={{ height: "95%", overflow: "hidden", width: "35%" }}
      wrapperClassName={styles.wrapper}
    >
      <Card
        style={{ width: "100%", height: "100%" }}
        title={
          <Typography.Title level={5} style={{ display: "flex", alignItems: "center" }}>
            <NavLink to={`/smartControl/${room.id}`} className={styles["Settings__back"]}>
              <Glyph name="arrow_back_ios" />
            </NavLink>
            {room.title} - {t(nameEquipment)}
          </Typography.Title>
        }
      >
        <Settings setIsLoading={setIsLoading} nameEquipment={nameEquipment} room={room} />
      </Card>
    </Spin>
  );
};

export default SettingsMap;
