import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  groups: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const getGroups = thunk("groups/getGroups", async ({ viewId }, { getState }) => {
  const groups = await DWMConnector.getGroups(viewId);
  return groups;
});

EXTRA_REDUCERS[getGroups.fulfilled] = (state, action) => {
  state.groups = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const groupsSlice = createSlice({
  name: "groups",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});
export default groupsSlice.reducer;
