import { useSelector } from "react-redux";
import Header from "../Header/Header";

const CGU = () => {
  const cgu = useSelector((state) => state.contractPolicyWS.cgu);
  return (
    <>
      <Header />
      <div
        style={{ width: "90vw", margin: "20px auto" }}
        dangerouslySetInnerHTML={{
          __html: cgu,
        }}
      ></div>
    </>
  );
};

export default CGU;
