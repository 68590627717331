import { Card, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";

const CardBooking = ({ setModalBooking }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  return (
    <Row gutter={[10, 20]}>
      <Col flex={"auto"}>
        <Card
          bodyStyle={{ padding: "8px" }}
          style={{ cursor: "pointer", height: "60px" }}
          onClick={() => setModalBooking(true)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Glyph
              name="event"
              style={{ color: colors?.grey_40, fontSize: "30px", marginRight: "16px" }}
            />
            <div style={{ display: "flex", flexFlow: "column" }}>
              <Typography.Text style={{ textTransform: "uppercase" }}>
                {t("CurrentReservation")}
              </Typography.Text>
              <Typography.Text strong>{t("Consultbook")}</Typography.Text>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default CardBooking;
