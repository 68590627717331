import { Button, Dropdown, Space, Typography } from "antd";
import { useMemo } from "react";
import Glyph from "../../Glyph/Glyph";
import { useMapboxInstance } from "./useMapboxInstance";

const FloorSelector = () => {
  const { floors, floor, setFloor } = useMapboxInstance();

  const items = useMemo(() => {
    return (floors || []).map((i) => ({ label: i.name, key: i.id }));
  }, [floors]);

  return (
    <Dropdown trigger={["click"]} menu={{ items, onClick: ({ key }) => setFloor(key) }}>
      <Button
        type="dashed"
        size="middle"
        style={{ backgroundColor: "white", minWidth: 120, justifyContent: "flex-start" }}
      >
        <Space>
          <Glyph className={"secondary"} name={"my_location"} />
          <Typography.Text>{floor?.name || "No selected"}</Typography.Text>
        </Space>
      </Button>
    </Dropdown>
  );
};

export default FloorSelector;
