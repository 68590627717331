import { Card, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectEvent } from "../../../services/redux/services/FacilitiesWS";
import Glyph from "../../Common/Glyph/Glyph";
import styles from "./DashboardFacilities.module.less";

const EventItem = ({ resident, event }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isFacility = useSelector((state) => state.userWS.userData.profile?.rights.facility);

  return (
    <Card
      className={clsx(styles.event, { [styles.hidden]: event.hidden && !resident })}
      onClick={() => dispatch(selectEvent(event._id))}
    >
      <div
        className={styles.strip}
        style={{
          backgroundColor: event.category?.color,
        }}
      />
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Typography.Text type="secondary" strong>
            {moment(event.slot.start).format("HH:mm")} - {moment(event.slot.end).format("HH:mm")}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Title level={5}>
            {event.new
              ? event.slot.organizer?.displayName
              : event.title || t("facilities.event.new")}
          </Typography.Title>
        </Col>
        {!event.new && (
          <>
            <Col span={3}>
              <Glyph name={event.category?.icon} />
            </Col>
            <Col span={21}>
              <Typography.Text>
                {event.category?.title || t("facilities.event.noCategory")}
              </Typography.Text>
            </Col>
          </>
        )}
        <Col span={3}>
          <Glyph name="place" />
        </Col>
        <Col span={21}>
          <Typography.Text>{event.location?.title}</Typography.Text>
        </Col>

        {event.new && (
          <Col span={24}>
            <Tag style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              {t("facilities.event.notHandled")}
            </Tag>
          </Col>
        )}

        {!event.new && (
          <Col span={24}>
            <Row gutter={[10, 10]} wrap={false}>
              <Col span={3}>
                <Glyph name="person" />
              </Col>
              <Col flex={"auto"}>
                <Typography.Text>{event.slot.organizer?.displayName}</Typography.Text>
              </Col>
              {event.tasks.total > 0 && (
                <Col flex={"none"}>
                  <Tooltip
                    title={t("facilities.event.tasks.tooltip", {
                      completed: event.tasks.completed,
                      total: event.tasks.total,
                    })}
                  >
                    <Space
                      className={clsx({
                        [styles.completed]: event.tasks.completed >= event.tasks.total,
                      })}
                    >
                      <Glyph name="task_alt" />
                      <Typography.Text>
                        {event.tasks.completed}/{event.tasks.total}
                      </Typography.Text>
                    </Space>
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        )}
        {event.hidden && isFacility && (
          <>
            <Col flex="auto" />
            <Col flex={"none"}>
              <Space>
                <Glyph name="visibility_off" />
                <Typography.Text>{t("Hidden")}</Typography.Text>
              </Space>
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
};

export default EventItem;
