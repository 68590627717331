import { useKeycloak } from "@react-keycloak/web";
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Input,
  message,
  Row,
  Segmented,
  Typography,
} from "antd";
import clsx from "clsx";
import { groupBy } from "ramda";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../hook/useDebounce";
import useDidUpdateEffect from "../../../../hook/useDidUpdate";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import {
  addToTeam,
  getTeam,
  removeFromTeam,
  searchUsers,
} from "../../../../services/redux/services/SpaceService";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../SpaceBooking.module.less";

const TeamDrawer = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const [tab, setTab] = useState("team");
  const [selectedUser, setSelectedUser] = useState();

  const team = useSelector((state) => state.spaceServiceWS.team);
  const users = useSelector((state) => state.spaceServiceWS.users);
  const isLoading = useSelector(
    createLoadingSelector([
      "spaceService/getTeam",
      "spaceService/addToTeam",
      "spaceService/removeFromTeam",
    ]),
  );

  useEffect(() => {
    if (!team?.length)
      dispatch(getTeam())
        .unwrap()
        .catch(() => message.error("Can't retrieve team"));
  }, [dispatch, team?.length]);

  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);
  useDidUpdateEffect(() => {
    dispatch(
      searchUsers({
        search: searchInputDebounced,
        page: 1,
        size: 50,
      }),
    );
    // Voluntarily omiting some deps to run the hook only with search input
  }, [searchInputDebounced]);

  const handleAddUser = () => {
    dispatch(addToTeam({ userId: selectedUser.id }))
      .unwrap()
      .then(() => message.success("User added"))
      .catch(() => message.error("User not added"));
  };
  const handleRemoveUser = () => {
    dispatch(removeFromTeam({ userId: selectedUser.id }))
      .unwrap()
      .then(() => message.success("User removed"))
      .catch(() => message.error("User not removed"));
  };

  const groupedUsers = Object.entries(
    groupBy((i) => i.firstname.charAt(0).toUpperCase(), tab === "team" ? team : users?.items || []),
  ).sort(([a], [b]) => a.localeCompare(b));

  return (
    <>
      <Drawer
        className={clsx(styles.team)}
        width={400}
        placement="right"
        onClose={onClose}
        open={!!visible}
      >
        <div>
          <Typography.Title level={4}>Manage my team</Typography.Title>
          <Typography.Paragraph type="secondary" strong>
            {t("spas.admin.map.search.helper")}
          </Typography.Paragraph>
          <Input
            placeholder={t("spas.admin.map.search")}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Segmented
            style={{ marginTop: 10 }}
            block
            size="middle"
            value={tab}
            onChange={setTab}
            options={[
              { label: "My Team", value: "team" },
              { label: "Add Persons", value: "add" },
            ]}
          />
        </div>

        <div style={{ padding: "24px" }}>
          <Row gutter={[0, 20]}>
            {groupedUsers?.map(([letter, users]) => (
              <Col key={letter} span={24}>
                <Typography.Title level={4} type="secondary">
                  {letter}
                </Typography.Title>
                <Row gutter={[0, 10]}>
                  {users?.map((user) => (
                    <Col key={user.id} span={24}>
                      <Card
                        bordered={false}
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedUser(user)}
                      >
                        <Card.Meta
                          avatar={
                            <Avatar
                              size={"large"}
                              src={`${user.photoUrl}?access_token=${keycloak.token}`}
                            >
                              {`${user.firstname} ${user.lastname}`
                                .toUpperCase()
                                .split(" ")
                                .map((i) => i.charAt(0).toUpperCase())
                                .join("")}
                            </Avatar>
                          }
                          title={`${user.firstname} ${user.lastname}`}
                          description={user.email}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
          </Row>
        </div>
      </Drawer>

      <Drawer
        className={clsx(styles.team)}
        width={400}
        placement="right"
        onClose={() => setSelectedUser(null)}
        open={!!selectedUser}
      >
        <div style={{ padding: "24px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Avatar
              style={{ alignSelf: "center" }}
              size={64}
              src={`${selectedUser?.photoUrl}?access_token=${keycloak.token}`}
            >
              {`${selectedUser?.firstname} ${selectedUser?.lastname}`
                .toUpperCase()
                .split(" ")
                .map((i) => i.charAt(0).toUpperCase())
                .join("")}
            </Avatar>

            <div style={{ textAlign: "center" }}>
              <Typography.Title level={4}>{selectedUser?.displayName}</Typography.Title>
              <Typography.Paragraph type="secondary">{selectedUser?.function}</Typography.Paragraph>
            </div>

            {!!selectedUser?.email && (
              <Card bordered={false}>
                <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                  <Glyph name="mail" className={"secondary"} />
                  <Typography.Text strong>{selectedUser?.email}</Typography.Text>
                </div>
              </Card>
            )}
            {!!selectedUser?.mobilePhone && (
              <Card bordered={false}>
                <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                  <Glyph name="phone_iphone" className={"secondary"} />
                  <Typography.Text strong>{selectedUser?.mobilePhone}</Typography.Text>
                </div>
              </Card>
            )}
            {!!selectedUser?.phone && (
              <Card bordered={false}>
                <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                  <Glyph name="phone" className={"secondary"} />
                  <Typography.Text strong>{selectedUser?.phone}</Typography.Text>
                </div>
              </Card>
            )}
            {!!selectedUser?.company && (
              <Card bordered={false}>
                <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                  <Glyph name="business" className={"secondary"} />
                  <Typography.Text strong>{selectedUser?.company}</Typography.Text>
                </div>
              </Card>
            )}
          </div>
        </div>

        {!team.find((u) => u.id === selectedUser?.id) ? (
          <Button
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              margin: 24,
              width: "calc(100% - (24px * 2))",
            }}
            block
            icon={<Glyph name={"person_add"} />}
            type="primary"
            onClick={handleAddUser}
            disabled={isLoading}
            loading={isLoading}
          >
            Ajouter à mon équipe
          </Button>
        ) : (
          <Button
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              margin: 24,
              width: "calc(100% - (24px * 2))",
            }}
            block
            icon={<Glyph name={"person_remove"} />}
            type="primary"
            onClick={handleRemoveUser}
            disabled={isLoading}
            loading={isLoading}
          >
            Retirer de mon équipe
          </Button>
        )}
      </Drawer>
    </>
  );
};

export default TeamDrawer;
