import { Button, InputNumber, message, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as Label } from "../../../../../assets/svg/label-presence.svg";
import clsx from "clsx";
import styles from "../../SpaceBooking.module.less";
import { useRef, useState } from "react";
import { isNil, update } from "ramda";
import { useDispatch } from "react-redux";
import { confirmPresence, listSlots } from "../../../../../services/redux/services/SpaceService";

const RequestModalPresence = ({ visible, onCancel, slot, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const input_1 = useRef();
  const input_2 = useRef();
  const input_3 = useRef();
  const input_4 = useRef();
  const input_5 = useRef();
  const input_6 = useRef();

  const [code, setCode] = useState(["", "", "", "", "", ""]);

  return (
    <Modal
      className={clsx(styles.modal)}
      destroyOnClose={true}
      open={visible}
      onCancel={onCancel}
      title={<Typography.Text strong>{t("spas.request.confirm.presence")}</Typography.Text>}
      bodyStyle={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      footer={[
        <Button
          key="save"
          block
          type="primary"
          //loading={isLoading}
          //disabled={isLoading}
          style={{ width: "350px" }}
          onClick={() => {
            dispatch(
              confirmPresence({
                slotId: slot.id,
                siteId: slot.request.siteId,
                code: parseInt(code.join("")),
              }),
            )
              .unwrap()
              .then(() => {
                message.success(t("spas.presence.success"));
                onCancel();
                close();
                dispatch(listSlots());
              })
              .catch((error) => {
                console.error(error);
                if (error.code === "409") {
                  message.error(t("spas.prsence.alreadyConfirmed"));
                } else if (error.code === "403") {
                  message.error(t("spas.presence.noConfirmed"));
                } else {
                  message.error(t("spas.presence.error"));
                }
              });
          }}
        >
          {t("spas.request.validate")}
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <Label />
        <Typography.Text type="secondary" strong>
          Saisir le code à 6 chiffres situé sur l’étiquette de bureau
        </Typography.Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "row",
          alignItems: "center",
          marginTop: "60px",
        }}
      >
        <InputNumber
          className={styles.inputNumber}
          ref={input_1}
          size="large"
          max={9}
          style={{ marginRight: "8px" }}
          controls={false}
          onChange={(value) => {
            setCode(update(0, value, code));
            if (!isNil(value)) {
              input_2.current.focus();
            }
          }}
        />
        <InputNumber
          className={styles.inputNumber}
          ref={input_2}
          size="large"
          max={9}
          style={{ marginRight: "8px" }}
          controls={false}
          onChange={(value) => {
            setCode(update(1, value, code));
            if (!isNil(value)) {
              input_3.current.focus();
            }
          }}
        />
        <InputNumber
          className={styles.inputNumber}
          ref={input_3}
          size="large"
          max={9}
          style={{ marginRight: "8px" }}
          controls={false}
          onChange={(value) => {
            setCode(update(2, value, code));
            if (!isNil(value)) {
              input_4.current.focus();
            }
          }}
        />
        -
        <InputNumber
          className={styles.inputNumber}
          ref={input_4}
          size="large"
          max={9}
          style={{ marginLeft: "8px" }}
          controls={false}
          onChange={(value) => {
            setCode(update(3, value, code));
            if (!isNil(value)) {
              input_5.current.focus();
            }
          }}
        />
        <InputNumber
          className={styles.inputNumber}
          ref={input_5}
          size="large"
          max={9}
          style={{ marginLeft: "8px" }}
          controls={false}
          onChange={(value) => {
            setCode(update(4, value, code));
            if (!isNil(value)) {
              input_6.current.focus();
            }
          }}
        />
        <InputNumber
          className={styles.inputNumber}
          ref={input_6}
          size="large"
          max={9}
          style={{ marginLeft: "8px" }}
          controls={false}
          onChange={(value) => setCode(update(5, value, code))}
        />
      </div>
    </Modal>
  );
};

export default RequestModalPresence;
