import { Card, Image, Space, Typography } from "antd";
import { isEmpty } from "ramda";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import { listWorkplaceWithDetails } from "../../../../../../services/redux/services/SpaceService";
import Glyph from "../../../../../Common/Glyph/Glyph";
import styles from "../../../SpaceBooking.module.less";

const CreateStepWorkplace = ({ value, onChange, siteId }) => {
  const { colors } = useDesignTokens();

  const mapData = useSelector((state) => state.mapDataWS);
  const workplaces = useSelector((state) => listWorkplaceWithDetails(state, siteId));

  return (
    <>
      <Typography.Paragraph style={{ margin: "0" }} strong>
        Poste de travail
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">Sélectionnez un poste de travail</Typography.Paragraph>
      <br />
      <Space style={{ width: "100%" }} direction="vertical" size="small">
        {[...(workplaces?.availability || [])]
          ?.sort((a, b) => (a?.workplaces?.title < b?.workplaces?.title ? -1 : 1))
          ?.map((workplace, index) => (
            <Card
              key={index}
              style={{
                cursor: "pointer",
                border: value === workplace.id ? `2px solid ${colors.primary_base}` : "none",
                padding: value === workplace.id ? 0 : 2,
              }}
              bodyStyle={{ padding: 10 }}
              bordered={false}
              onClick={() => {
                onChange(workplace.id);
              }}
            >
              <Card.Meta
                avatar={
                  workplace?.workplaces?.photo && !isEmpty(workplace?.workplaces?.photo) ? (
                    <Image width={60} preview={false} src={workplace?.workplaces?.photo[0]} />
                  ) : (
                    <div className={styles["picture_icon"]}>
                      <Glyph
                        style={{ fontSize: "30px", color: "white", opacity: 0.8 }}
                        name="desk"
                      />
                    </div>
                  )
                }
                title={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography.Text style={{ fontSize: 12 }} strong>
                      {workplace?.workplaces?.title}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: 12 }}>
                      {
                        mapData[siteId]?.mapData?.floors?.find(
                          (f) =>
                            f.reference ===
                            mapData[siteId]?.mapData?.resources?.find(
                              (r) => r.id === workplace?.workplaces?.roomId,
                            )?.floorReference,
                        )?.displayName
                      }
                    </Typography.Text>
                  </div>
                }
                description={
                  <Typography.Text style={{ fontSize: 12 }}>
                    {
                      mapData[siteId]?.mapData?.categories?.find(
                        (c) => c.id === workplace?.workplaces?.categoryId,
                      )?.title
                    }
                  </Typography.Text>
                }
              />
            </Card>
          ))}
      </Space>
    </>
  );
};

export default CreateStepWorkplace;
