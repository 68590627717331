import { Card, Col, Layout, Modal, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as WifiIMG } from "../../../assets/svg/wifi.svg";
import useDesignTokens from "../../../helpers/useDesignTokens";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { getWifiInfos } from "../../../services/redux/services/WifiWS";
import QRCode from "../../Common/QRCode";
import { TitleSource } from "../../Common/Teleporters/Title";
import Tile from "../../Common/Tile/Tile";

const Wifi = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const [showBigQR, setShowBigQR] = useState(false);

  const isLoading = useSelector(createLoadingSelector(["wifi/getWifiInfos"]));
  const wifiInfos = useSelector((state) => state.wifiWS.wifiInfos);

  useEffect(() => {
    if (!wifiInfos) dispatch(getWifiInfos());
  }, [dispatch, wifiInfos]);

  return (
    <>
      <TitleSource>{t("wifi_access", { ns: "csv" })}</TitleSource>
      <Layout style={{ padding: "7% 10%" }}>
        <Spin spinning={isLoading}>
          <Card style={{ padding: "40px 0" }}>
            <Row align="middle">
              <Col
                span={8}
                style={{ padding: "0 30px", borderRight: `1px solid ${colors.primary_base}` }}
              >
                <WifiIMG style={{ width: "100%", height: "auto", margin: "30% 0" }} />
              </Col>
              <Col span={16} style={{ padding: "0 80px" }}>
                <Row gutter={[30, 30]}>
                  <Col span={24}>
                    <Typography.Title level={5}>{t("wifi.ssid")}</Typography.Title>
                    <Tile style={{ margin: 0 }}>
                      <Typography.Text strong>{wifiInfos?.ssid}</Typography.Text>
                    </Tile>
                  </Col>
                  <Col span={24}>
                    <Typography.Title level={5}>{t("wifi.password")}</Typography.Title>
                    <Tile style={{ margin: 0 }}>
                      <Typography.Text strong>{wifiInfos?.password}</Typography.Text>
                    </Tile>
                  </Col>
                  {wifiInfos?.encoded && (
                    <>
                      <Col style={{ flex: 1 }}>
                        <Typography.Title level={5} type="secondary">
                          {t("wifi.helper.title")}
                        </Typography.Title>
                        <Typography.Text type="secondary" strong>
                          {t("wifi.helper.description")}
                        </Typography.Text>
                      </Col>
                      <Col
                        onClick={() => setShowBigQR(true)}
                        style={{ cursor: "pointer", width: 150 }}
                      >
                        <QRCode value={wifiInfos.encoded} />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>

      {wifiInfos?.encoded && (
        <Modal destroyOnClose open={showBigQR} onCancel={() => setShowBigQR(false)} footer={null}>
          <QRCode value={wifiInfos.encoded} />
        </Modal>
      )}
    </>
  );
};

export default Wifi;
