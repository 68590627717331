import { Button, Space, Typography } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import Tile from "../../Common/Tile/Tile";
import { useTranslation } from "react-i18next";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const PlaceAroundMe = ({ place }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  return (
    <div>
      {place?.address && (
        <div>
          <Glyph name="place" style={{ color: colors?.interactive_03 }} />
          <Typography.Text>{place?.address}</Typography.Text>
        </div>
      )}

      <Space
        style={{ width: "100%", padding: "15px 0", gap: "5px" }}
        direction="vertical"
        size="small"
      >
        {place?.phone && (
          <Tile>
            <div>
              <Glyph name="phone" style={{ marginRight: "8px", color: colors?.secondary_base }} />
              <Typography.Text strong>{t("phone_number")}</Typography.Text>
            </div>
            <Typography.Text>{place?.phone}</Typography.Text>
          </Tile>
        )}

        {place?.website && (
          <a
            href={place?.website}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              logEvent(analytics, "around_me_select_website");
            }}
          >
            <Tile>
              <div>
                <Glyph
                  name="public"
                  style={{ marginRight: "8px", color: colors?.secondary_base }}
                />
                <Typography.Text strong>{t("web_site")}</Typography.Text>
              </div>
              <Glyph name="navigate_next" />
            </Tile>
          </a>
        )}
      </Space>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${place?.lat},${place?.lon}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            logEvent(analytics, "around_me_itinerary");
          }}
        >
          <Button icon={<Glyph name="directions_walk" />} type="primary">
            {t("Itinerary")}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default PlaceAroundMe;
