import { Button, Form, Input, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import useDesignTokens from "../../../../hook/useDesignTokens";

// import component
import Glyph from "../../../Common/Glyph/Glyph";
import Tile from "../../../Common/Tile/Tile";
// import css
import styles from "../Ticketing.module.less";

const SelectRoom = ({ isVisible, onCancel, setSelectedRoom, spaceTypeRestriction }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const [current, setCurrent] = useState(0);
  const [searchRoom, setSearch] = useState("");

  const campus = useSelector((state) => state.clientsWS.campus);

  const GUTTER_SIZE = 8;

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const searchResult = searchRoom
    ? campus?.mapData?.resources
        .filter((value) => {
          if (spaceTypeRestriction && spaceTypeRestriction.indexOf(value.categoryId) === -1)
            return false;

          if (searchRoom === "") {
            return value;
          } else {
            return value.title.toLowerCase().includes(searchRoom.toLowerCase());
          }
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1))
    : null;

  const Row = ({ style, index }) => (
    <Tile
      className={styles["tile--room"]}
      style={{ ...style, height: style.height - GUTTER_SIZE }}
      onClick={() => {
        setSelectedRoom(searchResult[index]);
        onCancel();
        setSearch("");
      }}
    >
      <Typography.Text
        strong
        style={{
          //width: "220px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Glyph
          name="meeting_room"
          style={{
            fontWeight: "normal",
            marginRight: "8px",
            verticalAlign: "-4px",
            color: colors.secondary_base,
          }}
        />
        {searchResult[index].title}
      </Typography.Text>
      <Typography.Text>
        {t("Floor")}{" "}
        {
          campus?.mapData?.floors.find(
            (floor) => floor.reference === searchResult[index].floorReference,
          )?.displayName
        }
        <Glyph name="navigate_next" style={{ verticalAlign: "-4px" }} />
      </Typography.Text>
    </Tile>
  );

  return (
    <Modal
      destroyOnClose
      title={
        <Typography.Title level={5} style={{ textAlign: "center" }}>
          {t("ChooseLocation")}
        </Typography.Title>
      }
      open={isVisible}
      width={655}
      onCancel={() => {
        onCancel();
        setCurrent(0);
        setSelectedRoom();
        setSearch("");
      }}
      footer={
        current === 0 ? (
          <Button
            ghost
            style={{ width: "375px" }}
            onClick={() => {
              onCancel();
              setSelectedRoom();
            }}
          >
            {t("Back")}
          </Button>
        ) : null
      }
      bodyStyle={{ zIndex: "999", height: 650 }}
    >
      <Form>
        <div
          className="steps-content"
          style={{
            display: current < 1 ? "block" : "none",
          }}
        >
          <Input
            onChange={handleSearch}
            value={searchRoom}
            placeholder={t("FindSpace")}
            prefix={<Glyph name="search" />}
            style={{ marginBottom: "16px" }}
          />
          <Typography.Text strong style={{ color: colors.pending_light }}>
            {t("UseSearch")}
          </Typography.Text>
          <Space style={{ width: "100%", marginTop: "16px" }} direction="vertical" size="small">
            {!searchResult ? null : (
              <>
                {searchResult.length === 0 ? (
                  <Typography.Text type="secondary">{t("NoResult")}</Typography.Text>
                ) : (
                  <List height={500} itemCount={searchResult.length} itemSize={50}>
                    {Row}
                  </List>
                )}
              </>
            )}
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default SelectRoom;
