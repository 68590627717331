// import redux
import {
  setBlinds,
  getComfortBlinds,
} from "../../../services/redux/services/../../../../../services/redux/services/ConfortWS";
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Slider, message } from "antd";

// import css
import styles from "../SettingsUI.module.less";

// import component
import Glyph from "../../../../Common/Glyph/Glyph";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

const BlindDimmer = ({
  setIsLoading,
  setStatus,
  zone,
  isAuto,
  setIsAuto,
  isLoading,
  selectedRoomNomap,
}) => {
  const { t } = useTranslation();

  const selectedRoom = useSelectedRoom();
  const dispatch = useDispatch();

  const [blind, setBlind] = useState(0);
  const comfortBlinds = useSelector((state) => state.clientsWS.blinds);
  const comfortAngle = useSelector((state) => state.clientsWS.angle);

  useEffect(() => {
    if (zone) {
      if (!comfortBlinds[zone]?.result?.Data || !comfortAngle[zone]?.result?.Data) return;
      setBlind(comfortBlinds[zone].result.Data);
    } else {
      if (!comfortBlinds[0]?.result?.Data || !comfortAngle[0]?.result?.Data) return;
      setBlind(comfortBlinds[0].result.Data);
    }
  }, [comfortBlinds, comfortAngle, zone]);

  useEffect(() => {
    if (isAuto) {
      setBlind(50);
    }
  }, [isAuto]);

  const dispatchBlind = (value) => {
    setIsLoading(true);
    logEvent(analytics, "smart_control_command_blind", {
      zone: zone !== undefined ? "zone" : "total",
      type_blind: "dimmer",
    });
    dispatch(
      setBlinds({
        roomId: selectedRoomNomap?.id || selectedRoom?.id,
        zone: zone ? zone : 0,
        command: "SET",
        value: value,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
        selectedRoom?.features?.comfort?.blind?.zones?.map((zoneBlind) =>
          dispatch(
            getComfortBlinds({
              roomId: selectedRoomNomap?.id || selectedRoom?.id,
              zone: zoneBlind,
            }),
          )
            .unwrap()
            .catch(() => {
              message.error(t("ErrorGetBlind"), 5);
            }),
        );
      })
      .catch(() => {
        message.error(t("ErrorSetBlind"), 5);
        setStatus("error");
        if (zone) {
          if (!comfortBlinds[zone]?.result?.Data) return;
          setBlind(comfortBlinds[zone].result.Data);
        } else {
          if (!comfortBlinds[0]?.result?.Data) return;
          setBlind(comfortBlinds[0].result.Data);
        }
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const BlindOn = () => {
    dispatchBlind(0);
    setIsAuto(false);
    logEvent(analytics, "smart_control_command_blind", {
      type_blind: "dimmer",
    });
  };

  const BlindOff = () => {
    dispatchBlind(100);
    setIsAuto(false);
    logEvent(analytics, "smart_control_command_blind", {
      type_blind: "dimmer",
    });
  };

  return (
    <>
      <div className={styles["SettingsUI__content"]}>
        <Button
          icon={<Glyph name="shutter_open" />}
          size="default"
          type="default"
          onClick={BlindOn}
          style={{ borderRadius: "100%" }}
          disabled={isLoading}
        ></Button>
        <Slider
          style={{ filter: isAuto ? "saturate(0)" : "saturate(1)" }}
          max={100}
          min={0}
          step={25}
          value={blind}
          onChange={setBlind}
          onAfterChange={(value) => {
            dispatchBlind(value);
            setIsAuto(false);
          }}
          disabled={isLoading}
        />
        <Button
          icon={<Glyph name="shutter_closed" />}
          type="default"
          size="default"
          onClick={BlindOff}
          style={{ borderRadius: "100%" }}
          disabled={isLoading}
        ></Button>
      </div>
    </>
  );
};

export default BlindDimmer;
