import React, { useEffect } from "react";

import { Typography, Button } from "antd";
import { Link, NavLink } from "react-router-dom";
import useDesignTokens from "../../../hook/useDesignTokens";
import { useTranslation } from "react-i18next";

// import component
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "ramda";
import moment from "moment";
import { listSlots } from "../../../services/redux/services/SpaceService";

const Widget = () => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();

  const slots = useSelector((state) => state.spaceServiceWS.slots);
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  useEffect(() => {
    dispatch(
      listSlots({
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      }),
    );
  }, [dispatch]);
  return (
    <div>
      <Typography.Text strong style={{ fontSize: "16px", marginLeft: "8px" }}>
        {t("Today")}
      </Typography.Text>
      {!isEmpty(slots) ? (
        <NavLink to="/spaceBooking">
          <Tile
            style={{
              height: "auto",
              padding: "10px",
              margin: "0",
              width: "100%",
              alignItems: "baseline",
            }}
          >
            <div style={{ display: "flex", flexFlow: "column" }}>
              <Typography.Text strong>
                <Glyph
                  style={{ color: colors.secondary_base, fontWeight: "normal", marginRight: "8px" }}
                  name="business"
                />
                {
                  sites.find(
                    (site) =>
                      site.id ===
                      slots?.find((slot) => moment(slot.date).isSame(moment().format("YYYY-MM-DD")))
                        ?.request?.siteId,
                  )?.title
                }
              </Typography.Text>
              <Typography.Text strong style={{ marginTop: "16px" }}>
                <Glyph
                  name="schedule"
                  style={{ color: colors.pending_light, fontWeight: "normal", marginRight: "8px" }}
                />
                {t(
                  `spas.period.${
                    slots?.find((slot) => moment(slot.date).isSame(moment().format("YYYY-MM-DD")))
                      ?.request?.period
                  }`,
                )}
              </Typography.Text>
              {slots?.find((slot) => moment(slot.date).isSame(moment().format("YYYY-MM-DD")))
                ?.request?.recurrence !== "NONE" && (
                <>
                  <Typography.Text strong style={{ marginTop: "16px" }}>
                    <Glyph
                      name="sync"
                      style={{
                        color: colors.pending_light,
                        fontWeight: "normal",
                        marginRight: "8px",
                      }}
                    />
                    {t(
                      `spas.recurrence.${
                        slots?.find((slot) =>
                          moment(slot.date).isSame(moment().format("YYYY-MM-DD")),
                        )?.request?.recurrence
                      }`,
                      {
                        day: moment(
                          slots?.find((slot) =>
                            moment(slot.date).isSame(moment().format("YYYY-MM-DD")),
                          )?.request?.startDate,
                        ).format("dddd"),
                      },
                    )}
                  </Typography.Text>
                  <Typography.Text strong style={{ marginTop: "16px" }}>
                    <Glyph
                      name="event_busy"
                      style={{
                        color: colors.pending_light,
                        fontWeight: "normal",
                        marginRight: "8px",
                      }}
                    />
                    {t("spas.request.endDate", {
                      date: slots?.find((slot) =>
                        moment(slot.date).isSame(moment().format("YYYY-MM-DD")),
                      )?.request?.endDate,
                    })}
                  </Typography.Text>
                </>
              )}
            </div>
            <div>
              <Glyph style={{ fontSize: "24px" }} name="navigate_next" />
            </div>
          </Tile>
        </NavLink>
      ) : (
        <div>
          <Typography.Paragraph strong style={{ marginTop: "16px" }}>
            {t("spas.widget.noResa")}
          </Typography.Paragraph>
          <Button ghost style={{ width: "100%" }}>
            <Link to="/spaceBooking">{t("spas.request.new")}</Link>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Widget;
