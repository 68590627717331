/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ListIconTransport from "../../../../helpers/ListIconTransport";
import ReactDOMServer from "react-dom/server";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";
import { isNil } from "ramda";

const MapsGoogleAroundMe = ({ categorie, place, setPlace }) => {
  const map = useRef();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const campus = useSelector((state) => state.clientsWS.campus);

  const lat = sites?.find((c) => c?.id === campus?.mapData?.id)?.lat;
  const lng = sites?.find((c) => c?.id === campus?.mapData?.id)?.lon;

  const [markers, setMarkers] = useState([]);

  const selectMarker = useCallback((marker) => {
    setMarkers((markers) => {
      markers?.forEach((m) => {
        if (m.marker === marker || m.id === marker) {
          m.marker.setIcon(m.iconSelected);
        } else {
          m.marker.setIcon(m.icon);
        }
      });
      return markers;
    });
  }, []);

  useEffect(() => {
    if (!lat || !lng) return;
    map.current = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: lat,
        lng: lng,
      },
      zoom: 17,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      disableDefaultUI: true,
    });
  }, [lat, lng]);

  useEffect(() => {
    const newMarkers = categorie?.places?.map((p, index) => {
      const customIcon = ListIconTransport(categorie?.icon);
      const customIconString =
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(ReactDOMServer.renderToString(customIcon));

      const customIconSeleted = ListIconTransport(categorie?.icon + "_selected");
      const customIconStringSelected =
        "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(ReactDOMServer.renderToString(customIconSeleted));

      const marker = new window.google.maps.Marker({
        position: { lat: p?.lat, lng: p?.lon },
        map: map.current,
        icon: {
          url: customIconString,
        },
      });

      marker.addListener("click", () => {
        selectMarker(marker);
        map.current.setZoom(17);
        map.current.setCenter({ lat: p?.lat, lng: p?.lon });
        setPlace(p);
        logEvent(analytics, "around_me_select_poi_from map");
      });

      return {
        marker,
        icon: customIconString,
        iconSelected: customIconStringSelected,
        id: p?.id,
      };
    });
    setMarkers((markers) => {
      markers?.forEach((m) => m.marker.setMap(null));
      return newMarkers;
    });
  }, [categorie?.icon, categorie?.places, selectMarker, setPlace]);

  useEffect(() => {
    selectMarker(place?.id);
    if (place) {
      map.current.setZoom(17);
      map.current.setCenter({ lat: place?.lat, lng: place?.lon });
    }
  }, [selectMarker, place]);

  useEffect(() => {
    if (!isNil(categorie) && isNil(place)) {
      map.current.setZoom(12);
    } else if (isNil(categorie) && isNil(place)) {
      map.current.setZoom(17);
      map.current.setCenter({ lat: lat, lng: lng });
    }
  }, [categorie, lat, lng, place]);

  return <div id="map" style={{ width: "100%", height: "100%", position: "absolute" }} />;
};

export default MapsGoogleAroundMe;
