import { Layout, Select } from "antd";
import { getCgu, getPrivacyPolicy } from "../../../services/redux/services/ContractPolicyWS";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getlang } from "../../../services/redux/services/ClientsWS";

const { Option } = Select;
const Header = () => {
  const dispatch = useDispatch();

  const url = window.location.href;
  const segments = url.split("/");
  const cguSegment = segments.find((segment) => segment.toLowerCase().includes("cgu"));
  const privacyPolicySegment = segments.find((segment) =>
    segment.toLowerCase().includes("privacypolicy"),
  );
  const language = useSelector((state) => state.clientsWS.lang);

  const [lang, setLang] = useState("fr");

  useEffect(() => {
    if (cguSegment) {
      dispatch(getCgu({ os: "IOS", locale: lang }));
    }
  }, [cguSegment, dispatch, lang]);

  useEffect(() => {
    if (privacyPolicySegment) {
      dispatch(getPrivacyPolicy({ os: "IOS", locale: lang }));
    }
  }, [dispatch, lang, privacyPolicySegment]);

  useEffect(() => {
    dispatch(getlang());
  }, [dispatch]);

  return (
    <Layout.Header style={{ zIndex: "998", width: "100%" }}>
      <div style={{ borderBottom: "0px", display: "flex", alignItems: "center" }}>
        <Select
          size={"medium"}
          style={{ width: "70px", textAlign: "center" }}
          onChange={(value) => setLang(value)}
          defaultValue={lang}
        >
          {language?.locales?.map((locale, index) => (
            <Option key={index} value={locale} style={{ textAlign: "center" }}>
              {locale}
            </Option>
          ))}
        </Select>
      </div>
    </Layout.Header>
  );
};

export default Header;
