import { Button, Card, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory, useLocation, useParams } from "react-router-dom";

// import redux
import {
  addFavoris,
  addLastSearch,
  removeFavoris,
} from "../../../services/redux/services/ClientsWS";

// import component
import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../hook/useDesignTokens";
import useSelectedRoom from "../../../services/redux/useSelectedRoom";
import Glyph from "../../Common/Glyph/Glyph";
import useMap from "../../Common/Map/useMap";
import RoomDetails from "../../Common/RoomDetails/RoomDetails";

// Firebase
import { logEvent } from "firebase/analytics";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import { analytics } from "../../../services/api/Firebase/Firebase";

const RoomGuidMe = () => {
  const { selectedOnMap, centerOnPlaceId } = useMap();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedRoom = useSelectedRoom();
  const { colors } = useDesignTokens();
  const location = useLocation();

  const params = useParams();

  const favoris = useSelector((state) => state.clientsWS.favoris);
  const campus = useSelector((state) => state.clientsWS.campus);

  useDidUpdateEffect(() => {
    if (selectedOnMap?.map?.findAndOrder?.placeId)
      history.push(`/indoorMapping/${selectedOnMap.map.findAndOrder.placeId}`);
  }, [history, selectedOnMap?.map?.findAndOrder?.placeId]);

  const placeId =
    location?.state?.location?.placeId ||
    location?.state?.room?.map.findAndOrder.placeId ||
    params.id;
  useEffect(() => {
    if (placeId.toLowerCase() === "undefined" || placeId.toLowerCase() === "null")
      history.push("/indoorMapping");
    if (location?.state && placeId) centerOnPlaceId(placeId);
  }, [centerOnPlaceId, history, location?.state, placeId]);

  const addFav = () => {
    dispatch(addFavoris(selectedRoom));
    logEvent(analytics, "add_to_favorite", {
      poi_name: selectedRoom?.title,
    });
  };
  const removeFav = () => {
    dispatch(removeFavoris(selectedRoom.id));
  };

  useEffect(() => {
    if (selectedRoom?.id) dispatch(addLastSearch(selectedRoom));
  }, [dispatch, selectedRoom]);

  const roomFavoris = favoris.find((f) => f.id === selectedRoom.id);

  if (!selectedRoom) return <Redirect to="/indoorMapping" />;
  return (
    <Card
      title={
        <>
          <Typography.Title level={5}>
            <NavLink
              to="/indoorMapping"
              onClick={() => {
                centerOnPlaceId(null);
              }}
            >
              <Glyph
                name="arrow_back_ios"
                style={{
                  verticalAlign: "-2px",
                  fontSize: "20px",
                  color: "black",
                  marginRight: "8px",
                }}
              />
            </NavLink>
            {selectedRoom?.title}
          </Typography.Title>
          <Typography.Text strong style={{ fontSize: "14px", color: colors.pending_light }}>
            {t("Floor")}{" "}
            {
              campus?.mapData?.floors.find(
                (floor) => floor.reference === selectedRoom.floorReference,
              )?.displayName
            }
          </Typography.Text>
        </>
      }
      extra={
        roomFavoris ? (
          <Button
            style={{ border: "none", background: "none", color: colors.secondary_base }}
            type="primary"
            icon={<Glyph name="star" />}
            onClick={removeFav}
          ></Button>
        ) : (
          <Button
            style={{ border: "none", background: "none", color: colors.grey_40 }}
            type="primary"
            icon={<Glyph name="star" />}
            onClick={addFav}
          ></Button>
        )
      }
      actions={[
        <NavLink
          to={{
            pathname: "/indoorMapping/itinerary",
            state: {
              back: `/indoorMapping/${selectedRoom.map.findAndOrder.placeId}`,
              selectedRoom: selectedRoom,
            },
          }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            type="primary"
            style={{
              width: "350px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
            icon={
              <Glyph
                name="directions_walk"
                style$={{ verticalAlign: "-1px", marginRight: "8px" }}
              />
            }
          >
            {t("Itinerary")}
          </Button>
        </NavLink>,
      ]}
      style={{ width: "35%", height: "fit-content", overflow: "auto" }}
    >
      <RoomDetails room={selectedRoom} withTitle={false} withOccupancy />
    </Card>
  );
};

export default RoomGuidMe;
