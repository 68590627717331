import { createAsyncThunk as thunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { keys, pick } from "ramda";

import DWMConnector from "../../api/DWMConnector";

const BASE_FILTERS = {
  startDate: null,
  endDate: null,
  floor: null,
  equipments: [],
  services: [],
  seats: null,
  types: null,
};

const INITIAL_STATE = {
  roomFastbooking: [],
  allRoomFastbooking: [],
  booking: null,
  filters: BASE_FILTERS,
  fastbookingSlots: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};
let siteIdStorage = localStorage.getItem("siteId");
///////////////////////// ROOM FASTBOOKING ///////////////////////////////
export const getRoomFastbooking = thunk(
  "fastbooking/getRoomFastbooking",
  async (filters, { getState }) => {
    const state = getState();
    const allFilters = Object.assign({}, state.fastbookingWS.filters, filters);
    const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
    const viewId = state.userWS.userData.views.find(
      (v) => v.type === "fastBooking" || v.type === "fastBookingV2",
    );
    const fastbooking = await DWMConnector.fastbooking(viewId.id, allFilters, siteId);
    fastbooking.rooms = fastbooking.rooms.map((room) => ({
      ...room,
      resources: state.clientsWS.campus.mapData.resources.find((r) => r.id === room.roomId),
    }));
    return fastbooking;
  },
);

EXTRA_REDUCERS[getRoomFastbooking.fulfilled] = (state, action) => {
  state.roomFastbooking = action.payload;
};

export const getAllRoomFastbooking = thunk(
  "fastbooking/getAllRoomFastbooking",
  async (filters, { getState }) => {
    const state = getState();
    const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
    const viewId = state.userWS.userData.views.find(
      (v) => v.type === "fastBooking" || v.type === "fastBookingV2",
    );
    const allFastbooking = await DWMConnector.fastbooking(viewId.id, filters, siteId);
    allFastbooking.rooms = allFastbooking.rooms.map((room) => ({
      ...room,
      resources: state.clientsWS.campus.mapData.resources.find((r) => r.id === room.roomId),
    }));
    return allFastbooking;
  },
);

EXTRA_REDUCERS[getAllRoomFastbooking.fulfilled] = (state, action) => {
  state.allRoomFastbooking = action.payload;
};

export const getFastbookingSlots = thunk(
  "fastbooking/getFastbookingSlots",
  async ({ slotStartTime, slotEndTime, day }, { getState }) => {
    const state = getState();
    const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
    const viewId = state.userWS.userData.views.find(
      (v) => v.type === "fastBooking" || v.type === "fastBookingV2",
    );
    const fastbookingSlots = await DWMConnector.fastbookingSlots(
      viewId.id,
      slotStartTime,
      slotEndTime,
      day,
      siteId,
    );
    return fastbookingSlots.map((room) => ({
      ...room,
      resources: state.clientsWS.campus.mapData.resources.find((r) => r.id === room.uid),
    }));
  },
);

EXTRA_REDUCERS[getFastbookingSlots.fulfilled] = (state, action) => {
  state.fastbookingSlots = action.payload;
};

export const setMakeBooking = thunk(
  "fastbooking/setMakeBooking",
  async ({ roomId, startDate, duration, endDate }, { getState }) => {
    const state = getState();
    const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
    const viewId = state.userWS.userData.views.find(
      (v) => v.type === "fastBooking" || v.type === "fastBookingV2",
    );
    const makeBooking = await DWMConnector.setMakeBooking(
      viewId.id,
      roomId,
      startDate,
      duration,
      endDate,
      siteId,
    );
    return makeBooking;
  },
);

EXTRA_REDUCERS[setMakeBooking.fulfilled] = (state, action) => {
  state.booking = action.payload;
  state.roomFastbooking.rooms = state.roomFastbooking.rooms.map((room) => ({
    ...room,
    pending: action.meta.arg.roomId === room.roomId,
  }));
};

export const setRemoveBooking = thunk(
  "fastbooking/setRemoveBooking",
  async ({ slotId, roomId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find(
      (v) => v.type === "fastBooking" || v.type === "fastBookingV2",
    );
    const removeBooking = await DWMConnector.setRemoveBooking(viewId.id, slotId, roomId);
    return removeBooking;
  },
);

EXTRA_REDUCERS[setRemoveBooking.fulfilled] = (state, action) => {
  state.roomFastbooking = action.payload;
};

const fastbookingSlice = createSlice({
  name: "fastbooking",
  initialState: INITIAL_STATE,
  reducers: {
    setFilter(state, action) {
      state.filters = action.payload;
    },
    patchFilter(state, action) {
      Object.assign(state.filters, pick(keys(BASE_FILTERS), action.payload));
    },
    removeFilter(state) {
      state.filters = pick(["types"], state.filters);
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const fastbookingFilters = createSelector(
  (state) => state.clientsWS.campus.mapData,
  (state) => state.fastbookingWS.filters,
  (mapData, filters) => {
    return {
      ...filters,
      types: [mapData.categories.filter((t) => t.bookable === true)[0].id],
    };
  },
);

export const { setFilter, removeFilter, patchFilter } = fastbookingSlice.actions;
export default fastbookingSlice.reducer;
