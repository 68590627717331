import Glyph from "../views/Common/Glyph/Glyph";
import useDesignTokens from "./useDesignTokens";

const SwitchIconTransport = (mode) => {
  const { colors } = useDesignTokens();

  // eslint-disable-next-line default-case
  switch (mode) {
    case "walking":
      return <Glyph name="directions_walk" style={{ color: colors?.grey_60 }} />;
    case "metro":
      return <Glyph name="subway" style={{ color: colors?.grey_60 }} />;
    case "train":
      return <Glyph name="train" style={{ color: colors?.grey_60 }} />;
    case "bus":
      return <Glyph name="directions_bus" style={{ color: colors?.grey_60 }} />;
  }
};

export default SwitchIconTransport;
