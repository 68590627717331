import { useRef, useState, useEffect, useMemo } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import moment from "moment";
import { Typography, Segmented, DatePicker, Form } from "antd";
import { isEmpty } from "ramda";
import { useSelector } from "react-redux";
import { TitleSource } from "../../Common/Teleporters/Title";
import { useTranslation } from "react-i18next";

import styles from "./PowerBi.module.less";

import Checkbox from "../../Common/Checkbox/Checkbox";

const { RangePicker } = DatePicker;

const PowerBi = () => {
  const embed = useRef();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const report = useSelector((state) => state.powerBiWS.report);

  const [valueDuration, setValueDuration] = useState();
  const [valueInterval, setValueInterval] = useState();
  const [pages, setPages] = useState();
  const [pageReport, setPageReport] = useState();

  useEffect(() => {
    setPages();
    setPageReport();
  }, [report]);

  useEffect(() => {
    if (!pages?.length || pageReport) return;
    const page = pages[0]?.displayName;
    setPageReport(page);
    embed.current.setPage(pages?.find((p) => p.displayName === page)?.name);
  }, [pageReport, pages]);

  const baseFilterDate = useMemo(
    () => ({
      $schema: report?.filters?.find((f) => f.type === "DATE")?.schema,
      target: {
        table: report?.filters?.find((f) => f.type === "DATE")?.target?.table,
        column: report?.filters?.find((f) => f.type === "DATE")?.target?.column,
      },
    }),
    [report?.filters],
  );

  const baseFilterHour = useMemo(
    () => ({
      $schema: report?.filters?.find((f) => f.type === "HOUR")?.schema,
      target: {
        table: report?.filters?.find((f) => f.type === "HOUR")?.target?.table,
        column: report?.filters?.find((f) => f.type === "HOUR")?.target?.column,
      },
    }),
    [report?.filters],
  );

  useEffect(() => {
    if (!pages?.length || pageReport) return;
    setValueDuration("day");
    setValueInterval("1S");
    const filterDate = {
      ...baseFilterDate,
      logicalOperator: "And",
      conditions: [
        {
          operator: "GreaterThanOrEqual",
          value: moment().startOf("day").subtract(7, "days").toISOString(),
        },
        {
          operator: "LessThanOrEqual",
          value: moment().endOf("day").subtract(1, "days").toISOString(),
        },
      ],
    };
    const filterHours = {
      ...baseFilterHour,
      logicalOperator: "And",
      conditions: [
        { operator: "GreaterThanOrEqual", value: 8 },
        { operator: "LessThanOrEqual", value: 18 },
      ],
    };
    embed.current.updateFilters(models.FiltersOperations.Replace, [filterDate, filterHours]);
  }, [baseFilterDate, baseFilterHour, pageReport, pages?.length]);

  const onloaded = () => {
    console.log("Report has loaded");
  };

  const onRendered = async () => {
    console.log("Report has rendered");
    let pages = await embed.current.getPages();
    setPages(pages);
  };

  const onError = (event) => {
    if (event) {
      console.error("Report has error", event.detail);
    }
  };

  const eventHandlersMap = new Map([
    ["loaded", onloaded],
    ["rendered", onRendered],
    ["error", onError],
  ]);

  const onChangePage = async (e) => {
    await embed.current.setPage(pages?.find((p) => p.displayName === e.target.value)?.name);
    setPageReport(e.target.value);
  };

  const onChangeHour = async (value) => {
    await embed.current?.updateFilters(models.FiltersOperations.Replace, [
      {
        ...baseFilterHour,
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: value === "day" || value === "morning" ? 8 : 13,
          },
          { operator: "LessThanOrEqual", value: value === "morning" ? 13 : 18 },
        ],
      },
    ]);
    setValueDuration(value);
  };

  const onChangeDate = async (value) => {
    await embed.current?.updateFilters(models.FiltersOperations.Replace, [
      {
        ...baseFilterDate,
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value:
              value === "1S"
                ? moment().startOf("day").subtract(1, "week").toISOString()
                : value === "1M"
                ? moment().startOf("day").subtract(1, "month").toISOString()
                : moment().startOf("day").subtract(3, "month").toISOString(),
          },
          {
            operator: "LessThanOrEqual",
            value: moment().endOf("day").subtract(1, "days").toISOString(),
          },
        ],
      },
    ]);
    form.resetFields();
    setValueInterval(value);
  };

  const onChangeDateSelector = async (value) => {
    await embed.current?.updateFilters(models.FiltersOperations.Replace, [
      {
        ...baseFilterDate,
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: moment(value[0]).startOf("day").toISOString(),
          },
          { operator: "LessThanOrEqual", value: moment(value[1]).endOf("day").toISOString() },
        ],
      },
    ]);
  };

  if (!report) return null;
  return (
    <div>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          Analytics
        </Typography.Title>
      </TitleSource>
      <div style={{ margin: "16px 8px 8px 8px" }}>
        <div style={{ overflowX: "auto", zIndex: "999", display: "flex", height: "60px" }}>
          {report?.pages.map((page, index) => (
            <Checkbox
              key={index}
              value={page}
              text={page}
              onClick={onChangePage}
              checked={pageReport === page}
            />
          ))}
        </div>
        <Form
          size="middle"
          form={form}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {!isEmpty(report?.filters?.find((f) => f.type === "HOUR")) && (
            <article style={{ marginLeft: "8px" }}>
              <div style={{ display: "flex", flexFlow: "column" }}>
                <Typography.Text strong>{t("Duration")}</Typography.Text>
                <Typography.Text style={{ width: "340px" }}>{t("TypeDuration")}</Typography.Text>
              </div>
              <Segmented
                style={{ marginTop: "8px" }}
                value={valueDuration}
                options={[
                  {
                    label: <div style={{ margin: "0" }}>{t("spas.period.DAY")}</div>,
                    value: "day",
                  },
                  {
                    label: <div style={{ margin: "0" }}>{t("spas.period.MORNING")}</div>,
                    value: "morning",
                  },
                  {
                    label: <div style={{ margin: "0" }}>{t("spas.period.AFTERNOON")}</div>,
                    value: "afternoon",
                  },
                ]}
                onChange={onChangeHour}
              />
            </article>
          )}

          {!isEmpty(report?.filters?.find((f) => f.type === "DATE")) && (
            <article style={{ marginLeft: "8px" }}>
              <div style={{ display: "flex", flexFlow: "column" }}>
                <Typography.Text strong>{t("Interval")}</Typography.Text>
                <Typography.Text style={{ width: "340px" }}>{t("IntervalDate")}</Typography.Text>
              </div>
              <div style={{ display: "flex", marginTop: "8px" }}>
                <Segmented
                  style={{ marginRight: "8px" }}
                  value={valueInterval}
                  options={[
                    { label: <p style={{ margin: "0" }}>1S</p>, value: "1S" },
                    {
                      label: <p style={{ margin: "0" }}>1M</p>,
                      value: "1M",
                    },
                    {
                      label: <p style={{ margin: "0" }}>3M</p>,
                      value: "3M",
                    },
                  ]}
                  onChange={onChangeDate}
                />
                <Form.Item size="middle" style={{ margin: "0 8px" }} name="dateSelector">
                  <RangePicker
                    placeholder={["date début", "date fin"]}
                    format="DD-MM-YYYY"
                    size="middle"
                    onChange={onChangeDateSelector}
                    disabledDate={(date) => date.isSameOrAfter(moment(), "date")}
                  />
                </Form.Item>
              </div>
            </article>
          )}
        </Form>
      </div>
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          embedUrl: report?.embedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: report?.embedToken,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
            },
            commands: [
              {
                includeExclude: {
                  displayOption: models.CommandDisplayOption.Hidden,
                },
              },
            ],
          },
        }}
        eventHandlers={eventHandlersMap}
        cssClassName={styles["report-style-class"]}
        getEmbeddedComponent={(embedObject) => {
          embed.current = embedObject;
        }}
      />
    </div>
  );
};

export default PowerBi;
