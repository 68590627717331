import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Typography, Spin } from "antd";

// import css
import styles from "./SettingsUI.module.less";

// import component
import Glyph from "../../../Common/Glyph/Glyph";
import BlindDimmer from "./Dimmer/BlindDimmer";
import TiltDimmer from "./Dimmer/TiltDimmer";
import BlindUpDown from "./UpAndDown/BlindUpDown";

const SettingsBlind = ({ extra, zone, isAuto, setIsAuto, comfort, selectedRoom }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Card
      className={styles["SettingsUI"]}
      title={
        <Typography.Title level={5} style={{ display: "flex", alignItems: "center" }}>
          <Glyph name="shutter" className={styles["SettingsUI__pictos"]} />
          {zone ? t("Blind") + ` - zone ${zone.label}` : t("Blinds")}
        </Typography.Title>
      }
      extra={
        isLoading ? (
          <Spin />
        ) : status === "error" ? (
          <Glyph name="cancel" className={styles["SettingsUI__error"]} />
        ) : status === "success" ? (
          <Glyph name="check_circle" className={styles["SettingsUI__success"]} />
        ) : (
          extra
        )
      }
      style={{ padding: "15px", margin: "0 15px" }}
    >
      {comfort?.blind?.options.includes("dimmer") ? (
        <BlindDimmer
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          zone={zone}
          isAuto={isAuto}
          setIsAuto={setIsAuto}
          isLoading={isLoading}
          selectedRoomNomap={selectedRoom}
        />
      ) : comfort?.blind?.options.includes("openClose") ? (
        <BlindUpDown
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          zone={zone}
          isAuto={isAuto}
          setIsAuto={setIsAuto}
          isLoading={isLoading}
          selectedRoomNomap={selectedRoom}
        />
      ) : comfort?.blind?.options.includes("tilt") ? (
        <>
          <BlindDimmer
            setStatus={setStatus}
            setIsLoading={setIsLoading}
            zone={zone}
            isAuto={isAuto}
            setIsAuto={setIsAuto}
            isLoading={isLoading}
            selectedRoomNomap={selectedRoom}
          />
          <TiltDimmer
            setStatus={setStatus}
            setIsLoading={setIsLoading}
            zone={zone}
            isAuto={isAuto}
            setIsAuto={setIsAuto}
            isLoading={isLoading}
            selectedRoomNomap={selectedRoom}
          />
        </>
      ) : null}
    </Card>
  );
};

export default SettingsBlind;
