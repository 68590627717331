import { Card, Col, DatePicker, Form, Row, Select, Typography } from "antd";
import frFR from "antd/lib/locale/fr_FR";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import Glyph from "../../../../../Common/Glyph/Glyph";
import styles from "../../../SpaceBooking.module.less";

import { isNil } from "ramda";

const { Option } = Select;

const CreateStepsDate = ({ visible }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const slots = useSelector((state) => state.spaceServiceWS.slots);
  const workplaceAvailable = useSelector((state) => state.spaceServiceWS.workplaceCount);

  const workingDays = [1, 2, 3, 4, 5];

  return (
    <>
      <Typography.Paragraph strong type="secondary">
        {t("spas.request.site")}
      </Typography.Paragraph>
      <Card style={{ marginBottom: 10 }}>
        <Row gutter={[20, 0]}>
          <Col>
            <Glyph name="business" className={"secondary"} />
          </Col>
          <Col flex={"auto"}>
            <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
              <Select
                className="accent"
                bordered={false}
                size={"middle"}
                style={{
                  width: "100%",
                  marginTop: -8,
                  marginBottom: -8,
                  backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                }}
                options={sites
                  ?.filter((site) => !!site?.spaceBooking)
                  ?.filter((s) => !isNil(s?.sectors))
                  .map((s, index) => ({
                    label: s.title,
                    value: s.id,
                    key: index,
                  }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue(["siteId"]) ? (
            <Form.Item noStyle name={["sectorId"]} rules={[{ required: true }]}>
              <Select
                size={"large"}
                className={clsx(styles.selectSector)}
                style={{
                  width: "100%",
                  display:
                    sites?.find((c) => c?.id === getFieldValue(["siteId"]))?.spaceBooking?.type ===
                    3
                      ? "block"
                      : "none",
                }}
              >
                {(sites.find((s) => s.id === getFieldValue(["siteId"]))?.sectors || [])
                  .filter((s) => s.canBook)
                  ?.sort((a, b) => (a?.title < b?.title ? -1 : 1))
                  .map((s, index) => (
                    <Option
                      key={index}
                      value={s.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <p style={{ margin: "0" }}>{s.title}</p>
                        <p style={{ margin: "0" }}>
                          {
                            workplaceAvailable?.availability?.find((a) => a.sectorId === s.id)
                              ?.count
                          }{" "}
                          {t("spas.workplace.free")}
                        </p>
                      </div>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Typography.Title style={{ marginTop: "16px" }} level={4}>
        {t("spas.request.startDate.title")}
      </Typography.Title>
      <Card>
        <Row>
          <Col flex={"auto"}>
            <Typography.Text strong>{t("spas.request.startDate.helper")}</Typography.Text>
          </Col>
          <Col>
            <Form.Item noStyle name={["startDate"]} rules={[{ required: true }]}>
              <DatePicker
                locale={frFR}
                style={{ margin: -8 }}
                format="DD MMM YYYY"
                disabledDate={(date) =>
                  date.isBefore(moment(), "date") ||
                  !!slots?.find((s) => s?.date === moment(date).format("YYYY-MM-DD")) ||
                  !workingDays.includes(date.isoWeekday())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Typography.Title style={{ marginTop: "16px" }} level={4}>
        {t("spas.request.period.title")}
      </Typography.Title>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item noStyle name={["period"]} rules={[{ required: true }]}>
            <Select
              size={"large"}
              style={{ width: "100%" }}
              placeholder={t("spas.request.period.helper")}
              options={[
                {
                  label: t("spas.period.DAY"),
                  value: "DAY",
                },
                {
                  label: t("spas.period.MORNING"),
                  value: "MORNING",
                },
                {
                  label: t("spas.period.AFTERNOON"),
                  value: "AFTERNOON",
                },
              ]}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Typography.Title style={{ marginTop: "16px" }} level={4}>
        {t("spas.request.recurrence")}
      </Typography.Title>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item noStyle name={["recurrence"]} rules={[{ required: false }]}>
            <Select
              size={"large"}
              style={{ width: "100%" }}
              placeholder={t("spas.recurrence.NONE")}
              options={[
                {
                  label: t("spas.recurrence.NONE"),
                  value: "NONE",
                },
                {
                  label: t("spas.recurrence.EVERY_DAY"),
                  value: "EVERY_DAY",
                },
                {
                  label: t("spas.recurrence.EVERY_WEEK", {
                    day: moment(getFieldValue(["startDate"])).format("dddd"),
                  }),
                  value: "EVERY_WEEK",
                },
                {
                  label: t("spas.recurrence.EVERY_2_WEEK", {
                    day: moment(getFieldValue(["startDate"])).format("dddd"),
                  }),
                  value: "EVERY_2_WEEK",
                },
              ]}
            />
          </Form.Item>
        )}
      </Form.Item>

      <br />
      <br />
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => prev.recurrence !== next.recurrence}
        rules={[{ required: true }]}
      >
        {({ getFieldValue }) =>
          getFieldValue("recurrence") && getFieldValue("recurrence") !== "NONE" ? (
            <Card>
              <Row>
                <Col flex={"auto"}>
                  <Typography.Text strong>
                    {t("spas.request.endDate", { date: "" })}
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item noStyle name={["endDate"]} rules={[{ required: true }]}>
                    <DatePicker
                      style={{ margin: -8 }}
                      format="DD MMM YYYY"
                      disabledDate={(date) => date.isBefore(getFieldValue(["startDate"]))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          ) : null
        }
      </Form.Item>
    </>
  );
};

export default CreateStepsDate;
