import { Button, message, Slider } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import redux
import { setLighting, getComfortLighting } from "../../../../../services/redux/services/ConfortWS";
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";

// import component
import Glyph from "../../../../Common/Glyph/Glyph";

// import css
import styles from "../SettingsUI.module.less";

import { useTranslation } from "react-i18next";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

const LightDimmer = ({
  setIsLoading,
  setStatus,
  zone,
  isAuto,
  setIsAuto,
  isLoading,
  selectedRoomNomap,
}) => {
  const { t } = useTranslation();
  const selectedRoom = useSelectedRoom();
  const dispatch = useDispatch();

  const comforts = useSelector((state) => state.clientsWS.lighting);

  const [light, setLight] = useState(0);

  useEffect(() => {
    if (zone) {
      if (comforts[zone]?.result?.Data) {
        setLight(comforts[zone].result.Data);
      }
    } else {
      if (comforts[0]?.result?.Data) {
        setLight(comforts[0].result.Data);
      }
    }
  }, [comforts, zone]);

  useEffect(() => {
    if (isAuto) {
      setLight(50);
    }
  }, [isAuto]);

  const dispatchLight = (value) => {
    setIsLoading(true);
    logEvent(analytics, "smart_control_command_light", {
      zone: zone !== undefined ? "zone" : "total",
      type_light: "dimmer",
    });
    dispatch(
      setLighting({
        roomId: selectedRoomNomap?.id || selectedRoom.id,
        zone: zone ? zone : 0,
        command: "SET",
        value: value,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
        selectedRoom?.features?.comfort?.light?.zones?.map((zoneLight) =>
          dispatch(getComfortLighting({ roomId: selectedRoom.id, zone: zoneLight.id }))
            .unwrap()
            .catch(() => {
              message.error(t("ErrorGetLight"), 5);
            }),
        );
      })
      .catch(() => {
        message.error(t("ErrorSetLight"), 5);
        setStatus("error");
        if (zone) {
          if (comforts[zone]?.result?.Data) {
            setLight(comforts[zone].result.Data);
          }
        } else {
          if (comforts[0]?.result?.Data) {
            setLight(comforts[0].result.Data);
          }
        }
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const lightOn = () => {
    logEvent(analytics, "smart_control_command_light", {
      type_light: "dimmer",
    });
    dispatchLight(100);
  };

  const lightOff = () => {
    logEvent(analytics, "smart_control_command_light", {
      type_light: "dimmer",
    });
    dispatchLight(0);
  };

  return (
    <div className={styles["SettingsUI__content"]}>
      <Button
        icon={<Glyph name="lightbulb_off" />}
        size="default"
        type="default"
        onClick={() => {
          lightOff();
          setIsAuto(false);
        }}
        style={{ borderRadius: "100%" }}
        disabled={isLoading}
      ></Button>
      <Slider
        style={{ filter: isAuto ? "saturate(0)" : "saturate(1)" }}
        max={100}
        min={0}
        step={10}
        value={light}
        onChange={setLight}
        onAfterChange={(value) => {
          dispatchLight(value);
          setIsAuto(false);
        }}
        disabled={isLoading}
      />
      <Button
        icon={<Glyph name="lightbulb_on" />}
        type="default"
        size="default"
        onClick={() => {
          lightOn();
          setIsAuto(false);
        }}
        style={{ borderRadius: "100%" }}
        disabled={isLoading}
      ></Button>
    </div>
  );
};

export default LightDimmer;
