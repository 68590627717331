import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  orders: null,
  services: [],
  allServices: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const AllOrders = thunk(
  "catering/AllOrders",
  async ({ page, size, inprogress }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "catering");
    const allOrders = await DWMConnector.AllOrders(viewId.id, page, size, inprogress);
    allOrders.tickets = allOrders?.tickets?.map((room) => ({
      ...room,
      resources: state.clientsWS.campus.mapData.resources.find((r) => r.id === room.roomId),
    }));
    return allOrders;
  },
);

EXTRA_REDUCERS[AllOrders.fulfilled] = (state, action) => {
  state.orders = action.payload;
};

export const servicesCatering = thunk(
  "catering/servicesCatering",
  async ({ roomId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "catering");
    const servicesCatering = await DWMConnector.servicesCatering(viewId.id, roomId);
    return servicesCatering;
  },
);

EXTRA_REDUCERS[servicesCatering.fulfilled] = (state, action) => {
  state.services = action.payload;
};

export const allServicesCatering = thunk(
  "catering/allServicesCatering",
  async (_, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "catering");
    const allServicesCatering = await DWMConnector.allServicesCatering(viewId.id);
    return allServicesCatering;
  },
);

EXTRA_REDUCERS[allServicesCatering.fulfilled] = (state, action) => {
  state.allServices = action.payload;
};

export const createPresation = thunk(
  "catering/createPresation",
  async (
    { roomId, ticketType, categoryId, startDate, endDate, values, no_Attendees, total },
    { getState, dispatch },
  ) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "catering");
    const createPresation = await DWMConnector.createPresation(
      viewId.id,
      roomId,
      ticketType,
      categoryId,
      startDate,
      endDate,
      values,
      no_Attendees,
      total,
    );
    await dispatch(AllOrders({ page: 1, size: 10, inprogress: false }));
    return createPresation;
  },
);

EXTRA_REDUCERS[servicesCatering.fulfilled] = (state, action) => {
  state.services = action.payload;
};

export const deletePrestation = thunk(
  "catering/deletePrestation",
  async ({ ticketId }, { getState, dispatch }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "catering");
    await DWMConnector.deletePrestation(viewId.id, ticketId);
    await dispatch(AllOrders({ page: 1, size: 10, inprogress: false }));
  },
);

export const updatePrestation = thunk(
  "catering/updatePrestation",
  async (
    { ticketId, roomId, startDate, endDate, values, no_Attendees, total },
    { getState, dispatch },
  ) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "catering");
    await DWMConnector.updatePrestation(
      viewId.id,
      ticketId,
      roomId,
      startDate,
      endDate,
      values,
      no_Attendees,
      total,
    );
    await dispatch(AllOrders({ page: 1, size: 10, inprogress: false }));
  },
);

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const cateringSlice = createSlice({
  name: "catering",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default cateringSlice.reducer;
