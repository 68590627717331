import { Layout, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TitleSource } from "../../Common/Teleporters/Title";
import styles from "./News.module.less";
import { useSelector } from "react-redux";
import NewsList from "./NewsList";
import NewsDetails from "./NewsDetails";
import TopicsList from "./TopicsList";
import EditorList from "./Editor/EditorList";
import EditorNews from "./Editor/EditorNews";
import "react-quill/dist/quill.snow.css";

const News = () => {
  const { t } = useTranslation();
  const { sectionId } = useParams();

  const views = useSelector((state) => state.userWS.userData?.views);

  return (
    <>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          {t(views?.find((v) => v?.id === parseInt(sectionId))?.title, {
            ns: "csv",
          })}
        </Typography.Title>
      </TitleSource>
      <Switch>
        <Route path="/news/:sectionId/editor" exact>
          <Layout className={styles.dashboardContainer}>
            <Layout>
              <EditorList />
            </Layout>
          </Layout>
        </Route>
        <Route path="/news/:sectionId/editor/:id" exact>
          <EditorNews />
        </Route>
        <Route path="/news/:sectionId/new" exact>
          <EditorNews />
        </Route>
        <Route path="/news/:sectionId" exact>
          <Layout className={styles.dashboardContainer}>
            <Layout>
              <NewsList />
            </Layout>
          </Layout>
        </Route>
        <Route path="/news/:sectionId/:topicId" exact>
          <Layout className={styles.dashboardContainer}>
            <Layout>
              <TopicsList />
            </Layout>
          </Layout>
        </Route>
        <Route path="/news/:sectionId/:topicId/:id" exact>
          <NewsDetails />
        </Route>
      </Switch>
    </>
  );
};

export default News;
