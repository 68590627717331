import moment from "moment";

const ConvertTime = (TimeInSecond) => {
  const time = moment.duration(TimeInSecond, "seconds");

  if (time.asMinutes() < 1) {
    return "Moins d'une minute";
  } else if (time.asHours() < 1) {
    return `${Math.floor(time.asMinutes())} mins`;
  } else {
    const hours = Math.floor(time.asHours());
    const minutes = time.minutes();
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  }
};

export default ConvertTime;
