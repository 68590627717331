export function getImageLightness(imageSrc, callback) {
  let downloadedImg = new Image();
  downloadedImg.crossOrigin = "Anonymous";
  downloadedImg.addEventListener("load", imageReceived, false);
  downloadedImg.src = imageSrc;
  // var img = document.createElement("img");
  // img.src = imageSrc;
  // img.style.display = "none";
  // console.log(img.crossOrigin)
  // img.crossOrigin = "Anonymous";

  // document.body.appendChild(img);

  var colorSum = 0;

  function imageReceived() {
    // create canvas
    var canvas = document.createElement("canvas");
    canvas.width = downloadedImg.width;
    canvas.height = downloadedImg.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(downloadedImg, 0, 0);

    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;
    var r, g, b, avg;

    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];

      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }

    var brightness = Math.floor(colorSum / (this.width * this.height));
    callback(brightness);
  }
}
