import { useSelector } from "react-redux";
import Header from "../Header/Header";

const Privacypolicy = () => {
  const privacyPolicy = useSelector((state) => state.contractPolicyWS.privacyPolicy);

  return (
    <>
      <Header />
      <div
        style={{ width: "90vw", margin: "20px auto" }}
        dangerouslySetInnerHTML={{
          __html: privacyPolicy,
        }}
      ></div>
    </>
  );
};

export default Privacypolicy;
