import axios from "axios";

const errorData = (err) => ({
  status: err?.response?.status,
  code: err?.response?.status + "",
  message: err.message,
  data: err?.response?.data,
  stack: err.stack,
});

class SmartControlConnector {
  constructor() {
    this.baseURL = process.env.REACT_APP_DBRM_URL;
    this.token = undefined;

    this.axiosInst = axios.create({
      baseURL: `${this.baseURL}/api`,
    });

    // Add interceptors
    this.axiosInst.interceptors.request.use((config) => {
      let authData = localStorage.getItem("auth_data_dwm");
      authData = JSON.parse(authData) || {};
      config.headers.Authorization = `Bearer ${authData.token}`;
      config.headers["client-apikey"] = process.env.REACT_APP_DBRM_APIKEY;
      return config;
    });
  }

  init({ token }) {
    this.token = token;
  }

  /**
  |--------------------------------------------------
  | ROOMS
  |--------------------------------------------------
  */
  async listRooms(siteId, locale) {
    try {
      const res = await this.axiosInst.get(`/intranet/sites/${siteId}`, { params: { locale } });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | CONFORT
  |--------------------------------------------------
  */

  /** ACOFFSET */
  async getComfortAcOffset(roomId, zone) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/ac/offset",
        },
        params: {
          zone: zone,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async setAcOffset(roomId, zone = 0, command, repeats) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {
          acZone: zone,
          command: command,
          repeats: repeats,
        },
        {
          headers: {
            "comfort-path": "/ac/offset",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /** ACFANSPEED */
  async getComfortAcFanSpeed(roomId, zone) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/ac/fanSpeed",
        },
        params: {
          zone: zone,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async setAcFanSpeed(roomId, zone, command) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {
          acZone: zone,
          command: command,
        },
        {
          headers: {
            "comfort-path": "/ac/fanSpeed",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async setAcMode(roomId, zone, command) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {
          acZone: zone,
          command: command,
        },
        {
          headers: {
            "comfort-path": "/ac/mode",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /** LIGHTNING */
  async getComfortLighting(roomId, zone) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/lighting",
        },
        params: {
          zone: zone,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async setLighting(roomId, zone, command, value) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {
          zone: zone,
          command: command,
          value: value,
        },
        {
          headers: {
            "comfort-path": "/lighting",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /** STORES */
  async getComfortBlinds(roomId, zone) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/blindShutter",
        },
        params: {
          group: zone,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async getComfortBlindsAngle(roomId, zone) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/blindShutter/angle",
        },
        params: {
          group: zone,
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async setBlinds(roomId, zone, command, value) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {
          group: zone,
          command: command,
          value: value,
        },
        {
          headers: {
            "comfort-path": "/blindShutter",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async setBlindsAngle(roomId, zone, command, value) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {
          group: zone,
          command: command,
          value: value,
        },
        {
          headers: {
            "comfort-path": "/blindShutter/angle",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /** A SUPPPRIMER LUS TARD */
  async getZonesLighting(roomId) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/lighting/zones",
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  async getZonesBlind(roomId) {
    try {
      const res = await this.axiosInst.get(`/comfort/${roomId}`, {
        headers: {
          "comfort-path": "/blindShutter/groups",
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /** MODE AUTO */
  async setModeAuto(roomId) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        {},
        {
          headers: {
            "comfort-path": "/scenes/auto",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }

  /** SIGNALER PRESENCE */
  async setVirtualRemote(roomId, command) {
    try {
      await this.axiosInst.post(
        `/comfort/${roomId}`,
        { command: command },
        {
          headers: {
            "comfort-path": "/virtualRemote",
          },
        },
      );
    } catch (error) {
      console.error(error);
      throw errorData(error);
    }
  }
}

export default new SmartControlConnector();
