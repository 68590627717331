import { Image, Typography } from "antd";

import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { ReactComponent as ErrorUndraw } from "../../../assets/svg/error.svg";

import "./Password.less";
import { useTranslation } from "react-i18next";

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="password__layout">
      <div className="password__bg">
        <Image src={Logo} style={{ height: "58px" }} preview={false} />
        <Typography.Title
          level={4}
          className="processing"
          style={{ marginBottom: "40px", marginTop: "20px" }}
        >
          {t("Public.error.activated")}
        </Typography.Title>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ErrorUndraw />
        </div>
      </div>
    </div>
  );
};

export default Error;
