import { createAsyncThunk as thunk, createSelector, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import DWMConnector from "../../api/DWMConnector";
import { selectedCampus } from "./UserWS";

const INITIAL_STATE = {
  configuration: null,
  date: moment().format("YYYY-MM-DD"),

  events: [],
  selectedEventId: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// ROOM FASTBOOKING ///////////////////////////////
export const getConfiguration = thunk("facilities/getConfiguration", async (_, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state).id;
  const config = await DWMConnector.getFacilitiesConfig(siteId);
  return config;
});
EXTRA_REDUCERS[getConfiguration.fulfilled] = (state, action) => {
  state.configuration = action.payload;
};

export const updateCategories = thunk(
  "facilities/updateCategories",
  async (categories, { dispatch, getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const res = await DWMConnector.updateFacilitiesCategories(siteId, categories);
    await dispatch(listEvents());
    return res;
  },
);
EXTRA_REDUCERS[updateCategories.fulfilled] = (state, action) => {
  state.configuration.categories = action.payload;
};

export const listEvents = thunk(
  "facilities/listEvents",
  async ({ resident = false } = {}, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { date } = state.facilitiesWS;
    const events = await DWMConnector.listFacilitiesEvents(siteId, {
      date: resident ? moment().format("YYYY-MM-DD") : date,
      resident,
    });
    return events.events;
  },
);
EXTRA_REDUCERS[listEvents.fulfilled] = (state, action) => {
  state.events = action.payload;
};
EXTRA_REDUCERS[listEvents.rejected] = (state, action) => {
  state.events = [];
};

export const updateEvent = thunk("facilities/updateEvent", async (data, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state).id;
  const { selectedEventId: eventId } = state.facilitiesWS;
  const event = await DWMConnector.updateFacilitiesEvent(siteId, eventId, data);
  return event;
});
EXTRA_REDUCERS[updateEvent.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};

export const createTab = thunk("facilities/createTab", async ({ ...data }, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state).id;
  const { selectedEventId: eventId } = state.facilitiesWS;
  const event = await DWMConnector.createFacilitiesEventTab(siteId, eventId, data);
  return event;
});
EXTRA_REDUCERS[createTab.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};
export const updateTab = thunk("facilities/updateTab", async ({ tabId, ...data }, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state).id;
  const { selectedEventId: eventId } = state.facilitiesWS;
  const event = await DWMConnector.updateFacilitiesEventTab(siteId, eventId, tabId, data);
  return event;
});
EXTRA_REDUCERS[updateTab.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};
export const deleteTab = thunk("facilities/deleteTab", async ({ tabId }, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state).id;
  const { selectedEventId: eventId } = state.facilitiesWS;
  await DWMConnector.deleteFacilitiesEventTab(siteId, eventId, tabId);
  return { eventId, tabId };
});
EXTRA_REDUCERS[deleteTab.fulfilled] = (state, action) => {
  const { eventId, tabId } = action.payload;
  const eIdx = state.events.findIndex((e) => e._id === eventId);
  const tIdx = state.events[eIdx]?.tabs.findIndex((t) => t._id === tabId);

  if (typeof tIdx === "number" && tIdx !== -1) {
    state.events[eIdx].tabs.splice(tIdx, 1);
  }
};
export const subscribeTab = thunk(
  "facilities/subscribeTab",
  async ({ tabId, subscribe }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    const event = await DWMConnector.subscribeFacilitiesEventTab(siteId, eventId, tabId, subscribe);
    return event;
  },
);
EXTRA_REDUCERS[subscribeTab.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};

export const createSection = thunk(
  "facilities/createSection",
  async ({ tabId, ...data }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    const event = await DWMConnector.createFacilitiesEventSection(siteId, eventId, tabId, data);
    return { tabId, event };
  },
);
EXTRA_REDUCERS[createSection.fulfilled] = (state, action) => {
  // state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
  const eIdx = state.events.findIndex((e) => e._id === action.payload.event._id);
  if (eIdx === -1) return;
  state.events[eIdx] = action.payload.event;
  const tIdx = state.events[eIdx].tabs.findIndex((t) => t._id === action.payload.tabId);
  if (tIdx === -1) return;
  const sectionsLength = state.events[eIdx].tabs[tIdx].sections.length;
  state.events[eIdx].tabs[tIdx].sections[sectionsLength - 1].editFirst = true;
};
export const updateSection = thunk(
  "facilities/updateSection",
  async ({ tabId, sectionId, ...data }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    const event = await DWMConnector.updateFacilitiesEventSection(
      siteId,
      eventId,
      tabId,
      sectionId,
      data,
    );
    return event;
  },
);
EXTRA_REDUCERS[updateSection.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};
export const deleteSection = thunk(
  "facilities/deleteSection",
  async ({ tabId, sectionId }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    await DWMConnector.deleteFacilitiesEventSection(siteId, eventId, tabId, sectionId);
    return { eventId, tabId, sectionId };
  },
);
EXTRA_REDUCERS[deleteSection.fulfilled] = (state, action) => {
  const { eventId, tabId, sectionId } = action.payload;
  const eIdx = state.events.findIndex((e) => e._id === eventId);
  const tIdx = state.events[eIdx]?.tabs.findIndex((t) => t._id === tabId);
  const sIdx = state.events[eIdx]?.tabs[tIdx]?.sections.findIndex((s) => s._id === sectionId);

  if (typeof sIdx === "number" && sIdx !== -1) {
    state.events[eIdx].tabs[tIdx].sections.splice(sIdx, 1);
  }
};

export const postComment = thunk(
  "facilities/postComment",
  async ({ tabId, ...data }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    const event = await DWMConnector.postFacilitiesEventComment(siteId, eventId, tabId, data);
    return event;
  },
);
EXTRA_REDUCERS[postComment.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};

export const uploadFiles = thunk(
  "facilities/uploadFiles",
  async ({ tabId, sectionId, files, onUploadProgress }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    const event = await DWMConnector.uploadFacilitiesEventFiles(
      siteId,
      eventId,
      tabId,
      sectionId,
      files,
      onUploadProgress,
    );
    return event;
  },
);
EXTRA_REDUCERS[uploadFiles.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};
export const deleteFile = thunk(
  "facilities/deleteFile",
  async ({ tabId, sectionId, fileName }, { getState }) => {
    const state = getState();
    const siteId = selectedCampus(state).id;
    const { selectedEventId: eventId } = state.facilitiesWS;
    const event = await DWMConnector.deleteFacilitiesEventFile(
      siteId,
      eventId,
      tabId,
      sectionId,
      fileName,
    );
    return event;
  },
);
EXTRA_REDUCERS[deleteFile.fulfilled] = (state, action) => {
  state.events = state.events.map((e) => (e._id === action.payload._id ? action.payload : e));
};

const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: INITIAL_STATE,
  reducers: {
    setDate(state, action) {
      state.date = moment(action.payload).format("YYYY-MM-DD");
    },
    selectEvent(state, action) {
      state.selectedEventId = action.payload;
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const richFacilitiesEvents = createSelector(
  (state) => state.clientsWS.campus.mapData,
  (state) => state.facilitiesWS.events,
  (mapData, events) => {
    return events.map((e) => ({
      ...e,
      location: mapData.resources.find((r) => r.id === e.location.uid),
    }));
  },
);

export const filteredEvents = createSelector(
  richFacilitiesEvents,
  (state) => state.PreferencesManager.facilities.filters,
  (events, filters) => {
    return events.filter((e) => {
      if (filters.categories.length) if (!filters.categories.includes(e.category?.id)) return false;
      if (filters.rooms.length)
        if (
          !filters.rooms.includes(e.location?.id) &&
          !filters.rooms.includes(e.location?.categoryId)
        )
          return false;
      if (filters.organizer) if (filters.organizer.value !== e.slot.organizer?.id) return false;
      if (!filters.hidden) if (e.hidden) return false;
      if (filters.tasks) if (!e.tasks?.total || e.tasks.total === e.tasks.completed) return false;
      if (filters.new) if (e.new) return false;
      return true;
    });
  },
);

export const selectedEvent = createSelector(
  richFacilitiesEvents,
  (state) => state.facilitiesWS.selectedEventId,
  (events, selectedEventId) => {
    return events.find((e) => e._id === selectedEventId);
  },
);

export const roomsFilterOptionsSelector = createSelector(
  (state) => state.facilitiesWS.configuration?.rooms,
  (state) => state.clientsWS.campus?.mapData?.resources || [],
  (state) => state.clientsWS.campus?.mapData?.categories || [],
  (configuration, rooms, categories) => {
    if (!configuration?.length) {
      return {
        rooms: rooms.filter((r) => r.features.bookable),
        categories: categories.filter((r) => r.bookable),
      };
    }

    const toReturn = { rooms: [], categories: [] };

    for (const i of configuration) {
      const room = rooms.find((r) => r.id === i.uid);
      if (!room) continue;
      toReturn.rooms.push(room);
    }

    toReturn.categories = toReturn.rooms.reduce((acc, curr) => {
      const category = categories.find((c) => c.id === curr.categoryId);
      if (!category || acc.some((c) => c.id === category.id)) return acc;
      return [...acc, category];
    }, []);

    return toReturn;
  },
);

export const { setDate, selectEvent } = facilitiesSlice.actions;
export default facilitiesSlice.reducer;
