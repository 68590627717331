import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import languages from "./lang";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ["translation", "csv"],
    defaultNS: "translation",

    resources: languages,
    supportedLngs: ["fr", "en", "nl"],
    fallbackLng: "en",
    debug: true,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
