import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  planning: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// PLANNING ///////////////////////////////

export const getPlanning = thunk(
  "planning/getPlanning",
  async ({ locale, startDate, endDate }, { getState }) => {
    const state = getState();
    const planningId = state.userWS.userData.views.find((v) => v.type === "planning");
    const planning = await DWMConnector.getPlanning(planningId.id, locale, startDate, endDate);
    planning.slots.items = planning.slots.items.map((item) => ({
      ...item,
      resources: item.locations.map((location) =>
        state.clientsWS.campus.mapData.resources.find((r) => r.id === location.resourceId),
      ),
    }));
    return planning;
  },
);

EXTRA_REDUCERS[getPlanning.fulfilled] = (state, action) => {
  state.planning = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const planningSlice = createSlice({
  name: "planning",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default planningSlice.reducer;
