/* eslint-disable no-unused-vars */
import { Button, Col, Form, message, Modal, Row, Typography } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../../../services/redux/managers/LoadingManager";
import Glyph from "../../../../Common/Glyph/Glyph";
import styles from "../../SpaceBooking.module.less";
import RequestDetails from "../../Admin/RequestDetails";
import { STEPS } from "../../SpaceBookingResident";
import {
  cancelSlot,
  getToday,
  listWorkplace,
  updateSlot,
} from "../../../../../services/redux/services/SpaceService";
import * as moment from "moment";
import RequestStepUpdate from "./Steps/RequestStepUpdate";
import SpaceWhoHere from "../../SpaceWhoHere";
import { isNil } from "ramda";
import RequestModalPresence from "./RequestModalPresence";

const RequestDetailsModal = ({ slot, onCancel, setSelectedSlot }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isLoading = useSelector(createLoadingSelector(["spaceService/cancelSlot"]));
  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const campus = useSelector((state) => state.clientsWS.campus);
  const userData = useSelector((state) => state.userWS.userData);

  const campusData = userData.campus.find((c) => c.id === campus?.mapData?.id);

  const [step, setStep] = useState(STEPS.DETAILS);
  const [infosWorkplace, setInfosWorkplace] = useState([slot?.spaceId]);
  const [showModalPresence, setShowModalPresence] = useState(false);

  const handleSubmit = (dateOnly) => {
    dispatch(cancelSlot({ id: slot.id, data: { dateOnly } }))
      .unwrap()
      .then(() => {
        message.success(t("spas.messages.cancel.success"));
        setSelectedSlot(null);
        dispatch(getToday());
      })
      .catch((error) => {
        console.error(error);
        message.error(t("spas.messages.cancel.error"));
      });
  };

  const confirm = () => {
    slot?.request?.recurrence !== "NONE"
      ? Modal.confirm({
          title: <Typography.Text strong>{t("spas.request.cancel")}</Typography.Text>,
          okText: (
            <div block loading={isLoading} disabled={isLoading}>
              {t("spas.request.actions.occurence")}
            </div>
          ),
          cancelText: (
            <div block loading={isLoading} disabled={isLoading} onClick={() => handleSubmit(false)}>
              {t("spas.request.actions.serie")}
            </div>
          ),
          onOk: () => handleSubmit(true),
          closable: true,
          maskClosable: true,
        })
      : Modal.warning({
          title: <Typography.Text strong>{t("spas.request.cancel")}</Typography.Text>,
          okText: (
            <div block loading={isLoading} disabled={isLoading} onClick={() => handleSubmit(true)}>
              {t("Confirm")}
            </div>
          ),
          closable: true,
          maskClosable: true,
        });
  };

  const handleSubmitForm = async (values) => {
    try {
      dispatch(
        updateSlot({
          ...values,
          id: slot?.request?.id,
          spaceId: !isNil(infosWorkplace) ? infosWorkplace[0] : "",
        }),
      )
        .unwrap()
        .then(() => {
          setStep(STEPS.DETAILS);
          setSelectedSlot(null);
        })
        .catch((error) => {
          console.error(error);
          message.error(t("spas.messages.create.error"));
        });
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (!isNil(slot)) {
      dispatch(
        listWorkplace({
          siteId: slot?.request?.siteId,
          sectorId: slot?.request?.sectorId,
          date: moment().format("YYYY-MM-DD"),
        }),
      );
    }
  }, [dispatch, slot]);

  useEffect(() => {
    form.resetFields();
  }, [form, slot]);

  let morning;
  let afternoon;
  let day;
  let date = moment().format("HH:mm:ss");
  if (!isNil(campusData?.spaceBooking?.morningHours) && slot?.request?.period === "MORNING") {
    morning =
      moment(date, "HH:mm:ss").isAfter(
        moment(campusData?.spaceBooking?.morningHours[0], "HH:mm:ss"),
      ) &&
      moment(date, "HH:mm:ss").isBefore(
        moment(campusData?.spaceBooking?.morningHours[1], "HH:mm:ss"),
      );
  }

  if (!isNil(campusData?.spaceBooking?.afternoonHours) && slot?.request?.period === "AFTERNOON") {
    afternoon =
      moment(date, "HH:mm:ss").isAfter(
        moment(campusData?.spaceBooking?.afternoonHours[0], "HH:mm:ss"),
      ) &&
      moment(date, "HH:mm:ss").isBefore(
        moment(campusData?.spaceBooking?.afternoonHours[1], "HH:mm:ss"),
      );
  }

  if (!isNil(campusData?.spaceBooking?.afternoonHours) && slot?.request?.period === "DAY") {
    day =
      moment(date, "HH:mm:ss").isAfter(
        moment(campusData?.spaceBooking?.morningHours[0], "HH:mm:ss"),
      ) &&
      moment(date, "HH:mm:ss").isBefore(
        moment(campusData?.spaceBooking?.afternoonHours[1], "HH:mm:ss"),
      );
  }

  return (
    <>
      {!!slot && (
        <>
          <Modal
            destroyOnClose={true}
            className={clsx(styles.modal)}
            open={true}
            onCancel={() => {
              onCancel();
              setStep(STEPS.DETAILS);
            }}
            title={
              step === STEPS.DETAILS ? (
                <Typography.Text strong>{moment(slot?.date).format("DD MMM YYYY")}</Typography.Text>
              ) : (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => setStep(STEPS.DETAILS)}
                >
                  <div style={{ cursor: "pointer", marginRight: "8px" }}>
                    <Glyph name="arrow_back_ios" />
                  </div>
                  <Typography.Text strong>{t("spas.request.update")}</Typography.Text>
                </div>
              )
            }
            width={1000}
            bodyStyle={{ padding: "0" }}
            okButtonProps={{ block: true }}
            cancelButtonProps={{ block: true }}
            footer={[
              !moment(slot?.date).isBefore(moment(), "date") ? (
                <>
                  <Button
                    key="cancel"
                    block
                    ghost
                    loading={isLoading}
                    disabled={isLoading}
                    style={{ width: "350px" }}
                    onClick={confirm}
                  >
                    {t("spas.request.actions.cancel")}
                  </Button>
                  {sites?.find((c) => c?.id === form.getFieldValue(["siteId"]))?.spaceBooking
                    ?.type === 3 &&
                    moment(slot?.date).format("L") === moment().format("L") &&
                    !slot?.confirmed &&
                    (morning || afternoon || day) && (
                      <Button
                        key="confirm"
                        block
                        type="primary"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ width: "350px" }}
                        icon={<Glyph name="done" />}
                        onClick={() => setShowModalPresence(true)}
                      >
                        {t("spas.request.confirm.presence")}
                      </Button>
                    )}
                  {step === STEPS.DETAILS &&
                    sites?.find((c) => c?.id === form.getFieldValue(["siteId"]))?.spaceBooking
                      ?.type !== 3 &&
                    form.getFieldValue(["siteId"]) && (
                      <Button
                        key="update"
                        block
                        type="primary"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ width: "350px" }}
                        onClick={() => {
                          setStep(STEPS.UPDATE);
                        }}
                      >
                        {t("spas.request.actions.updateReservation")}
                      </Button>
                    )}
                  {step === STEPS.UPDATE && (
                    <Button
                      key="save"
                      block
                      type="primary"
                      loading={isLoading}
                      disabled={isLoading}
                      style={{ width: "350px" }}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      {t("spas.request.actions.saveReservation")}
                    </Button>
                  )}
                </>
              ) : null,
            ]}
          >
            <Form
              form={form}
              style={{ height: "100%" }}
              initialValues={{
                startDate: moment(slot?.request?.startDate),
                siteId: slot?.request?.siteId,
                sectorId: slot?.request?.sectorId,
                period: slot?.request?.period,
                recurrence: slot?.request?.recurrence,
                endDate: moment(slot?.request?.endDate),
              }}
              onValuesChange={(changedValues, values) => {
                if (changedValues.siteId) {
                  form.setFieldsValue({
                    sectorId: sites?.find((c) => c?.id === values?.siteId)?.sectors[0]?.id,
                  });
                }
              }}
              onFinish={handleSubmitForm}
            >
              <div style={{ display: step === STEPS.DETAILS ? "block" : "none", height: "100%" }}>
                <Row gutter={[0, 40]} style={{ width: "100%", height: "100%" }}>
                  <Col
                    span={14}
                    style={{
                      borderRight: "1px solid #a8bec3ff",
                      paddingTop: "30px",
                      paddingRight: "30px",
                      paddingLeft: "30px",
                      paddingBottom: "30px",
                    }}
                  >
                    <Col style={{ marginBottom: "10px" }}>
                      <RequestDetails.Site slot={slot} />
                    </Col>
                    {sites?.find((c) => c?.id === slot?.request?.siteId)?.spaceBooking?.type !==
                      1 && (
                      <Col style={{ marginBottom: "10px" }}>
                        <RequestDetails.Sector slot={slot} />
                      </Col>
                    )}

                    <Col style={{ marginBottom: "10px" }}>
                      <RequestDetails.Infos slot={slot} />
                    </Col>
                    <Col>
                      <RequestDetails.From slot={slot} />
                    </Col>
                  </Col>
                  <Col span={10}>
                    <SpaceWhoHere slot={slot} />
                  </Col>
                </Row>
              </div>

              <div style={{ display: step === STEPS.UPDATE ? "block" : "none", height: "100%" }}>
                <RequestStepUpdate slot={slot} />
              </div>
            </Form>
          </Modal>
          <RequestModalPresence
            visible={showModalPresence}
            onCancel={() => setShowModalPresence(false)}
            slot={slot}
            close={onCancel}
          />
        </>
      )}
    </>
  );
};

export default RequestDetailsModal;
