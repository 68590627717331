import { combineReducers } from "redux";

import LoadingManager from "./managers/LoadingManager";
import PreferencesManager from "./managers/PreferencesManager";

import clientsWS from "./services/ClientsWS";
import confortWS from "./services/ConfortWS";
import contractPolicyWS from "./services/ContractPolicyWS";
import facilitiesWS from "./services/FacilitiesWS";
import fastbookingWS from "./services/FastbookingWS";
import newsWS from "./services/NewsWS";
import occupancyWS from "./services/OccupancyWS";
import placesInfosWS from "./services/PlacesInfosWS";
import planningWS from "./services/PlanningWS";
import powerBiWS from "./services/PowerBiWS";
import realTimeWS from "./services/RealTimeWS";
import spaceServiceWS from "./services/SpaceService";
import spaceServiceAdminWS from "./services/SpaceServiceAdmin";
import ticketingWS from "./services/TicketingWS";
import transfertWS from "./services/TransfertWS";
import userWS from "./services/UserWS";
import visitorsWS from "./services/VisitorsWS";
import wifiWS from "./services/WifiWS";
import manageExternalWS from "./services/ManageExternalWS";
import cateringWS from "./services/CateringWS";
import mapDataWS from "./services/MapDataWS";
import groupsWS from "./services/GroupsWS";
import onboardingWS from "./services/OnboardingWS";
import transportWS from "./services/TransportWS";
import aroundMeWS from "./services/AroundMeWS";

const rootReducer = combineReducers({
  LoadingManager,
  PreferencesManager,
  clientsWS,
  userWS,
  fastbookingWS,
  newsWS,
  planningWS,
  spaceServiceWS,
  spaceServiceAdminWS,
  ticketingWS,
  realTimeWS,
  occupancyWS,
  confortWS,
  visitorsWS,
  wifiWS,
  contractPolicyWS,
  powerBiWS,
  placesInfosWS,
  transfertWS,
  facilitiesWS,
  manageExternalWS,
  cateringWS,
  mapDataWS,
  groupsWS,
  onboardingWS,
  transportWS,
  aroundMeWS,
});

const configureRootReducer = (state, action) =>
  action.type === "auth/logout" // Clear store if user logged out
    ? rootReducer(undefined, action)
    : rootReducer(state, action);

export default configureRootReducer;
