import { Button, Form, Modal, Segmented, Tooltip, Typography, message } from "antd";
import Glyph from "../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Tile from "../../../Common/Tile/Tile";
import { useDispatch, useSelector } from "react-redux";
import styles from "../News.module.less";
import { useEffect, useState } from "react";
import I18nInput from "../../../Common/Inputs/I18nInputs/I18nInput";
import {
  createTopics,
  deleteTopics,
  getNewsByTopics,
  getTopics,
  updateTopics,
} from "../../../../services/redux/services/NewsWS";
import { useTranslation } from "react-i18next";
import { isNil } from "ramda";
import { SortableElement, SortableContainer, SortableHandle } from "react-sortable-hoc";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditorTopics = ({ isModalOpen, onCancel }) => {
  const { colors } = useDesignTokens();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { sectionId } = useParams();

  const topicsList = useSelector((state) => state.newsWS.topics);

  const [isAdd, setIsAdd] = useState(false);
  const [lang, setLang] = useState("fr");
  const [topic, setTopic] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = (values) => {
    if (isNil(topic)) {
      dispatch(createTopics({ label: values?.label, viewId: sectionId }))
        .unwrap()
        .then(() => {
          dispatch(getTopics({ viewId: sectionId }));
          dispatch(
            getNewsByTopics({
              locale: i18n.language.split("-")[0],
              viewId: sectionId,
            }),
          );
          setIsAdd(false);
          setTopic(null);
          setIsDisabled(false);
          form.resetFields();
          message.success(t("news.editor.topics.create.success"));
        })
        .catch(() => message.error(t("news.editor.topics.create.error")));
    } else {
      dispatch(
        updateTopics({
          topicId: topic.id,
          label: values?.label,
          position: topic.position,
          viewId: sectionId,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getTopics({ viewId: sectionId }));
          dispatch(
            getNewsByTopics({
              locale: i18n.language.split("-")[0],
              viewId: sectionId,
            }),
          );
          setIsAdd(false);
          setTopic(null);
          setIsDisabled(false);
          message.success(t("news.editor.topics.update.success"));
        })
        .catch(() => message.error(t("news.editor.topics.update.error")));
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [form, topic, isModalOpen]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(
      updateTopics({
        topicId: topicsList[oldIndex].id,
        label: topicsList[oldIndex].label,
        position: newIndex,
        viewId: sectionId,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(getTopics({ viewId: sectionId }));
        dispatch(
          getNewsByTopics({
            locale: i18n.language.split("-")[0],
            viewId: sectionId,
          }),
        );
        setIsAdd(false);
        setTopic(null);
        message.success(t("news.editor.topics.update.success"));
      })
      .catch(() => message.error(t("news.editor.topics.update.error")));
  };

  const DragHandle = SortableHandle(() => (
    <Glyph name="menu" style={{ marginRight: "8px", cursor: "pointer" }} />
  ));

  const SortableItem = SortableElement(({ value }) => (
    <Tile style={{ margin: "5px", zIndex: "9999", position: "relative", cursor: "default" }}>
      <div>
        <DragHandle />
        <Typography.Text strong> {value?.label[i18n.language.split("-")[0]]}</Typography.Text>
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{ marginRight: "48px", cursor: "pointer" }}
          onClick={() => {
            setIsAdd(true);
            setTopic(value);
          }}
        >
          <Glyph name="edit" style={{ color: colors?.primary_light }} />
        </div>
        <div
          style={{ marginRight: "16px", cursor: value?.isUsed ? "default" : "pointer" }}
          onClick={() => {
            !value?.isUsed &&
              dispatch(deleteTopics({ topicId: value?.id, viewId: sectionId }))
                .unwrap()
                .then(() => {
                  message.success(t("news.editor.topics.delete"));
                  dispatch(getTopics({ viewId: sectionId }));
                  dispatch(
                    getNewsByTopics({
                      locale: i18n.language.split("-")[0],
                      viewId: sectionId,
                    }),
                  );
                });
          }}
        >
          {value?.isUsed ? (
            <Tooltip title={t("news.editor.topics.use")}>
              <Glyph name="delete" style={{ color: colors?.grey_60 }} />
            </Tooltip>
          ) : (
            <Glyph name="delete" style={{ color: colors?.error_light }} />
          )}
        </div>
      </div>
    </Tile>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className={styles.sortableList} style={{ width: "100%" }}>
        {items.map((value, index) => (
          <SortableItem key={value?.id} index={index} value={value} />
        ))}
      </ul>
    );
  });

  return (
    isModalOpen && (
      <Modal
        // destroyOnClose
        width={600}
        open={isModalOpen}
        onCancel={() => {
          onCancel();
          setIsAdd(false);
          setTopic(null);
        }}
        footer={false}
        title={
          <Typography.Text strong>
            <Glyph name="folder" style={{ fontWeight: "normal", marginRight: "8px" }} />
            {t("news.editor.new.topic")}
          </Typography.Text>
        }
      >
        <div
          className={styles.addTopics}
          onClick={() => setIsAdd(true)}
          style={{ display: isAdd === false ? "flex" : "none" }}
        >
          <div>
            <Typography.Text style={{ color: colors.primary_light }}>
              <Glyph name="add" />
            </Typography.Text>
            <Typography.Text strong style={{ color: colors.primary_light }}>
              {t("news.editor.topics.add")}
            </Typography.Text>
          </div>
        </div>

        <Form
          onFinish={handleSubmit}
          form={form}
          className={styles.hidden}
          style={{ display: isAdd === true ? "block" : "none" }}
          initialValues={{ label: topic?.label }}
        >
          <Typography.Paragraph strong>{t("news.editor.topics.new")}</Typography.Paragraph>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              alignItems: "center",
            }}
          >
            <Typography.Text style={{ marginBottom: "4px" }}>{t("Languages")}</Typography.Text>
            <Segmented
              size="meddium"
              value={lang}
              //style={{ width: "100%" }}
              options={[
                {
                  label: <p style={{ margin: "0" }}>🇫🇷 {t("French")}</p>,
                  value: "fr",
                },
                {
                  label: <p style={{ margin: "0" }}>🇬🇧 {t("English")}</p>,
                  value: "en",
                },
              ]}
              onChange={(value) => {
                setLang(value);
              }}
            />
          </div>
          <Form.Item name={["label"]}>
            <I18nInput
              locale={lang}
              placeholder={t("news.editor.topics.name")}
              className={styles.inputTopic}
            />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              ghost
              onClick={() => {
                setIsAdd(false);
                setTopic(null);
              }}
              style={{ width: "130px", marginRight: "8px" }}
            >
              {t("Cancel")}
            </Button>
            <Button
              size="small"
              type="primary"
              style={{ width: "130px" }}
              onClick={() => {
                form.submit();
                setIsDisabled(true);
              }}
              disabled={isDisabled}
            >
              {topic ? t("news.editor.topics.update") : t("news.editor.topics.create")}
            </Button>
          </div>
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: `1px solid ${colors?.grey_60}`,
            marginBottom: "8px",
          }}
        >
          <Typography.Text>{t("news.editor.topics.list")}</Typography.Text>
          <div>
            <Typography.Text style={{ marginRight: "16px" }}>
              {t("news.editor.update")}
            </Typography.Text>
            <Typography.Text>{t("news.editor.delete")}</Typography.Text>
          </div>
        </div>
        <SortableList items={topicsList} onSortEnd={onSortEnd} useDragHandle />
      </Modal>
    )
  );
};

export default EditorTopics;
