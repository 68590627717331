import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Layout,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../../hook/useDebounce";
import useDidUpdateEffect from "../../../../hook/useDidUpdate";
import {
  searchSpace,
  searchSpacesWithResources,
} from "../../../../services/redux/services/SpaceServiceAdmin";
import useMap, { useMapLayer } from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import Options from "../../../Common/Options/Options";
import StepsTracker from "../../../Common/StepsTracker/StepsTracker";
import styles from "../SpaceBooking.module.less";
import AffectationModals from "./AffectationModals";
import RequestDetails from "./RequestDetails";

const Focused = {
  Request: "request",
  Search: "search",
};

const SpaceBookingMap = ({ toAssign = [] }) => {
  useMapLayer();
  const { selectedOnMap, centerOnPlaceId, filterOnPlaceIds } = useMap();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const requestCard = useRef();
  const searchCard = useRef();

  const [idx, setIdx] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [current, setCurrent] = useState();

  const [showModalsAffectation, setShowModalsAffectation] = useState(false);

  const [focusedCard, setFocusedCard] = useState(
    toAssign?.length ? Focused.Request : Focused.Search,
  );
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedDesk, setSelectedDesk] = useState();

  const [searchInput, setSearchInput] = useState("");
  const searchSpaceResults = useSelector(searchSpacesWithResources);

  const sectorId = useSelector((state) => state.spaceServiceAdminWS.selectedSectorId);
  const date = useSelector((state) => state.spaceServiceAdminWS.byStatusDate);

  useEffect(() => {
    setCurrent(toAssign[idx]);
  }, [idx, toAssign]);

  useDidUpdateEffect(() => {
    if (!selectedOnMap) return;
    const searchResult = searchSpaceResults?.find((r) => r.id === selectedOnMap.id);
    setSelectedRoom(searchResult);
    // Voluntarily omiting some deps to emulate a click event on map
  }, [selectedOnMap]);

  const searchInputDebounced = useDebounce(searchInput, 500);
  useDidUpdateEffect(() => {
    dispatch(
      searchSpace({
        sectorId,
        search: searchInputDebounced,
        period: current?.request.period,
        date,
      }),
    );
    // Voluntarily omiting some deps to run the hook only with search or slot changes
  }, [current, dispatch, searchInputDebounced]);

  useEffect(() => {
    setSelectedRoom(null);
    setSelectedDesk(null);
  }, [current, searchInputDebounced]);

  useEffect(() => {
    filterOnPlaceIds(searchSpaceResults.map((s) => s.map.findAndOrder.placeId));
  }, [filterOnPlaceIds, searchSpaceResults]);

  useEffect(() => {
    if (!requestCard?.current || !searchCard?.current) return;
    if (focusedCard === Focused.Request) {
      searchCard.current.querySelector(".ant-card-body").scroll(0, 0);
    } else {
      requestCard.current.querySelector(".ant-card-body").scroll(0, 0);
    }
  }, [focusedCard]);

  return (
    <Layout.Content className={styles.floatingContainer}>
      <div>
        {!!current && (
          <Card
            ref={requestCard}
            style={{ flexGrow: focusedCard === Focused.Request ? 1 : 0 }}
            bodyStyle={{ display: "flex", flexDirection: "column" }}
            actions={
              toAssign.length
                ? completed.includes(idx)
                  ? undefined
                  : [
                      <Row className={styles.actions} gutter={[10, 10]}>
                        {selectedDesk && (
                          <Col span={24}>
                            <Button
                              block
                              type="primary"
                              icon={<Glyph name="desk" />}
                              onClick={() => setShowModalsAffectation(true)}
                            >
                              {t("spas.request.actions.assignIn", { desk: selectedDesk?.title })}
                            </Button>
                          </Col>
                        )}
                        <Col span={24}>
                          <Button
                            block
                            type="default"
                            icon={<Glyph name="auto_fix_high" />}
                            onClick={() => {
                              setSelectedDesk(null); // Remove selected desk to force auto placement mode
                              setShowModalsAffectation(true);
                            }}
                          >
                            {t("spas.request.actions.assignAuto")} (
                            {toAssign.length - completed.length})
                          </Button>
                        </Col>
                      </Row>,
                    ]
                : [
                    <Row className={styles.actions} gutter={[10, 10]}>
                      <Col span={24}>
                        <Button
                          block
                          type="primary"
                          icon={<Glyph name="edit" />}
                          onClick={() => history.push("/spaceBooking/admin", { slots: [current] })}
                        >
                          {t("spas.request.actions.updateSpace", { count: 1 })}
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          block
                          type="default"
                          icon={<Glyph name="cancel" />}
                          onClick={() => {
                            setSelectedDesk(null); // Remove selected desk to force auto placement mode
                            setShowModalsAffectation(true);
                          }}
                        >
                          {t("spas.request.actions.removeSpace", { count: 1 })}
                        </Button>
                      </Col>
                    </Row>,
                  ]
            }
          >
            <div onMouseEnter={() => setFocusedCard(Focused.Request)}>
              <Row gutter={[10, 10]}>
                <Col>
                  <Glyph name="event" />
                </Col>
                <Col flex={"auto"}>
                  <Typography.Title level={5}>
                    {moment(current?.date).format("dddd DD MMM")}
                  </Typography.Title>
                </Col>
                {toAssign.length > 1 && (
                  <>
                    <Col>
                      <Typography.Title strong level={5} className="secondary">
                        {completed.length}/{toAssign.length}
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <StepsTracker
                        steps={toAssign.map((s, index) => ({
                          key: index,
                          current: index === idx,
                          active: !!completed.includes(index),
                        }))}
                      />
                    </Col>
                  </>
                )}
                {idx > 0 && (
                  <Col span={3}>
                    <Card
                      bordered={false}
                      style={{ height: "100%", cursor: "pointer" }}
                      bodyStyle={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                      onClick={() => setIdx(idx - 1)}
                    >
                      <Glyph name={"chevron_left"} style={{ verticalAlign: "middle" }} />
                    </Card>
                  </Col>
                )}
                <Col span={24 - (idx > 0 ? 3 : 0) - (idx < toAssign.length - 1 ? 3 : 0)}>
                  <RequestDetails.User slot={current} />
                </Col>
                {idx < toAssign.length - 1 && (
                  <Col span={3}>
                    <Card
                      bordered={false}
                      style={{ height: "100%", cursor: "pointer" }}
                      bodyStyle={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                      onClick={() => setIdx(idx + 1)}
                    >
                      <Glyph name={"navigate_next"} style={{ verticalAlign: "middle" }} />
                    </Card>
                  </Col>
                )}
              </Row>

              <div style={{ position: "relative", flexGrow: 1 }}>
                <div style={{ position: "absolute", top: 0, width: "100%" }}>
                  <Divider />
                  <RequestDetails.Infos slot={current} />
                  {current.request.from && (
                    <>
                      <Divider />
                      <Typography.Title level={5}>
                        De : {current.request.from.displayName}
                      </Typography.Title>
                      <Typography.Paragraph>
                        {current.request.history[0]?.comment}
                      </Typography.Paragraph>
                      <Typography.Paragraph type="secondary">
                        {moment(current.request.history[0]?.date).format("lll")}
                      </Typography.Paragraph>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}

        <Card
          ref={searchCard}
          style={{ flexGrow: focusedCard === Focused.Search ? 1 : 0 }}
          onMouseEnter={() => setFocusedCard(Focused.Search)}
        >
          {selectedRoom ? (
            <>
              <Space align="start" size="large">
                <Button
                  size="small"
                  shape="circle"
                  type="dashed"
                  icon={<Glyph name="navigate_before" />}
                  onClick={() => {
                    setSelectedRoom(null);
                    setSelectedDesk(null);
                  }}
                />
                <div>
                  <Typography.Title level={4}>{selectedRoom.title}</Typography.Title>
                  <Typography.Paragraph type="secondary">
                    {t("Floor")} {selectedRoom.floorReference}
                  </Typography.Paragraph>
                </div>
              </Space>
              <br />
              <br />
              <Tag>
                <Badge color={"green"} />{" "}
                {t("spas.admin.header.available", {
                  count: selectedRoom.workplaces.reduce(
                    (acc, curr) => (curr.available ? acc + 1 : acc),
                    0,
                  ),
                  total: selectedRoom.workplaces.length,
                })}
              </Tag>
              {!!selectedRoom.features.seats && (
                <Tag>
                  <Glyph name="person" /> {selectedRoom.features.seats}
                </Tag>
              )}
              {selectedRoom.equipments.map((e) => (
                <Tag>
                  <Glyph name={e.icon} /> {e.title}
                </Tag>
              ))}

              <div style={{ position: "relative", flexGrow: 1 }}>
                <div style={{ position: "absolute", top: 0, padding: "20px 0", width: "100%" }}>
                  {selectedRoom.photos[0] && (
                    <img src={selectedRoom.photos[0]} alt="" style={{ width: "100%" }} />
                  )}
                  <Row gutter={[0, 20]}>
                    {selectedRoom.workplaces?.map((workplace) => (
                      <Col span={24}>
                        <Card>
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Space>
                                <Glyph name={"desk"} className={"secondary"} />
                                <Typography.Text strong>{workplace.title}</Typography.Text>
                              </Space>
                              {workplace.available && !!toAssign.length ? (
                                <Radio
                                  checked={selectedDesk?.id === workplace.id}
                                  onClick={() => setSelectedDesk(workplace)}
                                />
                              ) : !workplace.available ? (
                                <Typography.Text strong type="secondary">
                                  {t("Full")}
                                </Typography.Text>
                              ) : null}
                            </div>
                            {workplace.slots.DAY && (
                              <RequestDetails.User
                                period
                                slot={workplace.slots.DAY}
                                onClick={
                                  toAssign.length
                                    ? undefined
                                    : () => {
                                        setCurrent(workplace.slots.DAY);
                                        setFocusedCard(Focused.Request);
                                      }
                                }
                              />
                            )}
                            {workplace.slots.MORNING && (
                              <RequestDetails.User
                                period
                                slot={workplace.slots.MORNING}
                                onClick={
                                  toAssign.length
                                    ? undefined
                                    : () => {
                                        setCurrent(workplace.slots.MORNING);
                                        setFocusedCard(Focused.Request);
                                      }
                                }
                              />
                            )}
                            {workplace.slots.AFTERNOON && (
                              <RequestDetails.User
                                period
                                slot={workplace.slots.AFTERNOON}
                                onClick={
                                  toAssign.length
                                    ? undefined
                                    : () => {
                                        setCurrent(workplace.slots.AFTERNOON);
                                        setFocusedCard(Focused.Request);
                                      }
                                }
                              />
                            )}
                          </Space>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              <Typography.Title level={5}>{t("spas.admin.map.search.title")}</Typography.Title>
              <Typography.Paragraph type="secondary" strong>
                {t("spas.admin.map.search.helper")}
              </Typography.Paragraph>
              <Input
                placeholder={t("spas.admin.map.search")}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />

              <div style={{ position: "relative", flexGrow: 1 }}>
                <div style={{ position: "absolute", top: 0, padding: "20px 0", width: "100%" }}>
                  <Row gutter={[10, 10]}>
                    {searchSpaceResults?.map((room) => (
                      <Col span={24} style={{ cursor: "pointer" }}>
                        <Card
                          bordered={false}
                          onClick={() => {
                            centerOnPlaceId(room.map?.findAndOrder?.placeId);
                          }}
                        >
                          <Row gutter={[20]} wrap={false} align="middle">
                            <Col flex={"none"}>
                              <Glyph name={room.category.icon} className={"secondary"} />
                            </Col>
                            <Col flex={"auto"} className="ellipsis">
                              <Typography.Paragraph strong>{room.title}</Typography.Paragraph>
                              <Typography.Text>
                                {t("spas.admin.header.available", {
                                  count: room.workplaces.reduce(
                                    (acc, curr) => (curr.available ? acc + 1 : acc),
                                    0,
                                  ),
                                  total: room.workplaces.length,
                                })}
                              </Typography.Text>
                            </Col>
                            <Col flex={"none"}>
                              <Glyph name="navigate_next" />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </>
          )}
        </Card>
      </div>
      <Options />

      <AffectationModals
        visible={showModalsAffectation}
        date={date}
        slots={selectedDesk ? [current] : toAssign}
        selectedRoom={selectedRoom}
        selectedDesk={selectedDesk}
        onContinue={() => {
          if (idx < toAssign.length - 1) {
            setIdx(idx + 1); // FIXME: Will crash if begin by end of array
            setCompleted([...completed, idx]);
            setSelectedRoom(null);
            setSelectedDesk(null);
            setShowModalsAffectation(false);
          } else {
            history.push("/spaceBooking");
          }
        }}
        onFinish={() => history.push("/spaceBooking")}
        onCancel={() => setShowModalsAffectation(false)}
      />
    </Layout.Content>
  );
};

export default SpaceBookingMap;
