import { createAsyncThunk as thunk, createSlice, createSelector } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  ticketing: null,
  category: null,
  attachment: [],
  selectedTicketId: null,
  groupFilter: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// TICKETING ///////////////////////////////
export const getListTicket = thunk(
  "ticketing/getListTicket",
  async ({ locale, page, size }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "ticketing");
    const previous = state.ticketingWS.ticketing;
    if (!page && previous && previous?.totalPages === previous?.page)
      return state.ticketingWS.ticketing;
    const ticketing = await DWMConnector.getListTicket(
      viewId.id,
      locale,
      page ?? (previous?.page || 0) + 1,
      size,
    );
    if (page) {
      return ticketing;
    } else {
      return {
        ...ticketing,
        items: (state?.ticketingWS?.ticketing?.items || []).concat(ticketing.items),
      };
    }
  },
);

EXTRA_REDUCERS[getListTicket.fulfilled] = (state, action) => {
  state.ticketing = action.payload;
};

export const getListCategory = thunk(
  "ticketing/getListCategory",
  async ({ locale }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "ticketing");
    const category = await DWMConnector.getListCategory(viewId.id, locale);
    return category;
  },
);

EXTRA_REDUCERS[getListCategory.fulfilled] = (state, action) => {
  state.category = action.payload;
};

export const createTicket = thunk(
  "ticketing/createTicket",
  async ({ typeId, categoryId, description, resourceId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "ticketing");
    const res = await DWMConnector.createTicket(
      viewId.id,
      typeId,
      categoryId,
      description,
      resourceId,
    );
    return res;
  },
);

export const addAttachment = thunk(
  "ticketing/addAttachment",
  async ({ ticketId, files }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "ticketing");
    const addAttachment = await DWMConnector.addAttachment(viewId.id, ticketId, files);
    return addAttachment;
  },
);

EXTRA_REDUCERS[addAttachment.fulfilled] = (state, action) => {
  state.attachment = action.payload;
};

export const addComment = thunk(
  "ticketing/addComment",
  async ({ ticketId, comment, locale }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "ticketing");
    const addComment = await DWMConnector.addComment(viewId.id, ticketId, comment, locale);
    return addComment;
  },
);

EXTRA_REDUCERS[addComment.fulfilled] = (state, action) => {
  state.ticketing.items = state.ticketing.items.map((item) =>
    item.id === action.payload.id ? action.payload : item,
  );
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const ticketingSlice = createSlice({
  name: "ticketing",
  initialState: INITIAL_STATE,
  reducers: {
    selectTicket(state, action) {
      state.selectedTicketId = action.payload;
    },
    setGroupFilter(state, action) {
      state.groupFilter = action.payload;
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const selectedTicket = createSelector(
  (state) => state.ticketingWS.selectedTicketId,
  (state) => state.ticketingWS.ticketing?.items,
  (ticketId, tickets = []) => {
    return tickets.find((t) => t.id === ticketId);
  },
);

export const filteredTickets = createSelector(
  (state) => state.ticketingWS.ticketing,
  (state) => state.ticketingWS.groupFilter,
  (ticketing, groupFilter) => {
    return ticketing
      ? {
          ...ticketing,
          items: ticketing.items.filter((t) => !groupFilter || t.ticketGroupId === groupFilter),
        }
      : null;
  },
);

export const { selectTicket, setGroupFilter } = ticketingSlice.actions;
export default ticketingSlice.reducer;
