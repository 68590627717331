import { Card, Segmented, Space, Typography } from "antd";
import Tile from "../../Common/Tile/Tile";
import Glyph from "../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState } from "react";
import useDesignTokens from "../../../hook/useDesignTokens";
import { isEmpty } from "ramda";
import { ReactComponent as Favoris } from "../../../assets/svg/favTransport.svg";
import moment from "moment";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const ListStop = ({ setStop, setLine, setInputSearchTo }) => {
  const { t } = useTranslation();
  const { symbols } = useDesignTokens();

  const listTransport = useSelector((state) => state.transportWS.listTransport.stopAreas);
  const favoris = useSelector((state) => state.clientsWS.favorisTransport);

  const [value, setValue] = useState("All");

  return (
    <Card
      style={{ width: "30%", height: "fit-content", overflow: "auto", maxHeight: "100%" }}
      title={
        <>
          <Typography.Title level={5}>{t("transport.title")}</Typography.Title>
          <Typography.Text strong type="secondary">
            {t("transport.subtitle")}
          </Typography.Text>
        </>
      }
    >
      <Segmented
        style={{ width: "100%" }}
        size="large"
        value={value}
        options={[
          {
            label: <p style={{ margin: "0" }}>{t("transport.segmented.allStop")}</p>,
            value: "All",
          },
          {
            label: <p style={{ margin: "0" }}>{t("transport.segmented.fav")}</p>,
            value: "Favoris",
          },
        ]}
        onChange={(value) => {
          setValue(value);
        }}
      />
      <div style={{ overflowY: "scroll", height: "500px", padding: "0 10px 0 0" }}>
        <Space
          style={{ width: "100%", marginTop: "16px", display: value === "All" ? "flex" : "none" }}
          direction="vertical"
          size="small"
        >
          {listTransport?.map((list, index) => (
            <Tile
              style={{ margin: "0", height: "auto", padding: "10px", flexFlow: "wrap" }}
              key={index}
              href="transport/stop"
              onClick={() => {
                setStop(list);
                setInputSearchTo(list);
                logEvent(analytics, `transport_stop_view`);
              }}
            >
              <div style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "wrap",
                    marginBottom: "8px",
                    alignItems: "center",
                  }}
                >
                  <Glyph name="directions_bus" style={{ marginRight: "8px", fontSize: "30px" }} />
                  {list?.lines?.map((line, index) => (
                    <Typography.Text
                      key={index}
                      strong
                      style={{
                        backgroundColor: line?.color,
                        borderRadius: symbols?.base_shape?.radius,
                        textAlign: "center",
                        color: line?.textColor,
                        minWidth: "30px",
                        height: "30px",
                        lineHeight: "30px",
                        padding: "0 6px",
                        marginRight: "4px",
                      }}
                    >
                      {line?.code}
                    </Typography.Text>
                  ))}
                </div>
                <Typography.Text strong>{list?.name}</Typography.Text>
              </div>

              <Glyph name="navigate_next" />
            </Tile>
          ))}
        </Space>

        <Space
          style={{
            width: "100%",
            marginTop: "16px",
            display: value === "Favoris" ? "flex" : "none",
          }}
          direction="vertical"
          size="small"
        >
          {!isEmpty(favoris) ? (
            favoris?.map((fav, index) => (
              <Tile
                key={index}
                style={{ margin: "0", height: "auto", padding: "10px" }}
                href="/transport/stop/line"
                onClick={() => {
                  setLine(fav);
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      marginBottom: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Glyph
                      name={
                        fav?.modes?.find((m) => m?.id === "metro") ? "subway" : "directions_bus"
                      }
                      style={{ marginRight: "8px", fontSize: "30px" }}
                    />
                    <Typography.Text
                      strong
                      style={{
                        backgroundColor: fav?.color,
                        borderRadius: symbols?.base_shape?.radius,
                        textAlign: "center",
                        color: fav?.textColor,
                        minWidth: "30px",
                        height: "30px",
                        lineHeight: "30px",
                        padding: "0 6px",
                        marginRight: "4px",
                      }}
                    >
                      {fav?.code}
                    </Typography.Text>
                  </div>
                  <Typography.Text strong>{fav?.direction}</Typography.Text>
                  <div style={{ display: "flex", flexFlow: "row" }}>
                    {!isEmpty(fav?.departures) && (
                      <Typography.Text style={{ marginRight: "8px" }}>{t("In")}</Typography.Text>
                    )}
                    {fav?.departures?.slice(0, 3)?.map((departure, index) => (
                      <Typography.Text key={index} style={{ marginRight: "8px" }}>
                        {moment(departure).fromNow(true)} |
                      </Typography.Text>
                    ))}
                  </div>
                </div>

                <Glyph name="navigate_next" />
              </Tile>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Favoris />
              <Typography.Text strong type="secondary" style={{ marginTop: "16px" }}>
                {t("transport_no_favorite")}
              </Typography.Text>
            </div>
          )}
        </Space>
      </div>
    </Card>
  );
};

export default ListStop;
