import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  listTransport: {},
  listLines: {},
  listStopForRoutes: {},
  journeys: {},
  detailsJourney: {},
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const GetStopsNearLocation = thunk(
  "transport/GetStopsNearLocation",
  async ({ campusId }) => {
    const transport = await DWMConnector.GetStopsNearLocation(campusId);
    return transport;
  },
);

EXTRA_REDUCERS[GetStopsNearLocation.fulfilled] = (state, action) => {
  state.listTransport = action.payload;
};

export const GetSchedulesForStop = thunk(
  "transport/GetSchedulesForStop",
  async ({ campusId, stopId }) => {
    const stop = await DWMConnector.GetSchedulesForStop(campusId, stopId);
    return stop;
  },
);

EXTRA_REDUCERS[GetSchedulesForStop.fulfilled] = (state, action) => {
  state.listLines = action.payload;
};

export const GetAutocompletePlaces = thunk(
  "transport/GetAutocompletePlaces",
  async ({ campusId, q }) => {
    const stop = await DWMConnector.GetAutocompletePlaces(campusId, q);
    return stop;
  },
);

EXTRA_REDUCERS[GetAutocompletePlaces.fulfilled] = (state, action) => {
  state.listStopForRoutes = action.payload;
};

export const GetJourneysBetweenTwoPoints = thunk(
  "transport/GetJourneysBetweenTwoPoints",
  async ({ campusId, from, to }) => {
    const journeys = await DWMConnector.GetJourneysBetweenTwoPoints(campusId, from, to);
    return journeys;
  },
);

EXTRA_REDUCERS[GetJourneysBetweenTwoPoints.fulfilled] = (state, action) => {
  state.journeys = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const TransportSlice = createSlice({
  name: "transport",
  initialState: INITIAL_STATE,
  reducers: {
    removeSearch(state, action) {
      state.listStopForRoutes = {};
    },
    addDetails(state, action) {
      state.detailsJourney = action.payload;
    },
    removeDetails(state, action) {
      state.detailsJourney = {};
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const { removeSearch, addDetails, removeDetails } = TransportSlice.actions;
export default TransportSlice.reducer;
