import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  news: [],
  item: null,
  comment: null,
  newsHome: [],
  newsByTopics: [],
  newsEditor: [],
  itemEditor: [],
  topics: [],
  images: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// NEWS ///////////////////////////////
export const getNewsHome = thunk("news/getNewsHome", async ({ locale, siteId }) => {
  const news = await DWMConnector.getNewsHome(locale, siteId);
  return news;
});

EXTRA_REDUCERS[getNewsHome.fulfilled] = (state, action) => {
  state.newsHome = action.payload;
};

export const getNewsByTopics = thunk("news/getNewsByTopics", async ({ locale, viewId }) => {
  const news = await DWMConnector.getNewsByTopics(viewId, locale);
  return news;
});

EXTRA_REDUCERS[getNewsByTopics.fulfilled] = (state, action) => {
  state.newsByTopics = action.payload;
};

export const getNews = thunk(
  "news/getNews",
  async ({ locale, topic, date, init = false, viewId }) => {
    const news = await DWMConnector.getNews(viewId, locale, topic, date);
    return { news, init };
  },
);

EXTRA_REDUCERS[getNews.fulfilled] = (state, action) => {
  state.news = action.payload.init ? action.payload.news : state.news.concat(action.payload.news);
};

export const getNewsItem = thunk("news/getNewsItem", async ({ itemId, locale, viewId }) => {
  const item = await DWMConnector.getNewsItem(viewId, itemId, locale);
  return item;
});

EXTRA_REDUCERS[getNewsItem.fulfilled] = (state, action) => {
  state.item = action.payload;
};

export const listCommentNews = thunk(
  "news/listCommentNews",
  async ({ itemId, date, locale, viewId }) => {
    const commentNews = await DWMConnector.listCommentNews(viewId, itemId, date, locale);
    return commentNews;
  },
);

EXTRA_REDUCERS[listCommentNews.fulfilled] = (state, action) => {
  state.comment = action.payload;
};

export const addCommentNews = thunk(
  "news/addCommentNews",
  async ({ itemId, comment, locale, viewId }) => {
    const commentNews = await DWMConnector.addCommentNews(viewId, itemId, comment, locale);
    return commentNews;
  },
);

export const deleteCommentnews = thunk(
  "news/deleteCommentnews",
  async ({ viewId, itemId, commentId }) => {
    const deleteComment = await DWMConnector.deleteCommentnews(viewId, itemId, commentId);
    return deleteComment;
  },
);

export const likesNews = thunk("news/likesNews", async ({ itemId, like, viewId }) => {
  const likesNews = await DWMConnector.likesNews(viewId, itemId, like);
  return likesNews;
});

export const likesComments = thunk(
  "news/likesComments",
  async ({ itemId, commentId, like, viewId }) => {
    const likesComments = await DWMConnector.likesComments(viewId, itemId, commentId, like);
    return likesComments;
  },
);

export const readNews = thunk("news/readNews", async ({ itemId, viewId }) => {
  const readNews = await DWMConnector.readNews(viewId, itemId);
  return readNews;
});

export const deleteNews = thunk(
  "news/deleteNews",
  async ({ itemId, viewId }, { getState, dispatch }) => {
    const deleteNews = await DWMConnector.deleteNews(viewId, itemId);
    return deleteNews;
  },
);

export const updateNews = thunk(
  "news/updateNews",
  async (
    {
      itemId,
      header,
      title,
      subtitle,
      content,
      topics,
      startDate,
      endDate,
      groups,
      authorizeComment,
      sendNotification,
      event,
      viewId,
    },
    { getState },
  ) => {
    const updateNews = await DWMConnector.updateNews(
      viewId,
      itemId,
      header,
      title,
      subtitle,
      content,
      topics,
      startDate,
      endDate,
      groups,
      authorizeComment,
      sendNotification,
      event,
    );
    return updateNews;
  },
);

export const createNews = thunk(
  "news/createNews",
  async (
    {
      header,
      title,
      subtitle,
      content,
      topics,
      startDate,
      endDate,
      groups,
      event,
      authorizeComment,
      sendNotification,
      viewId,
    },
    { getState },
  ) => {
    const createNews = await DWMConnector.createNews(
      viewId,
      header,
      title,
      subtitle,
      content,
      topics,
      startDate,
      endDate,
      groups,
      event,
      authorizeComment,
      sendNotification,
    );
    return createNews;
  },
);

export const getNewsEditor = thunk(
  "news/getNewsEditor",
  async ({ topic, date, init = false, viewId }) => {
    const newsEditor = await DWMConnector.getNewsEditor(viewId, topic, date);
    return { newsEditor, init };
  },
);

EXTRA_REDUCERS[getNewsEditor.fulfilled] = (state, action) => {
  state.newsEditor = action.payload.init
    ? action.payload.newsEditor
    : state.newsEditor.concat(action.payload.newsEditor);
};

export const getNewsItemEditor = thunk("news/getNewsItemEditor", async ({ itemId, viewId }) => {
  const itemEditor = await DWMConnector.getNewsItemEditor(viewId, itemId);
  return itemEditor;
});

EXTRA_REDUCERS[getNewsItemEditor.fulfilled] = (state, action) => {
  state.itemEditor = action.payload;
};

export const hideNews = thunk("news/hideNews", async ({ itemId, hide, viewId }) => {
  const hideNews = await DWMConnector.hideNews(viewId, itemId, hide);
  return hideNews;
});

export const getTopics = thunk("news/getTopics", async ({ viewId }) => {
  const topics = await DWMConnector.getTopics(viewId);
  return topics;
});

EXTRA_REDUCERS[getTopics.fulfilled] = (state, action) => {
  state.topics = action.payload;
};

export const createTopics = thunk("news/createTopics", async ({ label, position, viewId }) => {
  const createTopics = await DWMConnector.createTopics(viewId, label, position);
  return createTopics;
});

export const deleteTopics = thunk(
  "news/deleteTopics",
  async ({ topicId, viewId }, { getState, dispatch }) => {
    const deleteTopics = await DWMConnector.deleteTopics(viewId, topicId);
    return deleteTopics;
  },
);

export const updateTopics = thunk(
  "news/updateTopics",
  async ({ topicId, label, position, viewId }, { getState, dispatch }) => {
    const updateTopics = await DWMConnector.updateTopics(viewId, topicId, label, position);
    return updateTopics;
  },
);

export const getImageNews = thunk("news/getImageNews", async ({ date, init = false }) => {
  const images = await DWMConnector.getImageNews(date);
  return { images, init };
});

EXTRA_REDUCERS[getImageNews.fulfilled] = (state, action) => {
  state.images = action.payload.init
    ? action.payload.images
    : state.images.concat(action.payload.images);
};

export const addImagesNews = thunk("news/addImagesNews", async ({ image, fileName }) => {
  const addImagesNews = await DWMConnector.addImagesNews(image, fileName);
  return addImagesNews;
});

export const addImagesContentNews = thunk(
  "news/addImagesContentNews",
  async ({ image, fileName, siteId }) => {
    const addImagesContentNews = await DWMConnector.addImagesContentNews(image, fileName, siteId);
    return addImagesContentNews;
  },
);

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const newsSlice = createSlice({
  name: "news",
  initialState: INITIAL_STATE,
  reducers: {
    removeItemEditor(state, action) {
      state.itemEditor = [];
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const { removeItemEditor } = newsSlice.actions;
export default newsSlice.reducer;
