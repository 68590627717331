import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Divider,
  Layout,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import usePrevious from "../../../hook/usePrevious";
import {
  cancelSlot,
  createRequest,
  getToday,
  listFollowed,
  listSlots,
} from "../../../services/redux/services/SpaceService";
import Glyph from "../../Common/Glyph/Glyph";
import { TitleSource } from "../../Common/Teleporters/Title";
import styles from "./SpaceBooking.module.less";

import CreateRequestModal from "./Spaas/CreateRequestModal/CreateRequestModal";
import ManagePeopleModal from "./Spaas/ManagePeopleModal";
import RequestDetailsModal from "./Spaas/RequestDetailsModal/RequestDetailsModal";

import Tile from "../../Common/Tile/Tile";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { isNil } from "ramda";

let time;

export const STEPS = {
  DATES: "DATES",
  VALIDATION: "VALIDATION",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  PEOPLE: "PEOPLE",
  PHONEBOOK: "PHONEBOOK",
  DETAILS: "DETAILS",
  UPDATE: "UPDATE",
  WORKPLACE: "WORKPLACE",
  WORKPLACE_MAP: "WORKPLACE_MAP",
  REVIEW: "REVIEW",
};

const SpaceBookingResident = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useDesignTokens();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const pending = useSelector((state) => state.spaceServiceWS.followedPending);
  const today = useSelector((state) => state.spaceServiceWS.today);
  const slots = useSelector((state) => state.spaceServiceWS.slots);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [status, setStatus] = useState(null);
  const [step, setStep] = useState(STEPS.DATES);

  const previousDate = usePrevious(selectedDate);

  // Prevent the popup to appear if the user select a different month or year
  useDidUpdateEffect(() => {
    if (!selectedDate.isSame(previousDate, "month")) return;
    const slot = slots.find((s) => moment(s.date).isSame(selectedDate, "date"));
    if (slot) setSelectedSlot(slot);
    else if (!isNil(sites?.find((s) => s?.id === mapData?.id)?.sectors)) {
      setShowCreateModal(selectedDate.toISOString());
    } else {
      error();
    }
    // Voluntarily omiting some deps to run the hook only with date changes
  }, [selectedDate]);

  useEffect(() => {
    if (previousDate && selectedDate.isSame(previousDate, "month")) return;
    dispatch(
      listSlots({
        startDate: moment(selectedDate).startOf("month").subtract(1, "month").toISOString(),
        endDate: moment(selectedDate).endOf("month").add(6, "month").toISOString(),
      }),
    );
  }, [dispatch, previousDate, selectedDate]);

  useEffect(() => {
    dispatch(getToday());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listFollowed());
  }, [dispatch]);

  const workingDays = [1, 2, 3, 4, 5];

  const error = () => {
    Modal.error({
      title: <Typography.Text strong>{t("spas.request.error.sectors")}</Typography.Text>,
      content: t("spas.request.error.sectors.content"),
      okText: <div block>{t("Closed")}</div>,
      closable: true,
      maskClosable: true,
    });
  };

  return (
    <>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          {t("space_booking", { ns: "csv" })}
        </Typography.Title>
      </TitleSource>

      <Layout className={styles.dashboardContainer}>
        <Layout>
          {today && (
            <>
              <Typography.Title level={3} style={{ margin: 0 }}>
                {t("Today")}
              </Typography.Title>
              <Row gutter={[10, 20]}>
                <Col flex={"auto"}>
                  <Card bodyStyle={{ paddingTop: 8, paddingBottom: 8 }}>
                    <div style={{ width: "100%", display: "flex", gap: 10 }}>
                      <Card
                        style={{
                          flexGrow: 1,
                          backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                        }}
                        bodyStyle={{ padding: 10 }}
                        bordered={false}
                      >
                        <div className={clsx(styles.stat, styles.estimated)}>
                          <Glyph name={"business"} />
                          <Typography.Title level={5} className={"secondary"}>
                            {sites.find((s) => s.id === today.request.siteId)?.title}
                          </Typography.Title>
                        </div>
                      </Card>
                      <Divider type="vertical" style={{ height: "auto" }} />
                      {today.space && (
                        <>
                          <Card
                            style={{ flexGrow: 1 }}
                            bodyStyle={{ padding: 10 }}
                            bordered={false}
                          >
                            <div className={clsx(styles.stat, styles.estimated)}>
                              <Glyph name={"desk"} />
                              <Typography.Title level={5}>
                                {today.sector?.title || "--"} - {today.space?.title || "--"}
                              </Typography.Title>
                            </div>
                          </Card>
                          <Divider type="vertical" style={{ height: "auto" }} />
                        </>
                      )}

                      <Card style={{ flexGrow: 1 }} bodyStyle={{ padding: 10 }} bordered={false}>
                        <div className={clsx(styles.stat)}>
                          <Glyph name={"schedule"} />
                          <Typography.Title level={5}>
                            {t(`spas.period.${today.request.period}`)}
                          </Typography.Title>
                        </div>
                      </Card>
                      {/* <Divider type="vertical" style={{ height: "auto" }} />
                      <Card style={{ flexGrow: 1 }} bodyStyle={{ padding: 10 }} bordered={false}>
                        <div className={clsx(styles.stat)}>
                          <RequestDetails.Glyph noLabel slot={today} />
                          <Typography.Title level={5}>
                            {t(`spas.status.${today.status}`)}
                          </Typography.Title>
                        </div>
                      </Card> */}
                    </div>
                  </Card>
                </Col>
                {/* {sites?.find((s) => s.id === campus?.mapData?.id)?.spaceBooking?.type !== 1 && (
                  <Col>
                    <Card style={{ height: "100%", cursor: "pointer" }}>
                      <div className={clsx(styles.stat, styles.map)}>
                        <Glyph name={"map"} />
                        <br />
                        <Space align="end">
                          <Typography.Title level={5}>
                            {t("spas.request.actions.map")}
                          </Typography.Title>
                          <Typography.Title level={5}>
                            <Glyph name={"navigate_next"} />
                          </Typography.Title>
                        </Space>
                      </div>
                    </Card>
                  </Col>
                )} */}
              </Row>
            </>
          )}

          <Card bodyStyle={{ padding: 0 }} style={{ overflow: "initial", height: "auto" }}>
            <div
              style={{
                padding: 16,
              }}
            >
              <Row>
                <Col flex="auto">
                  <Typography.Title>{t("spas.planning")}</Typography.Title>
                </Col>
                <Col flex="none" style={{ marginRight: "16px" }}>
                  <Button
                    block
                    type="primary"
                    icon={<Glyph name="add" />}
                    onClick={() => setShowCreateModal(true)}
                  >
                    {t("spas.request.new")}
                  </Button>
                </Col>
                <Col flex="none">
                  <Badge count={pending?.length}>
                    <Button
                      shape="circle"
                      ghost
                      icon={<Glyph name="manage_accounts" />}
                      onClick={() => setShowManageModal(true)}
                    ></Button>
                  </Badge>
                </Col>
              </Row>
              <Divider style={{ margin: "0" }} />
            </div>

            <Calendar
              onChange={() => logEvent(analytics, "spaas_select_date_current_week")}
              style={{ padding: "0 16px" }}
              dateCellRender={(date) => {
                const slot = slots.find((s) => moment(s.date).isSame(date, "date"));
                if (!slot) return null;
                const site = sites.find((s) => s.id === slot.request.siteId);
                return (
                  <Space direction="vertical" size="small" style={{ width: "100%" }}>
                    <Tile
                      style={{
                        alignItems: "flex-start",
                        flexFlow: "column",
                        margin: "0",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Typography.Text strong>{site.title}</Typography.Text>
                        {slot?.confirmed && (
                          <Glyph
                            name="check_circle"
                            style={{
                              color: colors.success_light,
                              marginLeft: "2px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          />
                        )}
                      </div>

                      <Space size="small">
                        <Glyph name="desk" className={"secondary"} />
                        <Typography.Text>{t(`spas.period.${slot.request.period}`)}</Typography.Text>
                      </Space>
                    </Tile>
                  </Space>
                );
              }}
              validRange={[moment().subtract(1, "month"), moment().add(2, "years")]}
              onSelect={setSelectedDate}
              disabledDate={(date) =>
                (!slots?.find((s) => s.date === moment(date).format("YYYY-MM-DD")) &&
                  date.isBefore(moment(), "date")) ||
                !workingDays.includes(date.isoWeekday())
              }
            />
          </Card>
        </Layout>
      </Layout>

      {!!showCreateModal && (
        <CreateRequestModal
          visible={showCreateModal}
          onSubmit={(values) => {
            time = setTimeout(() => {
              dispatch(
                createRequest({
                  ...values,
                  comment: " ",
                }),
              )
                .unwrap()
                .then(() => {
                  logEvent(analytics, "spaas_book_desk", {
                    type: sites?.find((s) => s.id === values.siteId)?.spaceBooking?.type,
                    campus_name: sites?.find((s) => s.id === values.siteId)?.label,
                  });
                  setStatus("success");
                  setStep(STEPS.SUCCESS);
                  dispatch(getToday());
                })
                .catch((error) => {
                  console.error(error);
                  setStatus("error");
                  setStep(STEPS.ERROR);
                });
            }, 6000);
          }}
          status={status}
          setStep={setStep}
          step={step}
          onCancel={() => {
            setShowCreateModal(false);
            setStatus(null);
            clearTimeout(time);
            setStep(STEPS.DATES);
          }}
        />
      )}

      <ManagePeopleModal
        visible={showManageModal}
        onCancel={() => {
          setShowManageModal(false);
        }}
      />

      {!!selectedSlot && (
        <RequestDetailsModal
          slot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          onCancel={() => setSelectedSlot(null)}
          onCancelSlot={(data) => {
            dispatch(cancelSlot(data))
              .unwrap()
              .then(() => {
                setSelectedSlot(null);
              });
          }}
        />
      )}
    </>
  );
};

export default SpaceBookingResident;
