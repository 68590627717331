import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Card, Col, Comment, List, Row, Typography } from "antd";
import clsx from "clsx";
import moment from "moment";
import { always, cond, equals, T } from "ramda";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../SpaceBooking.module.less";
import useDesignTokens from "../../../../hook/useDesignTokens";

const ITEM_STYLE = { display: "flex", alignItems: "center", gap: 12 };

const RequestDetailsUser = ({ slot, period = false, onClick }) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <Card bordered={false} style={{ cursor: onClick ? "pointer" : "default" }} onClick={onClick}>
      <Card.Meta
        avatar={
          <Avatar
            size={"large"}
            src={`${slot?.request.user.photoUrl}?access_token=${keycloak.token}`}
          >
            {slot?.request.user.displayName
              .split(" ")
              .map((i) => i.charAt(0).toUpperCase())
              .join("")}
          </Avatar>
        }
        title={slot?.request.user.displayName}
        description={
          period ? (
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Glyph name="schedule" style={{ fontSize: "1em" }} />
              {t(`spas.period.${slot?.request.period}`)}
            </div>
          ) : (
            slot?.request.user.function
          )
        }
      />
    </Card>
  );
};

const RequestDetailsSector = ({ slot }) => {
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  return (
    <Row gutter={[10, 0]} align="middle">
      <Col flex={"auto"}>
        <Card bordered={false} bodyStyle={{ padding: "10px 20px" }}>
          <Typography.Text strong>
            {
              sites
                ?.find((s) => s.id === slot?.request?.siteId)
                ?.sectors?.find((s) => s.id === slot?.request?.sectorId)?.title
            }
          </Typography.Text>
        </Card>
      </Col>
    </Row>
  );
};

const RequestDetailsSite = ({ slot }) => {
  const { colors } = useDesignTokens();
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  return (
    <Row gutter={[10, 0]} align="middle">
      <Col>
        <Glyph name={"business"} style={{ fontSize: "2em" }} className="secondary" />
      </Col>
      <Col flex={"auto"}>
        <Card
          bordered={false}
          bodyStyle={{
            padding: "10px 20px",
            backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
          }}
        >
          <Typography.Text strong className="secondary">
            {sites?.find((s) => s.id === slot?.request?.siteId)?.title}
          </Typography.Text>
        </Card>
      </Col>
    </Row>
  );
};

const RequestStatusGlyph = ({ noLabel = false, slot }) => {
  const { t } = useTranslation();

  const icon = cond([
    [equals("PENDING"), always("help")],
    [equals("APPROVED"), always("check_circle")],
    [equals("DECLINED"), always("cancel")],
    [T, always("help")],
  ])(slot?.status);

  return (
    <div style={ITEM_STYLE}>
      <Glyph name={icon} className={clsx(styles.glyph, styles[slot?.status])} />
      {!noLabel && <Typography.Text strong>{t(`spas.status.${slot?.status}`)}</Typography.Text>}
    </div>
  );
};

const RequestPresenceGlyph = ({ noLabel = false, slot }) => {
  const { t } = useTranslation();

  return (
    <div style={ITEM_STYLE}>
      <Glyph name="check_circle" className={clsx(styles.glyph, styles[slot?.status])} />
      {!noLabel && <Typography.Text strong>{t(`spas.presence.confirmed`)}</Typography.Text>}
    </div>
  );
};

const RequestDetailsInfos = ({ slot }) => {
  const { t } = useTranslation();
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  return (
    <Row gutter={[10, 10]}>
      {slot?.space && (
        <Col span={24}>
          <Card bordered={false}>
            <div style={ITEM_STYLE}>
              <Glyph name="desk" className={"secondary"} />
              <Typography.Text strong>{slot?.space?.title}</Typography.Text>
            </div>
          </Card>
        </Col>
      )}
      {sites?.find((c) => c?.id === slot?.request?.siteId)?.spaceBooking?.type === 4 && (
        <Col span={24}>
          <Card bordered={false}>
            <RequestStatusGlyph slot={slot} />
          </Card>
        </Col>
      )}
      {sites?.find((c) => c?.id === slot?.request?.siteId)?.spaceBooking?.type === 3 &&
        slot.confirmed && (
          <Col span={24}>
            <Card bordered={false}>
              <RequestPresenceGlyph slot={slot} />
            </Card>
          </Col>
        )}
      <Col span={24}>
        <Card bordered={false}>
          <div style={ITEM_STYLE}>
            <Glyph name="schedule" />
            <Typography.Text strong>{t(`spas.period.${slot?.request.period}`)}</Typography.Text>
          </div>
        </Card>
      </Col>
      {slot?.request.recurrence && slot?.request.recurrence !== "NONE" && (
        <>
          <Col span={24}>
            <Card bordered={false}>
              <div style={ITEM_STYLE}>
                <Glyph name="sync" />
                <Typography.Text strong>
                  {t(`spas.recurrence.${slot?.request.recurrence}`, {
                    day: moment(slot?.date).format("dddd"),
                  })}
                </Typography.Text>
              </div>
            </Card>
          </Col>
          <Col span={24}>
            <Card bordered={false}>
              <div style={ITEM_STYLE}>
                <Glyph name="event_busy" />
                <Typography.Text strong>
                  {t("spas.request.endDate", {
                    date: moment(slot?.request.endDate).format("ll"),
                  })}
                </Typography.Text>
              </div>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

const RequestDetailsFrom = ({ slot }) => {
  const { t } = useTranslation();
  return (
    <Card bordered={false}>
      <div style={ITEM_STYLE}>
        <Glyph name="person" />
        <Typography.Text strong>
          {t("spas.request.from", {
            user: slot?.request.from.displayName,
          })}
        </Typography.Text>
      </div>
    </Card>
  );
};

const RequestDetailsComments = ({ slot }) => {
  const { t } = useTranslation();

  return (
    <List
      dataSource={slot?.request.history.map((h) => ({
        author: (
          <Typography.Text strong style={{ fontSize: "1.2em" }}>
            {h.from.displayName}
          </Typography.Text>
        ),
        datetime: (
          <Typography.Text style={{ fontSize: "1em", marginLeft: "auto" }}>
            {moment(h.date).format("lll")}
          </Typography.Text>
        ),
        content: (
          <>
            <Card
              bordered={false}
              style={{ marginBottom: 10 }}
              bodyStyle={{ display: "flex", alignItems: "center", gap: 10, padding: 6 }}
              className="secondary"
            >
              <Glyph name="info" className="secondary" style={{ fontSize: "1em" }} />
              <Typography.Text strong className="secondary">
                {t(`spas.history.${h.action}`)}
              </Typography.Text>
            </Card>
            <Typography.Paragraph>{h.comment}</Typography.Paragraph>
          </>
        ),
      }))}
      renderItem={(item) => (
        <List.Item>
          <Comment
            style={{ width: "100%" }}
            author={item.author}
            content={item.content}
            datetime={item.datetime}
          />
        </List.Item>
      )}
    />
  );
};

const RequestDetails = {
  User: RequestDetailsUser,
  Site: RequestDetailsSite,
  Sector: RequestDetailsSector,
  Glyph: RequestStatusGlyph,
  Infos: RequestDetailsInfos,
  From: RequestDetailsFrom,
  Comments: RequestDetailsComments,
};

export default RequestDetails;
