import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  TimePicker,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import { STEPS } from "./Catering";
import { FixedSizeList as List } from "react-window";
import useDesignTokens from "../../../hook/useDesignTokens";
import RoomDetails from "../../Common/RoomDetails/RoomDetails";
import clsx from "clsx";
import styles from "./Catering.module.less";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { createPresation, servicesCatering } from "../../../services/redux/services/CateringWS";
import { isEmpty, isNil, partition } from "ramda";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import checkDelayRule from "../../../helpers/checkdaysRules";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const GUTTER_SIZE = 8;

const CreateServicesModal = ({ visible, onCancel, step, setStep, startDate, setStartDate }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [searchSpaces, setSearch] = useState("");
  const [space, setSpace] = useState();
  const [rubrique, setRubrique] = useState();
  const [infoServices, setInfoServices] = useState();
  const [presta, setPresta] = useState(null);
  const [total, setTotal] = useState(0);
  const [noAttendees, setNoAttendees] = useState(0);

  const campus = useSelector((state) => state.clientsWS.campus);
  const services = useSelector((state) => state.cateringWS.services);

  const searchResult = searchSpaces
    ? campus?.mapData?.resources
        ?.filter((r) => !isEmpty(r?.services))
        ?.filter((r) => (searchSpaces ? new RegExp(searchSpaces, "i").test(r.title) : true))
        .sort((a, b) => (a.title > b.title ? 1 : -1))
    : null;

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const ListSpaces = ({ style, index }) => (
    <Tile
      style={{
        ...style,
        height: style.height - GUTTER_SIZE,
        padding: GUTTER_SIZE,
        justifyContent: "normal",
        margin: "0",
        width: "99%",
      }}
      onClick={() => {
        setStep(STEPS.INFOS);
        setSpace(searchResult[index]);
      }}
    >
      <div
        style={{ display: "flex", flexFlow: "row", justifyContent: "space-between", width: "100%" }}
      >
        <Typography.Text strong>
          <Glyph
            name="meeting_room"
            style={{ fontWeight: "normal", marginRight: "8px", color: colors?.secondary_base }}
          />
          {searchResult[index]?.title}
        </Typography.Text>
        <div style={{ display: "flex", flexFlow: "row" }}>
          <Typography.Text>{searchResult[index]?.floorReference}</Typography.Text>
          <Glyph name="navigate_next" />
        </div>
      </div>
    </Tile>
  );

  useEffect(() => {
    if (!space) return;
    dispatch(servicesCatering({ roomId: space?.id }));
  }, [dispatch, space]);

  const [canOrder, canNotOrder] = !isNil(rubrique)
    ? partition((s) => checkDelayRule(startDate, s?.form?.orderNotice), rubrique?.categories)
    : [];

  const handleSubmit = () => {
    setStep(STEPS.RESUME);
    setPresta({
      name: infoServices?.name,
      categoryId: infoServices?.id,
      ticketType: infoServices?.ticketTypeId,
      formProps: {
        no_Attendees: noAttendees,
        total: total,
        startDate: moment(startDate).toISOString(),
        endDate: moment(startDate).add(1, "hours").toISOString(),
        values: Object.entries(form.getFieldValue(["values"])).reduce((acc, [key, value]) => {
          return [...acc, { key: key, value: value }];
        }, []),
      },
    });
  };

  return (
    <Modal
      className={clsx(styles.modal)}
      destroyOnClose
      title={<Typography.Title level={5}>{t("catering.newPresta")}</Typography.Title>}
      open={!!visible}
      onCancel={() => {
        onCancel();
        setPresta([]);
        form.resetFields();
        setStartDate(moment());
      }}
      footer={[
        step === STEPS.SEARCH ? (
          <div style={{ display: "flex" }}>
            <Button ghost block style={{ width: "220px" }} onClick={() => onCancel()}>
              {t("Cancel")}
            </Button>
          </div>
        ) : step === STEPS.INFOS ? (
          <div style={{ display: "flex" }}>
            <Button ghost block style={{ width: "220px" }} onClick={() => setStep(STEPS.SEARCH)}>
              {t("Back")}
            </Button>
            <Button
              type="primary"
              block
              icon={<Glyph name="save" />}
              style={{ width: "220px" }}
              onClick={() => setStep(STEPS.SERVICES)}
            >
              {t("catering.thisSpace")}
            </Button>
          </div>
        ) : step === STEPS.SERVICES ? (
          <div style={{ display: "flex" }}>
            <Button ghost block style={{ width: "220px" }} onClick={() => setStep(STEPS.INFOS)}>
              {t("Back")}
            </Button>
          </div>
        ) : step === STEPS.RUBRIQUE ? (
          <div style={{ display: "flex" }}>
            <Button ghost block style={{ width: "220px" }} onClick={() => setStep(STEPS.SERVICES)}>
              {t("Back")}
            </Button>
          </div>
        ) : step === STEPS.DETAILS ? (
          <div style={{ display: "flex" }}>
            <Button
              ghost
              block
              key="cancel"
              style={{ width: "220px" }}
              onClick={() => setStep(STEPS.RUBRIQUE)}
            >
              {t("Back")}
            </Button>
            <Button
              type="primary"
              block
              style={{ width: "220px" }}
              onClick={() => {
                form.submit();
              }}
            >
              {t("catering.addPresta")}
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <Button
              type="primary"
              block
              style={{ width: "220px" }}
              onClick={() => {
                logEvent(analytics, "catering_new_request");
                dispatch(
                  createPresation({
                    roomId: space.id,
                    ticketType: presta?.ticketType,
                    categoryId: presta?.categoryId,
                    startDate: presta?.formProps?.startDate,
                    endDate: presta?.formProps?.endDate,
                    values: presta?.formProps?.values,
                    no_Attendees: presta?.formProps?.no_Attendees,
                    total: presta?.formProps?.total,
                  }),
                );
                onCancel();
              }}
            >
              {t("catering.saveOrder")}
            </Button>
          </div>
        ),
      ]}
      style={{ height: "750px" }}
      bodyStyle={{ padding: "0" }}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          startDate: startDate,
        }}
        onValuesChange={(changedValues, values) => {
          const value = infoServices?.form?.elements
            ?.filter((e) => e.type === "NUMBER")
            ?.reduce((acc, curr) => acc + (curr.fee || 0) * (values.values[curr.key] || 0), 0);
          const noAttendees = infoServices?.form?.elements
            ?.filter((e) => e.type === "NUMBER")
            .reduce((acc, curr) => acc + (values.values[curr.key] || 0), 0);
          setNoAttendees(noAttendees);
          setTotal(Math.round(value * 100) / 100);

          if (changedValues.startDate) {
            setStartDate(changedValues.startDate);
            setTotal(0);
          }
        }}
      >
        <div
          style={{
            display: step === STEPS.SEARCH ? "flex" : "none",
            height: "100%",
            flexFlow: "column",
            padding: "16px",
          }}
        >
          <Typography.Text strong>{t("spas.admin.map.search")}</Typography.Text>
          <Typography.Text type="secondary">{t("catering.askSpace")}</Typography.Text>
          <Input
            style={{ width: "100%", margin: "16px 0" }}
            placeholder="Rechercher un espace"
            prefix={<Glyph name="search" />}
            onChange={handleSearch}
            value={searchSpaces}
          />
          <div>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {searchResult?.length === 0 ? (
                <Typography.Text>{t("NoResult")}</Typography.Text>
              ) : (
                <>
                  <List height={350} itemCount={searchResult?.length} itemSize={60}>
                    {ListSpaces}
                  </List>
                </>
              )}
            </Space>
          </div>
        </div>
        <div
          style={{
            display: step === STEPS.INFOS ? "flex" : "none",
            height: "100%",
            flexFlow: "column",
            padding: "16px",
          }}
        >
          <RoomDetails room={space} />

          <Card style={{ marginBottom: "16px", marginTop: "16px" }} bodyStyle={{ padding: "8px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography.Text strong>
                <Glyph
                  name="event"
                  style={{ marginRight: "8px", color: colors.secondary_base, fontWeight: "normal" }}
                />
                {t("catering.orderThe")}
              </Typography.Text>
              <Form.Item noStyle name={["startDate"]}>
                <DatePicker
                  format="DD MMM YYYY"
                  disabledDate={(date) => date.isBefore(moment(), "date")}
                />
              </Form.Item>
            </div>
          </Card>
          <Card bodyStyle={{ padding: "8px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography.Text strong>
                <Glyph
                  name="event"
                  style={{ marginRight: "8px", color: colors.secondary_base, fontWeight: "normal" }}
                />
                {t("From")}
              </Typography.Text>
              <Form.Item noStyle name={["startDate"]} trigger="onSelect">
                <TimePicker showNow={false} format="HH:mm" />
              </Form.Item>
            </div>
          </Card>
        </div>
        <div
          style={{
            display: step === STEPS.SERVICES ? "flex" : "none",
            height: "100%",
            flexFlow: "column",
            padding: "16px",
          }}
        >
          <Typography.Title level={5}>{t("catering.addAPrestra")}</Typography.Title>
          <Typography.Text type="secondary" style={{ marginBottom: "16px" }}>
            {t("catering.selectRubrique")}
          </Typography.Text>
          <Space style={{ width: "100%" }} direction="vertical" size="small">
            {services?.map((service) => (
              <Tile
                style={{ margin: "0" }}
                onClick={() => {
                  setRubrique(service);
                  setStep(STEPS.RUBRIQUE);
                }}
              >
                <Typography.Text strong>{service?.label}</Typography.Text>
                <Glyph name="navigate_next" />
              </Tile>
            ))}
          </Space>
        </div>
        <div
          style={{
            display: step === STEPS.RUBRIQUE ? "flex" : "none",
            height: "100%",
            flexFlow: "column",
            padding: "16px",
          }}
        >
          <Typography.Text type="secondary" strong style={{ marginBottom: "16px" }}>
            {rubrique?.label}
          </Typography.Text>
          <Space style={{ width: "100%" }} direction="vertical" size="small">
            {!isEmpty(canOrder) && (
              <>
                <Typography.Text strong>{t("catering.prestationAvailable")}</Typography.Text>
                {canOrder?.map((categorie, index) => (
                  <Tile
                    key={index}
                    style={{ margin: "0" }}
                    onClick={() => {
                      setStep(STEPS.DETAILS);
                      setInfoServices(categorie);
                    }}
                  >
                    <Typography.Paragraph strong style={{ margin: "0" }}>
                      {categorie?.name}
                    </Typography.Paragraph>
                    <Glyph name="navigate_next" />
                  </Tile>
                ))}
              </>
            )}
            {!isEmpty(canNotOrder) && (
              <>
                <Typography.Text strong>{t("catering.prestationNoAvailable")}</Typography.Text>
                <Typography.Text
                  type="secondary"
                  strong
                  style={{ width: "450px", marginBottom: "16px" }}
                >
                  {t("catering.orderOutOfTime")}
                </Typography.Text>
                {canNotOrder?.map((categorie, index) => (
                  <Tile key={index} style={{ margin: "0", cursor: "default" }}>
                    <Typography.Paragraph strong style={{ margin: "0" }}>
                      {categorie?.name}
                    </Typography.Paragraph>
                  </Tile>
                ))}
              </>
            )}
          </Space>
        </div>
        <div
          style={{
            display: step === STEPS.DETAILS ? "flex" : "none",
            height: "100%",
            flexFlow: "column",
            padding: "16px 0 0 0",
          }}
        >
          <div style={{ padding: "0 16px 0 16px" }}>
            <Typography.Text type="secondary" strong>
              {rubrique?.label} / {infoServices?.name}
            </Typography.Text>
            <Space style={{ width: "100%", margin: "9px 0" }} direction="vertical" size="small">
              {infoServices?.form?.elements?.map((element, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography.Text strong>
                      {element?.name} {element?.required ? "*" : null}
                    </Typography.Text>
                    {element?.type === "NUMBER" && (
                      <Typography.Text strong>
                        {element?.fee.toFixed(2)} {infoServices?.form?.currency}
                      </Typography.Text>
                    )}
                  </div>

                  {element?.type === "NUMBER" ? (
                    <Card>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Text strong>{t("amount")}</Typography.Text>
                        <Form.Item
                          noStyle
                          name={["values", element?.key]}
                          rules={[
                            {
                              required: element?.required,
                              message: t("obligatory"),
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ margin: -8 }}
                            size="medium"
                            min={0}
                            defaultValue={0}
                          />
                        </Form.Item>
                      </div>
                    </Card>
                  ) : (
                    <Form.Item
                      name={["values", element?.key]}
                      rules={[
                        {
                          required: element?.required,
                          message: t("obligatory"),
                        },
                      ]}
                    >
                      <TextArea
                        maxLength={100}
                        style={{
                          height: 100,
                          resize: "none",
                        }}
                      />
                    </Form.Item>
                  )}
                  <Typography.Text strong type="secondary">
                    {element?.description}
                  </Typography.Text>
                </div>
              ))}
            </Space>
          </div>
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "40px",
              borderTop: "1px solid black",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 16px",
              position: "sticky",
              bottom: "0",
            }}
          >
            <Typography.Text strong>{t("Total")}</Typography.Text>
            <Typography.Text>
              {total.toFixed(2)}
              {infoServices?.form?.currency}
            </Typography.Text>
          </div>
        </div>
        <div
          style={{
            display: step === STEPS.RESUME ? "flex" : "none",
            height: "100%",
            flexFlow: "column",
            padding: "16px 0 0 0",
          }}
        >
          <div style={{ padding: "0 16px 0 16px" }}>
            <Typography.Title level={5}>{t("catering.resume")}</Typography.Title>
            <Space
              style={{ width: "100%", marginBottom: "16px" }}
              direction="vertical"
              size="small"
            >
              <Tile style={{ margin: "0" }} onClick={() => setStep(STEPS.DETAILS)}>
                <Typography.Text strong>{presta?.name}</Typography.Text>
                <Typography.Text>{presta?.formProps?.no_Attendees}</Typography.Text>
              </Tile>
            </Space>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateServicesModal;
