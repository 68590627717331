import { any, gt, propSatisfies, __ } from "ramda";

const INITIAL_STATE = {};

export default function LoadingManager(state = INITIAL_STATE, action) {
  const { type } = action;
  const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let stack =
    requestState === "pending" ? (state[requestName] || 0) + 1 : (state[requestName] || 0) - 1;

  if (stack < 0) stack = 0;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: stack,
  };
}

export const createLoadingSelector = (actions) => (state) => {
  // returns false only when all actions is not loading
  const isLoading = propSatisfies(gt(__, 0), __, state.LoadingManager);
  return any(isLoading)(actions);
};
