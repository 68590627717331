import { Button, Card, message, Radio, Space, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

// import component
import useSelectedRoom from "../../../services/redux/useSelectedRoom";
import useMap from "../../Common/Map/useMap";
import Glyph from "../../Common/Glyph/Glyph";
import RoomDetails from "../../Common/RoomDetails/RoomDetails";
import Tile from "../../Common/Tile/Tile";
import useDesignTokens from "../../../hook/useDesignTokens";

// import css
import Form from "antd/lib/form/Form";
import Confirmation from "./Confirmation";
import styles from "./Fastbooking.module.less";

// import redux
import { isEmpty, isNil } from "ramda";
import { addFavoris, removeFavoris } from "../../../services/redux/services/ClientsWS";
import {
  getRoomFastbooking,
  setMakeBooking,
  setRemoveBooking,
} from "../../../services/redux/services/FastbookingWS";
import { TitleSource } from "../../Common/Teleporters/Title";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

let time;
const RoomFastbooking = () => {
  const { centerOnPlaceId } = useMap();
  const dispatch = useDispatch();
  const selectedRoom = useSelectedRoom();
  const location = useLocation();
  const history = useHistory();
  const { colors } = useDesignTokens();

  const { t, i18n } = useTranslation();

  const [isVisibleConfirmation, setVisibleConfirmation] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(null);
  const [duration, setDuration] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const favoris = useSelector((state) => state.clientsWS.favoris);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const occupancy = useSelector((state) => state.occupancyWS.occupancy);
  const campus = useSelector((state) => state.clientsWS.campus);

  const roomFavoris = favoris.find((f) => f.id === selectedRoom.id);

  const addFav = () => {
    dispatch(addFavoris(selectedRoom));
  };

  const removeFav = () => {
    dispatch(removeFavoris(selectedRoom.id));
  };

  useEffect(() => {
    if (!isEmpty(filters) && !isNil(filters)) {
      dispatch(
        getRoomFastbooking({
          locale: i18n.language.split("-")[0],
          ...filters,
          startDate: filters.startDate || moment().toISOString(),
        }),
      );
    }
  }, [dispatch, filters, i18n.language]);

  const timeOut = () => {
    time = setTimeout(() => {
      dispatch(
        setMakeBooking({
          roomId: selectedRoom.id,
          startDate: filters.startDate
            ? moment(filters.startDate).toISOString()
            : moment().toISOString(),
          duration: parseInt(duration),
        }),
      )
        .unwrap()
        .then(() => {
          setCurrent(current + 1);
          setStatus("success");
          logEvent(analytics, "fast_booking_book_confirmed");
        })
        .catch(() => {
          setCurrent(current);
          setCurrent(current + 2);
          setStatus("error");
        });
    }, 6000);
  };

  const timeVailable =
    roomFastbooking && roomFastbooking?.rooms?.find((room) => room?.roomId === selectedRoom?.id);

  const onChange = (e) => {
    setDuration(e.target.value);
    logEvent(analytics, "fast_booking_booking_duration", {
      duration: e.target.value,
    });
  };

  return (
    <>
      <TitleSource>{t("Fastbooking")}</TitleSource>
      {!isNil(filters?.startDate) ||
      ((occupancy.status === "AVAILABLE" || occupancy.status === "UNKNOWN") &&
        selectedRoom?.features?.bookable === true) ? (
        <Card
          title={
            <>
              <Typography.Title level={5}>
                <NavLink to="/fastBooking" onClick={() => centerOnPlaceId()}>
                  <Glyph
                    name="arrow_back_ios"
                    style={{
                      verticalAlign: "-2px",
                      fontSize: "20px",
                      color: "black",
                      marginRight: "8px",
                    }}
                  />
                </NavLink>
                {selectedRoom.title}
              </Typography.Title>
              <Typography.Text strong style={{ fontSize: "14px", color: colors.pending_light }}>
                {location?.state?.room?.availableTime >= 60
                  ? `${t("Available")} > 1h`
                  : `${moment
                      .duration(
                        moment(location?.state?.room?.endDate).diff(moment(), "minutes"),
                        "minutes",
                      )
                      .minutes()}
                        mins`}{" "}
                - {t("Floor")}{" "}
                {
                  campus?.mapData?.floors.find(
                    (floor) => floor.reference === selectedRoom.floorReference,
                  )?.displayName
                }
              </Typography.Text>
            </>
          }
          extra={
            roomFavoris ? (
              <Button
                style={{ border: "none", background: "none", color: colors.secondary_base }}
                type="primary"
                icon={<Glyph name="star" />}
                onClick={removeFav}
              ></Button>
            ) : (
              <Button
                style={{ border: "none", background: "none", color: colors.grey_40 }}
                type="primary"
                icon={<Glyph name="star" />}
                onClick={addFav}
              ></Button>
            )
          }
          actions={[
            <Button
              type="primary"
              style={{ width: "350px" }}
              disabled={!duration}
              onClick={() => {
                setVisibleConfirmation(true);
                timeOut();
              }}
            >
              {t("Book")}
            </Button>,
          ]}
          style={{ width: "35%", height: "fit-content" }}
        >
          <RoomDetails room={selectedRoom} withTitle={false} />
          <Typography.Title level={5} style={{ margin: "16px 0" }}>
            {t("BookDuring")}
          </Typography.Title>
          <Form>
            <Radio.Group onChange={onChange}>
              <Space style={{ width: "100%" }} direction="vertical" size="small">
                <Tile
                  style={{
                    margin: "0",
                    display: "inherit",
                  }}
                >
                  <Radio
                    value="30"
                    className={styles["radio-fastbooking"]}
                    disabled={timeVailable?.availableTime < 30}
                  >
                    <Typography.Text strong>30 mins</Typography.Text>
                  </Radio>
                </Tile>
                <Tile
                  style={{
                    margin: "0",
                    display: "inherit",
                  }}
                >
                  <Radio
                    value="45"
                    className={styles["radio-fastbooking"]}
                    disabled={timeVailable?.availableTime < 45}
                  >
                    <Typography.Text strong>45 mins</Typography.Text>
                  </Radio>
                </Tile>
                <Tile
                  style={{
                    margin: "0",
                    display: "inherit",
                  }}
                >
                  <Radio
                    value="60"
                    className={styles["radio-fastbooking"]}
                    disabled={timeVailable?.availableTime < 60}
                  >
                    <Typography.Text strong>1h</Typography.Text>
                  </Radio>
                </Tile>
              </Space>
            </Radio.Group>
          </Form>
        </Card>
      ) : (
        <Card
          title={
            <>
              <Typography.Title level={5}>
                <NavLink to="/fastBooking" onClick={() => centerOnPlaceId()}>
                  <Glyph
                    name="arrow_back_ios"
                    style={{
                      verticalAlign: "-2px",
                      fontSize: "20px",
                      color: "black",
                      marginRight: "8px",
                    }}
                  />
                </NavLink>
                {selectedRoom?.title}
              </Typography.Title>
              <Typography.Text strong style={{ fontSize: "14px", color: colors.pending_light }}>
                {selectedRoom?.features?.bookable === true ? "Occupé" : ""}
              </Typography.Text>
            </>
          }
          extra={
            roomFavoris ? (
              <Button
                style={{ border: "none", background: "none", color: colors.secondary_base }}
                type="primary"
                icon={<Glyph name="star" />}
                onClick={removeFav}
              ></Button>
            ) : (
              <Button
                style={{ border: "none", background: "none", color: colors.grey_40 }}
                type="primary"
                icon={<Glyph name="star" />}
                onClick={addFav}
              ></Button>
            )
          }
          actions={[
            <Button
              loading={isLoading}
              type="primary"
              style={{ width: "350px" }}
              onClick={() => {
                logEvent(analytics, "fast_booking_itinerary");
              }}
            >
              <Link
                to={{
                  pathname: "/indoorMapping/itinerary",
                  state: {
                    back: `/fastBooking/${selectedRoom?.map?.findAndOrder?.placeId}`,
                    selectedRoom: selectedRoom,
                  },
                }}
                style={{ color: "white" }}
              >
                {t("SeeItinerary")}
              </Link>
            </Button>,
            selectedRoom?.features?.bookable === true ? (
              <Button
                loading={isLoading}
                ghost
                style={{ width: "350px" }}
                disabled={!location?.state?.room?.isOrganizer}
                onClick={() => {
                  setIsLoading(true);
                  dispatch(
                    setRemoveBooking({
                      slotId: location?.state?.room?.id,
                      roomId: selectedRoom?.id,
                    }),
                  )
                    .unwrap()
                    .then(() => {
                      message.success(t("BookCanceled"));
                      history.push("/fastBooking");
                      setIsLoading(false);
                      logEvent(analytics, "fast_booking_book_cancelled");
                    })
                    .catch(() => {
                      message.error(t("ErrorCancelBook"));
                      setIsLoading(false);
                    });
                }}
              >
                {t("CancelBooking")}
              </Button>
            ) : null,
          ]}
          style={{ width: "35%", height: "fit-content" }}
        >
          <RoomDetails room={selectedRoom} withTitle={false} />
        </Card>
      )}

      <Confirmation
        isVisible={isVisibleConfirmation}
        current={current}
        setCurrent={setCurrent}
        status={status}
        selectedRoom={selectedRoom}
        duration={duration}
        onCancel={() => {
          setVisibleConfirmation(false);
          clearTimeout(time);
        }}
      />
    </>
  );
};

export default RoomFastbooking;
