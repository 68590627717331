import { Button, Input, Space, Table, Typography } from "antd";
import moment from "moment";
import { pluck } from "ramda";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../../../hook/useDebounce";
import useDidUpdateEffect from "../../../../../hook/useDidUpdate";
import { createLoadingSelector } from "../../../../../services/redux/managers/LoadingManager";
import Glyph from "../../../../Common/Glyph/Glyph";
import { ActionBannerSource } from "../ActionBanner";
import PendingRequestModal from "../PendingRequestModal";
import { SORT } from "../SpaceBookingDashboard";

const STATUS = "APPROVED";

const Approved = ({ data, onListRequests, onSeeMore, onRefresh }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showPendingModal, setShowPendingModal] = useState(false);

  const isLoading = useSelector(createLoadingSelector(["spaceService/listRequests"]));

  const searchInput = useRef();
  const searchInputDebounced = useDebounce(searchInputValue, 500);
  useDidUpdateEffect(() => {
    if (!data) return;
    onListRequests({
      status: STATUS,
      page: 1,
      size: +data.size,
      search: searchInputDebounced,
      sortField: data.order,
      sortDir: data.direction,
    })
      .unwrap()
      .then(() => {
        searchInput.current.focus();
      });
    // Voluntarily omiting some deps to run the hook only with search input
  }, [onListRequests, searchInputDebounced]);

  const onPagination = (page, size) => {
    onListRequests({
      status: STATUS,
      page,
      size,
      search: searchInputDebounced,
      sortField: data.order,
      sortDir: data.direction,
    });
  };

  const columns = [
    {
      title: t("spas.admin.tabs.resident"),
      dataIndex: ["request", "user", "displayName"],
      sorter: true,
      sortOrder: data?.order === "userName" ? SORT[data?.direction] : undefined,
      onHeaderCell: () => ({
        onClick: () => {
          onListRequests({
            status: STATUS,
            page: +data.page,
            size: +data.size,
            search: searchInputDebounced,
            sortField: data.direction === "DESC" ? undefined : "userName",
            sortDir: data.order !== "userName" ? "ASC" : data.direction === "ASC" ? "DESC" : "ASC",
          });
        },
      }),
    },
    {
      title: t("spas.admin.tabs.manager"),
      dataIndex: ["request", "user", "manager", "displayName"],
    },
    {
      title: t("spas.admin.tabs.space"),
      dataIndex: ["space", "title"],
    },
    {
      title: t("spas.admin.tabs.affectedAt"),
      dataIndex: ["statusDate"],
      render: (value) => moment(value).format("DD/MM/YYYY - HH:mm"),
      sorter: true,
      sortOrder: data?.order === "statusDate" ? SORT[data?.direction] : undefined,
      onHeaderCell: () => ({
        onClick: () => {
          onListRequests({
            status: STATUS,
            page: +data.page,
            size: +data.size,
            search: searchInputDebounced,
            sortField: data.direction === "DESC" ? undefined : "statusDate",
            sortDir:
              data.order !== "statusDate" ? "ASC" : data.direction === "ASC" ? "DESC" : "ASC",
          });
        },
      }),
    },
    {
      title: t("spas.admin.tabs.createdAt"),
      dataIndex: ["request", "createdAt"],
      render: (value) => moment(value).format("DD/MM/YYYY - HH:mm"),
      sorter: true,
      sortOrder: data?.order === "createdAt" ? SORT[data.direction] : undefined,
      onHeaderCell: () => ({
        onClick: () => {
          onListRequests({
            status: STATUS,
            page: +data.page,
            size: +data.size,
            search: searchInputDebounced,
            sortField: data.direction === "DESC" ? undefined : "createdAt",
            sortDir: data.order !== "createdAt" ? "ASC" : data.direction === "ASC" ? "DESC" : "ASC",
          });
        },
      }),
    },
    {
      title: t("Infos"),
      key: "seeMore",
      render: (_, record) => (
        <Typography.Text style={{ cursor: "pointer" }} underline onClick={() => onSeeMore(record)}>
          {t("SeeMore")}
        </Typography.Text>
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Input
          ref={searchInput}
          placeholder={t("spas.admin.tabs.search")}
          style={{ maxWidth: 400 }}
          disabled={isLoading}
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />
        <Table
          loading={isLoading}
          rowKey={"id"}
          bordered={false}
          style={{ marginLeft: -20, marginRight: -20, maxWidth: "none" }}
          size="small"
          columns={columns}
          dataSource={data?.items || []}
          rowSelection={{
            selectedRowKeys: pluck("id", selectedRows),
            onChange: (_, rows) => setSelectedRows(rows),
          }}
          pagination={
            data
              ? {
                  current: +data.page,
                  pageSize: +data.size,
                  total: data.totalItems,
                  onChange: onPagination,
                }
              : undefined
          }
        />
      </Space>

      <PendingRequestModal
        visible={showPendingModal}
        slots={selectedRows}
        onSubmit={() => {
          setShowPendingModal(false);
          onRefresh();
        }}
        onCancel={() => setShowPendingModal(false)}
      />

      <ActionBannerSource visible={selectedRows.length}>
        <Typography.Text>
          {t("spas.admin.tabs.selected", { count: selectedRows.length })}
        </Typography.Text>
        <Space>
          <Button icon={<Glyph name="desk" />} type="primary" onClick={() => null} disabled>
            {t("spas.request.actions.map")}
          </Button>
          <Button
            icon={<Glyph name="desk" />}
            type="primary"
            onClick={() => history.push("/spaceBooking/admin", { slots: selectedRows })}
          >
            {t("spas.request.actions.updateSpace", { count: selectedRows.length })}
          </Button>
          <Button
            icon={<Glyph name="desk" />}
            type="primary"
            onClick={() => setShowPendingModal(true)}
          >
            {t("spas.request.actions.removeSpace", { count: selectedRows.length })}
          </Button>
        </Space>
      </ActionBannerSource>
    </>
  );
};

export default Approved;
