import { Card, Space, Typography } from "antd";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Tile from "../../../Common/Tile/Tile";
import styles from "../Transport.module.less";
import { addLastSearchTransport } from "../../../../services/redux/services/ClientsWS";
import { isEmpty } from "ramda";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const Routes = ({ setIsJourney, setInputSearchFrom }) => {
  const { colors, symbols } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listStop = useSelector((state) => state.transportWS.listStopForRoutes);
  const lastSearch = useSelector((state) => state.clientsWS.lastSearchTransport);

  return (
    <div style={{ overflowY: "scroll", height: "500px", padding: "10px" }}>
      {!isEmpty(listStop) ? (
        <div>
          <Space style={{ width: "100%", padding: "10px" }} direction="vertical" size="small">
            {listStop?.places?.map((place, index) => (
              <Tile
                style={{ margin: "0", height: "auto", padding: "10px", flexFlow: "wrap" }}
                key={index}
                onClick={() => {
                  setIsJourney(true);
                  setInputSearchFrom(place);
                  dispatch(addLastSearchTransport(place));
                  logEvent(analytics, `transport_itinerary_result`);
                }}
              >
                {place?.type !== "address" ? (
                  <div style={{ width: "90%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "wrap",
                        marginBottom: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Glyph
                        name="directions_bus"
                        style={{ marginRight: "8px", fontSize: "30px", color: colors?.grey_60 }}
                      />
                      {place?.lines?.map((line, index) => (
                        <Typography.Text
                          key={index}
                          strong
                          style={{
                            backgroundColor: line?.color,
                            borderRadius: symbols?.base_shape?.radius,
                            textAlign: "center",
                            color: line?.textColor,
                            minWidth: "30px",
                            height: "30px",
                            lineHeight: "30px",
                            padding: "0 6px",
                            marginRight: "4px",
                          }}
                        >
                          {line?.code}
                        </Typography.Text>
                      ))}
                    </div>
                    <Typography.Text strong>{place?.name}</Typography.Text>
                  </div>
                ) : (
                  <div style={{ width: "90%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "wrap",
                        marginBottom: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Glyph
                        name="place"
                        style={{ color: colors?.secondary_base, marginRight: "16px" }}
                      />
                      <div style={{ display: "flex", flexFlow: "column" }}>
                        <Typography.Text strong>{place?.name}</Typography.Text>
                        <Typography.Text>
                          {place?.zipcode} {place?.city}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                )}

                <Glyph name="navigate_next" />
              </Tile>
            ))}
          </Space>
        </div>
      ) : (
        <div style={{ marginTop: "16px" }}>
          {lastSearch?.length > 0 && <Typography.Text>{t("my_latest_research")}</Typography.Text>}

          <Space style={{ width: "100%" }} direction="vertical" size="small">
            {lastSearch?.map((last, index) => (
              <Card
                key={index}
                className={styles["lastSearch"]}
                bodyStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexFlow: "row",
                  width: "100%",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsJourney(true);
                  setInputSearchFrom(last);
                  logEvent(analytics, `transport_itinerary_result`);
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Glyph
                    name="history"
                    style={{ color: colors?.secondary_base, marginRight: "8px" }}
                  />
                  <Typography.Text strong>{last?.name}</Typography.Text>
                </div>
                <Glyph name="navigate_next" />
              </Card>
            ))}
          </Space>
        </div>
      )}
    </div>
  );
};

export default Routes;
