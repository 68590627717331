import { Button, Col, Form, Modal, Progress, Row, Typography } from "antd";
import clsx from "clsx";
import * as moment from "moment";
import { T, cond, equals } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Confirm } from "../../../../../assets/svg/confirmation.svg";
import { ReactComponent as Error } from "../../../../../assets/svg/error.svg";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { getMapData } from "../../../../../services/redux/services/MapDataWS";
import { listWorkplace, workplaceCount } from "../../../../../services/redux/services/SpaceService";
import Glyph from "../../../../Common/Glyph/Glyph";
import styles from "../../SpaceBooking.module.less";
import { STEPS } from "../../SpaceBookingResident";
import PeopleFollow from "../PeopleFollow";
import CreateStepDate from "./Steps/CreateStepDate";
import CreateStepReview from "./Steps/CreateStepReview";
import CreateStepWorkplace from "./Steps/CreateStepWorkplace";
import CreateStepWorkplaceMap from "./Steps/CreateStepWorkplaceMap";

const STEP_STYLES = {
  paddingTop: 30,
  paddingLeft: 30,
  paddingBottom: 30,
  paddingRight: 30,
  flexGrow: 1,
};

const AnimatedProgress = ({ status, step }) => {
  const [percent, setPercent] = useState(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (step !== STEPS.VALIDATION) {
      setPercent(0);
      setReady(false);
    }
  }, [step]);

  useEffect(() => {
    let interval;
    if (ready && step === STEPS.VALIDATION) {
      interval = setInterval(() => {
        setPercent(percent + 20);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [ready, percent, step]);

  useEffect(() => {
    if (!ready) setReady(true);
  }, [ready]);

  return (
    <Progress
      type="circle"
      percent={percent}
      style={{ display: "flex", justifyContent: "end" }}
      width={80}
      showInfo={percent >= 100}
      status={status === "error" ? "exception" : status === "success" ? "success" : "normal"}
    />
  );
};

const CreateRequestModal = ({ visible, onSubmit, onCancel, status, setStep, step }) => {
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const campus = useSelector((state) => state.clientsWS.campus);
  const slots = useSelector((state) => state.spaceServiceWS.slots);

  const siteId = Form.useWatch("siteId", form);
  // const sectorId = Form.useWatch("sectorId", form);

  const spaceBookingType = useMemo(() => {
    return sites?.find((c) => c.id === siteId)?.spaceBooking?.type || 0;
  }, [siteId, sites]);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setStep(STEPS.VALIDATION);
      await onSubmit(values);
    } finally {
      setIsLoading(false);
    }
  };

  const INITIAL_VALUES = {
    startDate: typeof visible === "string" ? moment(visible).toISOString() : moment().toISOString(),
    siteId: sites?.find((c) => c?.id === campus?.mapData?.id)?.id,
    sectorId: sites?.find((c) => c?.id === campus?.mapData?.id)?.sectors[0]?.id,
    period: "DAY",
    recurrence: "NONE",
  };

  useEffect(() => {
    dispatch(
      workplaceCount({
        siteId: INITIAL_VALUES.siteId,
        date: moment(INITIAL_VALUES.startDate).format("YYYY-MM-DD"),
      }),
    );
  }, [INITIAL_VALUES.siteId, INITIAL_VALUES.startDate, dispatch]);

  useEffect(() => {
    dispatch(
      listWorkplace({
        siteId: INITIAL_VALUES.siteId,
        sectorId: INITIAL_VALUES.sectorId,
        date: moment(INITIAL_VALUES.startDate).format("YYYY-MM-DD"),
        period: INITIAL_VALUES.period,
      }),
    );
  }, [
    INITIAL_VALUES.period,
    INITIAL_VALUES.sectorId,
    INITIAL_VALUES.siteId,
    INITIAL_VALUES.startDate,
    dispatch,
  ]);

  const isStepSubmit = step === STEPS.VALIDATION || step === STEPS.SUCCESS || step === STEPS.ERROR;

  const error = () => {
    Modal.error({
      title: <Typography.Text strong>{t("spas.request.error.date")}</Typography.Text>,
      okText: <div block>{t("Closed")}</div>,
      closable: true,
      maskClosable: true,
    });
  };

  return (
    <Modal
      destroyOnClose={true}
      className={clsx(styles.modal)}
      open={!!visible}
      title={
        <>
          <Typography.Text strong>
            {typeof visible === "string"
              ? moment(visible).format("DD MMM YYYY")
              : t("spas.request.new")}
          </Typography.Text>
          {cond([
            [equals(STEPS.WORKPLACE_MAP), () => <CreateStepWorkplaceMap.Helper />],
            [T, () => null],
          ])(step)}
        </>
      }
      onCancel={onCancel}
      width={isStepSubmit ? undefined : 1000}
      bodyStyle={{ padding: "0" }}
      footer={null}
    >
      <Form
        form={form}
        preserve={false}
        initialValues={{
          ...INITIAL_VALUES,
          startDate: moment(INITIAL_VALUES.startDate),
          endDate: moment(INITIAL_VALUES.startDate).endOf("day"),
        }}
        onValuesChange={(changed, values) => {
          if (changed.siteId) {
            const sectorId = sites?.find((c) => c?.id === values?.siteId)?.sectors[0]?.id;
            form.setFieldsValue({ sectorId });
            dispatch(getMapData({ locale: i18n.language.split("-")[0], siteId: values?.siteId }));
            dispatch(
              listWorkplace({
                siteId: values?.siteId,
                sectorId: sectorId,
                date: moment(values?.startDate).format("YYYY-MM-DD"),
                period: values?.period,
              }),
            );
          }
          if (changed.sectorId || changed.startDate || changed.period) {
            dispatch(
              listWorkplace({
                siteId: values?.siteId,
                sectorId: values?.sectorId,
                date: moment(values?.startDate).format("YYYY-MM-DD"),
                period: values?.period,
              }),
            );
          }
          if (changed.siteId || changed.startDate) {
            dispatch(
              workplaceCount({
                siteId: values?.siteId,
                date: moment(values?.startDate).format("YYYY-MM-DD"),
              }),
            );
          }
          if (changed.spaceId) {
            setStep(STEPS.REVIEW);
          }
        }}
        onFinish={handleSubmit}
        onFinishFailed={({ values, errorFields, outOfDate }) =>
          console.log({ values, errorFields, outOfDate })
        }
        style={{ height: "100%" }}
      >
        <Row gutter={[0, 40]} style={{ width: "100%", height: "100%" }}>
          <Col
            span={isStepSubmit ? 24 : 14}
            style={{
              borderRight: isStepSubmit ? "none" : "1px solid #a8bec3ff",
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* DATE */}
            <div
              style={{
                ...STEP_STYLES,
                display: step === STEPS.DATES ? "block" : "none",
              }}
            >
              <CreateStepDate visible={visible} />
            </div>
            {/* WORKPLACE */}
            <div
              style={{
                overflowY: "scroll",
                display: step === STEPS.WORKPLACE ? "block" : "none",
                padding: "30px",
              }}
            >
              <Form.Item noStyle name={["spaceId"]}>
                <CreateStepWorkplace siteId={form.getFieldValue(["siteId"])} />
              </Form.Item>
            </div>
            {/* WORKPLACE MAP */}
            {spaceBookingType === 3 && process.env.REACT_APP_MAP_PROVIDER === "mapbox" && (
              <div
                style={{
                  ...STEP_STYLES,
                  display: step === STEPS.WORKPLACE_MAP ? "block" : "none",
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                }}
              >
                <Form.Item noStyle shouldUpdate={(pre, cur) => pre.sectorId !== cur.sectorId}>
                  {({ getFieldValue }) => (
                    <Form.Item noStyle name={["spaceId"]}>
                      <CreateStepWorkplaceMap sectorId={getFieldValue("sectorId")} />
                    </Form.Item>
                  )}
                </Form.Item>
              </div>
            )}
            {/* REVIEW */}
            <div
              style={{
                ...STEP_STYLES,
                display: step === STEPS.REVIEW ? "block" : "none",
              }}
            >
              <CreateStepReview form={form} />
            </div>
            {/* LOADING */}
            <div
              style={{
                ...STEP_STYLES,
                display: step === STEPS.VALIDATION ? "block" : "none",
              }}
            >
              <Row gutter={[0, 40]} style={{ width: "100%" }}>
                <Col
                  span={24}
                  style={{
                    paddingTop: "30px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography.Title strong level={2}>
                        {t("spas.request.reservation")}
                      </Typography.Title>
                      <AnimatedProgress status={status} step={step} />
                    </div>
                    <div>
                      <Typography.Paragraph strong style={{ fontSize: "24px", margin: "24px 0" }}>
                        <Glyph
                          name="check_circle"
                          style={{
                            marginRight: "8px",
                            fontSize: "20px",
                            verticalAlign: "-1px",
                            color: colors.success_light,
                          }}
                        />
                        {sites?.find((site) => site.id === form.getFieldValue(["siteId"]))?.title}
                      </Typography.Paragraph>
                      <Typography.Paragraph strong style={{ fontSize: "24px", margin: "24px 0" }}>
                        <Glyph
                          name="check_circle"
                          style={{
                            marginRight: "8px",
                            fontSize: "20px",
                            verticalAlign: "-1px",
                            color: colors.success_light,
                          }}
                        />
                        {moment(form.getFieldValue(["startDate"])).format("DD MMM YYYY")}
                      </Typography.Paragraph>
                      <Typography.Paragraph strong style={{ fontSize: "24px", margin: "24px 0" }}>
                        <Glyph
                          name="check_circle"
                          style={{
                            marginRight: "8px",
                            fontSize: "20px",
                            verticalAlign: "-1px",
                            color: colors.success_light,
                          }}
                        />
                        {t(`spas.period.${form.getFieldValue(["period"])}`)}
                      </Typography.Paragraph>
                      <Typography.Paragraph strong style={{ fontSize: "24px", margin: "24px 0" }}>
                        <Glyph
                          name="check_circle"
                          style={{
                            marginRight: "8px",
                            fontSize: "20px",
                            verticalAlign: "-1px",
                            color: colors.success_light,
                          }}
                        />
                        {t("spas.recurrence.RECURRENCE")}{" "}
                        {form.getFieldValue(["recurrence"])
                          ? t(`spas.recurrence.${form.getFieldValue(["recurrence"])}`, {
                              day: moment(visible).format("dddd"),
                            })
                          : t("spas.recurrence.NONE")}
                      </Typography.Paragraph>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
            {/* SUCCESS */}
            <div
              style={{
                ...STEP_STYLES,
                display: step === STEPS.SUCCESS ? "block" : "none",
              }}
            >
              <Row gutter={[0, 40]} style={{ width: "100%" }}>
                <Col
                  span={24}
                  style={{
                    paddingTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col>
                    <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                      <Confirm />
                      <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: "20px" }}>
                        <Glyph
                          name="check_circle"
                          style={{
                            fontSize: "18px",
                            verticalAlign: "-1px",
                            marginRight: "8px",
                            fontWeight: "normal",
                            color: colors.success_light,
                          }}
                        />
                        {t("spas.request.resaAccepted")}
                      </Typography.Paragraph>
                      <Typography.Paragraph strong className="waiting">
                        {moment(form.getFieldValue(["startDate"])).format("DD MMM YYYY")}
                      </Typography.Paragraph>
                      <Typography.Paragraph strong className="waiting">
                        {sites?.find((site) => site.id === form.getFieldValue(["siteId"]))?.title}
                      </Typography.Paragraph>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
            {/* ERROR */}
            <div
              style={{
                ...STEP_STYLES,
                display: step === STEPS.ERROR ? "block" : "none",
              }}
            >
              <Row gutter={[0, 40]} style={{ width: "100%" }}>
                <Col
                  span={24}
                  style={{
                    paddingTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col>
                    <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                      <Error />
                      <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: "20px" }}>
                        <Glyph
                          name="cancel"
                          style={{
                            fontSize: "18px",
                            verticalAlign: "-1px",
                            marginRight: "8px",
                            fontWeight: "normal",
                            color: colors.error_light,
                          }}
                        />
                        {t("spas.request.resaRefused")}
                      </Typography.Paragraph>
                      <Typography.Paragraph strong className="waiting">
                        {t("spas.request.tryAgain")}
                      </Typography.Paragraph>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
            <div
              style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                gap: 8,
                padding: 10,
                zIndex: 99,
              }}
            >
              {step === STEPS.DATES ? (
                <>
                  <Button ghost block key="cancel" onClick={() => onCancel()}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    type="primary"
                    block
                    key="submit"
                    loading={isLoading}
                    disabled={
                      !!slots?.find(
                        (s) =>
                          s?.date ===
                          moment(form.getFieldValue(["startDate"])).format("YYYY-MM-DD"),
                      )
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (spaceBookingType !== 3) {
                        if (
                          moment(form.getFieldValue(["startDate"])).isAfter(
                            moment(form.getFieldValue(["endDate"])),
                          )
                        ) {
                          error();
                        } else {
                          form.submit();
                        }
                      } else {
                        if (
                          moment(form.getFieldValue(["startDate"])).isAfter(
                            moment(form.getFieldValue(["endDate"])),
                          )
                        ) {
                          error();
                        } else {
                          setStep(STEPS.WORKPLACE_MAP);
                        }
                      }
                    }}
                  >
                    {t("Next")}
                  </Button>
                </>
              ) : step === STEPS.WORKPLACE ? (
                <>
                  <Button
                    ghost
                    block
                    key="cancel"
                    onClick={() => {
                      setStep(STEPS.DATES);
                    }}
                  >
                    {t("Back")}
                  </Button>
                  <Button
                    block
                    type="secondary"
                    key="submit"
                    onClick={() => {
                      setStep(STEPS.WORKPLACE_MAP);
                    }}
                  >
                    <Glyph name={"map"} />
                    {t("spas.request.actions.map")}
                  </Button>
                </>
              ) : step === STEPS.WORKPLACE_MAP ? (
                <>
                  <Button
                    ghost
                    block
                    key="cancel"
                    onClick={() => {
                      setStep(STEPS.DATES);
                    }}
                  >
                    {t("Back")}
                  </Button>
                  <Button
                    block
                    type="secondary"
                    key="submit"
                    onClick={() => {
                      setStep(STEPS.WORKPLACE);
                    }}
                  >
                    <Glyph name={"list"} />
                    {t("spas.request.actions.workplaces")}
                  </Button>
                </>
              ) : step === STEPS.REVIEW ? (
                <>
                  <Button
                    ghost
                    block
                    key="cancel"
                    onClick={() => {
                      if (spaceBookingType !== 3) {
                        setStep(STEPS.DATES);
                      } else {
                        setStep(STEPS.WORKPLACE_MAP);
                      }
                    }}
                  >
                    {t("Back")}
                  </Button>
                  <Button
                    type="primary"
                    block
                    key="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    <Glyph name={"desk"} />
                    {t("spas.request.actions.confirmBooking")}
                  </Button>
                </>
              ) : step === STEPS.SUCCESS ? (
                <Button type="primary" block key="cancel" onClick={() => onCancel()}>
                  {t("spas.request.seeDay")}
                </Button>
              ) : step === STEPS.ERROR ? (
                <Button type="primary" block key="cancel" onClick={() => onCancel()}>
                  {t("spas.request.backCalendar")}
                </Button>
              ) : (
                <Button ghost block key="cancel" onClick={() => onCancel()}>
                  {t("Cancel")}
                </Button>
              )}
            </div>
          </Col>
          {!isStepSubmit && (
            <Col span={10}>
              <div style={{ height: "100%", padding: "30px 20px" }}>
                <Form.Item
                  noStyle
                  shouldUpdate={(pre, cur) =>
                    pre.siteId !== cur.siteId || pre.startDate !== cur.startDate
                  }
                >
                  {({ getFieldValue }) => (
                    <PeopleFollow
                      siteId={getFieldValue(["siteId"])}
                      date={moment(getFieldValue(["startDate"])).format("YYYY-MM-DD")}
                    />
                  )}
                </Form.Item>
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateRequestModal;
