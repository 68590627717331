import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import { Avatar, Button, List, Typography } from "antd";
import { splitAt } from "ramda";
import styles from "./UserList.module.less";
import { useSelector } from "react-redux";

const UserList = ({
  users = [],
  onClick,
  onDelete,
  descriptionKey = "function",
  maxSize = Infinity,
  itemProps,
  ...rest
}) => {
  const [displayed, more] = splitAt(maxSize, users);
  const isVisitor = useSelector((state) => state.userWS.userData.profile.isVisitor);

  return users.length ? (
    <>
      <List
        {...rest}
        className={styles.list}
        itemLayout="horizontal"
        dataSource={displayed}
        renderItem={(user) => (
          <>
            <List.Item
              {...itemProps}
              key={user.id}
              onClick={onClick ? () => onClick(user) : undefined}
              extra={[
                onDelete && !isVisitor && (
                  <Button
                    key="delete"
                    type="danger"
                    shape="circle"
                    size="middle"
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      return onDelete(user);
                    }}
                  />
                ),
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                  // src={ACMConnector.baseURL + user.pictureUrl}
                  >
                    {user?.firstname?.charAt(0) + user?.lastname?.charAt(0)}
                  </Avatar>
                }
                title={
                  <Typography.Text style={{ cursor: "pointer", width: "100%", display: "block" }}>
                    {user?.firstname + " " + user?.lastname}
                  </Typography.Text>
                }
                description={user[descriptionKey]}
              />
            </List.Item>
          </>
        )}
      />
      <div style={{ position: "absolute", bottom: -15, right: 0 }}>
        {more.length > 1
          ? `+${more.length} autres`
          : more.length === 1
          ? `+${more.length} autre`
          : null}
      </div>
    </>
  ) : null;
};

export default UserList;
