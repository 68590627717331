// import redux
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";
import { setLighting } from "../../../../../services/redux/services/ConfortWS";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { message, Segmented } from "antd";

// import css
import styles from "../SettingsUI.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

const LightOnOff = ({ setIsLoading, setStatus, zone, selectedRoomNomap }) => {
  const { t } = useTranslation();
  const selectedRoom = useSelectedRoom();
  const dispatch = useDispatch();

  const dispatchLight = (value) => {
    const command = value === "Allumé" ? "L_ON" : "L_OFF";
    setIsLoading(true);
    logEvent(analytics, "smart_control_command_light", {
      zone: zone !== undefined ? "zone" : "total",
      type_light: "on/off",
    });
    dispatch(
      setLighting({
        roomId: selectedRoomNomap?.id || selectedRoom.id,
        zone: zone ? zone : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetLight"), 5);
        setStatus("error");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [value, setValue] = useState(null);

  return (
    <>
      <div className={styles["SettingsUI__content"]} style={{ justifyContent: "center" }}>
        <Segmented
          size="large"
          value={value}
          options={[
            { label: <p style={{ margin: "0" }}>Allumer</p>, value: "Allumé" },
            { label: <p style={{ margin: "0" }}>Éteindre</p>, value: "Éteint" },
          ]}
          onChange={(value) => {
            setValue(value);
            dispatchLight(value);
          }}
        />
      </div>
    </>
  );
};

export default LightOnOff;
