export const getParent = (arr, childId) => {
  let found;
  const Stop = () => {};
  try {
    arr.forEach((item) => {
      item.categories.forEach((cat) => {
        if (cat.id === childId) {
          found = item.id;
          throw Stop();
        }
      });
    });
  } catch (Stop) {}

  return found;
};

export const findCategory = (ticketsTypeList, categoryId) => {
  let found;
  const Stop = () => {};
  try {
    ticketsTypeList.forEach((tt) => {
      tt.categories.forEach((item) => {
        if (item.id === categoryId) {
          found = item;
          throw Stop();
        }
        item.categories.forEach((cat) => {
          if (cat.id === categoryId) {
            found = cat;
            throw Stop();
          }
        });
      });
    });
  } catch (Stop) {}

  return found;
};
