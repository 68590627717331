import { Button, Card, Col, Form, Row, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

// import component
import useSelectedRoom from "../../../../services/redux/useSelectedRoom";
import useMap, { useMapLayer } from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import RoomDetails from "../../../Common/RoomDetails/RoomDetails";
import useDesignTokens from "../../../../hook/useDesignTokens";

// import css
import Confirmation from "./Confirmation";

// import redux
import { addFavoris, removeFavoris } from "../../../../services/redux/services/ClientsWS";
import {
  getRoomFastbooking,
  setMakeBooking,
} from "../../../../services/redux/services/FastbookingWS";
import { TitleSource } from "../../../Common/Teleporters/Title";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";
import { useEffect } from "react";
import { getPlanning } from "../../../../services/redux/services/PlanningWS";
import { isEmpty, isNil } from "ramda";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

let time;
const RoomFastbooking = () => {
  const { centerOnPlaceId } = useMap();
  const dispatch = useDispatch();
  const selectedRoom = useSelectedRoom();
  const location = useLocation();
  const { colors } = useDesignTokens();
  useMapLayer();

  const { t, i18n } = useTranslation();

  const [isVisibleConfirmation, setVisibleConfirmation] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(null);
  const [duration, setDuration] = useState(30);
  const [form] = Form.useForm();

  const favoris = useSelector((state) => state.clientsWS.favoris);
  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const occupancy = useSelector((state) => state.occupancyWS.occupancy);

  const roomFavoris = favoris.find((f) => f.id === selectedRoom.id);

  const addFav = () => {
    dispatch(addFavoris(selectedRoom));
  };

  const removeFav = () => {
    dispatch(removeFavoris(selectedRoom.id));
  };

  const handleSubmit = async () => {
    logEvent(analytics, "fast_booking_booking_duration", {
      duration: duration,
    });
    time = setTimeout(() => {
      dispatch(
        setMakeBooking({
          roomId: selectedRoom?.id,
          startDate: moment().toISOString(),
          duration: duration,
        }),
      )
        .unwrap()
        .then(() => {
          logEvent(analytics, "fast_booking_book_confirmed");
          setCurrent(current + 1);
          setStatus("success");
          dispatch(
            getPlanning({
              locale: i18n.language.split("-")[0],
              startDate: moment().toISOString(),
              endDate: moment().add(7, "days").toISOString(),
            }),
          );
        })
        .catch(() => {
          setCurrent(current);
          setCurrent(current + 2);
          setStatus("error");
        });
    }, 6000);
  };

  useEffect(() => {
    if (!isEmpty(filters) && !isNil(filters)) {
      dispatch(
        getRoomFastbooking({
          locale: i18n.language.split("-")[0],
          ...filters,
          startDate: filters.startDate || moment().toISOString(),
        }),
      );
    }
  }, [dispatch, filters, i18n.language]);

  useEffect(() => {
    if (!location?.state?.room) return;
    centerOnPlaceId(selectedRoom?.map?.findAndOrder?.placeId);
  }, [centerOnPlaceId, location?.state?.room, selectedRoom?.map?.findAndOrder?.placeId]);

  return (
    <>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          {t("fast_booking_title", { ns: "csv" })}
        </Typography.Title>
      </TitleSource>
      <Card
        title={
          <>
            <Typography.Title level={5}>
              <NavLink to="/fastBookingV2/map" onClick={() => centerOnPlaceId()}>
                <Glyph
                  name="arrow_back_ios"
                  style={{
                    verticalAlign: "-2px",
                    fontSize: "20px",
                    color: "black",
                    marginRight: "8px",
                  }}
                />
              </NavLink>
              {selectedRoom?.title}
            </Typography.Title>
            <Typography.Text strong style={{ fontSize: "14px", color: colors.pending_light }}>
              {!!selectedRoom?.features?.bookable && occupancy.status === "AVAILABLE"
                ? location?.state?.room?.availableTime >= 60
                  ? `${t("Available")} > 1h - `
                  : `${location?.state?.room?.availableTime} mins`
                : null}
              {t("Floor")}{" "}
              {
                campus?.mapData?.floors.find(
                  (floor) => floor.reference === selectedRoom.floorReference,
                )?.displayName
              }
            </Typography.Text>
          </>
        }
        extra={
          roomFavoris ? (
            <Button
              style={{ border: "none", background: "none", color: colors.secondary_base }}
              type="primary"
              icon={<Glyph name="star" />}
              onClick={removeFav}
            ></Button>
          ) : (
            <Button
              style={{ border: "none", background: "none", color: colors.grey_40 }}
              type="primary"
              icon={<Glyph name="star" />}
              onClick={addFav}
            ></Button>
          )
        }
        actions={[
          occupancy.status === "AVAILABLE" && selectedRoom?.features?.bookable === true ? (
            <Button
              type="primary"
              style={{ width: "350px" }}
              disabled={!duration}
              onClick={() => {
                setVisibleConfirmation(true);
                form.submit();
              }}
            >
              {t("Book")}
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ width: "350px" }}
              onClick={() => {
                logEvent(analytics, "fast_booking_itinerary");
              }}
            >
              <Link
                to={{
                  pathname: "/indoorMapping/itinerary",
                  state: {
                    back: `/fastBookingV2/${selectedRoom?.map?.findAndOrder?.placeId}`,
                    selectedRoom: selectedRoom,
                  },
                }}
                style={{ color: "white" }}
              >
                {t("SeeItinerary")}
              </Link>
            </Button>
          ),
        ]}
        style={{ width: "35%", height: "fit-content" }}
      >
        <RoomDetails room={selectedRoom} withTitle={false} />
        {!!selectedRoom?.features?.bookable && (
          <Form
            form={form}
            style={{ marginTop: "30px" }}
            initialValues={{
              startDate: moment(),
            }}
            onFinish={handleSubmit}
          >
            <Row gutter={[10, 20]}>
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        <Glyph
                          name="event"
                          style={{ color: colors.secondary_base, marginRight: "8px" }}
                        />
                        {t("BookThe")} {moment().format("DD MMMM YYYY")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        <Glyph
                          name="schedule"
                          style={{ color: colors.secondary_base, marginRight: "8px" }}
                        />
                        {t("At")} {moment().format("HH:mm")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        <Glyph
                          name="timelapse"
                          style={{ color: colors.secondary_base, marginRight: "8px" }}
                        />
                        {t("When")}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          shape="circle"
                          size="small"
                          style={{ border: "none" }}
                          icon={<Glyph name="remove_circle" />}
                          onClick={() => {
                            if (duration !== 30) setDuration(duration - 15);
                          }}
                        ></Button>
                        <Typography.Text>
                          {duration < 60
                            ? `${duration} mins`
                            : moment().startOf("day").add(duration, "minutes").format("hh[h]mm")}
                        </Typography.Text>
                        <Button
                          shape="circle"
                          size="small"
                          style={{ border: "none" }}
                          icon={<Glyph name="add_circle" />}
                          onClick={() => {
                            if (duration !== 240) setDuration(duration + 15);
                          }}
                        ></Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Card>

      <Confirmation
        isVisible={isVisibleConfirmation}
        current={current}
        setCurrent={setCurrent}
        status={status}
        selectedRoom={selectedRoom}
        duration={duration}
        onCancel={() => {
          setVisibleConfirmation(false);
          clearTimeout(time);
        }}
      />
    </>
  );
};

export default RoomFastbooking;
