import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { useMapLayer } from "../../Common/Map/useMap";
import { TitleSource } from "../../Common/Teleporters/Title";
import GuidMeSearch from "./GuidMeSearch";
import Itinerary from "./Itinerary";
import RoomGuidMe from "./RoomGuidMe";

const GuidMe = ({ setIsFloor }) => {
  useMapLayer();
  const { t } = useTranslation();

  return (
    <>
      <TitleSource>
        <Typography.Title level={1} style={{ margin: "0" }}>
          {t("guide_me_title", { ns: "csv" })}
        </Typography.Title>
      </TitleSource>
      <Switch>
        <Route exact path="/indoorMapping">
          <GuidMeSearch setIsFloor={setIsFloor} />
        </Route>
        <Route path="/indoorMapping/itinerary">
          <Itinerary />
        </Route>
        <Route path="/indoorMapping/:id">
          <RoomGuidMe />
        </Route>
      </Switch>
    </>
  );
};

export default GuidMe;
