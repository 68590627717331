import { useState } from "react";
import { Card, Typography, Button, Form } from "antd";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// import component
import Glyph from "../../Common/Glyph/Glyph";
import FilterOptions from "../../Common/FilterOptions/FilterOptions";

// import css
import "./Fastbooking.module.less";

// import redux
import { removeFilter } from "../../../services/redux/services/FastbookingWS";
import { useTranslation } from "react-i18next";

const Filter = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const filters = useSelector((state) => state.fastbookingWS.filters);

  const [seats, setSeats] = useState(filters.seats ? filters.seats : 1);

  return (
    <Card
      title={
        <>
          <Typography.Title level={5}>
            <NavLink to="/fastBooking">
              <Glyph
                name="arrow_back_ios"
                style={{
                  verticalAlign: "-2px",
                  fontSize: "20px",
                  color: "black",
                  marginRight: "8px",
                }}
              />
            </NavLink>
            {t("Filters")}
          </Typography.Title>
        </>
      }
      actions={[
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            ghost
            style={{ width: "150px", marginRight: "16px" }}
            onClick={() => {
              dispatch(removeFilter());
              setSeats(1);
              form.setFieldsValue({ endTime: moment().add(15, "minutes") });
              form.setFieldsValue({ startTime: moment() });
            }}
          >
            {t("Erase")}
          </Button>
          <Link to="/fastBooking" style={{ width: "inherit" }}>
            <Button
              style={{ width: "150px" }}
              type="primary"
              //style={{ width: "350px" }}
            >
              {t("Apply")}
            </Button>
          </Link>
        </div>,
      ]}
      style={{ width: "35%", height: "650px" }}
    >
      <div style={{ overflow: "auto", height: "450px" }}>
        <FilterOptions
          form={form}
          options={false}
          details={true}
          equipments={true}
          services={true}
          setSeats={setSeats}
          seats={seats}
        />
      </div>
    </Card>
  );
};

export default Filter;
