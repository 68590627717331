import { Card, Typography, Spin } from "antd";
import React, { useState } from "react";

// import redux

// import component
import Glyph from "../../../Common/Glyph/Glyph";

// import css
import styles from "./SettingsUI.module.less";

import { useTranslation } from "react-i18next";
import LightDimmer from "./Dimmer/LightDimmer";
import LightUpDown from "./UpAndDown/LightUpDown";
import LightOnOff from "./OnOff/LightOnOff";

const SettingsLight = ({ extra, zone, isAuto, setIsAuto, comfort, selectedRoom }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Card
      className={styles["SettingsUI"]}
      title={
        <Typography.Title level={5} style={{ display: "flex", alignItems: "center" }}>
          <Glyph name="emoji_objects" className={styles["SettingsUI__pictos"]} />
          {zone ? t("Lighting") + ` - zone ${zone.label}` : t("Lighting")}
        </Typography.Title>
      }
      extra={
        isLoading ? (
          <Spin />
        ) : status === "error" ? (
          <Glyph name="cancel" className={styles["SettingsUI__error"]} />
        ) : status === "success" ? (
          <Glyph name="check_circle" className={styles["SettingsUI__success"]} />
        ) : (
          extra
        )
      }
      style={{ padding: "15px", margin: "0 15px" }}
    >
      {comfort?.light?.options.includes("dimmer") ? (
        <LightDimmer
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          zone={zone}
          isAuto={isAuto}
          setIsAuto={setIsAuto}
          isLoading={isLoading}
          selectedRoomNomap={selectedRoom}
        />
      ) : comfort?.light?.options.includes("onOff") ? (
        <LightOnOff
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          zone={zone}
          isAuto={isAuto}
          setIsAuto={setIsAuto}
          isLoading={isLoading}
          selectedRoomNomap={selectedRoom}
        />
      ) : comfort?.light?.options.includes("upDown") ? (
        <LightUpDown
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          zone={zone}
          isAuto={isAuto}
          setIsAuto={setIsAuto}
          isLoading={isLoading}
          selectedRoomNomap={selectedRoom}
        />
      ) : null}
    </Card>
  );
};

export default SettingsLight;
