import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  aroundMe: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};
let siteIdStorage = localStorage.getItem("siteId");
///////////////////////// AROUNDME ///////////////////////////////
export const getAroundMe = thunk("aroundMe/getAroundMe", async (_, { getState }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "aroundMe");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  const aroundMe = await DWMConnector.getAroundMe(viewId.id, siteId);
  return aroundMe;
});
EXTRA_REDUCERS[getAroundMe.fulfilled] = (state, action) => {
  state.aroundMe = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const aroundMeSlice = createSlice({
  name: "aroundMe",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default aroundMeSlice.reducer;
