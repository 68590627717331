import { useState } from "react";
import PhoneBook from "../PhoneBook/PhoneBook";
import PeopleFollow from "./Spaas/PeopleFollow";
import { PhoneBookTitleTarget } from "../PhoneBook/PhoneBookTitle";
import { STEPS } from "./SpaceBookingResident";

const SpaceWhoHere = ({ slot }) => {
  const [stepHere, setStepHere] = useState(STEPS.PEOPLE);
  return (
    <>
      <div
        style={{
          display: stepHere === STEPS.PEOPLE ? "block" : "none",
          padding: "30px 20px 0 20px",
        }}
      >
        <PeopleFollow date={slot?.date} setStep={setStepHere} siteId={slot?.request?.siteId} />
      </div>
      <div
        style={{
          display: stepHere === STEPS.PHONEBOOK ? "block" : "none",
          height: "100%",
          padding: "20px",
        }}
      >
        <PhoneBookTitleTarget />
        <PhoneBook onBack={() => setStepHere(STEPS.PEOPLE)} manage={false} />
      </div>
    </>
  );
};

export default SpaceWhoHere;
