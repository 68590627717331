import { Button, Layout, notification } from "antd";
import moment from "moment";
import { isEmpty } from "ramda";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { getRoomFastbooking } from "../../../../services/redux/services/FastbookingWS";
import Glyph from "../../../Common/Glyph/Glyph";

import styles from "../Fastbooking.module.less";
import { useEffect } from "react";
import ModalDetails from "./ModalDetails";
import Booking from "./Booking/Booking";
import { getPlanning } from "../../../../services/redux/services/PlanningWS";

import CardBooking from "./CardBooking";
import CardFilter from "./CardFilter";
import CardRoom from "./CardRoom";
import useDidUpdateEffect from "../../../../hook/useDidUpdate";
import ModalListBooking from "./ModalListBooking";

const FastbookingList = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors } = useDesignTokens();

  //const [during, setDuring] = useState(30);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [visible, setVisible] = useState(false);
  const [modalBooking, setModalBooking] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const campus = useSelector((state) => state.clientsWS.campus);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const planning = useSelector((state) => state.planningWS.planning);

  const isRoom = planning?.slots?.items
    .filter((f) => f.locations.length !== 0)
    ?.filter((r) => r.isOrganizer === true);

  useEffect(() => {
    dispatch(
      getPlanning({
        locale: i18n.language.split("-")[0],
        startDate: moment().toISOString(),
        endDate: moment().add(7, "days").toISOString(),
      }),
    );
  }, [dispatch, i18n.language]);

  useDidUpdateEffect(() => {
    if (visible) return;
    dispatch(
      getRoomFastbooking({
        ...filters,
        locale: i18n.language.split("-")[0],
        startDate: filters?.startDate ? filters?.startDate : moment().toISOString(),
      }),
    );
  }, [dispatch, filters.floor, i18n.language]);

  useEffect(() => {
    if (roomFastbooking?.rooms?.find((r) => r?.pending === true)) {
      setTimeout(() => {
        dispatch(
          getRoomFastbooking({
            locale: i18n.language.split("-")[0],
            startDate: moment().toISOString(),
          }),
        );
        dispatch(
          getPlanning({
            locale: i18n.language.split("-")[0],
            startDate: moment().toISOString(),
            endDate: moment().add(7, "days").toISOString(),
          }),
        );
      }, 10000);
    }
  }, [dispatch, i18n.language, roomFastbooking?.rooms]);

  const openNotification = () => {
    const args = {
      message: "Attention",
      icon: <Glyph name="warning" style={{ color: colors.error_light }} />,
      description: t("AdvertisementMessage"),
      duration: 5,
    };
    notification.open(args);
  };

  useEffect(() => {
    if (document.getElementById("view")) {
      const handleScroll = () => {
        if (document.getElementById("view").scrollTop > 100) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };

      document.getElementById("view").addEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <>
      <Layout className={styles.dashboardContainer}>
        <Layout id="view">
          {!isEmpty(isRoom) &&
            !isEmpty(
              isRoom?.filter((r) => r?.locations?.find((l) => l.siteId === campus?.mapData?.id)),
            ) && <CardBooking setModalBooking={setModalBooking} />}

          <CardFilter setVisible={setVisible} openNotification={openNotification} />
          <CardRoom setModalDetails={setModalDetails} setSelectedRoom={setSelectedRoom} />
          {visible && (
            <Booking
              isVisible={visible}
              onCancel={() => {
                setVisible(false);
              }}
            />
          )}
        </Layout>
        {showScrollButton && (
          <Button
            icon={<Glyph name="arrow_upward" />}
            type="secondary"
            style={{ position: "fixed", right: "100px", bottom: "50px", zIndex: "9999" }}
            onClick={() => {
              document.getElementById("view").scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {t("backToFilter")}
          </Button>
        )}
      </Layout>

      <ModalDetails
        startDate={filters?.startDate ? filters?.startDate : moment()}
        isVisible={modalDetails}
        onCancel={() => setModalDetails(false)}
        selectedRoom={selectedRoom}
        setModalBooking={setModalBooking}
        bookingClose={() => {
          setVisible(false);
        }}
      />

      <ModalListBooking modalBooking={modalBooking} setModalBooking={setModalBooking} />
    </>
  );
};

export default FastbookingList;
