import { Divider, Segmented, Typography } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect, useRef, useState } from "react";
import styles from "./QrCode.module.less";
import clsx from "clsx";
import QRCodeStyling from "qr-code-styling";
import { useTranslation } from "react-i18next";

const QrCode = () => {
  const { colors, shadows, symbols } = useDesignTokens();
  const { t } = useTranslation();

  const ref = useRef(null);
  const qrCode = useRef(
    new QRCodeStyling({
      width: 150,
      height: 150,
      dotsOptions: {
        color: colors.primary_base,
        type: "rounded",
      },
      backgroundOptions: {
        color: "transparent",
      },
      imageOptions: {
        imageSize: 1,
        hideBackgroundDots: false,
      },
      qrOptions: {
        errorCorrectionLevel: "L",
      },
    }),
  );

  const [os, setOs] = useState("ios");
  const [active, setActive] = useState(false);

  useEffect(() => {
    qrCode.current.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.current.update({
      data: os === "ios" ? process.env.REACT_APP_STORE_IOS : process.env.REACT_APP_STORE_ANDROID,
    });
  }, [os]);

  return (
    <div
      className={clsx(styles.collapse, {
        [styles.active]: !!active,
      })}
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: `${symbols?.base_shape?.radius}px ${symbols?.base_shape?.radius}px 0 0`,
        boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
        cursor: "pointer",
        width: "90%",
        zIndex: 999,
      }}
      onClick={() => setActive(!active)}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Glyph name="lightbulb" style={{ color: colors?.grey_40, marginRight: "8px" }} />
        <Typography.Text strong>
          {t("qrcode.title", { app: process.env.REACT_APP_ONGLET })}
        </Typography.Text>
        <Glyph name="expand_less" style={{ marginLeft: "8px" }} />
      </div>

      <Divider style={{ margin: "5px" }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Segmented
          size="large"
          value={os}
          options={[
            {
              label: <p style={{ margin: "0" }}>iOS</p>,
              value: "ios",
            },
            {
              label: <p style={{ margin: "0" }}>Android</p>,
              value: "android",
            },
          ]}
          onChange={(value) => {
            setOs(value);
          }}
        />
      </div>
      {os === "ios" ? (
        <div style={{ marginTop: "16px" }}>
          <Typography.Text type="secondary">{t("qrcode.content")}</Typography.Text>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "8px" }}
          >
            <div className={styles["qrcode"]} ref={ref} />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "16px" }}>
          <Typography.Text type="secondary">{t("qrcode.content")}</Typography.Text>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "8px" }}
          >
            <div className={styles["qrcode"]} ref={ref} />
          </div>
        </div>
      )}
    </div>
  );
};

export default QrCode;
