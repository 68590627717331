import { Image } from "antd";
import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { useTranslation } from "react-i18next";

const FailureKeep = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="ReleaseRoom__layout">
      <div className="ReleaseRoom__bg">
        <Image src={Logo} style={{ height: "200px" }} preview={false} />
        <h1 style={{ fontWeight: "bold" }}>{t("Public.failure.request")}</h1>
        <h3>
          {t("Public.failure.spaces", {
            title: data?.room?.title,
            startHour: data?.slot?.startHour,
            endHour: data?.slot?.endHour,
          })}
          <br />
          <br />
          {t("Public.failure.excuse")}
        </h3>
      </div>
    </div>
  );
};

export default FailureKeep;
