import { Button, message, Slider, Segmented } from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// import redux
import { setAcFanSpeed, setAcMode } from "../../../../../services/redux/services/ConfortWS";
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";

import Glyph from "../../../../Common/Glyph/Glyph";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

// import css
import styles from "../SettingsUI.module.less";
const FAN_SPEED = ["AC_S1", "AC_S2", "AC_S3"];
const FAN_SPEED_OUTPUT = ["V1", "V2", "V3"];

const FanSpeedDimmer = ({
  setIsLoading,
  setStatus,
  zone,
  isAuto,
  setIsAuto,
  isLoading,
  selectedRoomNomap,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedRoom = useSelectedRoom();
  const comforts = useSelector((state) => state.confortWS.acFanSpeed);
  const [value, setValue] = useState("Manuel");

  const [fanSpeed, setFanSpeed] = useState(0);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setValue(comforts?.result?.Data === "AUTO" ? "Auto" : "Manuel");
  }, [comforts?.result?.Data]);

  useEffect(() => {
    setFanSpeed(
      comforts?.result?.Data === "AUTO" || !comforts?.result?.Data
        ? 2
        : FAN_SPEED_OUTPUT.indexOf(comforts?.result?.Data),
    );
  }, [comforts?.result?.Data]);

  useEffect(() => {
    if (value === "Auto") {
      setFanSpeed(2);
    }
  }, [value]);

  useEffect(() => {
    if (zone) {
      if (comforts[zone]?.result?.Data) {
        setFanSpeed(comforts[zone].result.Data);
      }
    } else {
      if (comforts[0]?.result?.Data) {
        setFanSpeed(comforts[0].result.Data);
      }
    }
  }, [comforts, zone]);

  useEffect(() => {
    if (isAuto) {
      setFanSpeed(2);
    }
  }, [isAuto]);

  const dispatchFanSpeed = (value) => {
    setIsLoading(true);
    const command = FAN_SPEED[value];
    logEvent(analytics, "smart_control_command_fanSpeed", {
      zone: zone !== undefined ? "zone" : "total",
      type_fanSpeed: "dimmer",
    });
    dispatch(
      setAcFanSpeed({
        roomId: selectedRoomNomap?.id || selectedRoom.id,
        zone: zone ? parseInt(zone.id) : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        message.error(t("ErrorSetVentilation"), 5);
        setStatus("error");
        if (zone) {
          if (comforts[zone]?.result?.Data) {
            setFanSpeed(comforts[zone].result.Data);
          }
        } else {
          if (comforts[0]?.result?.Data) {
            setFanSpeed(comforts[0].result.Data);
          }
        }
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const FanSpeedOnOff = () => {
    setIsActive(!isActive);
    const command = !isActive === true ? "AC_ON" : "AC_OFF";
    logEvent(analytics, "smart_control_command_fanSpeed", {
      zone: zone !== undefined ? "zone" : "total",
      type_fanSpeed: "dimmer",
    });
    dispatch(
      setAcMode({
        roomId: selectedRoomNomap?.id || selectedRoom.i,
        zone: zone ? zone : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetVentilation"), 5);
        setStatus("error");
        if (zone) {
          if (comforts[zone]?.result?.Data) {
            setFanSpeed(comforts[zone].result.Data);
          }
        } else {
          if (comforts[0]?.result?.Data) {
            setFanSpeed(comforts[0].result.Data);
          }
        }
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isModeAuto = (value) => {
    setIsActive(true);
    const command = value === "Auto" ? "AC_AUTO" : null;
    if (!command) {
      return dispatchFanSpeed(1);
    }
    dispatch(
      setAcMode({
        roomId: selectedRoomNomap?.id || selectedRoom.i,
        zone: zone ? parseInt(zone.id) : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetVentilation"), 5);
        setStatus("error");
        if (zone) {
          if (comforts[zone]?.result?.Data) {
            setFanSpeed(comforts[zone].result.Data);
          }
        } else {
          if (comforts[0]?.result?.Data) {
            setFanSpeed(comforts[0].result.Data);
          }
        }
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className={styles["SettingsUI__content"]}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Button
          icon={<Glyph name="power_settings_new" />}
          size="default"
          type="danger"
          onClick={() => {
            FanSpeedOnOff();
            setIsAuto(false);
          }}
          style={{
            borderRadius: "100%",
            marginRight: "16px",
            padding: "0",
            height: "48px",
            width: "48px",
            fontWeight: "bold",
          }}
          disabled={isLoading}
          className={clsx({
            [styles.Noactive]: isActive === false,
          })}
        ></Button>
        <Segmented
          size="large"
          style={{ filter: !isActive ? "saturate(0)" : "saturate(1)" }}
          value={value}
          options={[
            { label: <p style={{ margin: "0" }}>Manuel</p>, value: "Manuel" },
            {
              label: (
                <p style={{ margin: "0" }}>
                  <Glyph
                    name="auto_fix_high"
                    style={{ verticalAlign: "-1px", marginRight: "8px" }}
                  />
                  Auto
                </p>
              ),
              value: "Auto",
            },
          ]}
          onChange={(value) => {
            setValue(value);
            isModeAuto(value);
          }}
        />
      </div>
      <div
        className={styles["SettingsUI__content"]}
        style={{ filter: !isActive ? "saturate(0)" : "saturate(1)" }}
      >
        <Button
          icon={<Glyph name="fan_outline" style={{ fontSize: "14px", verticalAlign: "0px" }} />}
          size="default"
          type="danger"
          style={{ borderRadius: "100%" }}
          disabled={isLoading}
        ></Button>
        <Slider
          style={{ filter: isAuto ? "saturate(0)" : "saturate(1)" }}
          max={2}
          min={0}
          step={1}
          value={fanSpeed}
          onChange={setFanSpeed}
          onAfterChange={(value) => {
            dispatchFanSpeed(value);
            setIsAuto(false);
            setIsActive(true);
          }}
          disabled={isLoading}
        />
        <Button
          icon={<Glyph name="fan" style={{ fontSize: "14px", verticalAlign: "0px" }} />}
          type="danger"
          size="default"
          style={{ borderRadius: "100%", marginLeft: "14px" }}
          disabled={isLoading}
        ></Button>
      </div>
    </>
  );
};

export default FanSpeedDimmer;
