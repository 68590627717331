import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  wifiInfos: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// USER DATA ///////////////////////////////

export const getWifiInfos = thunk("wifi/getWifiInfos", async () => {
  const res = await DWMConnector.getWifiInfos();
  return res;
});
EXTRA_REDUCERS[getWifiInfos.fulfilled] = (state, action) => {
  state.wifiInfos = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const wifiSlice = createSlice({
  name: "wifi",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default wifiSlice.reducer;
