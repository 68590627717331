import { Button, Input, message, Modal, Typography } from "antd";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import { cancelSlot } from "../../../../services/redux/services/SpaceService";
import styles from "../SpaceBooking.module.less";

const CancelRequestModal = ({ visible, slot, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isCanceling = useSelector(createLoadingSelector(["spaceService/cancelSlot"]));

  const [comment, setComment] = useState("");

  useEffect(() => {
    setComment("");
  }, [visible]);

  const handleSubmit = (dateOnly) => {
    dispatch(cancelSlot({ id: slot.id, data: { dateOnly, comment } }))
      .unwrap()
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        console.error(error);
        message.error(t("spas.messages.cancel.error"));
      });
  };

  return (
    <Modal
      className={clsx(styles.modal)}
      open={visible}
      onCancel={onCancel}
      title={<Typography.Text strong>{t("spas.request.cancel")}</Typography.Text>}
      footer={[
        <Button
          block
          loading={isCanceling}
          disabled={isCanceling}
          onClick={() => handleSubmit(true)}
        >
          {t("spas.request.actions.occurence")}
        </Button>,
        <Button
          block
          loading={isCanceling}
          disabled={isCanceling}
          onClick={() => handleSubmit(false)}
        >
          {t("spas.request.actions.serie")}
        </Button>,
      ]}
    >
      <Typography.Title level={4}>{t("spas.request.comment.title")}</Typography.Title>
      <Input.TextArea
        placeholder={t("spas.request.comment.helper")}
        autoSize={{ minRows: 4, maxRows: 8 }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </Modal>
  );
};

export default CancelRequestModal;
