import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Typography } from "antd";
import clsx from "clsx";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../SpaceBooking.module.less";

const STEPS = {
  DATES: "DATES",
  LOCATION: "LOCATION",
};

const CreateRequestModal = ({ visible, mode = "admin", onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [step, setStep] = useState(STEPS.DATES);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  useEffect(() => {
    form.resetFields();
    setIsDisabled(true);
    setStep(STEPS.DATES);
  }, [form, visible]);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setIsDisabled(true);
      await onSubmit(values);
      setStep(STEPS.DATES);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className={clsx(styles.modal)}
      open={!!visible}
      title={<Typography.Text strong>{t("spas.request.details")}</Typography.Text>}
      onCancel={onCancel}
      footer={[
        step === STEPS.DATES ? (
          <Button ghost block key="cancel" onClick={() => onCancel()}>
            {t("Cancel")}
          </Button>
        ) : (
          <Button ghost block key="cancel" onClick={() => setStep(STEPS.DATES)}>
            {t("Back")}
          </Button>
        ),
        step === STEPS.DATES ? (
          <Button
            type="primary"
            block
            key="submit"
            loading={isLoading}
            onClick={() => (mode === "admin" ? form.submit() : setStep(STEPS.LOCATION))}
          >
            {t("Next")}
          </Button>
        ) : (
          <Button
            type="primary"
            block
            key="submit"
            loading={isLoading}
            disabled={isDisabled}
            onClick={() => form.submit()}
          >
            {t("Next")}
          </Button>
        ),
      ]}
    >
      <Form
        form={form}
        initialValues={{
          startDate: typeof visible === "string" ? moment(visible) : undefined,
          siteId: sites[0]?.id,
        }}
        onValuesChange={({ startDate }, { endDate }) => {
          if (startDate && endDate && startDate.isAfter(endDate)) {
            form.setFieldsValue({ endDate: moment(startDate) });
          }
          setTimeout(() => {
            form
              .validateFields()
              .then(() => {
                setIsDisabled(false);
              })
              .catch((error) => {
                setIsDisabled(true);
                console.error("error", error);
              });
          });
        }}
        onFinish={handleSubmit}
        onFinishFailed={({ values, errorFields, outOfDate }) =>
          console.log({ values, errorFields, outOfDate })
        }
      >
        <div style={{ display: step === STEPS.DATES ? "block" : "none" }}>
          <Row gutter={[40, 40]}>
            <Col span={24}>
              <Typography.Title level={4}>{t("spas.request.startDate.title")}</Typography.Title>
              <Card>
                <Row>
                  <Col flex={"auto"}>
                    <Typography.Text strong>{t("spas.request.startDate.helper")}</Typography.Text>
                  </Col>
                  <Col>
                    <Form.Item noStyle name={["startDate"]} rules={[{ required: true }]}>
                      <DatePicker
                        style={{ margin: -8 }}
                        format="DD MMM YYYY"
                        disabledDate={(date) => date.isBefore(moment(), "date")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>{t("spas.request.period.title")}</Typography.Title>
              <Form.Item noStyle name={["period"]} rules={[{ required: true }]}>
                <Select
                  size={"large"}
                  style={{ width: "100%" }}
                  placeholder={t("spas.request.period.helper")}
                  options={[
                    {
                      label: t("spas.period.DAY"),
                      value: "DAY",
                    },
                    {
                      label: t("spas.period.MORNING"),
                      value: "MORNING",
                    },
                    {
                      label: t("spas.period.AFTERNOON"),
                      value: "AFTERNOON",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>{t("spas.request.recurrence")}</Typography.Title>
              <Form.Item noStyle name={["recurrence"]} rules={[{ required: false }]}>
                <Select
                  size={"large"}
                  style={{ width: "100%" }}
                  placeholder={t("spas.recurrence.NONE")}
                  options={[
                    {
                      label: t("spas.recurrence.NONE"),
                      value: "NONE",
                    },
                    {
                      label: t("spas.recurrence.EVERY_DAY"),
                      value: "EVERY_DAY",
                    },
                    {
                      label: t("spas.recurrence.EVERY_WEEK"),
                      value: "EVERY_WEEK",
                    },
                    {
                      label: t("spas.recurrence.EVERY_MONTH"),
                      value: "EVERY_MONTH",
                    },
                  ]}
                />
              </Form.Item>
              <br />
              <br />
              <Form.Item noStyle shouldUpdate={(prev, next) => prev.recurrence !== next.recurrence}>
                {({ getFieldValue }) =>
                  getFieldValue("recurrence") && getFieldValue("recurrence") !== "NONE" ? (
                    <Card>
                      <Row>
                        <Col flex={"auto"}>
                          <Typography.Text strong>
                            {t("spas.request.endDate", { date: "" })}
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Form.Item noStyle name={["endDate"]} rules={[{ required: false }]}>
                            <DatePicker
                              style={{ margin: -8 }}
                              format="DD MMM YYYY"
                              disabledDate={(date) =>
                                date.isBefore(form.getFieldValue(["startDate"]))
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ) : null
                }
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div style={{ display: step === STEPS.LOCATION ? "block" : "none" }}>
          <Row gutter={[40, 40]}>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Typography.Title level={4}>
                    {moment(getFieldValue(["startDate"])).format("ll")}
                  </Typography.Title>
                )}
              </Form.Item>
              <Typography.Paragraph strong type="secondary">
                {t("spas.request.site")}
              </Typography.Paragraph>
              <Card style={{ marginBottom: 10 }}>
                <Row gutter={[20, 0]}>
                  <Col>
                    <Glyph name="business" className={"secondary"} />
                  </Col>
                  <Col flex={"auto"}>
                    <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
                      <Select
                        className="accent"
                        bordered={false}
                        size={"middle"}
                        style={{ width: "100%", marginTop: -8, marginBottom: -8 }}
                        options={sites.map((s) => ({
                          label: s.label,
                          value: s.id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue(["siteId"]) ? (
                    <Form.Item noStyle name={["sectorId"]} rules={[{ required: true }]}>
                      <Select
                        size={"large"}
                        style={{ width: "100%" }}
                        options={(
                          sites.find((s) => s.id === getFieldValue(["siteId"])).sectors || []
                        )
                          .filter((s) => s.canBook)
                          .map((s) => ({
                            label: s.title,
                            value: s.id,
                          }))}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>{t("spas.request.comment.title")}</Typography.Title>
              <Form.Item noStyle name={["comment"]} rules={[{ required: true }]}>
                <Input.TextArea
                  placeholder={t("spas.request.comment.helper")}
                  autoSize={{ minRows: 4, maxRows: 8 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateRequestModal;
