import {
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Progress,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import clsx from "clsx";
import moment from "moment";
import { identity, indexBy, prop, times } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  byStatusWithResources,
  getOccupancyRates,
  listRequests,
  selectSector,
} from "../../../../services/redux/services/SpaceServiceAdmin";
import { selectedCampus } from "../../../../services/redux/services/UserWS";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../SpaceBooking.module.less";
import ActionBanner from "./ActionBanner";
import Approved from "./DashboardTabs/Approved";
import Cancelled from "./DashboardTabs/Cancelled";
import Declined from "./DashboardTabs/Declined";
import Pending from "./DashboardTabs/Pending";
import Unassigned from "./DashboardTabs/Unassigned";
import RequestDetailsModal from "./RequestDetailsModal";
import TeamDrawer from "./TeamDrawer";

export const SORT = {
  ASC: "ascend",
  DESC: "descend",
};

const SpaceBookingDashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedSlot, setSelectedSlot] = useState();
  const [currentDate, setCurrentDate] = useState(moment());
  const [showTeamDrawer, setShowTeamDrawer] = useState(false);

  const campus = useSelector(selectedCampus);
  const sectorId = useSelector((state) => state.spaceServiceAdminWS.selectedSectorId);
  const allData = useSelector(byStatusWithResources);
  let occupancyRates = useSelector((state) => state.spaceServiceAdminWS.occupancyRates);
  occupancyRates = indexBy(prop("date"), occupancyRates || []);
  const nbWorkplaces = useSelector((state) => state.clientsWS.campus?.mapData?.workplaces?.length);

  useEffect(() => {
    if (campus && !sectorId) dispatch(selectSector(campus.sectors?.[0]?.id));
  }, [campus, dispatch, sectorId]);

  const handleListRequests = useCallback(
    (query = {}) => {
      return dispatch(listRequests({ ...query, sectorId, date: currentDate.toISOString() }));
    },
    [currentDate, dispatch, sectorId],
  );
  useEffect(() => {
    if (!sectorId || !currentDate) return;
    handleListRequests();
  }, [currentDate, handleListRequests, sectorId]);

  const roundedDate = moment(currentDate).startOf("week").toISOString();
  useEffect(() => {
    dispatch(
      getOccupancyRates({
        sectorId,
        startDate: moment(roundedDate).startOf("w").toISOString(),
        endDate: moment(roundedDate).endOf("w").toISOString(),
      }),
    );
  }, [dispatch, roundedDate, sectorId]);

  const handleWeekSelect = (date) => {
    setCurrentDate(date);
  };

  if (!campus) return null;
  return (
    <Layout className={styles.dashboardContainer}>
      <Layout>
        <Row gutter={[20, 20]}>
          <Col flex={"auto"}>
            <Select
              style={{ width: 300 }}
              value={sectorId}
              options={(campus.sectors || []).map((sector) => ({
                label: sector.title,
                value: sector.id,
              }))}
              onChange={(id) => dispatch(selectSector(id))}
            />
          </Col>
          <Col flex={"none"}>
            <Button
              type="primary"
              icon={<Glyph name="groups" />}
              onClick={() => setShowTeamDrawer(true)}
            >
              Manage my team
            </Button>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col flex={"auto"} />
          <Col>
            <div className={styles.weekPagination}>
              <div
                className={clsx(styles.previousWeek)}
                onClick={() => handleWeekSelect(moment(currentDate).subtract(1, "w"))}
              >
                <Glyph name={"navigate_before"} />
                <div>{t("spas.admin.header.weekPrevious")}</div>
              </div>
              <Divider type="vertical" />
              <Typography.Text strong>
                {t("spas.admin.header.week", { number: currentDate.week() })}
              </Typography.Text>
              <Divider type="vertical" />
              <div
                className={clsx(styles.nextWeek)}
                onClick={() => handleWeekSelect(moment(currentDate).add(1, "w"))}
              >
                <div>{t("spas.admin.header.weekNext")}</div>
                <Glyph name={"navigate_next"} />
              </div>
            </div>
          </Col>
          <Col flex={"auto"} />
          <Col span={24}>
            <Row gutter={[20, 20]} className={styles.daySelector}>
              {times(identity, 7).map((i) => {
                const date = moment(currentDate).isoWeekday(i + 1);
                return (
                  <Col flex={1}>
                    <Card
                      className={clsx({ [styles.selected]: date.isSame(currentDate, "day") })}
                      onClick={() => setCurrentDate(date)}
                    >
                      <Typography.Text type="secondary">{date.format("DD MMMM")}</Typography.Text>
                      <Typography.Title level={4}>{date.format("dddd")}</Typography.Title>
                      <Progress
                        percent={occupancyRates?.[date.format("YYYY-MM-DD")]?.rate || 0}
                        showInfo={false}
                        size="small"
                        status="normal"
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col flex={"auto"}>
            <Card className={styles.header}>
              <Row gutter={[10, 0]}>
                <Col>
                  <div className={clsx(styles.stat, styles.pending)}>
                    <Glyph name={"access_time"} />
                    <br />
                    <Space align="end">
                      <Typography.Title level={5}>
                        {t("spas.admin.header.pending")}
                      </Typography.Title>
                      <Typography.Title level={1}>{allData?.PENDING?.totalItems}</Typography.Title>
                    </Space>
                  </div>
                </Col>
                <Col>
                  <Divider type="vertical" style={{ height: "100%" }} />
                </Col>
                <Col>
                  <div className={clsx(styles.stat, styles.validated)}>
                    <Glyph name={"check_circle"} />
                    <br />
                    <Space align="end">
                      <Typography.Title level={5}>
                        {t("spas.admin.header.approved")}
                      </Typography.Title>
                      <Typography.Title level={1}>{allData?.APPROVED?.totalItems}</Typography.Title>
                    </Space>
                  </div>
                </Col>
                <Col>
                  <Divider type="vertical" style={{ height: "100%" }} />
                </Col>
                <Col flex={"auto"}>
                  <Row align={"middle"} className={clsx(styles.stat, styles.estimated)}>
                    <Col flex="auto">
                      <div>
                        <Space align="center">
                          <Glyph name={"data_usage"} />
                          <Typography.Title level={5}>
                            {t("spas.admin.header.estimated")}
                          </Typography.Title>
                        </Space>
                      </div>
                      <Typography.Text type={"secondary"}>
                        {t("spas.admin.header.occupied", {
                          count: occupancyRates?.[currentDate.format("YYYY-MM-DD")]?.weight || 0,
                          total: nbWorkplaces || 0,
                        })}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Title level={1}>
                        {occupancyRates?.[currentDate.format("YYYY-MM-DD")]?.rate || 0}%
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Progress
                        percent={occupancyRates?.[currentDate.format("YYYY-MM-DD")]?.rate || 0}
                        showInfo={false}
                        size={"large"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.header}
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/spaceBooking/admin", { slots: [] })}
            >
              <div className={clsx(styles.stat, styles.map)}>
                <Glyph name={"map"} />
                <br />
                <Space align="end">
                  <Typography.Title level={5}>{t("spas.admin.header.map")}</Typography.Title>
                  <Typography.Title level={5}>
                    <Glyph name={"navigate_next"} />
                  </Typography.Title>
                </Space>
              </div>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <Tabs type="card" destroyInactiveTabPane style={{ marginBottom: 60 }}>
          <Tabs.TabPane
            tab={
              <Space size={"large"}>
                <Typography.Text strong>
                  {t("spas.status.PENDING", { count: allData?.PENDING.totalItems || 0 })}
                </Typography.Text>
                <Typography.Text type="secondary">{allData?.PENDING.totalItems}</Typography.Text>
              </Space>
            }
            key="pending"
          >
            <Pending
              currentDate={moment(currentDate).toISOString()}
              data={allData?.PENDING}
              onListRequests={handleListRequests}
              onSeeMore={setSelectedSlot}
              onRefresh={() => {
                handleListRequests();
                dispatch(
                  getOccupancyRates({
                    sectorId,
                    startDate: moment(roundedDate).startOf("w").toISOString(),
                    endDate: moment(roundedDate).endOf("w").toISOString(),
                  }),
                );
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Space size={"large"}>
                <Typography.Text strong>
                  {t("spas.status.UNASSIGNED", { count: allData?.UNASSIGNED.totalItems || 0 })}
                </Typography.Text>
                <Typography.Text type="secondary">{allData?.UNASSIGNED.totalItems}</Typography.Text>
              </Space>
            }
            key="affected"
          >
            <Unassigned
              currentDate={moment(currentDate).toISOString()}
              data={allData?.UNASSIGNED}
              onListRequests={handleListRequests}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Space size={"large"}>
                <Typography.Text strong>
                  {t("spas.status.APPROVED", { count: allData?.APPROVED.totalItems || 0 })}
                </Typography.Text>
                <Typography.Text type="secondary">{allData?.APPROVED.totalItems}</Typography.Text>
              </Space>
            }
            key="validated"
          >
            <Approved
              currentDate={moment(currentDate).toISOString()}
              data={allData?.APPROVED}
              onListRequests={handleListRequests}
              onSeeMore={setSelectedSlot}
              onRefresh={() => {
                handleListRequests();
                dispatch(
                  getOccupancyRates({
                    sectorId,
                    startDate: moment(roundedDate).startOf("w").toISOString(),
                    endDate: moment(roundedDate).endOf("w").toISOString(),
                  }),
                );
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Space size={"large"}>
                <Typography.Text strong>
                  {t("spas.status.DECLINED", { count: allData?.DECLINED.totalItems || 0 })}
                </Typography.Text>
                <Typography.Text type="secondary">{allData?.DECLINED.totalItems}</Typography.Text>
              </Space>
            }
            key="declined"
          >
            <Declined
              currentDate={moment(currentDate).toISOString()}
              data={allData?.DECLINED}
              onListRequests={handleListRequests}
              onSeeMore={setSelectedSlot}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Space size={"large"}>
                <Typography.Text strong>
                  {t("spas.status.CANCELLED", { count: allData?.CANCELLED.totalItems || 0 })}
                </Typography.Text>
                <Typography.Text type="secondary">{allData?.CANCELLED.totalItems}</Typography.Text>
              </Space>
            }
            key="cancelled"
          >
            <Cancelled
              currentDate={moment(currentDate).toISOString()}
              data={allData?.CANCELLED}
              onListRequests={handleListRequests}
              onSeeMore={setSelectedSlot}
            />
          </Tabs.TabPane>
        </Tabs>

        <RequestDetailsModal slot={selectedSlot} onCancel={() => setSelectedSlot(null)} />

        <TeamDrawer visible={showTeamDrawer} onClose={() => setShowTeamDrawer(false)} />

        <ActionBanner.Target />
      </Layout>
    </Layout>
  );
};

export default SpaceBookingDashboard;
