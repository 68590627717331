import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cond, lt, gt } from "ramda";

import { Button, message } from "antd";

// import css
import styles from "../SettingsUI.module.less";

// import component
import Glyph from "../../../../Common/Glyph/Glyph";

// import redux
import { setAcOffset } from "../../../../../services/redux/services/ConfortWS";
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";

// autre
import useDebounce from "../../../../../hook/useDebounce";
import { useTranslation } from "react-i18next";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

const DegreesDimmer = ({ isLoading, setStatus, setIsAuto, zone, selectedRoomNomap }) => {
  const { t } = useTranslation();
  const selectedRoom = useSelectedRoom();
  const dispatch = useDispatch();
  const comforts = useSelector((state) => state.confortWS.acOffset);

  const [degrees, setDegrees] = useState(0);
  const [repeat, setRepeat] = useState(0);
  const debouncedRepeat = useDebounce(repeat, 500);

  useEffect(() => {
    if (comforts?.result?.Data) {
      if (comforts.result?.Data?.Measure === "IN_PROGRESS") {
        setDegrees(0);
      } else {
        setDegrees(comforts?.result?.Data?.Measure);
      }
    }
  }, [comforts]);

  const addDegrees = () => {
    if (parseFloat(degrees) < 3) {
      setDegrees(parseFloat(degrees) + 0.5);
    }
    setRepeat(repeat + 1);
    setIsAuto(false);
  };

  const removeDegrees = () => {
    if (parseFloat(degrees) > -3) {
      setDegrees(parseFloat(degrees) - 0.5);
    }
    setRepeat(repeat - 1);
    setIsAuto(false);
  };

  useEffect(() => {
    if (!debouncedRepeat) return;
    const command = cond([
      [lt(0), () => "AC_UP"],
      [gt(0), () => "AC_DW"],
    ])(debouncedRepeat);
    dispatch(
      setAcOffset({
        roomId: selectedRoomNomap?.id || selectedRoom?.id,
        zone: zone ? parseInt(zone.id) : 0,
        command: command,
        repeats: Math.abs(debouncedRepeat),
      }),
    )
      .unwrap()
      .then(() => {
        logEvent(analytics, "smart_control_command_heat", {
          zone: zone !== undefined ? "zone" : "total",
          type_heat: "dimmer",
        });
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetTemperatures"), 5);
        setStatus("error");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setRepeat(0);
      });
  }, [debouncedRepeat, dispatch, selectedRoom?.id, selectedRoomNomap?.id, setStatus, t, zone]);

  return (
    <div className={styles["SettingsUI__content"]}>
      <Button
        icon={<Glyph name="remove" />}
        type="ghost"
        onClick={removeDegrees}
        style={{ width: "100px" }}
        disabled={isLoading || degrees === "-3"}
      ></Button>
      <p className={styles["SettingsUI__content__text"]}>{degrees}°C</p>
      <Button
        icon={<Glyph name="add" />}
        type="ghost"
        onClick={addDegrees}
        style={{ width: "100px" }}
        disabled={isLoading || degrees === "3"}
      ></Button>
    </div>
  );
};

export default DegreesDimmer;
