import { Typography, Modal, Card, Progress, Tag, Space } from "antd";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";

const Places = ({ visible, onCancel, placeInfo }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const history = useHistory();

  const goSpace = (value) => {
    history.push({ pathname: `/indoorMapping/${value?.location?.placeId}`, state: value });
  };
  return (
    <Modal
      title={<Typography.Title level={5}>{placeInfo?.name}</Typography.Title>}
      open={visible}
      onOk={visible}
      onCancel={onCancel}
      footer={false}
    >
      <div>
        {!isNil(placeInfo?.affluence) ? (
          <>
            <Typography.Text strong>{t("Places.Frequentation")}</Typography.Text>
            <Card style={{ marginBottom: "24px", marginTop: "10px" }}>
              <div style={{ display: "flex", flexFlow: "column" }}>
                <Typography.Text strong>
                  <Glyph
                    name="schedule"
                    style={{ marginRight: "8px", color: colors.secondary_base }}
                  />
                  {t("Places.Now")}
                </Typography.Text>
                <Progress
                  style={{ textAlign: "center" }}
                  type="circle"
                  percent={
                    isNil(placeInfo?.affluence?.currentForecast?.occupancy)
                      ? 0
                      : placeInfo?.affluence?.currentForecast?.occupancy
                  }
                  trailColor={colors.grey_20}
                  strokeColor={colors.secondary_base}
                  strokeWidth="15"
                  strokeLinecap="square"
                  width={80}
                  format={(percent) => `${percent}%`}
                  status="normal"
                />
              </div>
            </Card>
          </>
        ) : null}

        <Typography.Text strong>{t("Places.Informations")}</Typography.Text>
        <Card style={{ marginBottom: "24px", marginTop: "10px", alignItems: "center" }}>
          <div style={{ display: "flex", flexFlow: "column", width: "250px" }}>
            <Typography.Text strong>{t("Places.hours")}</Typography.Text>
            {placeInfo?.openingHours?.map((hour, i) => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography.Text>
                  {moment()
                    .isoWeekday(i + 1)
                    .format("dddd")}
                </Typography.Text>
                {isEmpty(hour)
                  ? "Fermé"
                  : hour?.map((h) => <Typography.Text>{h.join(" - ")}</Typography.Text>)}
              </div>
            ))}
          </div>
        </Card>
        {!!placeInfo?.location && !isEmpty(placeInfo?.location) && (
          <Card
            onClick={() => {
              goSpace(placeInfo);
            }}
            style={{ marginBottom: "24px", cursor: "pointer" }}
          >
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Typography.Text strong>
                <Glyph name="place" style={{ color: colors.secondary_base }} />
                {t("Places.Location")}
              </Typography.Text>
              <Glyph name="navigate_next" />
            </div>
          </Card>
        )}

        {placeInfo?.equipments && !isEmpty(placeInfo?.equipments) && (
          <>
            <Typography.Text strong>{t("Places.Equipments")}</Typography.Text>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                marginTop: "8px",
                marginBottom: "16px",
                width: "450px",
                overflowX: "scroll",
              }}
            >
              {placeInfo?.equipments?.map((equipment) => (
                <Tag style={{ marginBottom: "8px" }} icon={<Glyph name={equipment?.icon} />}>
                  {equipment?.title}
                </Tag>
              ))}
            </div>
          </>
        )}

        {placeInfo?.services && !isEmpty(placeInfo?.services) && (
          <>
            <Typography.Text strong>{t("Places.Services")}</Typography.Text>

            <Space style={{ width: "100%", margin: "9px 0" }} direction="vertical" size="small">
              {placeInfo?.services?.map((service) => (
                <a target="_blank" rel="noreferrer" href={service?.url}>
                  <Card
                    bodyStyle={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <Glyph
                        name="credit_card"
                        style={{ color: colors?.secondary_base, marginRight: "8px" }}
                      />
                      <Typography.Text strong>{service?.name}</Typography.Text>
                    </div>
                    <Glyph name="navigate_next" />
                  </Card>
                </a>
              ))}
            </Space>
          </>
        )}

        {!!placeInfo?.others && (
          <>
            {" "}
            <Typography.Text strong>{t("Places.others")}</Typography.Text>
            <p
              dangerouslySetInnerHTML={{
                __html: placeInfo?.others,
              }}
            ></p>
          </>
        )}
      </div>
    </Modal>
  );
};

export default Places;
