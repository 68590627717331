import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Button, Dropdown, Layout, Select, Typography } from "antd";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import packageJson from "../../../../package.json";
import { updateOnboarding } from "../../../services/redux/services/OnboardingWS";
import { getUserData } from "../../../services/redux/services/UserWS";
import Glyph from "../../Common/Glyph/Glyph";
import { TitleTarget } from "../../Common/Teleporters/Title";
import styles from "./Header.module.less";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { Option } = Select;
const Header = ({ setIsOnboarding }) => {
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let siteId = localStorage.getItem("siteId");

  const userData = useSelector((state) => state.userWS.userData);
  const campus = useSelector((state) => state.clientsWS.campus);
  const viewType = useSelector((state) => state.clientsWS.lastView);

  const menu = (
    <div className={styles["Menu-header"]}>
      <div style={{ display: "flex" }}>
        <Avatar
          src={`${userData?.profile?.photoUrl}?access_token=${keycloak?.token}`}
          style={{
            height: "80px",
            width: "80px",
            marginRight: "16px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          {`${userData?.profile?.firstName} ${userData?.profile?.lastName}`
            .toUpperCase()
            .split(" ")
            .map((i) => i.charAt(0).toUpperCase())
            .join("")}
        </Avatar>
        <div style={{ display: "flex", flexFlow: "column" }}>
          <Typography.Text strong>
            {userData?.profile?.firstName} {userData?.profile?.lastName}
          </Typography.Text>
          <Typography.Text>{userData?.profile?.email}</Typography.Text>
          <Button
            ghost
            size="middle"
            style={{ marginTop: "16px" }}
            onClick={() => keycloak.logout()}
          >
            <Glyph name="logout" style={{ verticalAlign: "-3px", marginRight: "8px" }} />
            {t("logout", { ns: "csv" })}
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <Typography.Text style={{ fontSize: "10px" }}>v.{packageJson.version}</Typography.Text>
        <div>
          <Link
            style={{ textDecoration: "underline", marginRight: "16px", fontSize: "12px" }}
            to="/public/cgu"
            target="_blank"
          >
            CGU
          </Link>
          <Link
            style={{ textDecoration: "underline", fontSize: "12px" }}
            to="/public/privacyPolicy"
            target="_blank"
          >
            {t("PrivacyPolicy")}
          </Link>
        </div>
      </div>
    </div>
  );
  let onboardingArray = null;

  for (const key in ONBOARDINGS) {
    if (key.toUpperCase().includes(viewType?.toUpperCase())) {
      onboardingArray = ONBOARDINGS[key];
      break;
    }
  }

  return (
    <Layout.Header style={{ zIndex: "998", width: "100%" }}>
      <Typography.Title level={1} style={{ marginBottom: "0px" }}>
        <TitleTarget />
      </Typography.Title>

      <div style={{ borderBottom: "0px", display: "flex", alignItems: "center" }}>
        {!isNil(viewType) && !isNil(onboardingArray) && (
          <Button
            size="medium"
            type="ghost"
            icon={<Glyph name="help" style={{ fontSize: "18px" }} />}
            className={styles["help"]}
            onClick={() => {
              dispatch(
                updateOnboarding({
                  viewId: userData?.views?.find((v) => v.type === viewType)?.id,
                  onboarding: true,
                }),
              )
                .unwrap()
                .then(() => {
                  setIsOnboarding(true);
                  dispatch(
                    getUserData({
                      locale: i18n.language.split("-")[0],
                      siteId: siteId ? siteId : "",
                    }),
                  );
                });
            }}
          ></Button>
        )}

        {userData?.campus?.length > 1 && (
          <Select
            className="basic-single"
            classnameprefix="select"
            value={userData?.campus?.find((c) => c?.id === campus?.mapData?.id)?.title}
            isclearable="false"
            isrtl="false"
            issearchable="false"
            name="select-campus"
            style={{ marginRight: "16px", width: "150px" }}
          >
            {userData?.campus?.map((c, index) => (
              <Option key={index}>
                <a
                  href={`?siteId=${c.id}`}
                  style={{ width: "100%", height: "100%", display: "block" }}
                >
                  {c?.title}
                </a>
              </Option>
            ))}
          </Select>
        )}

        <Select
          size={"medium"}
          style={{ width: "70px", textAlign: "center" }}
          onChange={(value) => i18n.changeLanguage(value)}
          defaultValue={i18n.language.split("-")[0]}
        >
          {userData?.locales?.map((language, index) => (
            <Option key={index} value={language?.title} style={{ textAlign: "center" }}>
              {language?.icon}
            </Option>
          ))}
        </Select>

        <Dropdown overlay={menu} trigger={["click"]}>
          <Avatar
            src={`${userData?.profile?.photoUrl}?access_token=${keycloak.token}`}
            style={{
              height: "40px",
              width: "40px",
              marginLeft: "8px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            {`${userData?.profile?.firstName} ${userData?.profile?.lastName}`
              .toUpperCase()
              .split(" ")
              .map((i) => i.charAt(0).toUpperCase())
              .join("")}
          </Avatar>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default Header;
