import { Avatar, Button, Card, Col, Drawer, Modal, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";

import styles from "./Transfert.module.less";
import { ReactComponent as Delivred } from "../../../assets/svg/transfert.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateCorrespondant } from "../../../services/redux/services/TransfertWS";
import moment from "moment";

const TransfertInfos = () => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const transfertInfos = useSelector((state) => state.transfertWS.transfertInfos);

  let accesToken = localStorage.getItem("auth_data_dwm");
  accesToken = JSON.parse(accesToken);

  useEffect(() => {
    if (!transfertInfos) return;
    if (!transfertInfos?.transfert?.understood) setIsModalOpen(true);
  }, [transfertInfos]);

  const confirm = () => {
    dispatch(updateCorrespondant({ id: transfertInfos?.transfert?.id, understood: true }));
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
        <Col xs={24} xl={7} xxl={7}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Card>
                <Typography.Title level={5}>{t("transfert.myDeparture")}</Typography.Title>
                <Space style={{ width: "100%" }} direction="vertical" size="small">
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="place"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.site")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {transfertInfos?.transfert?.from?.site}
                    </Typography.Text>
                  </Tile>
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="account_balance"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.building")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {transfertInfos?.transfert?.from?.building} | Étage{" "}
                      {transfertInfos?.transfert?.from?.floor}
                    </Typography.Text>
                  </Tile>
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="move_to_inbox"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.boxes")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {moment(transfertInfos?.transfert?.boxDepositDate).format("DD MMM YYYY")}
                    </Typography.Text>
                  </Tile>
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="timelapse"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.move")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {moment(transfertInfos?.transfert?.moveOutDate).format("DD MMM YYYY")}
                    </Typography.Text>
                  </Tile>
                </Space>
              </Card>
            </Col>

            <Col span={24} style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
              <div className={styles["next-step"]}>
                <Glyph name="arrow_downward" />
              </div>
            </Col>

            <Col span={24}>
              <Card style={{ border: "2px solid", borderColor: colors.primary_base }}>
                <Typography.Title level={5}>{t("transfert.myMoveIn")}</Typography.Title>
                <Space style={{ width: "100%" }} direction="vertical" size="small">
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="event"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.moveIn")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {moment(transfertInfos?.transfert?.moveInDate).format("DD MMM YYYY")}
                    </Typography.Text>
                  </Tile>
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="business"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.building")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {transfertInfos?.transfert?.to?.building}
                    </Typography.Text>
                  </Tile>
                  <Tile>
                    <Typography.Text>
                      <Glyph
                        name="flight_land"
                        style={{ color: colors.secondary_base, marginRight: "8px" }}
                      />
                      {t("transfert.zone")}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "12px" }}>
                      {transfertInfos?.transfert?.to?.zone} | {transfertInfos?.transfert?.to?.floor}
                    </Typography.Text>
                  </Tile>
                </Space>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={0} xxl={0} style={{ justifyContent: "center" }}>
          <Button
            size="default"
            icon={<Glyph name="help" />}
            type="tertiary"
            onClick={() => setIsDrawerOpen(true)}
          >
            {t("transfert.help")}
          </Button>
        </Col>

        <Col xs={0} xl={1} xxl={1} style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ content: "", height: "100%", width: "2px", backgroundColor: colors.grey_40 }}
          ></div>
        </Col>

        <Col xs={0} xl={6} xxl={6}>
          <Typography.Paragraph strong>{t("transfert.question")}</Typography.Paragraph>
          <Typography.Paragraph>{t("transfert.contact")}</Typography.Paragraph>
          <Card bodyStyle={{ display: "flex", flexFlow: "row" }} style={{ marginTop: "24px" }}>
            <Avatar
              size={30}
              src={`${transfertInfos?.transfert?.correspondent?.photoUrl}?access_token=${accesToken.token}`}
            />
            <div style={{ display: "flex", flexFlow: "column", marginLeft: "16px" }}>
              <Typography.Text strong>
                {transfertInfos?.transfert?.correspondent?.firstname}{" "}
                {transfertInfos?.transfert?.correspondent?.lastname}
              </Typography.Text>
              <Typography.Text>
                {transfertInfos?.transfert?.correspondent?.function}
              </Typography.Text>
            </div>
          </Card>
          <Card style={{ marginTop: "24px" }}>
            <a
              href={`mailto:${transfertInfos?.transfert?.correspondent?.email}`}
              target="blank"
              style={{ fontWeight: "bold" }}
            >
              <Glyph name="mail" style={{ color: colors.secondary_base, marginRight: "8px" }} />
              {transfertInfos?.transfert?.correspondent?.email}
            </a>
          </Card>
          {transfertInfos?.transfert?.correspondent?.mobilePhone && (
            <Card style={{ marginTop: "16px" }}>
              <a
                href={`tel:${transfertInfos?.transfert?.correspondent?.mobilePhone}`}
                style={{ fontWeight: "bold" }}
              >
                <Glyph
                  name="phone_iphone"
                  style={{ color: colors.secondary_base, marginRight: "8px" }}
                />
                {transfertInfos?.transfert?.correspondent?.mobilePhone}
              </a>
            </Card>
          )}

          {transfertInfos?.transfert?.correspondent?.phone && (
            <Card style={{ marginTop: "16px" }}>
              <a
                href={`tel:${transfertInfos?.transfert?.correspondent?.phone}`}
                style={{ fontWeight: "bold" }}
              >
                <Glyph name="phone" style={{ color: colors.secondary_base, marginRight: "8px" }} />
                {transfertInfos?.transfert?.correspondent?.phone}
              </a>
            </Card>
          )}
        </Col>
      </Row>

      <Drawer
        height={500}
        open={isDrawerOpen}
        placement="bottom"
        onClose={() => setIsDrawerOpen(false)}
        title={<Typography.Paragraph strong>{t("transfert.question")}</Typography.Paragraph>}
      >
        <Typography.Paragraph>{t("transfert.contact")}</Typography.Paragraph>

        <Card bodyStyle={{ display: "flex", flexFlow: "row" }} style={{ marginTop: "24px" }}>
          <Avatar
            size={30}
            src={`${transfertInfos?.transfert?.correspondent?.photoUrl}?access_token=${accesToken.token}`}
          />
          <div style={{ display: "flex", flexFlow: "column", marginLeft: "16px" }}>
            <Typography.Text strong>
              {transfertInfos?.transfert?.correspondent?.firstname}{" "}
              {transfertInfos?.transfert?.correspondent?.lastname}
            </Typography.Text>
            <Typography.Text>{transfertInfos?.transfert?.correspondent?.function}</Typography.Text>
          </div>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <a
            href={`mailto:${transfertInfos?.transfert?.correspondent?.email}`}
            target="blank"
            style={{ fontWeight: "bold" }}
          >
            <Glyph name="mail" style={{ color: colors.secondary_base, marginRight: "8px" }} />
            {transfertInfos?.transfert?.correspondent?.email}
          </a>
        </Card>
        {transfertInfos?.transfert?.correspondent?.mobilePhone && (
          <Card style={{ marginTop: "16px" }}>
            <a
              href={`tel:${transfertInfos?.transfert?.correspondent?.mobilePhone}`}
              style={{ fontWeight: "bold" }}
            >
              <Glyph
                name="phone_iphone"
                style={{ color: colors.secondary_base, marginRight: "8px" }}
              />
              {transfertInfos?.transfert?.correspondent?.mobilePhone}
            </a>
          </Card>
        )}

        {transfertInfos?.transfert?.correspondent?.phone && (
          <Card style={{ marginTop: "16px" }}>
            <a
              href={`tel:${transfertInfos?.transfert?.correspondent?.phone}`}
              style={{ fontWeight: "bold" }}
            >
              <Glyph name="phone" style={{ color: colors.secondary_base, marginRight: "8px" }} />
              {transfertInfos?.transfert?.correspondent?.phone}
            </a>
          </Card>
        )}
      </Drawer>

      <Modal
        title={
          <Typography.Title level={5} style={{ margin: "0", textAlign: "center" }}>
            {t("transfert.myMove")}
          </Typography.Title>
        }
        open={isModalOpen}
        footer={null}
        width={312}
        closable={false}
      >
        <Delivred />
        <Typography.Paragraph strong style={{ marginBottom: "16px", marginTop: "24px" }}>
          {t("transfert.welcome")}
        </Typography.Paragraph>
        <Typography.Paragraph style={{ marginBottom: "40px" }}>
          {t("transfert.findInfo")}
        </Typography.Paragraph>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="secondary"
            size="default"
            icon={<Glyph name="done" />}
            onClick={() => {
              setIsModalOpen(false);
              confirm();
            }}
          >
            {t("transfert.understand")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TransfertInfos;
