import { useKeycloak } from "@react-keycloak/web";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  message,
  Progress,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import { cond, equals, evolve, indexBy, omit, prop, T } from "ramda";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { uuidv4 } from "../../../../helpers/utils";
import DWMConnector from "../../../../services/api/DWMConnector";
import {
  deleteFile,
  deleteSection,
  updateSection,
  uploadFiles,
} from "../../../../services/redux/services/FacilitiesWS";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../DashboardFacilities.module.less";

const TextSection = ({ section, editable = false }) => {
  const { t } = useTranslation();

  return (
    <>
      {!section.value[0] && (
        <Typography.Paragraph type="secondary">
          {t("facilities.event.section.TEXT.helper")}
        </Typography.Paragraph>
      )}
      <Form.Item name={["value", 0]}>
        {editable ? (
          <Input.TextArea autoSize={{ minRows: 7, maxRows: 12 }} />
        ) : (
          <Typography.Text style={{ whiteSpace: "pre-line" }}>{section.value[0]}</Typography.Text>
        )}
      </Form.Item>
    </>
  );
};

const RadioSection = forwardRef(({ form, section, editable = false }, ref) => {
  const { t } = useTranslation();

  const [newItem, setNewItem] = useState("");

  useEffect(() => {
    setNewItem("");
  }, [editable]);

  useImperativeHandle(ref, () => ({
    handleAdd() {
      handleAdd();
    },
  }));

  const handleAdd = () => {
    if (!newItem.trim()) return;
    const _id = `temp-${new Date().valueOf()}`;
    form.setFieldValue(["items"], {
      ...form.getFieldValue(["items"]),
      [_id]: { _id, title: newItem.trim(), value: uuidv4() },
    });
    setNewItem("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleAdd();
    }
  };

  return (
    <>
      {!section.items.length && !editable && (
        <Typography.Paragraph type="secondary">
          {t("facilities.event.section.RADIO.helper")}
        </Typography.Paragraph>
      )}
      <Form.Item name={["value", 0]}>
        <Radio.Group style={{ width: "fit-content" }}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Space direction="vertical">
                {Object.values(getFieldValue(["items"])).map((i) => (
                  <Radio key={i._id} value={i.value}>
                    <Space>
                      <Form.Item noStyle name={["items", i._id, "title"]}>
                        {editable ? <Input size="small" /> : i.title}
                      </Form.Item>
                      {editable && (
                        <Button
                          size="small"
                          type="text"
                          icon={<Glyph name={"delete"} />}
                          onClick={() => {
                            form.setFieldValue(
                              ["items"],
                              omit([i._id], form.getFieldValue(["items"])),
                            );
                          }}
                        />
                      )}
                    </Space>
                    <Form.Item hidden name={["items", i._id, "_id"]} />
                    <Form.Item hidden name={["items", i._id, "value"]} />
                  </Radio>
                ))}
                {editable && (
                  <Radio disabled>
                    <Space>
                      <Input
                        autoFocus
                        size="small"
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onBlur={handleAdd}
                      />
                      {/* <Button size="small" type="link" onClick={handleAdd}>
                        {t("Add")}
                      </Button> */}
                    </Space>
                  </Radio>
                )}
              </Space>
            )}
          </Form.Item>
        </Radio.Group>
      </Form.Item>
    </>
  );
});

const ListSection = forwardRef(({ form, section, editable = false }, ref) => {
  const { t } = useTranslation();

  const [newItem, setNewItem] = useState("");

  useImperativeHandle(ref, () => ({
    handleAdd() {
      handleAdd();
    },
  }));

  const handleAdd = () => {
    if (!newItem.trim()) return;
    const _id = `temp-${new Date().valueOf()}`;
    form.setFieldValue(["items"], {
      ...form.getFieldValue(["items"]),
      [_id]: { _id, title: newItem.trim(), value: "" },
    });
    setNewItem("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleAdd();
    }
  };

  return !section.items.length && !editable ? (
    <Typography.Paragraph type="secondary">
      {t("facilities.event.section.LIST.helper")}
    </Typography.Paragraph>
  ) : (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => (
        <List
          bordered
          rowKey={"_id"}
          dataSource={Object.values(getFieldValue(["items"]))}
          renderItem={(i) => (
            <List.Item>
              <Space>
                {editable ? (
                  <>
                    <Form.Item noStyle name={["items", i._id, "title"]}>
                      <Input size="small" />
                    </Form.Item>
                    <Button
                      size="small"
                      type="text"
                      icon={<Glyph name={"delete"} />}
                      onClick={() => {
                        form.setFieldValue(["items"], omit([i._id], form.getFieldValue(["items"])));
                      }}
                    />
                  </>
                ) : (
                  i.title
                )}
              </Space>
              <Form.Item hidden name={["items", i._id, "_id"]} />
              <Form.Item hidden name={["items", i._id, "value"]} />
            </List.Item>
          )}
          footer={
            editable ? (
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Input
                  autoFocus
                  size="small"
                  value={newItem}
                  onChange={(e) => setNewItem(e.target.value)}
                  onKeyPress={handleKeyPress}
                  onBlur={handleAdd}
                />
                {/* <Button size="small" type="link" onClick={handleAdd}>
                  {t("Add")}
                </Button> */}
              </div>
            ) : null
          }
        />
      )}
    </Form.Item>
  );
});

const CheckboxSection = forwardRef(({ form, section, editable = false }, ref) => {
  const { t } = useTranslation();

  const [newItem, setNewItem] = useState("");

  useEffect(() => {
    setNewItem("");
  }, [editable]);

  useImperativeHandle(ref, () => ({
    handleAdd() {
      handleAdd();
    },
  }));

  const handleAdd = () => {
    if (!newItem.trim()) return;
    const _id = `temp-${new Date().valueOf()}`;
    form.setFieldValue(["items"], {
      ...form.getFieldValue(["items"]),
      [_id]: { _id, title: newItem.trim(), value: uuidv4() },
    });
    setNewItem("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleAdd();
    }
  };

  return (
    <>
      {!section.items.length && !editable && (
        <Typography.Paragraph type="secondary">
          {t("facilities.event.section.CHECKBOX.helper")}
        </Typography.Paragraph>
      )}
      <Form.Item name={["value"]}>
        <Checkbox.Group>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Space direction="vertical">
                {Object.values(getFieldValue(["items"])).map((i) => (
                  <Checkbox key={i._id} value={i.value}>
                    <Space>
                      <Form.Item noStyle name={["items", i._id, "title"]}>
                        {editable ? <Input size="small" /> : i.title}
                      </Form.Item>
                      {editable && (
                        <Button
                          size="small"
                          type="text"
                          icon={<Glyph name={"delete"} />}
                          onClick={() => {
                            form.setFieldValue(
                              ["items"],
                              omit([i._id], form.getFieldValue(["items"])),
                            );
                          }}
                        />
                      )}
                    </Space>
                    <Form.Item hidden name={["items", i._id, "_id"]} />
                    <Form.Item hidden name={["items", i._id, "value"]} />
                  </Checkbox>
                ))}
                {editable && (
                  <Checkbox disabled>
                    <Space>
                      <Input
                        autoFocus
                        size="small"
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onBlur={handleAdd}
                      />
                      {/* <Button size="small" type="link" onClick={handleAdd}>
                        {t("Add")}
                      </Button> */}
                    </Space>
                  </Checkbox>
                )}
              </Space>
            )}
          </Form.Item>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
});

const FileSection = ({ form, tab, section }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(0);
  const [progress, setProgress] = useState(0);
  const [removeLoading, setRemoveLoading] = useState([]);

  const makeUpload = () => {
    setIsUploading(true);
    setProgress(0);

    return dispatch(
      uploadFiles({
        tabId: tab._id,
        sectionId: section._id,
        files: fileList.map((f) => f.originFileObj),
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      }),
    )
      .unwrap()
      .then(() => {
        setFileList([]);
        form.resetFields();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleRemove = (fileName) => {
    setRemoveLoading((val) => [...val, fileName]);
    return dispatch(
      deleteFile({
        tabId: tab._id,
        sectionId: section._id,
        fileName,
      }),
    )
      .unwrap()
      .catch(() => {
        message.error(t("ErrorOccured"));
      })
      .finally(() => {
        setRemoveLoading((val) => val.filter((i) => i !== fileName));
      });
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Upload.Dragger
        multiple
        listType="picture"
        fileList={fileList}
        onChange={({ fileList }) => setFileList(fileList)}
        beforeUpload={() => false}
      >
        <Space direction="vertical" align="center" style={{ width: "100%", padding: 10 }}>
          <Glyph name={"backup"} className="secondary" />
          <Typography.Title level={5}>{t("facilities.files.upload.title")}</Typography.Title>
          <Typography.Paragraph type="secondary">
            {t("facilities.event.section.FILE.helper")}
          </Typography.Paragraph>
        </Space>
      </Upload.Dragger>
      {isUploading ? (
        <Progress percent={progress} status="active" />
      ) : !!fileList[0] ? (
        <Space direction="vertical" align="end" style={{ width: "100%" }}>
          <Button size="middle" type="primary" onClick={makeUpload}>
            {t("facilities.files.upload.save")}
          </Button>
        </Space>
      ) : null}
      {!!section.items.length && (
        <List
          bordered
          dataSource={section.items}
          renderItem={(file) => (
            <>
              <List.Item
                actions={[
                  <Form.Item key={"status"} noStyle name={["items", file._id, "data", "status"]}>
                    <Select
                      key="status"
                      bordered={false}
                      style={{ width: 150, textAlign: "left" }}
                      size="small"
                      options={[
                        { label: t("facilities.files.status.NEW"), value: "NEW" },
                        { label: t("facilities.files.status.PENDING"), value: "PENDING" },
                        { label: t("facilities.files.status.VALIDATED"), value: "VALIDATED" },
                        { label: t("facilities.files.status.DECLINED"), value: "DECLINED" },
                        { label: t("facilities.files.status.ABANDONED"), value: "ABANDONED" },
                      ]}
                    />
                  </Form.Item>,
                  <Button
                    key="remove"
                    size="small"
                    type="ghost"
                    onClick={() => handleRemove(file.title)}
                    disabled={removeLoading.includes(file.title)}
                    loading={removeLoading.includes(file.title)}
                  >
                    {t("facilities.files.remove")}
                  </Button>,
                ]}
              >
                <Button
                  className={styles.fileLink}
                  size="small"
                  type="link"
                  href={`${DWMConnector.baseURL}${file.value}&access_token=${keycloak.token}`}
                  target="_blank"
                  icon={<Glyph name="description" />}
                >
                  {file.title}
                </Button>
              </List.Item>
              <Form.Item hidden name={["items", file._id, "_id"]} />
              <Form.Item hidden name={["items", file._id, "title"]} />
              <Form.Item hidden name={["items", file._id, "value"]} />
            </>
          )}
        />
      )}
    </Space>
  );
};

const Section = ({ tab, section, editable }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ref = useRef();

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isEditing) form.resetFields();
  }, [isEditing, form]);

  useEffect(() => {
    if (section.editFirst) {
      setIsEditing(true);
    }
  }, [section.editFirst]);

  const handleSubmit = () => {
    if (ref.current?.handleAdd) ref.current?.handleAdd();
    const values = form.getFieldsValue();
    const data = {
      ...values,
      items: Object.values(values.items || []).map((i) => ({
        ...i,
        _id: /^temp-/.test(i._id) ? undefined : i._id,
      })),
    };

    return dispatch(updateSection({ tabId: tab._id, sectionId: section._id, ...data }))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        form.resetFields();
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const onDelete = () => {
    return dispatch(deleteSection({ tabId: tab._id, sectionId: section._id }))
      .unwrap()
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  const enableEdition = editable && isEditing;

  const SectionInput = cond([
    [equals("TEXT"), () => TextSection],
    [equals("RADIO"), () => RadioSection],
    [equals("LIST"), () => ListSection],
    [equals("CHECKBOX"), () => CheckboxSection],
    [equals("FILE"), () => FileSection],
    [T, () => null],
  ])(section.type);

  return (
    <Form
      form={form}
      initialValues={evolve({ items: indexBy(prop("_id")) }, section)}
      onFinish={handleSubmit}
    >
      <Row gutter={[20, 10]}>
        <Col flex={"auto"}>
          <Form.Item noStyle name={["title"]}>
            {enableEdition ? (
              <Input />
            ) : (
              <Typography.Title level={3}>{section.title}</Typography.Title>
            )}
          </Form.Item>
        </Col>
        {editable && (
          <Col flex={"none"}>
            <Space>
              <Button
                type="text"
                size="small"
                icon={<Glyph name={"edit"} />}
                onClick={() => setIsEditing(!isEditing)}
              />
              <Button
                type="text"
                size="small"
                icon={<Glyph name={"delete"} />}
                onClick={onDelete}
              />
            </Space>
          </Col>
        )}
        <Col span={24}>
          <SectionInput
            ref={ref}
            form={form}
            tab={tab}
            section={section}
            editable={enableEdition}
          />
          <Form.Item hidden name={["type"]} />
        </Col>
        <Col flex={"auto"} />
        <Form.Item noStyle shouldUpdate>
          {({ isFieldsTouched }) =>
            isFieldsTouched() || enableEdition ? (
              <Space>
                <Button
                  size="middle"
                  type="dashed"
                  onClick={() => {
                    form.resetFields();
                    setIsEditing(false);
                  }}
                >
                  {t("facilities.event.save_cancel")}
                </Button>
                <Button size="middle" htmlType="submit" type="primary">
                  {t("facilities.event.save")}
                </Button>
              </Space>
            ) : null
          }
        </Form.Item>
      </Row>
    </Form>
  );
};

export default Section;
