import { Card, Input, Space, Typography } from "antd";
import { isEmpty, isNil } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import { patchFilter, setFilter } from "../../../services/redux/services/FastbookingWS";
import useMap from "../../Common/Map/useMap";
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import styles from "./GuidMe.module.less";
import useDesignTokens from "../../../hook/useDesignTokens";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const GUTTER_SIZE = 8;

const GuidMeSearch = ({ setIsFloor }) => {
  const { selectedOnMap, centerOnPlaceId } = useMap();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  let siteId = localStorage.getItem("siteId");

  const campus = useSelector((state) => state.clientsWS.campus);
  const favoris = useSelector((state) => state.clientsWS.favoris);
  const lastSearches = useSelector((state) => state.clientsWS.lastSearch);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);
  const sites = useSelector((state) => state.userWS.userData || []);

  const site = siteId
    ? sites?.campus?.find((s) => s.id === parseInt(siteId))
    : sites?.campus?.find((s) => s.id === sites?.profile?.campusId);

  const [searchRoom, setSearch] = useState("");

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  useEffect(() => {
    if (selectedOnMap?.map?.findAndOrder?.placeId)
      history.push(`/indoorMapping/${selectedOnMap.map.findAndOrder.placeId}`);
  }, [history, selectedOnMap?.map?.findAndOrder?.placeId]);

  // Filtre par défaut
  useEffect(() => {
    if (isNil(filters?.floor)) {
      const floor = allRoomFastbooking?.rooms?.reduce(
        (acc, r) =>
          !acc
            ? campus?.mapData?.floors.find((f) => f.reference === r?.resources?.floorReference)
            : acc,
        null,
      );
      dispatch(
        patchFilter({
          floor: floor?.id,
        }),
      );
    }
  }, [allRoomFastbooking, campus?.mapData?.floors, dispatch, filters]);

  const searchResult = searchRoom
    ? campus?.mapData?.resources
        .filter((r) => r.features.searchable === true)
        .filter((value) => (searchRoom ? new RegExp(searchRoom, "i").test(value?.title) : true))
        .sort((a, b) => (a?.floorReference > b?.floorReference ? -1 : 1))
        .sort((a, b) => (a?.title > b?.title ? 1 : -1))
    : null;

  const Row = ({ style, index }) => (
    <Tile
      href={`/indoorMapping/${searchResult[index].map.findAndOrder.placeId}`}
      className={styles["tile--room"]}
      onClick={() => {
        goRoom(searchResult[index]);
        logEvent(analytics, "select_poi_from_search", {
          poi_name: searchResult[index]?.title,
        });
      }}
      style={{ ...style, height: style.height - GUTTER_SIZE }}
    >
      <Typography.Text
        strong
        style={{
          width: "200px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Glyph
          name={
            campus?.mapData?.categories?.find((m) => m.id === searchResult[index]?.categoryId)?.icon
          }
          style={{
            fontWeight: "normal",
            marginRight: "8px",
            verticalAlign: "-4px",
            color: colors.secondary_base,
          }}
        />
        {searchResult[index]?.title}
      </Typography.Text>
      <Typography.Text>
        {t("Floor")}{" "}
        {
          campus?.mapData?.floors.find(
            (floor) => floor.reference === searchResult[index].floorReference,
          )?.displayName
        }
        <Glyph name="navigate_next" style={{ verticalAlign: "-4px" }} />
      </Typography.Text>
    </Tile>
  );

  const goRoom = (value) => {
    setIsFloor(false);
    centerOnPlaceId(value?.map?.findAndOrder?.placeId);
    // const { FnoMapManager } = window;
    // FnoMapManager.sendEvent("zoomOnMapcontainer", { containerId: 87571 });
    dispatch(
      setFilter({
        ...filters,
        floor: campus.mapData.floors.find((floor) => floor.reference === value.floorReference)?.id,
      }),
    );
  };

  return (
    <Card
      title={
        <>
          <Typography.Title level={5}>{site?.title}</Typography.Title>
          <Typography.Text strong style={{ fontSize: "14px", color: colors.pending_light }}>
            {t("FindSpace")}
          </Typography.Text>
        </>
      }
      style={{ width: "35%", height: "fit-content", overflow: "auto", maxHeight: "100%" }}
    >
      <Space
        style={{ width: "100%", padding: "15px 0", paddingBottom: "0" }}
        direction="vertical"
        size="large"
      >
        <Input
          placeholder={t("FindSpace")}
          prefix={<Glyph name="search" />}
          onChange={handleSearch}
          value={searchRoom}
        />
        {!searchResult ? null : (
          <Space style={{ width: "100%" }} direction="vertical" size="small">
            {searchResult.length === 0 ? (
              <Typography.Text type="secondary">{t("NoResult")}</Typography.Text>
            ) : (
              <List height={200} itemCount={searchResult.length} itemSize={50}>
                {Row}
              </List>
            )}
          </Space>
        )}

        {!searchResult && (
          <>
            {!isEmpty(favoris) && (
              <Space style={{ width: "100%" }} direction="vertical" size="small">
                {favoris.length !== 0 && <Typography.Text>{t("Favoris")}</Typography.Text>}

                {favoris.map((favori) => (
                  <Tile
                    href={`/indoorMapping/${favori.map.findAndOrder.placeId}`}
                    onClick={() => {
                      goRoom(favori);
                      logEvent(analytics, "select_poi_from_search", {
                        poi_name: favori?.title,
                      });
                    }}
                    style={{ width: "100%", margin: "0" }}
                  >
                    <Typography.Text
                      strong
                      style={{
                        width: "220px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Glyph
                        name="meeting_room"
                        style={{
                          fontWeight: "normal",
                          marginRight: "8px",
                          verticalAlign: "-4px",
                          color: colors.secondary_base,
                        }}
                      />
                      {favori?.title}
                    </Typography.Text>
                    <Typography.Text>
                      {t("Floor")}{" "}
                      {
                        campus?.mapData?.floors.find(
                          (floor) => floor.reference === favori.floorReference,
                        )?.displayName
                      }
                      <Glyph name="navigate_next" style={{ verticalAlign: "-4px" }} />
                    </Typography.Text>
                  </Tile>
                ))}
              </Space>
            )}

            {!isEmpty(lastSearches) && (
              <Space style={{ width: "100%" }} direction="vertical" size="small">
                {lastSearches.length !== 0 && <Typography.Text>{t("LastSearch")}</Typography.Text>}
                {lastSearches.map((lastSearch) => (
                  <Tile
                    href={`/indoorMapping/${lastSearch.map.findAndOrder.placeId}`}
                    style={{ width: "100%", margin: "0" }}
                    onClick={() => {
                      goRoom(lastSearch);
                      logEvent(analytics, "select_poi_from_search", {
                        poi_name: lastSearch?.title,
                      });
                    }}
                  >
                    <Typography.Text
                      strong
                      style={{
                        width: "220px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Glyph
                        name="history"
                        className="icon--secondary"
                        style={{
                          fontWeight: "normal",
                          marginRight: "8px",
                          verticalAlign: "-4px",
                        }}
                      />
                      {lastSearch?.title}
                    </Typography.Text>
                    <Typography.Text>
                      {t("Floor")}{" "}
                      {
                        campus?.mapData?.floors.find(
                          (floor) => floor.reference === lastSearch.floorReference,
                        )?.displayName
                      }
                      <Glyph name="navigate_next" style={{ verticalAlign: "-4px" }} />
                    </Typography.Text>
                  </Tile>
                ))}
              </Space>
            )}
          </>
        )}
      </Space>
    </Card>
  );
};

export default GuidMeSearch;
