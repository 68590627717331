import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Button, Col, Comment, Input, List, message, Row, Space, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { postComment } from "../../../../services/redux/services/FacilitiesWS";

const Comments = ({ tab, comments = [] }) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const profile = useSelector((state) => state.userWS.userData.profile);

  const [comment, setComment] = useState("");

  const onSubmit = () => {
    return dispatch(postComment({ tabId: tab?._id, comment: comment.trim() }))
      .unwrap()
      .then(() => {
        setComment("");
      })
      .catch(() => {
        message.error(t("ErrorOccured"));
      });
  };

  return (
    <>
      <Typography.Title level={3}>{t("facilities.event.comments")}</Typography.Title>
      <Row gutter={[10, 5]}>
        <Col flex="none">
          <Avatar size={"large"} src={`${profile?.photoUrl}?access_token=${keycloak.token}`}>
            {(profile?.firstName || "").charAt(0).toUpperCase()}
            {(profile?.lastName || "").charAt(0).toUpperCase()}
          </Avatar>
        </Col>
        <Col flex="auto">
          <Input.TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 5 }}
            placeholder={t("facilities.event.comments.add") + "..."}
          />
        </Col>
        <Col span={24}></Col>
        <Col flex={"auto"}></Col>
        <Col flex={"none"}>
          <Space>
            <Button size="small" type="primary" onClick={onSubmit}>
              {t("facilities.event.comments.send")}
            </Button>
          </Space>
        </Col>
      </Row>
      {!!comments.length && (
        <List
          itemLayout="horizontal"
          dataSource={comments.map((c) => ({
            author: c.from?.displayName,
            avatar: (
              <Avatar src={`${c.from?.photoUrl}?access_token=${keycloak.token}`} alt="Han Solo">
                {(c.from?.displayName || "")
                  .split(" ")
                  .map((i) => i.charAt(0).toUpperCase())
                  .join("")}
              </Avatar>
            ),
            content: c.comment,
            datetime: moment(c.createdAt).format("LLL"),
          }))}
          renderItem={(item) => (
            <li>
              <Comment
                style={{ whiteSpace: "pre-line" }}
                actions={item.actions}
                author={item.author}
                avatar={item.avatar}
                content={item.content}
                datetime={item.datetime}
              />
            </li>
          )}
        />
      )}
    </>
  );
};

export default Comments;
