import { NavLink } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "ramda";
import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import useMap from "../../Common/Map/useMap";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { setFilter } from "../../../services/redux/services/FastbookingWS";
import Glyph from "../../Common/Glyph/Glyph";

import styles from "./Fastbooking.module.less";

const RoomDetails = ({ room, item, planning }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useDesignTokens();

  const { centerOnPlaceId } = useMap();

  const filters = useSelector((state) => state.fastbookingWS.filters);
  const campus = useSelector((state) => state.clientsWS.campus);

  const goRoom = (value) => {
    centerOnPlaceId(value?.map?.findAndOrder?.placeId);
    dispatch(
      setFilter({
        ...filters,
        floor: campus.mapData.floors.find((floor) => floor.reference === value.floorReference)?.id,
      }),
    );
  };

  return (
    <NavLink
      to={{
        pathname: `/fastBooking/${room?.map?.findAndOrder?.placeId}`,
        state: { room: item },
      }}
      exact
      className={styles["article--room"]}
      style={{ display: "flex", margin: "16px 0" }}
    >
      <div
        onClick={() => {
          goRoom(room);
          logEvent(analytics, "fast_booking_select_poi_from_list", {
            poi_name: room?.title,
          });
        }}
        className={styles["tile--fastbooking"]}
        style={{ marginLeft: "8px", display: "flex", flexFlow: "column" }}
      >
        <div style={{ marginLeft: "8px", display: "flex" }}>
          {room?.photos && !isEmpty(room?.photos) ? (
            <div className={styles["pictures"]}>
              <Image preview={false} width={110} height={80} src={room?.photos[0]} />
            </div>
          ) : null}
          <div style={{ marginLeft: "8px" }}>
            <Typography.Paragraph strong style={{ fontSize: "14px", marginBottom: "0" }}>
              {room?.title}
            </Typography.Paragraph>
            {!planning ? (
              item?.available && (
                <Typography.Paragraph style={{ fontSize: "14px" }}>
                  {item?.availableTime >= 60
                    ? `${t("Available")} > 1h`
                    : `${item?.availableTime} mins`}
                </Typography.Paragraph>
              )
            ) : moment(item?.startDate) > moment() ? (
              <Typography.Paragraph style={{ fontSize: "14px" }}>
                Début à {moment(item?.startDate).format("HH:mm")}
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph style={{ fontSize: "14px" }}>
                Jusqu'à {moment(item?.endDate).format("HH:mm")}
              </Typography.Paragraph>
            )}

            <div
              style={{
                content: "",
                display: "block",
                width: "30px",
                height: "1px",
                margin: "8px 0",
                backgroundColor: colors.grey_20,
              }}
            ></div>
            <Typography.Paragraph
              style={{ fontSize: "11px", marginBottom: "0", marginRight: "8px" }}
            >
              <Glyph name="group" style={{ verticalAlign: "-2px", marginRight: "2px" }} />
              {room?.features?.seats}
            </Typography.Paragraph>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "8px",
            marginTop: "8px",
          }}
        >
          {room?.equipments &&
            room?.equipments
              .reduce((acc, id) => {
                return [...acc, campus.mapData.equipments.find((e) => e.id === id)];
              }, [])
              .map((equipment, index) => (
                <div key={index}>
                  <Typography.Text style={{ fontSize: "11px", marginRight: "6px" }}>
                    {equipment?.title}
                  </Typography.Text>
                </div>
              ))}
        </div>
      </div>
    </NavLink>
  );
};

export default RoomDetails;
